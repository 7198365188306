import {proxy, subscribe} from '@bitstillery/common/lib/proxy'

import {copy_object, merge_deep} from './utils'

// Legacy prop store.
if (process.env.MSI_PACKAGE === 'discover') {
    window.prop = function(init_value) {
        const state = {value: init_value}
        return (new_value) => {
            if (new_value !== undefined) {
                state.value = new_value
            }

            return state.value
        }
    }
}

/**
 * A modelref is an Array with a referencable object to attach to,
 * and a key reference to the Object[key] value. This way we can
 * always make a reference to a value; even if it's a primitive
 * value; see https://developer.mozilla.org/en-US/docs/Glossary/Primitive
 *
 * The referencable object may also be a function, to allow referencing
 * properties from the legacy prop store. A modelref always looks like:
 * [object_reference, 'key']
 *
 * To deal with nested function props like supplier().purchase_manager().artkey(),
 * a validation reference with a path can be used like:
 * [this.supplier, 'purchase_manager.artkey']
 *
 * The new Proxy/Object store doesn't need this; intstead one would make a
 * more direct reference like [supplier.purchase_manager, 'artkey']
 */
export function modelref_adapter(_modelref):{modelvalue: any, modelref: any} {
    if (!Array.isArray(_modelref)) throw new Error('model reference must be an array')
    if (_modelref.length !== 2) throw new Error(`modelref format [ref, 'key'] incorrect: ${_modelref}`)
    let modelref, modelvalue

    // No reference (yet); abort check
    if (!_modelref[0]) {
        return {modelvalue: null, modelref: null}
    }

    if (typeof _modelref[0] === 'function') {
        // Path reference to a prop; e.g. [@lead, 'country_code']
        modelref = [_modelref[0](), _modelref[1]]
    } else {
        modelref = [_modelref[0], _modelref[1]]
    }

    // Simple key reference to a nested prop
    if (modelref[1].includes('.')) {
        // Path reference to a nested prop
        modelvalue = _modelref[1].split('.').reduce((prev, key) => prev[key](), modelref[0])
    } else {
        const targetref = modelref[0][modelref[1]]
        if (typeof targetref === 'function') {
            modelvalue = targetref()
        } else {
            modelvalue = targetref
        }
    }

    return {modelvalue, modelref}
}

export class Store {

    templates = {
        persistent: {},
        volatile: {},
        session: {},
    }

    volatile = {}
    state = proxy({})

    /**
     * Merge deep on object `state`, but only the key/values in `blueprint`.
     */
    blueprint(state, blueprint) {
        for (const key of Object.keys(blueprint)) {
            if (Object.prototype.hasOwnProperty.call(state, key)) {
                if ((!Array.isArray(blueprint[key]) && blueprint[key] !== null) && typeof blueprint[key] === 'object') {
                    // (!) Convention: The contents of a state key with the name 'lookup' is
                    // always one-one copied from the state, instead of being
                    // blueprinted per-key. This is to accomodate key/value
                    // lookups, without having to define each key in the
                    // state's persistent section.
                    if (key === 'lookup') {
                        blueprint[key] = JSON.parse(JSON.stringify(state[key]))
                    } else {
                        this.blueprint(state[key], blueprint[key])
                    }

                } else {
                    blueprint[key] = state[key]
                }
            }
        }
        return blueprint
    }

    /**
     * Get key from local storage. If the item does not exist or
     * cannot be retrieved, the default "{}" is returned.
     * **/
    get(key: string):string {
        try {
            return localStorage.getItem(key) || '{}'
        } catch (err) {
            return '{}'
        }
    }

    get_session_storage(key: string):string {
        try {
            return sessionStorage.getItem(key) || '{}'
        } catch (err) {
            return '{}'
        }
    }

    load(persistent, volatile, session = {}) {
        const restored_state = {
            session: this.get_session_storage('store'),
            store: this.get('store'),
        }

        this.templates = {
            persistent,
            session,
            volatile,
        }
        this.volatile = volatile

        try {
            restored_state.store = JSON.parse(restored_state.store)
            restored_state.session = JSON.parse(restored_state.session)
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(`[store] failed to parse store/session: ${err}`)
        }

        const store_state = merge_deep(copy_object(this.templates.persistent), copy_object(restored_state.store))
        // override with previous identity for a better version bump experience.
        store_state.identity = restored_state.store.identity
        let session_state

        if (!restored_state.session) {
            // eslint-disable-next-line no-console
            console.log('[store] loading session state from local store')
            session_state = merge_deep(copy_object(this.templates.session), store_state.session)
        } else {
            // eslint-disable-next-line no-console
            console.log('[store] restoring existing session state')
            session_state = merge_deep(copy_object(this.templates.session), copy_object(restored_state.session))
            merge_deep(store_state, {session: session_state})
        }

        const state = merge_deep(store_state, copy_object(this.volatile))
        Object.assign(this.state, state)
    }

    save() {
        this.set('store', this.blueprint(this.state, copy_object(this.templates.persistent)))
        this.set_session('store', this.blueprint(this.state.session, copy_object(this.templates.session)))
    }

    set(key: string, item: object):void {
        try {
            return localStorage.setItem(key, JSON.stringify(item))
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Cannot use Local Storage; continue without.', err)
        }
    }

    set_session(key: string, item: object):void {
        try {
            return sessionStorage.setItem(key, JSON.stringify(item))
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Cannot use Session Storage; continue without.', err)
        }
    }

}

/**
 * Allows watching a proxy object or a primitive value, when
 * used together with the key property.
 * @param proxyObj - A non-primitive state property
 * @param key_or_callback - Used as key when a String, otherwise
 * @param callback
 * @returns
 */
export function watch(proxy_obj, key_or_callback:string|Function, callback?:Function) {
    if (typeof key_or_callback === 'string') {
        return subscribe(proxy_obj, function(new_value, old_value, subscribe_key) {
            if (key_or_callback === subscribe_key) callback(new_value, old_value)
        })
    } else if (typeof key_or_callback === 'function') {
        return subscribe(proxy_obj, key_or_callback)
    }
}
