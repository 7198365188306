import m from 'mithril'
import {Spinner} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {$t, api, notifier} from '@bitstillery/common/app'

import {$m} from '@/app'

export class AuthInvite extends MithrilTsxComponent<any> {

    async oncreate() {
        const redirect_to = m.route.param('redirect')

        const data = {code: m.route.param('code')}
        const {success, result} = await api.post('invite.use', data) as any
        if (!success) {
            notifier.notify($t('notifications.invite_failure'), 'danger', 7000)
            m.route.set(redirect_to || '/')
            return
        }
        $m.identity.new_token(result.token)
        m.route.set(redirect_to || '/')
    }

    view() {
        return <Spinner/>
    }

}
