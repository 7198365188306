import m from 'mithril'
import {Icon} from '@bitstillery/common/components'
import {Amount, SubAmount} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils.ts'

interface AmountUnitAttrs {
    case_amount_excise?: number // only if should_include_excise
    should_include_excise?: boolean

    display_currency?: string
    rate: number

    case_number_of_bottles: number
    case_amount: number
    currency: string
    excise: number
    origin: {
        case_amount: number
        currency: string
        case_amount_excise?: number // only if should_include_excise
    }
    className?: string
}

export class AmountUnit extends MithrilTsxComponent<AmountUnitAttrs> {

    view(vn: m.Vnode<AmountUnitAttrs>) {
        return (
            <div className={classes(vn.attrs.className, 'c-amount-unit')}>
                <div className="amount-bottle">
                    <Icon
                        name="bottle"
                        size="s"
                        type="unset"
                    />
                    {(() => {
                        if (vn.attrs.currency) {
                            if (vn.attrs.should_include_excise) {
                                return <div className="amount-excise">
                                    <Amount
                                        amount={vn.attrs.case_amount_excise / vn.attrs.case_number_of_bottles}
                                        currency={vn.attrs.currency}
                                        rate={vn.attrs.rate}
                                    />
                                    <SubAmount
                                        amount={vn.attrs.case_amount / vn.attrs.case_number_of_bottles}
                                        currency={vn.attrs.currency}
                                        label="Ex Duty"
                                        rate={vn.attrs.rate}
                                    />
                                </div>
                            } else {
                                return <Amount
                                    amount={vn.attrs.case_amount / vn.attrs.case_number_of_bottles}
                                    currency={vn.attrs.currency}
                                    display_currency={vn.attrs.display_currency}
                                    excise={vn.attrs.excise}
                                    rate={vn.attrs.rate}
                                />
                            }
                        } else {
                            const bottle_amount = vn.attrs.case_amount * vn.attrs.case_number_of_bottles
                            return bottle_amount ? bottle_amount : '-'
                        }
                    })()}
                </div>
                {(vn.attrs.currency && vn.attrs.origin && vn.attrs.origin.currency !== vn.attrs.currency) && <div className="alt-currency">
                    <Icon
                        name="money"
                        size="s"
                    />

                    {vn.attrs.should_include_excise ? <div className="amount-excise">
                        <Amount
                            amount={vn.attrs.origin.case_amount_excise / vn.attrs.case_number_of_bottles}
                            currency={vn.attrs.origin.currency}
                            excise={vn.attrs.excise}
                            rate={vn.attrs.rate}
                        />
                        <SubAmount
                            amount={vn.attrs.origin.case_amount / vn.attrs.case_number_of_bottles}
                            currency={vn.attrs.origin.currency}
                            label="Ex Duty"
                            rate={vn.attrs.rate}
                        />
                    </div> : <Amount
                        amount={vn.attrs.origin.case_amount / vn.attrs.case_number_of_bottles}
                        currency={vn.attrs.origin.currency}
                        display_currency={vn.attrs.display_currency}
                        excise={vn.attrs.excise}
                        rate={vn.attrs.rate}
                    />}
                </div>}

                <div className="amount-case">
                    <Icon
                        name="case"
                        size="s"
                        type="unset"
                    />

                    {(() => {
                        if (vn.attrs.currency) {
                            if (vn.attrs.should_include_excise) {
                                return <div className="amount-excise">
                                    <Amount
                                        amount={vn.attrs.case_amount_excise}
                                        currency={vn.attrs.currency}
                                        rate={vn.attrs.rate}
                                    />
                                    <SubAmount
                                        amount={vn.attrs.case_amount}
                                        currency={vn.attrs.currency}
                                        label="Ex Duty"
                                        rate={vn.attrs.rate}
                                    />
                                </div>
                            } else {
                                return <Amount
                                    amount={vn.attrs.case_amount}
                                    currency={vn.attrs.currency}
                                    display_currency={vn.attrs.display_currency}
                                    excise={vn.attrs.excise}
                                    rate={vn.attrs.rate}
                                />
                            }
                        } else {
                            return vn.attrs.case_amount ? vn.attrs.case_amount : '-'
                        }
                    })()}
                </div>

                {(vn.attrs.currency && vn.attrs.origin && vn.attrs.origin.currency !== vn.attrs.currency) && <div className="alt-currency">
                    <Icon
                        name="money"
                        size="s"
                        type="unset"
                    />
                    {vn.attrs.should_include_excise ? <div className="amount-excise">
                        <Amount
                            amount={vn.attrs.origin.case_amount_excise}
                            currency={vn.attrs.origin.currency}
                            rate={vn.attrs.rate}
                        />
                        <SubAmount
                            amount={vn.attrs.origin.case_amount}
                            currency={vn.attrs.origin.currency}
                            label="Ex Duty"
                            rate={vn.attrs.rate}
                        />
                    </div> : <Amount
                        amount={vn.attrs.origin.case_amount}
                        currency={vn.attrs.origin.currency}
                        display_currency={vn.attrs.display_currency}
                        excise={vn.attrs.excise}
                        rate={vn.attrs.rate}
                    />}
                </div>}
            </div>
        )
    }
}
