import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {$t} from '@bitstillery/common/app'

import {OrderItems} from './order_items/order_items'

import {$s} from '@/app'

export class FinalizeCart extends MithrilTsxComponent<any> {

    oninit() {
        $s.context.title = $t('page.title.checkout-2')
    }

    view(_vn:m.Vnode<any>) {
        return <OrderItems filter="all" />
    }
}
