import {logger} from '@bitstillery/common/app'

type level_type = 'info' | 'warning' | 'danger' | 'success'

export interface NotificationData {
    icon: string,
    message: string,
    id: number,
    level: level_type ,
}

/**
 * Class that manages the Notifications that can be displayed.
 */
export class Notifier {

    id = 1
    notifications: NotificationData[]

    load(notifications: NotificationData[]) {
        this.notifications = notifications
    }

    notify(message: string, level: level_type = 'info', timeout?: number, icon?: string, id?: number) {
        logger.debug(`notifier: ${message}`)
        if (!timeout) {
            if (level === 'danger') {
                timeout = 7000
            } else {
                timeout = 3500
            }
        }
        if (!icon) {
            icon = level
        }
        if (!id) {
            this.id += 1
        }
        else {
            // Reuse the notification when it is still active.
            const notification = this.notifications.find((i) => i.id === id)
            if (notification) {
                notification.message = message
                return notification.id
            }
        }

        const notification = {
            icon,
            id: id ? id : this.id,
            level,
            message,
        }
        this.notifications.push(notification)
        if (timeout !== -1) {
            setTimeout(() => {
                this.notifications.splice(this.notifications.findIndex(i => i.id === notification.id), 1)

            }, timeout)

        }

        return this.id
    }
}
