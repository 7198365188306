{
    "added": "Aggiunto",
    "back": "Indietro",
    "bar_categories": {
        "title": "Categorie"
    },
    "cart": {
        "active": "Carrelli attivi",
        "booked": {
            "help": "Questi articoli sono confermati e prenotati dal cliente e quindi riservati da noi. Vengono visualizzati qui solo a titolo di riferimento. Vi preghiamo di contattarci se desiderate apportare modifiche."
        },
        "checkout_button": "Carrello",
        "checkout_header": "Carrello",
        "checkout_one": "Carrello ({{count}})",
        "checkout_order": "Ordine alla carrello",
        "checkout_order_id": "Ordine alla carrello {{id}}",
        "checkout_other": "Carrello ({{count}})",
        "checkout_tip": "Checkout - per verificare l'ordine in corso",
        "checkout_zero": "Carrello",
        "content_one": "Nel carrello è presente il prodotto {{count}}.",
        "content_other": "Hai inserito nel carrello {{count}} {{units}} .",
        "empty_booked_desc": "I nuovi articoli aggiunti vengono visualizzati qui per essere esaminati. Una volta pronti, qui è anche possibile finalizzare l'ordine e prenotare gli articoli.",
        "empty_booked_title": "Non ci sono articoli selezionati di recente (ancora)",
        "empty_desc": "I prodotti aggiunti all'ordine vengono visualizzati qui per essere esaminati. Una volta pronti, qui è anche possibile finalizzare l'ordine e prenotare gli articoli.",
        "empty_title": "Aggiungi prodotti",
        "error_api": "Si è verificato un errore nel carrello.",
        "error_help": {
            "api": "Ricarica la pagina e/o rimuovi il prodotto che presenta problemi. Contatta l'assistenza se il problema persiste.",
            "less_available": "Aggiornare il carrello ridurrà l'importo del prodotto alla quantità disponibile.",
            "minimum_quantity": "L'aggiornamento del carrello aumenterà l'importo del prodotto fino alla quantità minima dell'ordine.",
            "price_up": "Aggiornare il carrello aumenterà l'articolo del carrello al prezzo corretto",
            "unavailable": "Aggiorna il carrello rimuoverà questo prodotto dal carrello"
        },
        "error_less_available": "Meno disponibile",
        "error_minimum_quantity": "Si applica la quantità minima d'ordine",
        "error_ok": "Prodotti pronti a procedere",
        "error_one": "Questo prodotto richiede la tua attenzione",
        "error_other": "Questi prodotti richiedono la tua attenzione",
        "error_price_up": "Il prezzo del prodotto è stato aumentato",
        "error_unavailable": "Non disponibile",
        "issues": "Aggiornamenti del carrello",
        "issues_action": "Affrontate le seguenti questioni relative ai prodotti per poter effettuare il check-out del vostro carrello.",
        "issues_none": "Il tuo carrello è pronto",
        "less_available_action": "Adattare l'importo del carrello di conseguenza o rimuovere il prodotto dal carrello per continuare il checkout.",
        "less_available_one": "Al momento è disponibile solo {{count}} {{units}} .",
        "less_available_other": "Al momento è disponibile solo {{count}} {{units}} .",
        "min_quantity": "La quantità minima ordinabile per questo prodotto è {{count}} {{units}} .",
        "min_quantity_action": "Adattare l'importo del carrello di conseguenza o rimuovere il prodotto dal carrello per continuare il checkout.",
        "min_quantity_confirm": "Confermare l'importo minimo dell'ordine: {{count}} {{units}}",
        "minus_all": "Rimuovere {{product}} dal carrello.",
        "minus_one": "Rimuovi {{count}} {{units}} dal tuo carrello.",
        "minus_other": "Rimuovi {{count}} {{units}} dal tuo carrello.",
        "new": "Carrello vuoto",
        "new_key": "Sono una traduttrice",
        "new_quantity_confirmed": "Confermata la nuova quantità.",
        "panel": {
            "header": {
                "cart": "Il tuo carrello ({{id}})",
                "cart_new": "Il tuo carrello"
            }
        },
        "plus_all": "Aggiungi {{product}} al tuo carrello",
        "plus_one": "Aggiungi {{count}} {{units}} al carrello",
        "plus_other": "Aggiungi {{count}} {{units}} al carrello",
        "price_changed": "Il prezzo di questo prodotto è cambiato da {{old_price}} {{currency}} a {{new_price}} {{currency}} .",
        "price_changed_action": "Confermare il prezzo modificato o rimuovere il prodotto dal carrello per continuare il checkout.",
        "price_confirm": "Conferma i nuovi prezzi.",
        "price_confirmed": "Confermato il nuovo prezzo.",
        "price_up_agree_help_one": "Aggiorna il carrello con il prezzo aumentato",
        "price_up_agree_help_other": "Aggiorna il carrello con i prezzi aumentati",
        "price_up_agree_one": "Accetto il prezzo maggiorato",
        "price_up_agree_other": "Accetto i prezzi maggiorati",
        "product_amount": "Il vostro carrello contiene ora {{count}} {{units}} di {{product}}",
        "product_removed": "Il prodotto {{product}} è stato rimosso dal carrello",
        "rounded_one": "Il numero è arrotondato a {{count}} caso ({{bottles}} bottiglie)",
        "rounded_other": "Il numero è arrotondato a {{count}} casse ({{bottles}} bottiglie).",
        "soi": {
            "booked_title": "Riservato a te",
            "cart_title": "Il contenuto del tuo carrello ({{count}} prodotti)"
        },
        "stock_one": "Possiamo fornirti {{count}} {{units}} ",
        "stock_other": "Possiamo fornirti {{count}} {{units}} ",
        "switched": "Sei appena passato al carrello {{cart}}",
        "switched_new": "Siete appena passati a un nuovo carrello",
        "total_bottles": "Totale bottiglie",
        "total_cases": "Totale casi",
        "total_price": "Prezzi totali",
        "unavailable": "Purtroppo questo prodotto non è più disponibile.",
        "unavailable_action": "Rimuovere il prodotto dal carrello per continuare il checkout.",
        "yours": "Il tuo carrello"
    },
    "checkout": {
        "booked": {
            "types": {
                "arriving": "Articoli in arrivo",
                "stock": "Da Stock",
                "tbo": "Da ordinare"
            }
        },
        "cart": "Carrello",
        "comment_delete_tip": "Cancellare il commento",
        "comment_store": "Salva il commento",
        "comment_stored": "Commento memorizzato",
        "comment_title": "Aggiungi un commento per questo prodotto",
        "delivery": {
            "asap": "Il prima possibile",
            "date": "In una data specifica"
        },
        "status": {
            "book": "Prenotare i prodotti; finalizzare l'ordine in un secondo momento",
            "book_help": "I prodotti sono prenotati per voi, potete aggiungere altri prodotti in un secondo momento e finalizzare l'ordine.",
            "confirm_cart_update_help": "Tutti gli aggiornamenti del carrello verranno applicati automaticamente",
            "finalize": "Finalizzare l'ordine",
            "finalize_help": "L'ordine diventa definitivo e noi lo controlliamo; potete già scaricare la fattura proforma.",
            "step0_title": "Il tuo carrello",
            "step1_button": "Finalizza il carrello",
            "step1_button_fix": "Aggiorna il carrello",
            "step1_title": "Verifica il carrello",
            "step2_button": "Effettua l'ordine",
            "step2_button_book": "Prodotti del libro",
            "step2_title": "Carrello"
        },
        "total": "Totale",
        "vat": "IVA",
        "vouchers": {
            "label": "Buoni"
        }
    },
    "dashboard": {
        "widgets": {
            "demo_user": {
                "context_1": "Con questo conto demo è possibile consultare le scorte, vedere i prezzi e scaricare i listini. È possibile aggiornare il proprio account contattando il responsabile delle vendite. Con il vostro account personale potrete effettuare ordini e contrassegnare gli articoli come preferiti.",
                "context_2": "Qualsiasi ordine con questo conto demo non sarà gestito da noi. Quindi non vi verrà addebitato alcun costo. Sentitevi liberi di sperimentare! Per qualsiasi domanda, non esitate a contattare il vostro responsabile vendite. I dettagli si trovano nell'angolo in basso a sinistra.",
                "title": "Benvenuto nel nostro portale, è bello che tu sia passato di qui!"
            },
            "latest_orders": {
                "activate_order_tip": "Attivare l'ordine",
                "active_order": "Ordine attuale",
                "stock_complete": "Tutti gli articoli sono disponibile in magazzino"
            },
            "tips": {
                "title": "Suggerimenti e trucchi"
            }
        }
    },
    "demo": {
        "dialog": {
            "button_ok": "Va bene, allora!",
            "checkout_context_1": "L'avete trovato! La pagina con i prodotti che si desidera ordinare e dove è possibile confermare l'ordine non funziona con un conto demo. È necessario un account completo. Per farlo, è necessario un account reale, che il vostro responsabile vendite può fornirvi.",
            "checkout_context_2": "E per favore, fateci sapere cosa ne pensate di questo portale. In questo modo potremo migliorarlo per voi (e per noi). Per qualsiasi domanda, contattate il vostro responsabile delle vendite. I dettagli si trovano nell'angolo in basso a sinistra.",
            "checkout_title": "Pagina di pagamento"
        },
        "favorites_disabled": "Purtroppo non è possibile memorizzare i preferiti con il conto demo. Per qualsiasi domanda, contattate il vostro responsabile delle vendite. I dettagli si trovano nell'angolo in basso a sinistra."
    },
    "email_address": "Indirizzo e-mail",
    "excise": {
        "excluding": "Esclusa l'accisa",
        "including": "Accise escluse"
    },
    "filters": {
        "pricelist": {
            "alcohol_format": "Formato dell'alcol",
            "alcohol_format_help": "Come vuoi formattare la percentuale di alcol?",
            "cancel_download": "Annullamento",
            "download": "Scarica il listino prezzi",
            "download_tip": "Esportazione del listino prezzi in formato Excel (.xlsx)",
            "download_tip_busy": "Il listino prezzi è attualmente in fase di composizione...",
            "filename": "esportazione del listino prezzi",
            "products_selected": "Prodotti: {{count}}/{{total}}",
            "select_all": "Seleziona tutti i prodotti...",
            "volume_format": "Formato del volume",
            "volume_format_help": "Come vuoi formattare il volume della bottiglia?"
        },
        "reset_one": "Azzeramento del filtro",
        "reset_other": "Azzeramento dei filtri",
        "selected_one": "{{count}} selezionato",
        "selected_other": "{{count}} selezionato",
        "title": {
            "alcohol": "Gradi di alcol",
            "availability": "Disponibilità",
            "offertype": "Tipo di offerta",
            "packaging": "Imballaggio",
            "refill": "Ricaricabile",
            "search": "Ricerca",
            "volume": "Volume"
        },
        "types": {
            "availability": {
                "eta": "Arrivo previsto: {{period}}",
                "purchase": "In arrivo",
                "stock": "Disponibile in magazzino",
                "tbo": "Da ordinare"
            },
            "offertype": {
                "FAVORITES": "I preferiti",
                "NEW_ARRIVALS": "Nuovi arrivi",
                "SPECIALS": "Offerte speciali"
            },
            "products": "Prodotti",
            "refill": {
                "nonref": "Non ricaricabile",
                "ref": "Ricaricabile"
            }
        }
    },
    "help": {
        "explain": "Spiega questa pagina"
    },
    "loading": "Caricamento",
    "location": "Posizione",
    "messroom": {
        "description": "Si tratta di un'impostazione una tantum per ottimizzare l'esperienza di acquisto nel nostro portale, mentre vi offriamo le informazioni più recenti su prezzi e disponibilità. Vi preghiamo di attendere finché non avremo preparato il vostro account sul portale. Grazie per la pazienza!",
        "step0": "Preparazione del conto",
        "step1": "Aggiornamento delle disponibilità",
        "step2": "Generazione dei prezzi",
        "steps_completed": "Tutto fatto! Ora verrete reindirizzati al portale...",
        "steps_processing": "Si prega di attendere mentre prepariamo il suo account sul portale...",
        "team": "Squadra {{team}}",
        "title": "Configurazione del portale una tantum"
    },
    "my_portal": {
        "news": "Notizie",
        "privacy": "Privacy",
        "profile": "Il mio profilo",
        "promotions": "Promozioni",
        "security": {
            "label": "Sicurezza",
            "notifications": {
                "current_password_invalid": "La tua password attuale non è valida",
                "new_password_invalid": "La tua nuova password non è valida",
                "password_updated": "La tua password è stata aggiornata; effettua nuovamente il login."
            },
            "password": {
                "current": "Password corrente",
                "current_help": "Per aggiornare la password attuale è necessario che la password sia aggiornata.",
                "new": "Nuova password",
                "new_help": "La password deve essere di almeno 8 caratteri",
                "repeat": "Ripetere la password",
                "repeat_help": "Dopo aver modificato la password, vi verrà chiesto di accedere nuovamente."
            },
            "save_button": "Modifica della password"
        },
        "settings": {
            "label": "Impostazioni",
            "price_quantity": "Prezzi e quantità",
            "price_quantity_help": "Utilizzate i prezzi delle bottiglie o delle casse in tutto il portale",
            "save": "Salva le impostazioni",
            "saved_profile": "Il vostro profilo è stato salvato",
            "saved_profile_error": "Non è stato possibile aggiornare il tuo profilo, riprova più tardi",
            "saved_settings": "Le impostazioni sono state salvate"
        }
    },
    "no": "No",
    "notifications": {
        "cart_api_error": "Siamo spiacenti, si è verificato un problema imprevisto durante l'aggiornamento del carrello. Prova a ricaricare il portale. Ci stiamo già lavorando, ma non esitare a contattare l'assistenza se il problema persiste.",
        "comment_deleted": "Commento rimosso",
        "comment_stored": "Commento memorizzato",
        "invite_failure": "Il tuo codice invito è scaduto o non è più valido.",
        "messroom_ready": "Il vostro portale è pronto per essere utilizzato!",
        "news": {
            "title": "Aggiornamento sulle novità!"
        },
        "order_booked": "I tuoi prodotti in ordine ({{id}}) sono riservati da noi!",
        "order_confirmed": "Il tuo ordine ({{id}}) è confermato da noi!",
        "order_failure": "Si è verificato un problema durante il caricamento dell'ordine {{order}}; Riprova più tardi",
        "order_issue": "Si è verificato un problema con uno o più articoli dell'ordine; si prega di controllare le avvertenze dell'ordine.",
        "order_repeated": "Le righe dell'ordine {{order}} sono state copiate nel tuo carrello",
        "promotion_redeemed": "Congratulazioni! Hai appena riscattato la tua promozione!",
        "removed_product": "Il prodotto {{product}} è stato rimosso dal carrello",
        "session_expired": "La sessione del portale è scaduta; purtroppo è necessario effettuare nuovamente il login."
    },
    "offer": {
        "arriving_soon": "In arrivo a breve",
        "best_deal": "Miglior offerta",
        "delivery_week_one": "{{count}} settimana",
        "delivery_week_other": "{{count}} settimane",
        "download_offer": "Scarica l'offerta",
        "expired": {
            "description": "Purtroppo l'offerta personale che stai visualizzando non è più valida. Consulta la nostra pagina delle scorte per scoprire altre offerte.",
            "title": "Offerta scaduta"
        },
        "latest_orders": "I vostri ultimi ordini",
        "latest_orders_empty": "Gli ordini di vendita più recenti vengono visualizzati qui",
        "loading_products": "Recupero dei prodotti...",
        "my_favourites": "I miei preferiti",
        "my_favourites_add": "Aggiungi ai miei preferiti",
        "my_favourites_help": "Contrassegnare gli articoli come preferiti utilizzando l'icona del cuore. Questi prodotti appariranno qui e sotto la voce di menu \"I miei preferiti\".",
        "my_favourites_remove": "Rimuovi dai preferiti",
        "my_favourites_tip": "I miei preferiti - una selezione dei vostri prodotti preferiti",
        "name": "Offerta",
        "new_arrivals": "Nuovi arrivi",
        "new_arrivals_tip": "Nuovi arrivi - i nostri prodotti più recenti; sono appena arrivati o arriveranno presto al piano.",
        "newest_arrivals": "I nostri ultimi arrivi",
        "no_personal": "Nessuna offerta personale al momento",
        "personal": "Le vostre offerte personali",
        "personal_not_found": "Purtroppo questa offerta speciale non è più disponibile; hai visto le altre offerte?",
        "personal_valid": "Un'offerta personale per te: '{{text}}'\n",
        "popup": {
            "continue": "Al cruscotto",
            "show_offer": "Mostra offerta"
        },
        "show_all": "Mostra tutti",
        "special": "Offerte speciali",
        "special_count": "Offerte speciali",
        "special_tip": "Offerte speciali - prodotti con un prezzo speciale, valido solo per un periodo di tempo limitato",
        "valid_until": "Valido fino a {{date}}",
        "view_offer": "Visualizza l'offerta"
    },
    "order": {
        "agree_t1": "Ha prodotti con stato doganale T1",
        "agree_tos": "Sono d'accordo con i <a href={{link}} target=\"_blank\">termini e condizioni</a>",
        "completion": "Completamento dell'ordine",
        "confirmed": {
            "description": "Ora tocca a noi: faremo in modo che la merce arrivi a te. Nel frattempo, ti invitiamo a tenere d'occhio il nostro magazzino per scoprire altre fantastiche offerte. Non esitare a contattarci per qualsiasi domanda sul tuo ordine.",
            "subtitle": "L'ordine è stato effettuato con successo",
            "title": "Congratulazioni!"
        },
        "delivery": "Preferenza di consegna",
        "delivery_date": "Data di consegna",
        "details": {
            "actions": {
                "download_delivery_note": "Scarica la bolla di consegna",
                "download_invoice": "Scarica la fattura",
                "download_order_note": "Scarica la nota d'ordine",
                "download_proforma": "Scarica il Proforma",
                "repeat_order": "Al carrello",
                "repeat_order_tip": "Copia il contenuto dell'ordine nel carrello. Questa operazione può essere effettuata solo per i prodotti e le quantità attualmente disponibili in magazzino."
            },
            "additional": {
                "description": "Descrizione",
                "price_unit": "Price per unit",
                "quantity": "Quantità",
                "type": "Tipo"
            },
            "additionals": "Articoli e sconti aggiuntivi",
            "amount": "Importo",
            "booked": "Prenotato",
            "booked_context": "Questi articoli sono confermati e prenotati dal cliente e quindi riservati da noi.",
            "cart": "Carrello",
            "cases": "Casi",
            "comment": "Come",
            "created_by": "Creato da",
            "credit": {
                "credited_from": "Accreditato da",
                "label": "Voci di credito"
            },
            "date": "Data",
            "empty": "Il tuo ordine non ha ancora prodotti",
            "empty_context": "Aggiungete i prodotti al vostro carrello dalla nostra panoramica delle scorte.",
            "invoice_date": "Data della fattura",
            "invoice_number": "Numero di fattura",
            "items": {
                "arriving": "In arrivo a breve",
                "basket_unconfirmed": "Articoli del carrello (non confermati)",
                "basket_unconfirmed_help": "Questi articoli necessitano ancora di una conferma da parte vostra prima di poter essere prenotati. Confermare l'ordine per procedere.",
                "stock": "Da Stock",
                "tbo": "Da ordinare"
            },
            "order_number": "Numero d'ordine",
            "order_status": "Stato dell'ordine",
            "price": "Prezzo",
            "product": "Prodotto",
            "specs": "Specifiche tecniche",
            "status": "Stato",
            "subtotal": "Subtotale",
            "total_value": "Valore totale",
            "vat": "IVA"
        },
        "empty_call_to_action": "Sfogliare lo stock",
        "loading_orders": "Caricamento degli ordini...",
        "new": "Nuovo",
        "tos": "Termini e condizioni"
    },
    "orders": {
        "invoice_date": "Fatturato il",
        "invoice_number": "Numero di fattura",
        "no_orders": "Non hai ancora ordini",
        "portal_status": {
            "Cancelled": "Annullato",
            "Confirmed": "Confermato",
            "Finalized": "Finalizzato",
            "Invoiced": "Fatturato",
            "New": "Nuovo",
            "Pending": "In attesa"
        },
        "status": "Stato dell'ordine",
        "status_active": "Attivo",
        "status_open": "Aperto"
    },
    "page": {
        "browse_stock_tip": "Sfogliare lo stock - panoramica di tutti i nostri prodotti",
        "dashboard": "Cruscotto",
        "dashboard_tip": "Dashboard - una panoramica dei nostri migliori affari e offerte",
        "latest_news": "Ultime notizie",
        "my_orders": "I miei ordini",
        "my_orders_tip": "I miei ordini: una panoramica di tutti i vostri ordini",
        "my_portal": "Il mio portale",
        "my_portal_tip": "Il mio portale - profilo, notizie e promozioni",
        "my_portal_tip_promotion": "Il mio portale - C'è una promozione attiva!",
        "subtitle": {
            "login": "Accedi per continuare...",
            "privacy": "Vendiamo alcolici, non i vostri dati!",
            "redeem": "Registratevi con il vostro codice unico...",
            "registration": "Ci parli della sua azienda...",
            "registration_completed": "Ora è possibile chiudere questa finestra..."
        },
        "title": {
            "checkout": "Cassa",
            "checkout-0": "Acquista i prodotti",
            "checkout-1": "Verifica il carrello",
            "checkout-2": "Finalizzare il carrello",
            "login": "Welcome to {{vendor}}!",
            "login_slug": "Accesso",
            "offer": {
                "my_favourites": "I miei preferiti",
                "new_arrivals": "Nuovi arrivi",
                "personal_offer": "Offerta personale - fino a {{date}}",
                "personal_offer_invalid": "Offerta personale scaduta",
                "special_offers": "Offerte speciali"
            },
            "order": "Ordine {{id}}",
            "privacy": "La nostra politica sulla privacy",
            "redeem": "Piacere di vederti!",
            "registration": "Registrazione",
            "registration_completed": "Grazie per la vostra candidatura!"
        }
    },
    "product": {
        "alcohol_percentage": "Percentuale di alcol",
        "best_before": "Meglio prima",
        "bottle_gtin": "Bottiglia GTIN",
        "bottle_volume": "Volume della bottiglia",
        "bottles": "Bottiglie",
        "bottles_per_case": "Bottiglie per cassa",
        "cases": "Casi",
        "category": "Categoria",
        "country_of_origin": "Paese di origine",
        "customs_status": "Stato doganale",
        "features": "Caratteristiche",
        "minimum_order_quantity": "Quantità minima ordinabile",
        "refill_status": "Ricaricabile",
        "stock_origin": "Questo prodotto proviene da {{country}}",
        "stock_origin_count_one": "Possiamo fornirti {{count}} {{units}} da questa origine",
        "stock_origin_count_other": "Possiamo fornirti {{count}} {{units}} da questa origine"
    },
    "profile": {
        "company": "La vostra azienda",
        "email_support": "Indirizzo e-mail dell'assistenza",
        "family_name": "Cognome",
        "first_name": "Nome",
        "mobile_phone_number": "Numero di cellulare",
        "new_password": "Scegliere una password",
        "new_password_help": "La nuova password che si desidera impostare",
        "new_password_placeholder": "Scegliere una password",
        "phone_email": "Numero di telefono o indirizzo e-mail",
        "phone_number": "Numero di telefono",
        "portal_welcome_desc": "Effettuare il login per continuare...",
        "portal_welcome_title": "Benvenuti nel nostro portale!",
        "register": "Registrazione dell'account gratuito",
        "removal": {
            "ack": "La richiesta è stata inviata!",
            "ack_description_1": "La sua richiesta è stata inviata. Vi risponderemo al più presto, ma almeno entro 4 settimane da ora.",
            "ack_description_2": "Ci mancherai, quindi se hai archiviato per sbaglio questa richiesta, contattaci all'indirizzo {{email_vendor}} o contatta il tuo responsabile personale dell'assistenza, {{manager}} all'indirizzo {{email}} o {{phone}}.",
            "cancel": "Annullamento della richiesta",
            "close_window": "Dialogo ravvicinato",
            "confirm": "Confermare la richiesta"
        },
        "removal_description_1": "Quando procedete, stabiliremo cosa fare con la vostra richiesta di cancellazione il prima possibile, ma almeno entro 4 settimane. Vi informeremo sulle azioni che intraprenderemo per soddisfare la vostra richiesta. Se la richiesta non viene soddisfatta, è possibile presentare un reclamo all'Autorità Garante per i dati personali.",
        "removal_description_2": "Prima di confermare la richiesta di cancellazione, vorremmo chiederle chi possiamo contattare per la sua azienda al suo posto. Potrebbe lasciarci un nome e un numero di telefono o un indirizzo e-mail? La preghiamo di farlo solo se ha l'autorizzazione della nuova persona da contattare. Non è obbligatorio lasciare questi dati di contatto!",
        "removal_notice": "Leggete con attenzione!",
        "removal_questions": "Per ulteriori informazioni, domande o osservazioni, scriveteci all'indirizzo {{email_vendor}} o contattate il vostro responsabile personale dell'assistenza, {{manager}} all'indirizzo {{email}} o {{phone}}.",
        "removal_title": "Ci mancherai!",
        "replacement": {
            "contact": "Informazioni di contatto",
            "name": "Referente"
        },
        "request_password": "Hai dimenticato la password?",
        "request_removal": "Richiesta di rimozione",
        "save_button": "Aggiornamento del profilo"
    },
    "promotions": {
        "active": "Attivo tra: {{date}}",
        "active_grace": "La promozione può essere riscattata fino a: {{date}}",
        "call_to_action": "Ordina ora!",
        "confirmed": {
            "description": "Ora tocca a noi: faremo in modo che la merce arrivi a te. Nel frattempo, ti invitiamo a tenere d'occhio il nostro magazzino per scoprire altre fantastiche offerte. Non esitare a contattarci per qualsiasi domanda sul tuo ordine.",
            "subtitle": "La tua promozione è stata riscattata!"
        },
        "description": "Abbiamo un'altra fantastica promozione per te! Diventa un vero barista risparmiando caffè in grani per ogni spesa di {{token_value}}. Ci sono diverse macchine da caffè che puoi guadagnare.",
        "details": "Clicca per visualizzare i dettagli della promozione",
        "eligible_multiple": "Un fagiolo attivo rappresenta {{token_value}} del valore dell'ordine di vendita che è stato confermato o fatturato dal tuo responsabile vendite. Hai raccolto {{turnover_draft}} del valore dell'ordine di vendita ammissibile, di cui {{turnover_redeemable}} è già stato confermato o fatturato.",
        "gifts": {
            "available": "Regali disponibili",
            "choose": "La vostra offerta",
            "choose_help": "È possibile scegliere un'offerta dopo essere diventati idonei alla promozione.",
            "disabled": "È possibile scegliere un regalo",
            "locked": "Sbloccate il vostro regalo aggiungendo {{amount}} al vostro ordine.",
            "locked_placeholder": "Prima sblocca il tuo dono...",
            "unlocked": "Congratulazioni! Hai sbloccato un regalo gratuito!",
            "unlocked_choice_made": "{{choice}} - Ottima scelta!",
            "unlocked_choose": "Selezionare il regalo preferito qui sotto...",
            "unlocked_placeholder": "Seleziona il tuo omaggio...",
            "unlocked_redirect": "Congratulazioni! Hai sbloccato un regalo gratuito! Controlla la sezione <a href={{link}}>promozioni</a> per maggiori informazioni sui vari omaggi..."
        },
        "giveaway": "Ordina {{spent_next}} {{currency}} per guadagnare un chicco di caffè!",
        "giveaway_encourage": "Ordina {{spent_next}} {{currency}} e ricevi un chicco di caffè!",
        "incentive_hurry_one": "Rimane solo {{count}} giorno!",
        "incentive_hurry_other": "Mancano solo {{count}} giorni!",
        "incentive_progress": "Ordina {{amount}} per un chicco di caffè!",
        "label": {
            "dialog_subtitle": "Hai mai desiderato diventare un barista? Questa è la tua occasione! Guadagna un chicco di caffè per ogni ordine di {{token_value}} e scegli una delle tre favolose macchine da caffè! E per finire, ti regaliamo ben 6 kg di caffè arabica biologico in grani! Conserva i chicchi e prepara il tuo delizioso Americano, Latte o Expresso!",
            "promotion": "Promozione",
            "redeemable_tokens": "Fagioli raccolti",
            "show_promotion": "Promozione dello spettacolo"
        },
        "order_reminder": "Sei quasi pronto a diventare un barista! Una volta raggiunto il sito {{token_value}}, potrai riscattare la tua promozione.",
        "order_reminder_eligible": "Sei a un passo dal diventare un barista! Stiamo esaminando il tuo ordine e riceverai un'e-mail di conferma entro 24 ore. Una volta ricevuta questa e-mail, potrai riscattare i tuoi chicchi di caffè. Grazie per la tua pazienza!",
        "redeem": "Riscatta",
        "terms": {
            "conditions": "Termini e condizioni",
            "period": "Periodo di promozione: {{period}}",
            "terms_0": "La promozione è valida per gli ordini effettuati tramite il nostro portale; contiamo tutti i tuoi ordini finalizzati durante questo periodo.",
            "terms_1": "Una volta che hai ordinato e sei idoneo a ricevere un caffè in grani, puoi ordinare immediatamente una macchina per caffè espresso tramite il portale, che aggiungeremo al tuo ordine.",
            "terms_2": "Le macchine per caffè espresso offerte sono soggette a disponibilità di magazzino. Se i modelli selezionati non sono disponibili, selezioneremo un modello comparabile/equivalente in accordo con te.",
            "terms_3": "Non siamo responsabili delle informazioni fornite dal produttore sul suo sito web. Si tratta di informazioni puramente informative.",
            "terms_4": "L'importo totale corrisponde al valore della merce ordinata e pagata per ogni fattura. I costi aggiuntivi fatturati non vengono conteggiati ai fini della promozione (spedizione / trasporto / pallet).",
            "terms_5": "La garanzia del prodotto è applicabile solo nei Paesi Bassi.",
            "terms_6": "Una macchina per caffè espresso non può essere scambiata con uno sconto in contanti.",
            "terms_7": "Questa promozione è stata realizzata per te con cura, ma è soggetta a errori tipografici."
        }
    },
    "registration": {
        "address": "Indirizzo",
        "address_placeholder": "Indirizzo dell'azienda",
        "agree": "Sì, sono d'accordo con i <a href={{link}} target=\"_blank\">termini e condizioni</a>",
        "back_login": "Torna al login",
        "city": "Città",
        "city_placeholder": "Città dell'azienda",
        "company_details": "Dettagli dell'azienda",
        "company_name": "Nome della società",
        "company_name_placeholder": "Nome dell'azienda",
        "consent": "E-mail commerciali e offerte da parte di {{vendor}}",
        "country": "Paese",
        "country_help": "Il paese in cui si opera",
        "email_address": "Indirizzo e-mail",
        "email_address_help": "L'indirizzo e-mail a cui possiamo contattarvi",
        "explanation": "{{vendor}} fornisce clienti Business to Business in tutta Europa e oltre. In qualità di apprezzato cliente di {{vendor}}, avrete accesso illimitato al nostro portale di vendita. Potrete godere dei vantaggi di un accesso 24 ore su 24, 7 giorni su 7, alla vasta gamma di prodotti che offriamo, ogni volta che vorrete. Effettuare un ordine è facile: basta navigare, scegliere, cliccare e il gioco è fatto!",
        "family_name": "Nome della famiglia",
        "family_name_placeholder": "Il vostro nome di famiglia",
        "first_name": "Nome",
        "first_name_placeholder": "Nome",
        "import_goods_temp_license": "Importo merci con una licenza temporanea",
        "invite": "Inviti",
        "message": "Domande?",
        "message_placeholder": "Avete domande da porci? Sentitevi liberi di farle...",
        "name": "Il tuo nome",
        "position": "Il ruolo della vostra azienda",
        "position_placeholder": "Il ruolo della vostra azienda",
        "postal_code": "Codice postale",
        "postal_code_placeholder": "Codice postale dell'azienda",
        "redeem": {
            "code": "Codice invito",
            "code_help": "Il vostro codice unico per accedere al nostro portale",
            "partnership": {
                "footer": "Con amore per il nostro pianeta",
                "header": "{{vendor}} è un partner orgoglioso di {{partner}}."
            },
            "step0_desc": "Sei entusiasta del settore degli alcolici come lo siamo noi? Accedi con il tuo codice personale per richiedere il tuo regalo e avere accesso al nostro assortimento ampio, vario e a prezzi vantaggiosi.",
            "step0_slug": "Insieme rendiamo il mondo un posto migliore",
            "step1_desc": "Inserite l'indirizzo e-mail che volete utilizzare, scegliete una password e cominciamo!",
            "step1_slug": "Su di te",
            "step2_desc": "Le seguenti informazioni sono corrette? Premete il pulsante \"Registrazione e login\" e sarete automaticamente inseriti nel portale.",
            "step2_slug": "Recensione Registrazione",
            "step3_slug": "Registrazione e accesso"
        },
        "review_request": "Si prega di rivedere tutte le informazioni fornite. Se tutto è corretto, potete inviare la vostra registrazione e vi risponderemo a breve.",
        "signup": "Diventa cliente",
        "step0_desc": "{{vendor}} fornisce ai clienti Business to Business. Per quale azienda volete richiedere un account?",
        "step0_slug": "La vostra azienda",
        "step1_desc": "Inserite l'indirizzo della vostra azienda.",
        "step1_slug": "Indirizzo dell'azienda",
        "step2_desc": "Come possiamo contattarvi?",
        "step2_slug": "Dettagli di contatto",
        "step3_desc": "Utilizzate le strutture di un magazzino doganale nell'UE?",
        "step3_desc_vat": "Indicare il numero di partita IVA e le preferenze di stoccaggio.",
        "step3_slug": "Dettagli logistici",
        "step4_desc": "Vi preghiamo di esaminare le informazioni che state per inviarci per la vostra richiesta di registrazione. Potete utilizzare il campo domande qui sotto, nel caso in cui abbiate delle domande da porci.",
        "step4_slug": "Recensione Registrazione",
        "step5_desc": "Verificheremo i dati aziendali forniti. Questo processo potrebbe richiedere alcuni giorni. Nel caso in cui avessimo bisogno di ulteriori informazioni, vi contatteremo personalmente. Dopo la conferma, riceverete un'e-mail di conferma della vostra registrazione.",
        "step5_footer": "Ci auguriamo di ricevere presto il vostro primo ordine online!",
        "step5_incentive": "Sapevate che abbiamo un nostro <a target=\"_blank\" href=\"https://www.youtube.com/@movingspirits474/videos\">canale Youtube</a>?",
        "step5_notify": "Grazie per la vostra candidatura! Elaboreremo la sua candidatura entro pochi giorni lavorativi.",
        "step5_slug": "Invia la richiesta",
        "step5_title": "Cosa succede dopo?",
        "submit": "Invia la registrazione",
        "telephone": "Il vostro numero di telefono",
        "telephone_placeholder": "+31 502110909",
        "terms_conditions": "Termini e condizioni generali",
        "vat_number": "Numero di partita IVA",
        "vat_number_help": "Il numero di partita IVA è necessario nei paesi dell'UE",
        "vat_number_placeholder": "Numero di partita IVA",
        "warehouse": {
            "address": "Indirizzo del magazzino",
            "address_help": "Indirizzo del vostro magazzino",
            "address_unloading": "Indirizzo di scarico",
            "address_unloading_help": "Il luogo in cui possiamo scaricare il vostro ordine",
            "bonded_eu": "Magazzino vincolato nell'UE?",
            "city": "Città del magazzino",
            "city_unloading": "Città di scarico",
            "excise_id": "ID accisa magazzino",
            "location_id": "ID posizione magazzino",
            "name": "Nome del magazzino",
            "name_unloading": "Scarico",
            "options": {
                "bonded": "Ho un mio magazzino autorizzato",
                "inc_vat": "Acquisto con IVA e accise incluse nei Paesi Bassi",
                "other": "Altro",
                "temp_license": "Importo merci con una licenza temporanea",
                "third_party": "Utilizzo un magazzino/partner di terze parti"
            },
            "postal_code": "Codice postale del magazzino",
            "postal_code_unloading": "Codice postale di scarico",
            "select": "Seleziona il magazzino",
            "shipping": {
                "free_shipping": "Ben fatto! Consegneremo il vostro ordine gratuitamente.",
                "incentive": "Aggiungi <b>{{amount}}</b> al vostro ordine per ottenere la consegna gratuita. Per ordini inferiori a <b>{{threshold}}</b>i costi di trasporto aggiuntivi saranno aggiunti alla fattura proforma che dovrete confermare."
            },
            "warehousing": "Magazzino"
        },
        "website": "Sito web aziendale",
        "website_placeholder": "la tua azienda.com"
    },
    "search": {
        "categories": {
            "category": "categoria",
            "search": "nome del prodotto"
        },
        "exact": "Cercare per nome del prodotto o GTIN utilizzando il termine \"{{term}}\".",
        "placeholder": {
            "general": "Che cosa sta cercando?",
            "raw": "Ricerca per nome del prodotto o GTIN",
            "raw_term": "Cercare per nome del prodotto o GTIN utilizzando il termine \"{{term}}\".",
            "suggestion": "Ricerca per {{type}} \"{{value}}\""
        },
        "suggestions": {
            "activate": "<Ctrl><Enter> per la ricerca di termini esatti"
        }
    },
    "select": "Selezionare",
    "spotlight": {
        "countdown": {
            "still_unsold": "Ancora invenduto",
            "valid_for": "Offerta ancora valida:"
        }
    },
    "status": {
        "error": {
            "redeem": {
                "invalid": "Peccato, questo codice non è corretto; riprovate.",
                "length": "Il codice di riscatto è di 6 caratteri",
                "unknown": "Si è verificato un errore durante la verifica del codice di riscatto; riprovare più tardi.",
                "used": "Questo codice invito è già stato utilizzato per creare un account."
            }
        }
    },
    "view": {
        "grid": "Griglia",
        "grid_switch": "Passare alla visualizzazione a griglia",
        "list": "Elenco",
        "list_switch": "Passare alla visualizzazione a elenco"
    },
    "yes": "Sì"
}
