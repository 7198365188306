import {$t} from '@bitstillery/common/app'

import {format_date} from './format'

export function to_specs(item) {
    const spec_line = [
        item.case_number_of_bottles,
        `${Number(item.bottle_volume).toFixed(1)} cl`,
        `${Number(item.bottle_alcohol_percentage).toFixed(1)}${$t('unit.percentage')}`,
        item.case_gift_box_type,
        item.bottle_refill_status.toUpperCase(),
        item.case_customs_status,
        item.case_tax_label,
        item.product_category_name,
    ]
    return spec_line.filter((i) => i).join(' / ')
}

export function to_tags(offer_item) {
    let bbd = ''
    if (offer_item.item_best_before_date) {
        bbd = `BBD ${format_date(offer_item.item_best_before_date)}`
    }
    const tags_line = [
        [offer_item.case_tax_label],
        [offer_item.item_damages],
        [offer_item.item_general_tags],
        [offer_item.item_pack_size],
        [offer_item.item_packaging],
        [bbd, 'bbd'],
    ]
    return tags_line.filter((i) => {
        return i[0] && i[0].length
    }).join(' / ')
}

export function to_cpp_cpl(offer_item) {
    const strings = [] as string[]
    if (offer_item.cases_per_pallet) {
        strings.push(`CPP: ${offer_item.cases_per_pallet}`)
    }
    if (offer_item.cases_per_pallet_layer) {
        strings.push(`CPL: ${offer_item.cases_per_pallet_layer}`)
    }

    return strings.join('; ')
}
