import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Icon, Tippy} from '@bitstillery/common/components'
import {watch} from '@bitstillery/common/lib/store'

export class Stepper extends MithrilTsxComponent<any> {
    watchers: any

    constructor(vn: m.Vnode<any>) {
        super()
        this.watchers = [
            watch(vn.attrs.model, 'selection', (i) => {
                // Sync step changes with the Stepper's onChange handler.
                if (vn.attrs.onChange) {
                    vn.attrs.onChange(i, vn.attrs.model.selection)
                }
            }),
        ]
    }

    onremove() {
        this.watchers.map((unwatch) => unwatch())
    }

    view(vn: m.Vnode<any>) {
        return (
            <div className={classes('c-stepper', {
                completed: vn.attrs.model.selection >= vn.attrs.options.length - 1,
                [vn.attrs.model._direction ? vn.attrs.model._direction : 'horizontal']: true,
                [vn.attrs.size ? `size-${vn.attrs.size}` : 'size-d']: true,
            })}>
                <div className="steps">
                    <div className="progress-line"/>
                    {vn.attrs.options.map((option, i) => <Tippy
                        className={classes('step', {
                            active: vn.attrs.model.selection === i,
                            completed: vn.attrs.model.selection >= i + 1,
                            disabled: vn.attrs.options[i]._disabled,
                        })}
                        content={vn.attrs.options[i].title}
                        onclick={() => {
                            if (!vn.attrs.options[i]._disabled) {
                                vn.attrs.model.selection = i
                            }
                        }}
                        placement={vn.attrs.tipPlacement}
                    >
                        {(() => {
                            if (option.link) {
                                return <a href={option.link}>
                                    <Icon name={vn.attrs.options[i].icon} type="unset" />
                                </a>
                            }
                            return <Icon name={vn.attrs.options[i].icon} type="unset"/>
                        })()}
                    </Tippy>)}
                </div>
            </div>
        )
    }
}
