import {$s, $t} from '@bitstillery/common/app'

export const CURRENCY_SYMBOLS = {
    EUR: '€',
    GBP: '£',
    JPY: '¥',
    USD: '$',
}

export function format_date(date) {
    if (!date) return ''

    if (!(date instanceof Date)) {
        date = new Date(date)
    }

    return date.toLocaleString('nl', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    })
}

export function format_days_since_date(date) {
    const today = new Date()
    const entry = new Date(date)
    const diff = Math.abs(entry.getTime() - today.getTime())
    const days = Math.floor(diff / (1000 * 3600 * 24))
    if (days === 0) {
        return 'Today'
    } else if (days === 1) {
        return '1 day ago'
    }
    return `${days} days ago`
}

export function format_delivery_period(value) {
    // Even if less then 1 always display as 1 week
    if (!value || value <= 1) return $t('offer.delivery_week', {count: 1})

    return $t('offer.delivery_week', {count: value})
}

export function format_leading_zero(input) {
    const input_str = `${input}`

    if (input_str.length < 2) {
        return `0${input_str}`
    }

    return input_str
}

/**
 * Formats a numeric value into a formatted money string with options for shorthand and currency addition.
 * @param {number} value The numeric value to format.
 * @param {boolean} [short=false] Whether to format the money into a short form (e.g., using 'K' for thousands).
 * @param {boolean} [currency=false] Whether to append the currency symbol to the formatted value.
 * @returns {string} The formatted money string.
 */
export function format_money(value, short = false, currency = null) {
    let minimum_fraction_digits = 2
    let maximum_fraction_digits = 2

    let formatted_value: number = value
    if (short && value > 100) {
        formatted_value = value / 1000
        minimum_fraction_digits = 1
        maximum_fraction_digits = 1
    }

    const formatter = new Intl.NumberFormat($s.language, {
        style: 'decimal',
        minimumFractionDigits: minimum_fraction_digits,
        maximumFractionDigits: maximum_fraction_digits,
    })

    let notation = formatter.format(formatted_value)
    if (currency) {
        notation = `${CURRENCY_SYMBOLS[currency]} ${notation}`
    } else if (short && value > 100) {
        notation = `${notation}K`
    }

    return notation
}

export function format_percentage(value) {
    if (isNaN(value)) return 0
    return parseFloat(value).toFixed(2)
}

export function format_textual_date(date) {
    const month_names = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    if (!(date instanceof Date)) {
        date = new Date(date)
    }

    let day = date.getDate()
    if (day === 1) {
        day = '1st'
    } else if (day === 2) {
        day = '2nd'
    } else if (day === 3) {
        day = '3rd'
    } else {
        day = day + 'th'
    }

    const month = month_names[date.getMonth()]
    return `${day} of ${month} ${date.getFullYear()}`

}

export function format_time_to_go(timestamp) {
    const today = new Date()
    const expiry = new Date(timestamp)
    const diff = Math.floor((expiry.getTime() - today.getTime()) / 1000)
    const seconds = diff % 60
    const diff_minutes = Math.floor (diff / 60)
    const minutes = diff_minutes % 60
    const hours = Math.floor (diff_minutes / 60)

    if (diff > 0) {
        return `${format_leading_zero(hours)}:${format_leading_zero(minutes)}:${format_leading_zero(seconds)}`
    }

    return '00:00:00'
}
