import m from 'mithril'
import {classes, copy_object} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, Icon} from '@bitstillery/common/components'
import {$s, logger} from '@bitstillery/common/app'
import {watch} from '@bitstillery/common/lib/store'

interface PanelContextAttrs {
    className?: string
    collection?: any
    count?: number
    icon?: string
    keep_open?: boolean
    minimizable?: boolean
    title?: string
}

export class PanelContext extends MithrilTsxComponent<PanelContextAttrs> {
    watchers = [] as any

    oninit(vnode) {
        if (vnode.attrs.icon) {
            // Used in the context toggles in panel_menu
            $s.context.icon = vnode.attrs.icon
        }

        if (!vnode.attrs.keep_open) {
            $s.panels.context.collapsed = true
        }

        this.watchers.push(watch($s.context, 'name', (new_name) => {
            // Watch the context name to collapse the panel if it's null;
            // the context is mutated from multiple locations; toggling
            // the collapsed state happens here however.
            if (!new_name) {
                $s.panels.context.collapsed = true
                if ($s.context.data && $s.context.data_restore) {
                    logger.debug('[panel-context] restoring original context data on close...')
                    Object.assign($s.context.data, copy_object($s.context.data_restore))
                }
            } else {
                $s.panels.context.collapsed = false
            }
        }))
    }

    onremove(vnode) {
        this.watchers.map((unwatch) => unwatch())

        if (!vnode.attrs.keep_open) {
            $s.panels.context.collapsed = true
        }
        Object.assign($s.context, {
            data: null,
            id: null,
            name: null,
        })
    }

    view(vnode:m.Vnode<PanelContextAttrs>):m.Children {
        // The count is used to show (optional) extra context
        // next to the PanelContext toggle in PanelMenu.
        if (vnode.attrs.count) {
            $s.context.count = vnode.attrs.count()
        } else if ($s.context.count !== null) {
            $s.context.count = null
        }

        let current_index = -1

        if (vnode.attrs.collection && $s.context.data && $s.context.data.artkey) {
            current_index = vnode.attrs.collection.state.items.findIndex((i) => {
                return i.artkey === $s.context.data.artkey
            })
        }

        return <div
            className={classes('c-panel-context', vnode.attrs.className, 'float-panel', {
                collapsed: $s.panels.context.collapsed,
                'float-panel--active': $s.env.layout === 'mobile',
                minimizable: vnode.attrs.minimizable,
            })}
            onkeydown={(e) => {
                // Only navigate context when ctrl is pressed.
                if (!e.ctrlKey) return
                if (e.key === 'ArrowLeft' && current_index > 0 && !$s.context.loading) {
                    vnode.attrs.collection.select_previous()
                } else if (e.key === 'ArrowRight' && current_index < vnode.attrs.collection.state.items.length - 1 && !$s.context.loading) {
                    vnode.attrs.collection.select_next()
                }
            }}
            tabindex="0"
        >
            <header onclick={() => {
                if (vnode.attrs.minimizable) {
                    $s.panels.context.collapsed = !$s.panels.context.collapsed
                }
            }}>
                <div className="title">
                    <Icon
                        className="icon-d"
                        name={(() => {
                            if ($s.context.icon) return $s.context.icon
                            if (vnode.attrs.icon) return vnode.attrs.icon
                            return 'card_account_details_outline'
                        })()}
                    />
                    <span>{(() => {
                        if ($s.context.title) return $s.context.title
                        if (vnode.attrs.title) return vnode.attrs.title
                        return 'Context Panel'
                    })()}</span>
                </div>
                <div className="panel-actions">
                    {(() => {
                        // No collection to operate on; skip collection actions
                        if (!vnode.attrs.collection || !$s.context.data) return
                        if (!$s.context.data.artkey) return

                        return [
                            <Button
                                disabled={current_index <= 0 || $s.context.loading}
                                icon="back"
                                onclick={() => vnode.attrs.collection.select_previous()}
                                tip="Previous item in the collection (ctrl + left key)"
                                type="info"
                                variant="toggle"
                            />,
                            <Button
                                disabled={(current_index >= vnode.attrs.collection.state.items.length - 1) || $s.context.loading}
                                icon="forward"
                                onclick={() => vnode.attrs.collection.select_next()}
                                tip="Next item in the collection (ctrl + right key)"
                                type="info"
                                variant="toggle"
                            />,
                        ]
                    })()}

                    {!vnode.attrs.minimizable && <Button
                        icon="close"
                        tip="Close this panel"
                        onclick={() => {
                            Object.assign($s.context, {
                                data: {},
                                icon: null,
                                id: null,
                                name: null,
                            })
                        }}
                        variant="toggle"
                    />}
                </div>
            </header>
            {vnode.children}
        </div>
    }
}
