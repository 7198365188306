import m from 'mithril'
import QRious from 'qrious'
import {MithrilTsxComponent} from 'mithril-tsx-component'

export default class QR extends MithrilTsxComponent<any> {
    url: string

    constructor(vnode: m.Vnode<any>) {
        super()
        this.url = vnode.attrs.url
    }

    view() {
        return (
            <div className="c-auth-qr">
                <canvas oncreate={(canvasVnode) => {
                    new QRious({
                        element: canvasVnode.dom,
                        size: 200,
                        value: this.url,
                    })
                }}/>
            </div>
        )
    }
}
