import {$s} from '@bitstillery/common/app'
import {watch} from '@bitstillery/common/lib/store'

function set_header_size() {
    const spacer = 8
    let header_size = 0
    if (!$s.page.header.collapsed) {
        header_size += spacer * 8
    }

    if ($s.page.header.menu) {
        header_size += spacer * 6
    }

    if ($s.page.header.submenu) {
        header_size += spacer * 6
    }

    document.documentElement.style.setProperty('--header-size', `${header_size}px`)
}

function update_env(env, layout) {
    const spacer = 8
    env.layout = layout
    if (layout === 'desktop' && !$s.page.header.menu) {
        $s.page.header.menu = true
    } else if ($s.page.header.menu) {
        $s.page.header.menu = false
    }

    let query_spacer

    if (layout === 'desktop') {
        query_spacer = spacer * 3
    } else if (layout === 'tablet') {
        query_spacer = spacer * 1.5
    } else if (layout === 'mobile') {
        query_spacer = spacer
    }
    document.documentElement.className = layout
    document.documentElement.style.setProperty('--query-spacer', `${query_spacer}px`)
    set_header_size()
}

export default function env(env) {
    const ua = navigator.userAgent.toLowerCase()
    // Matches $breakpoint-20; query from css variable after Discover UI refactor.
    const styles = getComputedStyle(document.documentElement)
    const breakpoint_20 = Number(styles.getPropertyValue('--breakpoint-20').trim().replace('px', ''))
    const breakpoint_40 = Number(styles.getPropertyValue('--breakpoint-40').trim().replace('px', ''))
    const is_desktop = window.matchMedia(`(min-width: ${breakpoint_40}px)`)
    const is_tablet = window.matchMedia(`(min-width: ${breakpoint_20}px) and (max-width: ${breakpoint_40 - 1}px)`)
    const is_mobile = window.matchMedia(`(max-width: ${breakpoint_20 - 1}px)`)

    Object.assign(env, {
        browserName: '',
        isBrowser: !!globalThis.navigator,
        isChrome: !!globalThis.chrome,
        isFirefox: ua.includes('firefox'),
        isSafari: (ua.indexOf('safari') >= 0 && ua.indexOf('chrome') < 0),
        layout: 'mobile',
        ua,
    })

    watch($s.page.header, (collapsed) => {
        set_header_size(env, collapsed)
    })

    is_desktop.addListener((e) => {
        if (e.matches) {
            update_env(env, 'desktop')
        }
    })
    is_tablet.addListener((e) => {
        if (e.matches) {
            update_env(env, 'tablet')
        }
    })
    is_mobile.addListener((e) => {
        if (e.matches) {
            update_env(env, 'mobile')
        }
    })

    // On desktop, 100vh works as expected, but using 100vh on mobile Safari/Chrome,
    // the browser elements (bottom-bar/nav-bar) uses implicit viewport space, in
    // order to have less reflows during scrolling. However, this makes content at
    // the edges overflow incorrectly. The proper fix is using dvh, but that's yet
    // unsupported in Blink. This is a workaround that sets the right
    // visible viewport height indirectly, by using a css variable var(--app-height).
    // Don't use 100vh, but this variable until dvh lands in all major browsers.
    // For more background info, see: https://www.bram.us/2021/07/08/the-large-small-and-dynamic-viewports
    const dynamic_view_port = () => {
        document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
    }

    const layout = is_desktop.matches ? 'desktop' : is_tablet.matches ? 'tablet' : is_mobile.matches ? 'mobile' : 'unknown'
    update_env(env, layout)
    window.addEventListener('resize', dynamic_view_port)
    dynamic_view_port()
}
