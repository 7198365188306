import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {NotificationData} from '../../groups/notification/notifier'

interface NotificationAttrs {
    notification: NotificationData
    key: any
    on_close: (notification: NotificationData) => unknown
}

export class Notification extends MithrilTsxComponent<NotificationAttrs> {

    view(vn: m.Vnode<NotificationAttrs>) {
        return (
            <div className={classes('c-notification', {[`type-${vn.attrs.notification.level}`]: true})}>
                <Icon className="notification-icon" name={vn.attrs.notification.icon} size="xl" type={vn.attrs.notification.level} />
                <div class="message">
                    <div class="body" innerHTML={vn.attrs.notification.message} />
                </div>
                <Icon
                    className="btn-close"
                    name="close"
                    type={vn.attrs.notification.level}
                    onclick={() => {
                        if (vn.attrs.on_close) {
                            vn.attrs.on_close(vn.attrs.notification)
                        }
                    }}
                />
            </div>
        )
    }
}
