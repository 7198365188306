import m from 'mithril'
import {Carousel, CarouselSlide} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'

interface CarouselGiveAwayAttrs {
    controls: boolean
    current: number
    indicators: boolean
    onchange: (index: number) => void
    slides: CarouselSlide[]
}

export class CarouselGiveAway extends MithrilTsxComponent<CarouselGiveAwayAttrs> {
    carousel_item(slide: CarouselSlide, active: boolean) {
        return (
            <div className={classes('item', {active})}>
                <div className="slide-header">
                    <div className="title">
                        {slide.link && <a href={slide.link} target="_blank">{slide.title}</a>}
                        {!slide.link && <span>{slide.title}</span>}
                    </div>
                    <div className="subtitle">
                        {slide.description}
                    </div>
                </div>
                <img alt="Slide image missing" src={slide.url}/>
            </div>
        )
    }

    view(vnode: m.Vnode<CarouselGiveAwayAttrs>) {
        return (
            <Carousel
                className='c-carousel-giveaway'
                controls={vnode.attrs.controls}
                current={vnode.attrs.current}
                indicators={vnode.attrs.indicators}
                item={this.carousel_item.bind(this)}
                mode='default'
                onchange={vnode.attrs.onchange}
                slides={vnode.attrs.slides}
                type="inline"
            />
        )
    }
}
