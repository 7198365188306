import {$s, api, config, logger} from '@bitstillery/common/app'

import {CountriesOfOriginResponse, ProductPhotosResponse} from '@/factserver_api/fact2server_api'

export {DISCOUNT_TYPES, offer_item_price} from '@bitstillery/common/lib/price'

export async function load_country_of_origin(offer_item_artkeys: number[]) {
    // const artkeys_to_fetch = offer_item_artkeys
    const artkeys_to_fetch = offer_item_artkeys.filter((artkey) => !(artkey in $s.countries_of_origin))
    if (!artkeys_to_fetch.length) return
    logger.debug(`[offer] loading countries of origin for ${artkeys_to_fetch.length} products`)

    const {result} = await api.post<CountriesOfOriginResponse[]>('portal/offer-items/countries-of-origin', {
        offer_item_artkeys: artkeys_to_fetch,
    }, true)

    for (const country of result) {
        if (!$s.countries_of_origin[country.artkey]) {
            $s.countries_of_origin[country.artkey] = []
        }
        $s.countries_of_origin[country.artkey].push(country)
    }
}

export async function load_product_photos(offer_item_artkeys: number[]) {
    const artkeys_to_fetch = offer_item_artkeys.filter((artkey) => !(artkey in $s.product_photos))
    if (!artkeys_to_fetch.length) return
    logger.debug(`[products] loading image data for ${artkeys_to_fetch.length} products`)

    const {result} = await api.post<ProductPhotosResponse[]>('portal/offer-items/product-photos', {
        offer_item_artkeys: artkeys_to_fetch,
    }, true)

    // Not all artkeys_to_fetch may return photos from the api. Diff the missing artkeys
    // from the api and add an empty placeholder, so it is not queried again.
    const artkey_matches = result.map((i) => i.artkey).filter((i) => artkeys_to_fetch.includes(i))
    const missing_artkeys = artkeys_to_fetch.filter((i) => !artkey_matches.includes(i))
    const product_photos = {}
    for (const missing_artkey of missing_artkeys) {
        if (!product_photos[missing_artkey]) product_photos[missing_artkey] = []
    }

    for (const photo of result) {
        if (!product_photos[photo.artkey]) product_photos[photo.artkey] = []
        product_photos[photo.artkey].push({
            artkey: photo.artkey,
            image_location: `${config.product_photo_host}/${photo.image_location}`,
            rank: photo.rank,
            thumbnail_location: photo.thumbnail_location ? `${config.product_photo_host}/${photo.thumbnail_location}` : undefined,
        })
    }

    Object.assign($s.product_photos, product_photos)
}

export function primary_photo(offer_item_artkey: number| undefined) : string | undefined {
    if (!offer_item_artkey) {
        return
    }
    const photos = $s.product_photos[offer_item_artkey]
    if (!photos || photos.length === 0) {
        return
    }

    return photos[0].thumbnail_location || photos[0].image_location
}

export function unit_amount(caseAmount, caseBottles) {
    let amount = caseAmount
    if ($s.cart.show_bottles) {
        return (amount * caseBottles)
    }
    return amount
}

export function unit_price(casePrice, caseBottles) {
    let price = Number(casePrice)
    if ($s.cart.show_bottles) {
        return price / caseBottles
    }

    return price
}
