import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'
import {Carousel, Support, VersionChecker} from '@bitstillery/common/components'
import {$t} from '@bitstillery/common/app'

import {$s} from '@/app'
import {MessRoom} from '@/components/messroom/messroom'
import {BarMainPortal} from '@/components/bar_main'
import {BarCategoriesPortal} from '@/components/bar_categories'
import {PanelMenuPortal} from '@/components/panels'
import {toggle_tawk} from '@/lib/tawk'

export class LayoutPortal extends MithrilTsxComponent<any> {

    oncreate() {
        toggle_tawk(true)
        const pathname = m.parsePathname(m.route.get()) as any
        const url_params = Object.fromEntries(new URLSearchParams(pathname.params))
        if ('show_popup_of' in url_params) {
            $s.carousel.selection = Number(url_params.show_popup_of)
        }
    }

    onupdate() {
        toggle_tawk(true)
    }

    view(vnode:m.Vnode<any>) {
        if (!$s.portal.ready) {
            return <MessRoom/>
        }
        return [
            <PanelMenuPortal/>,
            <div className={classes('l-main-col', {
                'header-collapsed': $s.page.header.collapsed,
            })}>
                {!!$s.carousel.selection && ($s.carousel.selection in $s.product_photos) && <Carousel
                    controls={true}
                    indicators={true}
                    onclose={() => $s.carousel.selection = null}
                    slides={$s.product_photos[$s.carousel.selection]?.map((photo) => ({url: photo.image_location}))}
                    selectedItem={$s.carousel.selection}
                />}
                <div className="header">
                    <BarMainPortal/>
                    <BarCategoriesPortal/>
                </div>
                {vnode.children}
            </div>,
            <Support/>,
            <VersionChecker title="Portal Update" reloadButton={$t('system.reload_portal')}>
                <p>
                    {`A new version of the ${process.env.MSI_TITLE} portal has just been published!`}<br/>
                    {'Please reload the portal in order to update to the latest version. '}<br/>
                    {'Don\'t worry; your cart content is securely stored and will still be there. '}
                </p>
            </VersionChecker>,
        ]
    }
}
