import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {Icon, RadioGroup} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {FilterSelectSingleState} from '@bitstillery/common/types'
import {$t} from '@bitstillery/common/app'

interface FilterSelectSingleAttrs {
    className: string
    filter: FilterSelectSingleState
    translate?: {
        label: boolean
        prefix: string
    }
}

export class FilterSelectSingle extends MithrilTsxComponent<FilterSelectSingleAttrs> {

    deactivate_filter(vnode:m.Vnode<FilterSelectSingleAttrs>) {
        vnode.attrs.filter.selection = ''
    }

    filtered_options(vnode:m.Vnode<FilterSelectSingleAttrs>) {
        return vnode.attrs.filter.options.filter((option) => {
            // Filter out inactive options; e.g. options that have no item count.
            if (option.length >= 3 && option[2] === 0) {
                return option[0] === vnode.attrs.filter.selection
            }
            return true
        })
    }

    oncreate(vnode:m.Vnode<FilterSelectSingleAttrs>) {
        vnode.attrs.filter._active = () => {
            return ![undefined, null, ''].includes(vnode.attrs.filter.selection)
        }
    }

    view(vnode:m.Vnode<FilterSelectSingleAttrs>) {
        const filtered_options = this.filtered_options(vnode)
        return (
            <div className={classes('c-filter-select-single', 'filter', vnode.attrs.className, {
                active: vnode.attrs.filter._active,
                collapsed: vnode.attrs.filter.collapsed || vnode.attrs.filter.disabled,
                disabled: filtered_options.length === 0 || vnode.attrs.filter.disabled,
            })}>
                <div className="title" onclick={() => {
                    vnode.attrs.filter.collapsed = !vnode.attrs.filter.collapsed
                }}>
                    <div class="name">
                        <Icon
                            active={vnode.attrs.filter._active}
                            className="btn-filter-toggle"
                            name={(() => {
                                if (vnode.attrs.filter.icon) return vnode.attrs.filter.icon
                                return vnode.attrs.filter.selection ? 'filterRemove' : 'filterPlus'
                            })()}
                            onclick={(e) => {
                                e.stopPropagation()
                                this.deactivate_filter(vnode)
                            }}
                            tip={() => {
                                const filter_title = $t(vnode.attrs.filter.title)
                                return vnode.attrs.filter._active ? $t('filters.actions.deactivate_filter', {name: filter_title}) : $t('filters.inactive_filter', {name: filter_title})
                            }}
                        />
                        {$t(vnode.attrs.filter.title)}
                        {filtered_options.length === 0 && (<span></span>)}
                    </div>
                    <Icon
                        className="btn-collapse"
                        name={vnode.attrs.filter.collapsed ? 'chevronDown' : 'chevronUp'}
                        type="info"
                    />
                </div>
                <div class="items">
                    {filtered_options.length > 0 && <RadioGroup
                        disabled={vnode.attrs.filter.disabled}
                        options={filtered_options}
                        ref={[vnode.attrs.filter, 'selection']}
                        translate={vnode.attrs.translate}
                    />}
                </div>
            </div>
        )
    }
}
