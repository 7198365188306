import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import nouislider from 'nouislider'
import {unique_id} from '@bitstillery/common/lib/utils'
import {watch} from '@bitstillery/common/lib/store'

export interface SliderAttrs {
    minimum: number
    maximum: number
    infinity: boolean
    step?: number
    value: number[]
}

export class Slider extends MithrilTsxComponent<SliderAttrs> {
    id = `slider-${unique_id(8, false)}`
    slider: any
    watchers:any = []

    oncreate(vnode: m.Vnode<SliderAttrs, this>): any {
        var element = document.getElementById(this.id)
        if (!element) {
            return
        }

        let start = vnode.attrs.value

        // Inactive state; default to minimum and maximum.
        if (!start.length) {
            start = [vnode.attrs.minimum, vnode.attrs.maximum]
        }

        this.slider = nouislider.create(element, {
            start,
            step: vnode.attrs.step || 1,
            connect: true,
            range: {
                min: vnode.attrs.minimum,
                '33%': Math.floor(vnode.attrs.maximum / 3),
                '66%': Math.floor(vnode.attrs.maximum / 3) * 2,
                max: vnode.attrs.maximum,
            },
            format: {
                to: (val: number) => {
                    if (vnode.attrs.infinity && val === vnode.attrs.maximum) {
                        return Infinity
                    }
                    return (+val).toFixed(0)
                },
                from: (val: number) => {
                    return (+val).toFixed(0)
                },
            },
            pips: {
                density: 5,
                mode: 'range',
                format: {to: (val: number) => vnode.attrs.infinity && vnode.attrs.maximum === val ? '∞' : val.toFixed(0)},
            },
            tooltips : [
                {to: (val: number) => vnode.attrs.infinity && vnode.attrs.maximum === val ? '∞' : val.toFixed(0)},
                {to: (val: number) => vnode.attrs.infinity && vnode.attrs.maximum === val ? '∞' : val.toFixed(0)},
            ],
        })

        // Two-way binding:
        // State updates UI-element
        this.watchers.push(watch(vnode.attrs.value, () => {
            if (!vnode.attrs.value.length) {
                this.slider.set([vnode.attrs.minimum, vnode.attrs.maximum])
            } else {
                this.slider.set(vnode.attrs.value)
            }
        }))

        // - UI-element updates state
        this.slider.on('change', (value: string[]) => {
            vnode.attrs.value.splice(0, vnode.attrs.value.length, ...value.map((i) => Number(i)))
        })
    }

    onremove() {
        // Cleanup two-way binding.
        this.slider.destroy(document.getElementById(this.id))
        this.watchers.map((watcher) => watcher())
    }

    view() {
        return <div className={'c-slider'}>
            <div id={this.id} />
        </div>
    }
}
