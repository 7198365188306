import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'
import {$t, store} from '@bitstillery/common/app'

import {$s} from '@/app'
import {DemoUserModal} from '@/components/demo_user/demo_user_modal'

export class DemoUserWidget extends MithrilTsxComponent<any> {

    data = proxy({
        show_modal: false,
    })

    oncreate() {
        if ($s.identity.demo) {
            if (!$s.dialog.demo_dashboard_user || !dayjs().isSame(dayjs($s.dialog.demo_dashboard_user), 'day')) {
                this.data.show_modal = true
                // Store the current time in localstorage. We'll show it again tomorrow.
                $s.dialog.demo_dashboard_user = dayjs().toJSON()
                store.save()
            }
        }
    }

    view(_vn:m.Vnode<any>) {
        if (!this.data.show_modal) return

        return <DemoUserModal
            onclose={() => this.data.show_modal = false}
            title={$t('dashboard.widgets.demo_user.title')}
            bodyContent={(
                <div className="demo-user-modal-text">
                    <p>{$t('dashboard.widgets.demo_user.context_1')}</p>
                    <p>{$t('dashboard.widgets.demo_user.context_2')}</p>
                </div>
            )}
        />

    }
}
