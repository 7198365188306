import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'
import {Icon} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {$t, events} from '@bitstillery/common/app'

import {FinalizeCart} from './finalize_cart'
import {VerifyCart} from './verify_cart'
import {OrderItems} from './order_items/order_items'

import {$m, $s} from '@/app'
import {PanelCart} from '@/components/panels'

export class OrderCheckout extends MithrilTsxComponent<any> {

    data = proxy({
        tabs: {
            active: 'tab-cart',
        },
    })

    async oninit() {
        $m.cart.data.stepper.selection = Number(m.route.param('step') ? m.route.param('step') : 1)
        if ($m.cart.data.stepper.selection === 1) {
            $s.page.icon = 'cartCheck'
        } else {
            $s.page.icon = 'cartRight'
            await $m.order.load_active_voucher()
        }

        $s.page.title = $t(`page.title.checkout-${$m.cart.data.stepper.selection}`)

        events.once('active-order.set-order', this.on_set_order.bind(this))
    }

    onremove() {
        // Make sure to clean up the event listener or it will register
        // each time the component mounts.
        events.off('active-order.set-order', this.on_set_order)
    }

    async on_set_order(order) {
        await Promise.all([
            $m.order.get_active_promotion(),
            $m.order.load_active_voucher(),
        ])
        this.set_delivery_date(order.requested_delivery_date)
    }

    set_delivery_date(deliveryDate) {
        $s.cart.delivery.date = deliveryDate
        if ($s.cart.delivery.date) {
            $s.cart.delivery.selection = 'date'
        } else {
            $s.cart.delivery.selection = 'asap'
        }
    }

    view(_vn:m.Vnode<any>) {
        return (
            <div className={classes('c-checkout', 'view-container', 'minimizable-context-panel', {
                [`step-${$m.cart.data.stepper.selection}`]: true,
            })}>
                <div className="view">
                    <div className="c-items-view list">
                        <div className="bar-checkout">
                            <div className="bar-header">
                                <div className="page-icon">
                                    <Icon className="icon-l" name={$s.page.icon} type="unset" />
                                </div>
                                <span className="title">{$t(`page.title.checkout-${$m.cart.data.stepper.selection}`)}</span>
                            </div>
                        </div>
                        {(() => {
                            if ($m.cart.data.stepper.selection === 1) {
                                if (!Object.keys($s.cart.items).length) {
                                    return <OrderItems filter="cart" />
                                }
                                return <VerifyCart />
                            } else if ($m.cart.data.stepper.selection === 2) {
                                return <FinalizeCart />
                            }
                        })()}
                    </div>
                </div>
                <PanelCart
                    count={$m.cart.cart_units}
                    icon="cart"
                    keep_open={true}
                    minimizable={true}
                    title={$s.page.title}
                />
            </div>
        )
    }
}
