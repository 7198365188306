{
    "collection": {
        "exclude_label": "Escludere",
        "exclude_one": "Escludere l'articolo {{count}} ",
        "exclude_other": "Sono esclusi gli articoli di {{count}} ",
        "exclude_tip": "Escludere articoli; {{count}} articoli attualmente inclusi",
        "include_label": "Includere",
        "include_one": "Includi l'articolo {{count}} ",
        "include_other": "Includi gli articoli di {{count}} ",
        "include_tip": "Includi elementi; {{count}} elementi già inclusi",
        "modify_selection": "Modifica la selezione",
        "no_results": "Nessun risultato trovato",
        "no_results_clear_filters": "Hai provato a cancellare i filtri attivi?"
    },
    "contact": {},
    "data_card": {
        "text": "Scheda dati",
        "tip": "Scheda dati Toggle"
    },
    "dialog": {
        "cancel": "Annullamento"
    },
    "filters": {
        "actions": {
            "clear_filter_selection": "Cancella il filtro: {{name}}/{{selection}}",
            "deactivate_filter": "Disattiva il filtro",
            "pricelist": "Listino prezzi"
        },
        "clear": "Cancella i filtri",
        "collapse": "Filtri di chiusura",
        "collapse_tip": "Chiudi tutti i filtri",
        "expand": "Espandi i filtri",
        "expand_tip": "Espandi tutti i filtri",
        "inactive_filter": "Il filtro è inattivo",
        "no_results": "Nessun prodotto",
        "no_results_hint": {
            "filters": "Modificare i filtri attivi?",
            "search": "Modificare i termini di ricerca?"
        },
        "no_selection": "Nessun elemento selezionato",
        "panel_title": "Filtri",
        "personal_offer": "Personale",
        "reset_disabled": "Abilita i filtri per utilizzare questa opzione",
        "reset_one": "Azzera il filtro",
        "reset_other": "Azzera i filtri di {{count}} ",
        "sort": {
            "direction": "Ordinamento",
            "direction_tip": "Cambia l'ordine di ordinamento",
            "name": "Ordinamento",
            "options": "Opzioni di ordinamento",
            "reset": "Azzera l'ordinamento",
            "types": {
                "avg_purchase_price": "Prezzo medio di acquisto",
                "created_by": "Creato da",
                "created_on": "Creato il",
                "delivery_period": "Tempi di consegna",
                "entry_date": "Data di iscrizione",
                "invoice_number": "Numero di fattura",
                "is_invoiced_on": "Data della fattura",
                "item_stock_age": "Età dello stock",
                "item_throughput": "Velocità di trasmissione",
                "last_price_change_age": "Ultima variazione di prezzo",
                "list_price": "Prezzo di vendita",
                "list_quantity": "Quantità",
                "lowest_price_competitors": "Il prezzo più basso della concorrenza",
                "margin_percentage": "Margine",
                "market_score": "Classifica",
                "number_of_cases": "Quantità",
                "number_of_cases_available": "Disponibilità",
                "portal_status": "Stato",
                "price_age_in_days": "Età del prezzo (giorni)",
                "price_per_case": "Prezzo per confezione",
                "product_name": "Nome del prodotto",
                "reference": "Riferimento",
                "stock_age": "Età dello stock",
                "total_value": "Valore totale"
            }
        },
        "title": {
            "cases_available": "Numero di casi disponibili",
            "category": "Categoria",
            "customs_status": "Stato doganale",
            "flags": "Proprietà dell'oggetto",
            "item_type": "Tipo di articolo",
            "offer_hash": "Offerte personali",
            "price_age": "Età del prezzo",
            "properties": "Proprietà",
            "relation": "Relazione",
            "relation_type": "Tipo di relazione",
            "stock_age": "Età dello stock"
        },
        "types": {
            "category": "Categorie",
            "packaging": {
                "+ Mug": "+ Tazza",
                "2 Glasses": "2 Bicchieri",
                "2 Golden Glasses": "2 Bicchieri d'oro",
                "Aluminium Bottle": "Bottiglia in alluminio",
                "Bottle": "Bottiglia",
                "Can": "Può",
                "Cannister": "Cannister",
                "Cradle": "Culla",
                "Flask": "Fiaschetta",
                "Frosted Bottle": "Bottiglia smerigliata",
                "GB + Jar of Cherries": "GB + barattolo di ciliegie",
                "GB with Cradle": "GB con culla",
                "Giftbag": "Borsa regalo",
                "Giftbox": "Scatola regalo",
                "Gifttin": "Scatola regalo",
                "Leather GB": "Pelle GB",
                "PET bottle": "Bottiglia in PET",
                "Pack": "Confezione",
                "Porcelain Bottle": "Bottiglia di porcellana",
                "Slightly dirty outercases": "Custodie leggermente sporche",
                "Square Bottle": "Bottiglia quadrata",
                "Wooden Giftbox": "Scatola regalo in legno"
            },
            "products": "Prodotti"
        },
        "units": {
            "%": "%",
            "cl": "cl",
            "cs": "cs",
            "day_one": "giorno",
            "day_other": "giorni"
        }
    },
    "forgot_password": {
        "confirmed": "L'e-mail è in arrivo. Controlla la tua casella di posta (e la cartella spam) per trovare l'e-mail con il link per la reimpostazione della password.",
        "description": "Hai dimenticato la password? Inserisci il tuo indirizzo e-mail qui sotto, clicca sul pulsante e ti verrà inviata un'e-mail che ti permetterà di reimpostare la tua password.",
        "email": "Il tuo indirizzo e-mail",
        "email_help": "L'indirizzo e-mail che utilizzi per il login",
        "start_flow": "Richiedi un'email di reset",
        "title": "Password dimenticata"
    },
    "issues": {
        "button": {
            "label": {
                "select_image": "Seleziona l'immagine",
                "submit_issue": "Invia il problema",
                "take_snapshot": "Scatta un'istantanea"
            }
        },
        "field": {
            "help": {
                "description": "Una descrizione concisa e completa del problema",
                "media": "Uno screenshot o un video che mostri il problema",
                "title": "Quattro o cinque parole che riassumono il problema",
                "url": "URL della pagina del problema"
            },
            "label": {
                "description": "Descrizione",
                "media": "Media",
                "title": "Titolo",
                "url": "URL"
            },
            "placeholder": {
                "description": "Una descrizione concisa e completa del problema, in modo da poterlo identificare meglio e più rapidamente.",
                "media": "Ctrl-v per incollare un'immagine",
                "title": "Quattro o cinque parole che riassumono il problema",
                "url": "URL della pagina del problema"
            }
        },
        "label": {
            "title": "Segnala un problema",
            "title_submitted": "Grazie per la segnalazione del bug!"
        },
        "support": {
            "help": {
                "template": "Il modello che viene utilizzato per i nuovi problemi di Gitlab"
            },
            "label": {
                "button_submit_issue": "Salva le impostazioni",
                "template": "Modello"
            },
            "placeholder": {
                "template": "Contiene un modello chiaro per la segnalazione ottimale dei bug."
            }
        },
        "text": {
            "submitted": "Grazie per aver inviato la segnalazione del tuo problema! Puoi controllare lo stato del tuo problema nel nostro <a href={{link}} target='_blank'>tracciatore di problemi</a>. Sentiti libero di aggiungere commenti al problema, sia per fornire un contesto aggiuntivo sia per avere ulteriori chiarimenti. Miglioriamo Discover & Portal un passo alla volta, grazie al tuo aiuto!"
        }
    },
    "landing": {
        "force_logout": "Spiacente, devi effettuare nuovamente il login...",
        "login": {
            "otp_code": "Gettone di sicurezza",
            "otp_code_help": "Inserisci qui il codice generato dall'applicazione",
            "step0_desc": "Utilizza le tue credenziali {{vendor}} per accedere. Se hai perso la password, puoi inserire il tuo indirizzo e-mail qui sotto e cliccare sul link \"Password dimenticata\".",
            "step0_slug": "Accesso al nostro portale",
            "step1_desc": "Per proteggere il tuo account da accessi non autorizzati, è necessaria un'app autenticatore aggiuntiva. Installa una di queste app di autenticazione sul tuo cellulare e scansiona il codice QR. Utilizza il token del tuo telefono per registrare il tuo dispositivo ed effettuare il login. Se qualcosa va storto durante il processo, inserisci nuovamente il token nella tua app. Non sei ancora riuscito? Contattaci per ricevere assistenza.",
            "step1_slug": "Sicurezza aggiuntiva",
            "step2_desc": "È necessario un token di sicurezza aggiuntivo. Controlla l'app dell'autenticatore per trovare il token. Hai perso l'accesso alla tua app Authenticator? Contattaci per ricevere aiuto.",
            "step2_slug": "Token di sicurezza richiesto",
            "subtitle": "Accedi per continuare...",
            "title": "Benvenuto su {{vendor}}!"
        }
    },
    "languages": {
        "de": "Tedesco",
        "en_gb": "Inglese",
        "es": "Spagnolo",
        "fr": "Francese",
        "it": "Italiano",
        "nl": "Olandese"
    },
    "my_portal": {
        "label": "Il mio portale",
        "logout": "Disconnessione",
        "settings": {
            "language": "Lingua",
            "language_help": "Generato dall'intelligenza artificiale; può contenere un uso scorretto del linguaggio"
        },
        "tip": "Il mio portale - Notizie, profilo e impostazioni"
    },
    "page": {
        "subtitle": {
            "forgot_password": "Reimposta la password",
            "reset_password": "Controlla la tua nuova password..."
        },
        "title": {
            "forgot_password": "Password dimenticata",
            "login": "Benvenuto su {{vendor}}!",
            "login_slug": "Accesso",
            "registration": "Registrazione",
            "reset_password": "Reimposta la password"
        }
    },
    "password_reset": {
        "confirmed": "La tua password è stata resettata",
        "description": {
            "new-account": "Dopo aver impostato la password, ti verrà chiesto di effettuare il login.",
            "reset": "Dopo aver reimpostato la password, ti verrà chiesto di accedere nuovamente."
        },
        "email": "Il tuo indirizzo e-mail",
        "email_help": "L'indirizzo e-mail che usi per il portale",
        "failed": "Richiesta di modifica della password fallita: \"{{reason}}\"",
        "new_password": "Nuova password",
        "new_password_help": "La tua password preferita; verifica con l'occhio se la tua password è corretta.",
        "new_password_repeat": "Ripeti la password",
        "new_password_repeat_help": "Ripeti la tua password",
        "reset_button": "Aggiorna la password",
        "title": {
            "new-account": "Crea password",
            "reset": "Aggiorna la password"
        }
    },
    "price": {
        "offer_until": "fine",
        "price_down": "Recentemente scontato da {{price}} {{currency}}"
    },
    "product": {
        "availability": {
            "eta": "Arrivo {{period}}",
            "stock": "In stock",
            "tbo": "Da ordinare"
        }
    },
    "profile": {
        "email": "Il tuo indirizzo e-mail",
        "email_help": "Il tuo indirizzo e-mail con cui effettuare il login",
        "email_placeholder": "you@yourbusiness.com",
        "forgot_pw": "Hai dimenticato la password?",
        "login": "Accedi",
        "password": "La tua password",
        "password_help": "La password per {{vendor}}",
        "password_placeholder": "Psst, è un segreto!"
    },
    "rating": {
        "rate": {
            "description": "Il tuo feedback ci aiuterà a migliorare il nostro servizio nei tuoi confronti",
            "placeholder": "Ti preghiamo di farci sapere se hai qualche suggerimento su come migliorare il tuo modo di ordinare.",
            "sent": "Grazie mille per il feedback!",
            "title": "Cosa ne pensi del nostro processo di ordinazione?"
        },
        "send.title": "Invia",
        "thanks": {
            "description": "Facciamo del nostro meglio per rendere il nostro servizio il migliore possibile. Se hai ulteriori domande o suggerimenti, contatta il tuo responsabile dell'assistenza.",
            "notification": "Grazie mille per il feedback!",
            "title": "Grazie a nome di {{team}}!"
        }
    },
    "sales_order_addition_types": {
        "discount": "Sconto",
        "other": "Altro",
        "pallet": "pallet",
        "revenue": "Entrate",
        "transport": "trasporto",
        "voucher": "Voucher",
        "waste_fund": "Fondo rifiuti"
    },
    "settings": {
        "beta": {
            "active": "Modalità beta attivata",
            "inactive": "Modalità beta disattivata"
        }
    },
    "status": {
        "error": {
            "login": {
                "credentials_invalid": "Si è verificato un problema con le tue credenziali di accesso! Verifica il tuo indirizzo e-mail e la tua password."
            },
            "message": "Si è verificato un errore inatteso; riprova più tardi (riferimento: {{message}})",
            "order": {
                "delivery_invalid": "La condizione di consegna non è soddisfatta"
            },
            "otp": {
                "invalid": "Il token di autenticazione non è valido; controlla l'app Authenticator.",
                "invalid_notify": "Il tuo token di autenticazione non è valido. Controlla la tua applicazione per trovare il codice corretto. Se il problema persiste, controlla che le impostazioni di data e ora del tuo dispositivo siano corrette.",
                "length": "Il token di autenticazione è composto da 6 cifrari"
            },
            "unknown": "Ci dispiace! Si è verificato un errore inaspettato. Stiamo cercando di prevenire questo tipo di problemi. Riprova più tardi per vedere se il problema è già stato risolto. Contattaci se il problema persiste a lungo. Grazie per la tua pazienza."
        },
        "success": {
            "login": {
                "forgot_pw": "Un'e-mail con le istruzioni per reimpostare la password viene inviata a {{email}} se si tratta di un indirizzo e-mail valido. Nessuna e-mail? Verifica l'indirizzo e-mail e controlla la cartella spam. Se non hai ricevuto alcuna e-mail, contattaci."
            },
            "redeem": {
                "verified": "Congratulazioni! Hai utilizzato con successo il codice per: {{name}}!"
            }
        }
    },
    "support": {
        "contact": {
            "collapse": "Seleziona i dettagli dell'assistenza",
            "email": "Email di contatto: {{contact}}",
            "mobile": "Cellulare di contatto: {{contact}}",
            "phone": "Telefono di contatto: {{contact}}",
            "support_manager": "manager di supporto",
            "team_etr": "Squadra A2BC",
            "team_msp": "Team Moving Spirits",
            "tech_support": "Tech Supporto"
        },
        "roles": {
            "default": "Responsabile dell'assistenza",
            "sales_nl": "Commerciante Paesi Bassi",
            "tech": "Assistenza tecnica"
        }
    },
    "system": {
        "incompatible": "Il tuo browser non soddisfa i requisiti minimi; aggiorna prima il tuo browser ({{code}})",
        "reload_portal": "Ricarica il portale",
        "scheme_change": "Un recente aggiornamento richiede un nuovo login"
    },
    "unit": {
        "bottle_one": "bottiglia",
        "bottle_other": "bottiglie",
        "case_one": "caso",
        "case_other": "casi",
        "percentage": "%"
    },
    "validation": {
        "email": "Sembra che questo sia un indirizzo e-mail non valido.",
        "form": {
            "action_required": "Questi campi richiedono la vostra attenzione:",
            "valid": "Il modulo sembra valido"
        },
        "length": {
            "fixed_length": "Questo campo richiede esattamente {{fixed_length}} caratteri",
            "max_length": "Questo campo non richiede più di {{max_length}} caratteri",
            "min_length": "Questo campo richiede almeno {{min_length}} caratteri"
        },
        "must_match": "I campi devono corrispondere",
        "password": {
            "cannot_end_with_space": "La password non può terminare con uno spazio",
            "min_length": "La password richiede almeno 9 caratteri",
            "one_lower_case": "La password deve contenere almeno una lettera minuscola",
            "one_upper_case": "La password deve contenere almeno una lettera maiuscola"
        },
        "required": "Questo campo è obbligatorio",
        "vat_id": {
            "country_incorrect": "L'ID IVA ha un codice paese errato; atteso: '{{expected}}'",
            "help_disabled": "L'ID IVA non è supportato per i paesi non UE: {{country}}",
            "help_validate": "Usa il pulsante Convalida per verificare l'ID IVA del paese: {{country}}",
            "invalid_format": "<a href='{{link}}' target='_blank'>Formato ID IVA</a> non valido per il paese: {{country_code}}",
            "label": "PARTITA IVA ({{country}})",
            "verify": "Verifica l'ID",
            "vies_match": "Partita IVA: {{match}}",
            "vies_nomatch": "Questo ID IVA sembra non essere valido"
        },
        "website": "Questo non è un indirizzo web valido"
    }
}