import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, Icon} from '@bitstillery/common/components'
import {blob_to_base64} from '@bitstillery/common/lib/utils'
import {proxy} from '@bitstillery/common/lib/proxy'
import {$t} from '@bitstillery/common/app'

let current_stream

interface FieldMediaUploadAttrs {
    disabled?: boolean
    model: {
        data: string
        name: string
        content_type: string
    }
    help?: string
    label?: string
    placeholder?: string
    icon?: string
    className?: string

    onafter_media: () => unknown
    onbefore_media: () => unknown

    validation: {
        dirty: boolean
        label: string
        _invalid?: {
            message: string
        }
    }
}

export class FieldMediaUpload extends MithrilTsxComponent<FieldMediaUploadAttrs> {

    data = proxy({
        blob_url: '',
    })

    $container: HTMLElement
    canvas = document.createElement('canvas')
    video = document.createElement('video')
    event_listener: (any) => void

    async prepare_media(vnode: m.Vnode<FieldMediaUploadAttrs>, blob) {
        if (vnode.attrs.validation) {
            vnode.attrs.validation.dirty = true
        }
        this.data.blob_url = URL.createObjectURL(blob)
        this.$container.style.backgroundImage = `url(${this.data.blob_url})`
        if (blob.type.startsWith('video/')) {
            const video = document.createElement('video')
            video.src = URL.createObjectURL(blob)
            video.addEventListener('loadeddata', () => {
                const canvas = document.createElement('canvas')
                canvas.width = video.videoWidth
                canvas.height = video.videoHeight
                const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
                const background_image = canvas.toDataURL('image/png')
                this.$container.style.backgroundImage = `url(${background_image})`

                // Cleanup
                URL.revokeObjectURL(video.src)
            }, {once: true})
        } else {
            this.data.blob_url = URL.createObjectURL(blob)
            this.$container.style.backgroundImage = `url(${this.data.blob_url})`
        }
        vnode.attrs.model.data = await blob_to_base64(blob)
        vnode.attrs.model.content_type = blob.type
    }

    oncreate(vnode: m.Vnode<FieldMediaUploadAttrs>) {
        this.$container = document.querySelector('.js-snapshot') as HTMLElement

        async function listener(event) {
            if (vnode.attrs.disabled) {
                return
            }
            const items = event.clipboardData.items
            for (const item of items) {
                if (item.type.startsWith('image/')) {
                    const blob = item.getAsFile()
                    if (blob) {
                        this.prepare_media(vnode, blob)
                    }
                }
            }
        }

        this.event_listener = listener.bind(this) // save for the remove
        document.addEventListener('paste', this.event_listener)
    }

    on_remove() {
        document.removeEventListener('paste', this.event_listener)
    }

    view(vnode: m.Vnode<FieldMediaUploadAttrs>) {
        const validation = vnode.attrs.validation

        const invalid = validation ? validation._invalid : false
        const disabled = vnode.attrs.disabled
        return <div className={classes('c-field-media-upload', 'field', vnode.attrs.className, {
            disabled: disabled,
            invalid: validation && invalid && validation.dirty,
            valid: validation && !invalid && validation.dirty,
        })}>
            {vnode.attrs.label && (
                <label>{vnode.attrs.label}
                    {vnode.attrs.icon && <Icon name={vnode.attrs.icon}/>}
                    {vnode.attrs.validation && <span className="validation">{validation.label}</span>}
                </label>
            )}

            <div className="media-area">
                <div className={classes('snapshot js-snapshot', {
                    'contains-image': this.data.blob_url,
                })}>
                    <Icon name="image" type="unset" />
                    <p>{vnode.attrs.placeholder}</p>
                </div>

                <div className="actions">
                    <Button
                        icon="screenshot"
                        onclick={async() => {
                            if (vnode.attrs.onbefore_media) {
                                vnode.attrs.onbefore_media()
                            }
                            if (!current_stream) {
                                const stream = await navigator.mediaDevices.getDisplayMedia()
                                this.video.srcObject = stream
                                await this.video.play()
                                // Wait until the video is ready to be captured
                                while (this.video.readyState < 3) {
                                    await new Promise(resolve => setTimeout(resolve, 100)) // Wait for 100ms before checking again
                                }
                            }

                            this.canvas.width = this.video.videoWidth
                            this.canvas.height = this.video.videoHeight
                            this.canvas.getContext('2d')?.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height)
                            if (vnode.attrs.onafter_media) {
                                vnode.attrs.onafter_media()
                            }
                            const blob = await new Promise(resolve => this.canvas.toBlob(resolve, 'image/png')) as any
                            this.prepare_media(vnode, blob)
                        }}
                        tip={$t('issues.button.label.take_snapshot')}
                        type="info"
                        variant="toggle"
                    />
                    <Button
                        icon="upload"
                        tip={$t('issues.button.label.select_image')}
                        type="info"
                        variant="toggle"
                        onclick={() => {
                            const file_input = document.createElement('input')
                            file_input.type = 'file'
                            file_input.onchange = (e) => {
                                // File and Blob apparently share the same api.
                                const file = e.target?.files[0]
                                if (!file) {
                                    return
                                }
                                vnode.attrs.model.name = file.name
                                vnode.attrs.model.content_type = file.type
                                this.prepare_media(vnode, file)
                            }

                            file_input.click()
                        }}
                    />
                    <Button
                        disabled={!this.data.blob_url}
                        icon="trash"
                        type="info"
                        variant="toggle"
                        onclick={() => {
                            vnode.attrs.model.data = ''
                            this.data.blob_url = ''
                            this.$container.style.backgroundImage = `url(${this.data.blob_url})`
                        }}
                    />
                </div>
            </div>
            {(() => {
                if (invalid && validation.dirty) {
                    return <div className="help validation">{invalid.message}</div>
                } else if (vnode.attrs.help) {
                    return <div className="help">{vnode.attrs.help}</div>
                }
            })()}
        </div>
    }
}
