import {jsPDF} from 'jspdf'
import {countries} from '@bitstillery/common/lib/countries'
import {DateTime} from 'luxon'

import {Account} from '../models/account'
import {SalesOrder} from '../models/sales_order'
import {format_iso_to_date} from '../ts_utils'
import {to_specs_with_features} from '../models/item'

import {CasesOrBottles, PDF} from './pdf'

export interface OuttakeInstructionRenderer {
    sales_order: SalesOrder
    account: Account
    cases_or_bottles: CasesOrBottles
    decimal_locale: string
}

/**
 * Maps the ArrivalNoticeRendererAttrs to base64 encoded string containing the Arrival Notice.
 *
 * @param attrs Needed data for the pdf (purchase order)
 * @returns Base64 encoded string for the created PDF.
 */
export function outtake_instruction_renderer(attrs: OuttakeInstructionRenderer): jsPDF {
    const pdf = new PDF(attrs.account)
    const doc: jsPDF = pdf.document

    doc.setProperties({
        title: `Packing list - ${attrs.sales_order.reference} - ${attrs.sales_order.supplier.name}`,
        author: attrs.account.name,
        keywords: 'Packing list, Sales',
        creator: attrs.account.name,
        subject: `Packing list - ${attrs.sales_order.reference} - ${attrs.sales_order.supplier.name}`,
    })

    pdf.title('Packing list')
    pdf.relation_address([
        attrs.sales_order.supplier.name,
        attrs.sales_order.supplier.street_address,
        attrs.sales_order.supplier.zip_code
            ? `${attrs.sales_order.supplier.zip_code} ${attrs.sales_order.supplier.city}`
            : attrs.sales_order.supplier.city,
        countries[attrs.sales_order.supplier.country_code],
    ])

    pdf.incoterm(`${attrs.sales_order.incoterm} - ${attrs.sales_order.incoterm_location}`)

    /* Destination */
    if (attrs.sales_order.destination && attrs.sales_order.destination.name) {
        const destination_address = []
        if (attrs.sales_order.destination.destination_type === 'warehouse') {
            destination_address.push(
                attrs.sales_order.destination.name,
                attrs.sales_order.destination.street_address,
                `${attrs.sales_order.destination.zip_code} ${attrs.sales_order.destination.city}`,
                countries[attrs.sales_order.destination.country_code],
            )
        }
        else {
            destination_address.push(
                attrs.sales_order.destination.name + ' - ' + countries[attrs.sales_order.destination.country_code],
            )
        }
        if (attrs.sales_order.destination.warehouse_id) {
            destination_address.push(`Location ID: ${attrs.sales_order.destination.warehouse_id}`)
        }
        if (attrs.sales_order.destination.excise_id) {
            destination_address.push(`Excise ID: ${attrs.sales_order.destination.excise_id}`)
        }
        destination_address.push('')
        pdf.destination_address(destination_address)
    } else {
        pdf.destination_address(attrs.sales_order.destination_location?.split('\n') || [])
    }

    /* Details */
    pdf.details(
        'Details:',
        ['Reference:', 'Date:', 'Invoice date:'],
        [
            attrs.sales_order.reference,
            format_iso_to_date(DateTime.now().toISODate()),
            format_iso_to_date(attrs.sales_order.is_invoiced_on) || '-',
        ],
    )
    let unit_quantity = 'cs.'
    if (attrs.cases_or_bottles === CasesOrBottles.bottles) {
        unit_quantity = 'btls.'
    }

    const soi_items = attrs.sales_order.sales_order_items
        .sort((source_a, source_b) => {
            return source_a.item.case.bottle.product.name > source_b.item.case.bottle.product.name ? 1 : -1
        })
        .map((soi) => {
            /* Quantity */
            let quantity = soi.number_of_cases
            if (attrs.cases_or_bottles === CasesOrBottles.bottles) {
                quantity = soi.number_of_bottles
            }

            return [
                pdf.text_cell(soi.item.lot),
                pdf.text_cell(`${quantity} ${unit_quantity}`),
                pdf.text_cell(soi.item.case.bottle.product.name),
                pdf.text_cell(to_specs_with_features(soi.item.case, soi.item.case.bottle, attrs.decimal_locale)),
                pdf.text_cell(soi.item.case.customs_status),
            ]
        })

    const main_table = pdf.auto_table(
        attrs.account,
        'en',
        ['Lot No.', 'Quantity', 'Description', '', 'Customs'],
        soi_items,
    )
    /* Total quantity */
    let total_quantity_soi = attrs.sales_order.sales_order_items.reduce(
        (previous, current) => current.number_of_cases + previous,
        0,
    )
    let total_quantity = `${total_quantity_soi} cs.`
    if (attrs.cases_or_bottles === CasesOrBottles.bottles) {
        total_quantity_soi = attrs.sales_order.sales_order_items.reduce(
            (previous, current) => current.number_of_bottles + previous,
            0,
        )
        total_quantity = `${total_quantity_soi} btls. (${total_quantity})`
    }
    const lowest_table = pdf.total_quantity(pdf.text_cell(`${total_quantity}`), main_table, 1)
    /* Render the comments, if any. */
    if (attrs.sales_order.outtake_instruction_comment) {
        pdf.additional_string_table(
            lowest_table,
            ['Comments:'],
            attrs.sales_order.outtake_instruction_comment.split('\n'),
        )
    }

    return doc
}
