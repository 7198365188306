import {$m, $s} from '@/app'

const pricelist_endpoints = ['portal/offer-items', 'portal/filter/statistics']

function pricelist_available(request, response) {
    if (response.status === 404 && pricelist_endpoints.includes(request.endpoint)) {
        $s.portal.ready = false
        return false
    } else if (response.status === 401) {
        $m.identity.logout_debounced()
        return false
    }
    return true
}

export default {
    get: [pricelist_available],
    post: [pricelist_available],
}
