import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Notification} from '@bitstillery/common/components'

import {NotificationData} from './notifier'

interface NotificationGroupAttrs {
    notifications: NotificationData[]
}

export class NotificationGroup extends MithrilTsxComponent<NotificationGroupAttrs> {

    notifications: NotificationData[]

    constructor(vn: m.Vnode<NotificationGroupAttrs>) {
        super()
        this.notifications = vn.attrs.notifications
    }

    on_close(notification) {
        this.notifications.splice(this.notifications.findIndex(i => i.id === notification.id), 1)
    }

    view(vn) {
        return (
            <div className="c-notifications">
                {vn.attrs.notifications.map((notification) =>
                    <Notification notification={notification} on_close={this.on_close.bind(this)} key={notification.id} />,
                )}
                {vn.children}
            </div>
        )
    }

}
