{
    "collection": {
        "bulk": {
            "tip": {
                "cancel": "Cancel bulk edit (undo all unpersisted changes)",
                "cancel_changes": "Cancel bulk edit (undo all unpersisted changes)",
                "persist_one": "Persist changed bulk item",
                "persist_other": "Persist {{count}} changed bulk items",
                "toggle": "Toggle bulk edit mode"
            }
        },
        "exclude_label": "Exclude",
        "exclude_one": "Exclude {{count}} item",
        "exclude_other": "Exclude {{count}} items",
        "exclude_tip": "Exclude items; {{count}} items currently included",
        "include_label": "Include",
        "include_one": "Include {{count}} item",
        "include_other": "Include {{count}} items",
        "include_tip": "Include items; {{count}} items already included",
        "modify_selection": "Modify selection",
        "no_results": "No results found",
        "no_results_clear_filters": "Did you try clearing the active filters?"
    },
    "contact": {},
    "data_card": {
        "text": "Data Card",
        "tip": "Toggle Data Card"
    },
    "dialog": {
        "cancel": "Cancel"
    },
    "filters": {
        "actions": {
            "clear_filter_selection": "Clear filter: {{name}}/{{selection}}",
            "deactivate_filter": "Deactivate filter",
            "pricelist": "Pricelist"
        },
        "clear": "Clear Filters",
        "collapse": "Collapse Filters",
        "collapse_tip": "Collapse all Filters",
        "expand": "Expand Filters",
        "expand_tip": "Expand all Filters",
        "inactive_filter": "Filter is inactive",
        "multi_selection": {},
        "no_results": "No Products",
        "no_results_hint": {
            "filters": "Modify your active filters?",
            "search": "Modify your search terms?"
        },
        "no_selection": "No items selected",
        "panel_title": "Filters",
        "personal_offer": "Personal",
        "reset_disabled": "Enable filters to use this option",
        "reset_one": "Reset filter",
        "reset_other": "Reset {{count}} filters",
        "sort": {
            "direction": "Sort order",
            "direction_tip": "Change sort order",
            "name": "Sort",
            "options": "Sort options",
            "reset": "Reset sort",
            "types": {
                "avg_purchase_price": "Purchase price",
                "created_by": "Created by",
                "created_on": "Created on",
                "delivery_period": "Delivery time",
                "entry_date": "Entry date",
                "invoice_number": "Invoice number",
                "is_invoiced_on": "Invoice date",
                "item_stock_age": "Stock age",
                "item_throughput": "Throughput",
                "last_price_change_age": "Price age",
                "list_price": "Sales price",
                "list_quantity": "Quantity",
                "lowest_price_competitors": "Lowest competitor price",
                "margin_percentage": "Margin",
                "market_score": "Rank",
                "number_of_cases": "Quantity",
                "number_of_cases_available": "Availability",
                "portal_status": "Status",
                "price_age_in_days": "Price age (days)",
                "price_per_case": "Base Sales price",
                "product_name": "Product name",
                "reference": "Referentie",
                "stock_age": "Stock age",
                "total_value": "Total value"
            }
        },
        "title": {
            "cases_available": "Number of cases available",
            "category": "Category",
            "customs_status": "Customs status",
            "flags": "Item Properties",
            "item_type": "Item Type",
            "offer_hash": "Personal Offers",
            "price_age": "Price age",
            "properties": "Properties",
            "relation": "Relation",
            "relation_type": "Relation type",
            "stock_age": "Stock age"
        },
        "types": {
            "category": "Categories",
            "packaging": {
                "+ Mug": "+ Mug",
                "2 Glasses": "2 Glasses",
                "2 Golden Glasses": "2 Golden Glasses",
                "Aluminium Bottle": "Aluminium Bottle",
                "Bottle": "Bottle",
                "Can": "Can",
                "Cannister": "Cannister",
                "Cradle": "Cradle",
                "Flask": "Flask",
                "Frosted Bottle": "Frosted Bottle",
                "GB + Jar of Cherries": "GB + Jar of Cherries",
                "GB with Cradle": "GB with Cradle",
                "Giftbag": "Giftbag",
                "Giftbox": "Giftbox",
                "Gifttin": "Gift tin",
                "Leather GB": "Leather GB",
                "PET bottle": "PET bottle",
                "Pack": "Pack",
                "Porcelain Bottle": "Porcelain Bottle",
                "Slightly dirty outercases": "Slightly dirty outercases",
                "Square Bottle": "Square Bottle",
                "Wooden Giftbox": "Wooden Giftbox"
            },
            "products": "Products"
        },
        "unit": {},
        "units": {
            "%": "%",
            "cl": "cl",
            "cs": "cs",
            "day_one": "day",
            "day_other": "days"
        }
    },
    "forgot_password": {
        "confirmed": "The email is on its way. Please check your inbox (and your spam folder) for the email with the reset password link.",
        "description": "Forgot your password? Enter your email address below, click on the button and an email will be sent to you which enables you to reset your password.",
        "email": "Your email address",
        "email_help": "The email address you use to login",
        "start_flow": "Request reset email",
        "title": "Forgot password"
    },
    "issues": {
        "button": {
            "label": {
                "select_image": "Select image",
                "submit_issue": "Submit issue",
                "take_snapshot": "Take snapshot"
            }
        },
        "field": {
            "help": {
                "description": "A concise and complete description of the issue",
                "media": "A screenshot or video showing the issue",
                "title": "Four or five words summarizing the issue",
                "url": "URL to the issue page"
            },
            "label": {
                "description": "Description",
                "media": "Media",
                "title": "Title",
                "url": "URL"
            },
            "placeholder": {
                "description": "A concise and complete description of the issue, so we can identify the issue better and faster.",
                "media": "Ctrl-v to paste an image",
                "title": "Four or five words summarizing the issue",
                "url": "URL to the issue page"
            }
        },
        "label": {
            "title": "Report an issue",
            "title_submitted": "Thank you for your bug report!"
        },
        "support": {
            "help": {
                "template": "The template that is being used for new Gitlab issues"
            },
            "label": {
                "button_submit_issue": "Save Settings",
                "template": "Template"
            },
            "placeholder": {
                "template": "This contains a clear template for optimal bug reports."
            }
        },
        "text": {
            "submitted": "Thank you for submitting your issue report! You can check the status of your issue in our <a href={{link}} target='_blank'>issue tracker</a>. Feel free to add related comments in the issue; either to provide additional context or just if you would like some additional clarifications. We make Discover & Portal better one step at a time, thanks to your help!"
        }
    },
    "landing": {
        "force_logout": "Sorry, you need to log in again...",
        "login": {
            "otp_code": "Security Token",
            "otp_code_help": "Enter the code generated by the app here",
            "step0_desc": "Use your {{vendor}} credentials for access. In case you lost your password, you can fill in your email address below and click the \"Forgot password\" link.",
            "step0_slug": "Logging in",
            "step1_desc": "An additional authenticator app is required to keep your account save from unauthorized access. Install one of these authenticator app suggestions on your mobile phone and scan the QR-code. Use the token from your phone to register your device and login. Please re-add the token in your app if anything goes wrong during the process. Still no luck? Contact us for support.",
            "step1_slug": "Additional Security",
            "step2_desc": "An additional security token is required. Please check your authenticator app for the token. Lost access to your authenticator app? Contact us for help.",
            "step2_slug": "Security Token Required",
            "subtitle": "Login to continue...",
            "title": "Welcome to {{vendor}}!"
        }
    },
    "languages": {
        "de": "German",
        "en_gb": "English",
        "es": "Spanish",
        "fr": "French",
        "it": "Italian",
        "nl": "Dutch"
    },
    "my_portal": {
        "label": "My Portal",
        "logout": "Logout",
        "settings": {
            "language": "Language",
            "language_help": "Generated by AI; may contain incorrect use of language"
        },
        "tip": "My Portal - News, Profile & Settings"
    },
    "page": {
        "subtitle": {
            "forgot_password": "Reset your password",
            "reset_password": "Please double-check your new password..."
        },
        "title": {
            "forgot_password": "Forgot password",
            "login": "Welcome to {{vendor}}!",
            "login_slug": "Login",
            "registration": "Registration",
            "reset_password": "Reset password"
        }
    },
    "password_reset": {
        "confirmed": "Your password has been reset",
        "description": {
            "new-account": "After setting your password, you will be asked to log in.",
            "reset": "After resetting your password, you will be asked to log in again."
        },
        "email": "Your email-address",
        "email_help": "The email-address you use for the portal",
        "failed": "Request to change your password failed: \"{{reason}}\"",
        "new_password": "New Password",
        "new_password_help": "Your preferred password; check with the eye whether your password is correct",
        "new_password_repeat": "Repeat password",
        "new_password_repeat_help": "Repeat your password",
        "reset_button": "Update Password",
        "title": {
            "new-account": "Create Password",
            "reset": "Update Password"
        }
    },
    "price": {
        "offer_until": "ends",
        "price_down": "Recently discounted from {{price}} {{currency}}"
    },
    "product": {
        "availability": {
            "eta": "Arrival ~ {{period}}",
            "stock": "In Stock",
            "tbo": "To Be Ordered"
        }
    },
    "profile": {
        "email": "Your email address",
        "email_help": "Your email address to login with",
        "email_placeholder": "you@yourbusiness.com",
        "forgot_pw": "Forgot your password?",
        "login": "Log in",
        "password": "Your password",
        "password_help": "The password for {{vendor}}",
        "password_placeholder": "Psst, it's a secret!"
    },
    "rating": {
        "rate": {
            "description": "Your feedback will help us to improve our service to you",
            "placeholder": "Please let us know if you have any suggestions on how we can improve how you order.",
            "sent": "Thank you very much for the feedback!",
            "title": "What do you think about our order process?"
        },
        "send.title": "Send",
        "thanks": {
            "description": "We do our best to make our service as good as possible. If you have any further questions or suggestions, please contact your support manager.",
            "notification": "Thank you very much for the feedback!",
            "title": "Thank you on behalf of {{team}}!"
        }
    },
    "sales_order_addition_types": {
        "discount": "Discount",
        "other": "Other",
        "pallet": "pallet",
        "revenue": "Revenue",
        "transport": "tranport",
        "voucher": "Voucher",
        "waste_fund": "Waste Fund"
    },
    "settings": {
        "beta": {
            "active": "Beta mode activated",
            "inactive": "Beta mode disabled"
        }
    },
    "status": {
        "error": {
            "login": {
                "credentials_invalid": "There is a problem with your login credentials! Please verify your email address and password."
            },
            "message": "An unexpected error occurred; please try again later (reference: {{message}})",
            "order": {
                "delivery_invalid": "The delivery condition is not met"
            },
            "otp": {
                "invalid": "Authentication token is invalid; please check your authenticator app.",
                "invalid_notify": "Your Authentication token is invalid. Please check your app for the right code. When the problem persists, check whether your device date/time settings are accurate.",
                "length": "Authentication token is 6 ciphers"
            },
            "unknown": "We're sorry! An unexpected error occurred. We are actively anticipating these kind of problems. Please try again later to see if the issue has been solved yet. Contact us if the issue persists for a longer time. Thank you for your patience."
        },
        "success": {
            "login": {
                "forgot_pw": "An email with instructions to reset your password is sent to {{email}} if it is a valid email address. No email? Verify the email address and check your spam folder. Please contact us if you still didn't receive any email."
            },
            "redeem": {
                "verified": "Congratulations! You successfully used the code for: {{name}}!"
            }
        }
    },
    "support": {
        "contact": {
            "collapse": "Toggle support details",
            "email": "Contact email: {{contact}}",
            "mobile": "Contact mobile: {{contact}}",
            "phone": "Contact phone: {{contact}}",
            "support_manager": "Your support manager",
            "team_etr": "Team A2BC",
            "team_msp": "Team Moving Spirits",
            "tech_support": "Tech Support"
        },
        "roles": {
            "default": "Support Manager",
            "sales_nl": "Trader Netherlands",
            "tech": "Tech Support"
        }
    },
    "system": {
        "incompatible": "Your browser doesn't meet the minimum requirements; please update your browser first ({{code}})",
        "reload_portal": "Reload Portal",
        "scheme_change": "A recent update requires you to login again"
    },
    "unit": {
        "bottle_one": "bottle",
        "bottle_other": "bottles",
        "case_one": "case",
        "case_other": "cases",
        "percentage": "%"
    },
    "validation": {
        "email": "This seems to be an invalid email-address",
        "form": {
            "action_required": "These fields require your attention:",
            "valid": "The form seems valid"
        },
        "length": {
            "fixed_length": "This field requires precisely {{fixed_length}} characters",
            "max_length": "This field requires no more than {{max_length}} characters",
            "min_length": "This field requires at least {{min_length}} characters"
        },
        "must_match": "The fields must match",
        "password": {
            "cannot_end_with_space": "Password cannot end with a space character",
            "min_length": "Password requires at least 9 characters",
            "one_lower_case": "Password must contain at least one lower case letter",
            "one_upper_case": "Password must contain at least one upper case letter"
        },
        "required": "This field is required",
        "vat_id": {
            "country_incorrect": "VAT ID has an incorrect country-code; expected: '{{expected}}'",
            "help_disabled": "VAT ID is not supported for non-EU country: {{country}}",
            "help_validate": "Use the Validate button to verify VAT ID for country: {{country}}",
            "invalid_format": "Invalid <a href='{{link}}' target='_blank'>VAT ID format</a> for country: {{country_code}}",
            "label": "VAT ID ({{country}})",
            "verify": "Verify ID",
            "vies_match": "VAT ID match: {{match}}",
            "vies_nomatch": "This VAT ID seems invalid"
        },
        "website": "This is not a valid website address"
    }
}