import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'
import {Button, FieldText} from '@bitstillery/common/components'
import {and, must_match, password, required, validation} from '@bitstillery/common/lib/validation'
import {$m, $s, $t, api, notifier} from '@bitstillery/common/app'

export class AuthResetPassword extends MithrilTsxComponent<any> {

    data = (() => {
        const $f = {
            password: '',
            password_repeat: '',
        }

        return proxy({$f})
    })()

    $v = {
        password: validation([this.data.$f, 'password'], and([required(), password()])),
        password_repeat: validation([this.data.$f, 'password_repeat'], and([
            required(), password(), must_match([this.data.$f, 'password']),
        ])),
    }

    request_id = m.route.param('request_id')
    is_new_account = m.route.param('is_new_account')

    oninit() {
        $s.page.title = $t('page.title.reset_password', {vendor: process.env.MSI_TITLE})
        $s.page.subtitle = $t('page.subtitle.reset_password')
    }

    async submit() {
        if (process.env.MSI_PACKAGE === 'portal') {
            const data = {
                new_password: this.data.$f.password,
                request_id: this.request_id,
            }
            const {result, status_code} = await api.post('portal/user/reset-password', data, true)
            if (status_code > 299) {
                notifier.notify($t('password_reset.failed', {reason: result.detail}), 'danger', 5000)
                return
            }
        } else {
            const data = {
                password: this.data.$f.password,
                request_id: this.request_id,
            }

            const {result, status_code} = await api.post('auth.reset_password', data)
            if (status_code > 299) {
                notifier.notify($t('password_reset.failed', {reason: result.message}), 'danger', 5000)
                return
            }
        }

        notifier.notify($t('password_reset.confirmed'), 'info')
        $m.identity.logout()
    }

    view() {
        const new_or_reset = this.is_new_account ? 'new-account' : 'reset'
        return (
            <div className="c-auth-reset-password landing">
                <div className="form stepper-form">
                    <div className="step">
                        <h3>{$t(`password_reset.title.${new_or_reset}`)}</h3>
                        <p className="step-info">{$t(`password_reset.description.${new_or_reset}`)}</p>

                        <FieldText
                            label={$t('password_reset.new_password')}
                            ref={[this.data.$f, 'password']}
                            help={$t('password_reset.new_password_help')}
                            type="password"
                            validation={this.$v.password}
                        />

                        <FieldText
                            label={$t('password_reset.new_password_repeat')}
                            ref={[this.data.$f, 'password_repeat']}
                            help={$t('password_reset.new_password_repeat_help')}
                            type="password"
                            validation={this.$v.password_repeat}
                        />
                    </div>

                    <Button
                        disabled={!!Object.values(this.$v).filter((i) => i && i._invalid).length}
                        onclick={this.submit.bind(this)}
                        text={$t('password_reset.reset_button')}
                        type="success"
                    />
                </div>
            </div>
        )
    }
}
