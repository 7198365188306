import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {Changed, Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {$t} from '@bitstillery/common/app'

import {$m, $s} from '@/app'

export class CartErrors extends MithrilTsxComponent<any> {
    view(_vn:m.Vnode<any>) {
        const errors = $s.cart.errors
        // Filter error categories with errors and with related offer_items.
        // Api errors may not have related offer_items; these are shown so
        // a custumer can remove the offending item as a last resort.
        const issues = Object.entries(errors).filter((i) => Object.keys(i[1]).length)
        return (
            <div className={classes('c-cart-errors', {
                collapsed: $s.panels.context.collapsed,
            })}>
                {issues.map(([errorCategory, errorEntries]) => {
                // We don't have to extra notify about a dropped price.
                    const entries = Object.entries(errorEntries)
                    return <div className={classes('category', {
                        [$m.cart.VALIDATION_TYPES[errorCategory]]: true,
                    })}>
                        <div className="header">
                            <Icon
                                name={$m.cart.VALIDATION_ICONS[errorCategory]}
                                type={$m.cart.VALIDATION_TYPES[errorCategory]}
                                tip={$t('cart.issues_action')}
                            />
                            <span>{$t(`cart.error_${errorCategory}`)}</span>
                        </div>
                        <div className="body">
                            {entries.map(([case_artkey]) => {
                                const cart_item = $s.cart.items[case_artkey]
                                if (!cart_item) return
                                let amount
                                const cart_units = $m.offer.unit_amount(cart_item.number_of_cases, cart_item.case_number_of_bottles)
                                if (errorCategory === 'price_up') {
                                    const old_price = $m.offer.unit_price(errors.price_up[case_artkey].basket_price, cart_item.case_number_of_bottles)
                                    const new_price = $m.offer.unit_price(errors.price_up[case_artkey].list_price, cart_item.case_number_of_bottles)
                                    amount = <Changed
                                        currency={$s.identity.user.currency}
                                        current={new_price}
                                        previous={old_price}
                                        type="warning"
                                    />
                                } else if (errorCategory === 'less_available') {
                                    const available_units = $m.offer.unit_amount(errors.less_available[case_artkey], cart_item.case_number_of_bottles)
                                    amount = <Changed
                                        current={available_units}
                                        previous={cart_units}
                                        type="warning"
                                    />
                                } else if (errorCategory === 'minimum_quantity') {
                                    const min_quantity_units = $m.offer.unit_amount(errors.minimum_quantity[case_artkey], cart_item.case_number_of_bottles)
                                    amount = <Changed
                                        current={min_quantity_units}
                                        previous={cart_units}
                                        type="warning"
                                    />
                                } else if (errorCategory === 'unavailable') {
                                    amount = <del>{cart_units}</del>
                                }

                                return <div className="issue">
                                    <div className="product-name">{cart_item.product_name}</div>
                                    <div className="amount">
                                        {amount}
                                    </div>
                                </div>
                            })}

                            <div className="help">
                                <Icon
                                    name="info"
                                    type="default"
                                    size="s"
                                />
                                {$t(`cart.error_help.${errorCategory}`)}
                            </div>
                        </div>
                    </div>
                })}
            </div>
        )}
}
