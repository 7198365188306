import {Case, Item} from './item'

export interface SalesOrderItem {
    artkey: number
    item: Item
    description: string | null
    number_of_cases: number
    price_per_case: string
    number_of_bottles: number
    vat_percentage: string
    total_was_sold_for: string
    vat_total: string
    vat_per_case: string
}

export interface SalesOrderTBOItem {
    artkey: number
    case: Case
    number_of_cases: number
    price_per_case: string
    vat_percentage: string
    vat_total: string
    total_was_sold_for_incl_excise: string
}

export interface SalesOrderCreditItem {
    artkey: number
    number_of_cases: number
    sales_order_item: SalesOrderItem
}

export interface SalesOrderAdditional {
    artkey: number
    description: string | null
    quantity: number
    price_per_unit: string
    total_value: string
    vat_percentage: string
}

export interface SalesOrder {
    artkey: number
    reference: string
    incoterm_location: string
    incoterm: string
    invoice_number: number | null
    includes_excise: boolean
    is_invoiced_on: string | null
    was_sold_in: string
    was_sold_for: string
    vat_total: string
    invoice_comment: string
    outtake_instruction_comment: string | null

    sales_order_items: SalesOrderItem[]
    sales_order_tbo_items: SalesOrderTBOItem[]
    sales_order_credit_items: SalesOrderCreditItem[]
    sales_order_additionals: SalesOrderAdditional[]
    is_vat_shifted: boolean
    vat_code: string | null

    destination_location: string | null
    destination: {
        name: string | null
        street_address: string
        zip_code: string
        city: string
        country_code: string
        warehouse_id: string | null
        excise_id: string | null
        vat_id: string
        destination_type: string
    }
    supplier: {
        name: string
        street_address: string
        zip_code: string
        city: string
        country_code: string
        relation_nr: number
        sales_payment_term: {
            description: string
        }
        vat_id: string
    }

}
