import {$s} from '@bitstillery/common/app'

export const DISCOUNT_TYPES = ['offer', 'spotlight']

export function offer_item_price(offer_item) {
    // The current price that users see as the regular price.
    let current = Number(offer_item.list_price)

    const errors = $s.cart.errors

    let has_discount = DISCOUNT_TYPES.includes(offer_item.offer_type)
    let previous = Number(offer_item.original_price_per_case)
    let list_price = Number(offer_item.list_price)

    let is_invalid = false
    let is_down = false
    let is_up = false

    if (errors.price_up[offer_item.case_artkey]) {
        // We don't show raised prices, unless it comes from a
        // price_up validation issue.
        is_up = true
        is_invalid = true
        previous = Number(errors.price_up[offer_item.case_artkey].basket_price)
        current = Number(errors.price_up[offer_item.case_artkey].list_price)
    } else if (list_price < previous) {
        is_down = true
    }

    let excise = Number(offer_item.excise_per_case ??= 0)
    const case_bottles = offer_item.case_number_of_bottles

    if ($s.cart.show_bottles) {
        excise = excise / case_bottles
        current = current / case_bottles
        previous = previous / case_bottles
    }

    return {case_bottles, excise, current, has_discount, previous, is_up, is_down, is_invalid}
}
