import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Progress} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'

export class Ranking extends MithrilTsxComponent<any> {

    ranking(model) {
        if (model.total === 1) return 'success'

        if (model.rank === 1) {
            // The item is ranked first of multiple items.
            return 'success'
        } else if ((model.rank / model.total) <= 0.5) {
            // The item is ranked last of multiple items.
            return 'info'
        } else if (model.rank < model.total) {
            return 'warning'
        }

        return 'danger'
    }

    view(vn:m.Vnode<any>) {
        return <div className={classes('c-ranking', this.ranking(vn.attrs.model))}>
            <Progress
                percentage={(() => {
                    const rank = vn.attrs.model.rank - 1
                    const total = vn.attrs.model.total - 1
                    return 100 - (rank / total * 100)
                })()}
                tip={vn.attrs.model.tip}
                type={vn.attrs.type ? vn.attrs.type : this.ranking(vn.attrs.model)}
            />
            <span>{vn.attrs.model.rank} / {vn.attrs.model.total}</span>
        </div>
    }
}
