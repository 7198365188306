import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Icon, Link} from '@bitstillery/common/components'

import {$s} from '@/app'

export class Breadcrumbs extends MithrilTsxComponent<any> {
    view() {
        return <div className="c-breadcrumbs">
            {(() => {
                const elements:m.Vnode[] = []
                for (let i = 0; i < $s.page.breadcrumbs.length - 1; i++) {
                    if ($s.page.breadcrumbs[i][0]) {
                        elements.push(<Link
                            className="breadcrumb"
                            href={$s.page.breadcrumbs[i][0]}
                            target="_self"
                        >{$s.page.breadcrumbs[i][1]}
                        </Link>)
                    } else {
                        elements.push(<div className="breadcrumb">{$s.page.breadcrumbs[i][1]}</div>)
                    }
                    elements.push(<Icon className='icon-d' name='chevronRight'/>)
                }

                return elements
            })()}

            {!!$s.page.breadcrumbs.length && <div className="breadcrumb current">
                {$s.page.breadcrumbs[$s.page.breadcrumbs.length - 1][1]}
            </div>}
        </div>
    }
}
