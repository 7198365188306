import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Icon, Spinner} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import m from 'mithril'

export class CollectionStatus extends MithrilTsxComponent<any> {

    view(vnode:m.Vnode<any>) {
        if (!vnode.attrs.collection.state.ready) return null
        const state = vnode.attrs.collection.state
        return <div className={classes('c-collection-status', vnode.attrs.className)}>
            {state.loading ? <Spinner/> : <Icon className="icon-d" size="s" name="storeSearch" type="unset" />}
            <span>{(() => {
                if ([undefined, null].includes(state.total)) {
                    return state.items.length
                }
                return `${state.items.length}/${state.total}`
            })()}</span>
        </div>
    }
}
