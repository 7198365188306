import {$s, store} from '@bitstillery/common/app'
import i18next from 'i18next'

export const supported_languages = (process.env.MSI_PACKAGE === 'discover') ? [
    ['en-GB', 'languages.en_gb'],
] : [
    ['de', 'languages.de'],
    ['en-GB', 'languages.en_gb'],
    ['es', 'languages.es'],
    ['fr', 'languages.fr'],
    ['it', 'languages.it'],
    ['nl', 'languages.nl'],
]

export function set_language(language) {
    if ($s.language === language) {
        return
    }

    if (!supported_languages.map((option) => option[0]).includes(language)) {
        return
    }
    $s.language = language
    store.save()
    i18next.changeLanguage(language)
}
