import m from 'mithril'
import {$m as _$m, $s as _$s, App, api, store} from '@bitstillery/common/app'

import '@/lib/cookieconsent'
import {config} from './lib/config'

import {DEFAULT_ROUTE, routes} from '@/routes'
import {persistent, volatile, session, StatePortal} from '@/lib/state'
import {init_tawk} from '@/lib/tawk'
import i18n_tags from '@/locales/locales'
import {models, ModelsPortal} from '@/models/models'
import hooks from '@/lib/api_hooks'

export const $m = Object.assign(_$m, models) as unknown as ModelsPortal
export const $s = _$s as unknown as StatePortal

store.load(persistent, volatile, session)

api.load_hooks(hooks)

class AppPortal extends App {

    async init() {
        super.init(config, models, store, i18n_tags)

        // User has a token and is logged in; retrieve a fresh
        // token from the backend, so we always start with up-to-date relation
        // data in the portal.
        if ($s.identity.token) {
            await $m.identity.refresh_token()
        }

        init_tawk()
        const $app = document.querySelector('.app') as HTMLElement
        m.route($app, DEFAULT_ROUTE, routes)
    }
}

export const app = new AppPortal()
app.init()

window.addEventListener('load', function() {
    window.cookieconsent.initialise({content: {href: '/#!/privacy'}})
})
