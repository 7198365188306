import {make_route, optional_auth, require_auth, require_no_auth} from '@bitstillery/common/lib/router'
import {LayoutLanding, AuthForgotPassword, AuthResetPassword} from '@bitstillery/common/components'

import {
    Dashboard,
    AuthRedeem,
    AuthRegistration,
    AuthLogin,
    AuthInvite,
    MyPortal,
    MyPortalPrivacy,
    OfferList,
    OrderCheckout,
    OrderDetails,
    OrderList,
} from './components/pages'
import {LayoutPortal} from './components/layout'

const without_auth = make_route(require_no_auth, LayoutLanding)
const with_auth = make_route(require_auth, LayoutPortal)
const opt_auth = make_route(optional_auth, LayoutLanding)

export const DEFAULT_ROUTE = '/dashboard'

export const routes = {
    '/dashboard':                           with_auth(Dashboard),
    '/dashboard/:key':                      with_auth(Dashboard),
    '/redeem':                              without_auth(AuthRedeem),
    '/invite/:code':                        without_auth(AuthInvite),
    '/login':                               without_auth(AuthLogin),
    '/my-portal':                           with_auth(MyPortal),

    '/offers':                              with_auth(OfferList),
    '/orders':                              with_auth(OrderList),
    '/orders/:artkey/checkout':             with_auth(OrderCheckout),
    '/orders/:artkey':                      with_auth(OrderDetails),

    // expose a privacy statement page for unauthorized visitors.
    '/privacy':                             opt_auth(MyPortalPrivacy),
    '/registration':                        opt_auth(AuthRegistration),
    '/user/forgot-password':                without_auth(AuthForgotPassword),
    '/user/reset-password':                 opt_auth(AuthResetPassword),
}
