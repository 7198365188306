import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {store} from '@bitstillery/common/app'

import {$s} from '@/app'

export class MyPortalNews extends MithrilTsxComponent<any> {
    constructor() {
        super()

        $s.news.seen = $s.news.id
        store.save()
    }

    view(_vn:m.Vnode<any>) {
        return (
            <div className="c-news">
                <div className="text-container">

                    <div className="emphasis"></div>

                    <h3></h3>

                    <p>Team {process.env.MSI_TITLE}</p>
                </div>
            </div>
        )
    }
}
