import m from 'mithril'
import {Button, BarCategories} from '@bitstillery/common/components'
import {Dropdown} from '@bitstillery/common/components'
import {classes, get_route} from '@bitstillery/common/lib/utils'

import {$s} from '@/app'
import {filters} from '@/components/pages/offers/offer_list'

export class BarCategoriesPortal extends BarCategories {

    select_category(category) {
        let selection = [category.artkey]
        if (category.children.length) {
            selection = [category.artkey, ...category.children.map((i) => i.artkey)]
        }

        filters.category.selection.splice(0, filters.category.selection.length, ...selection)
        filters.category.collapsed = false
        m.route.set(get_route('/offers', {category: filters.category.selection}))
    }

    slot() {
        return $s.categories.menu
            .filter((menu_item) => menu_item.priority > 0 && menu_item.number_of_products > 0)
            .map((menu_item) =>
                <Dropdown trigger="hover">
                    <Button
                        active={filters.category.selection.includes(menu_item.artkey)}
                        className={classes('menu', {
                            'active-child': (
                                !filters.category.selection.includes(menu_item.artkey) &&
                                menu_item.children.map((i) => i.artkey).some((i) => filters.category.selection.includes(i))
                            ),
                        })}

                        icon={menu_item.children.length ? 'chevronDown' : menu_item.icon}
                        onclick={() => this.select_category(menu_item)}
                        text={menu_item.name}
                        type='unset'
                        variant='unset'
                    />

                    {(menu_item.children.length > 0) && <div class="dropdown-body">
                        {menu_item.children
                            .filter((submenu_item) => submenu_item.number_of_products > 0)
                            .map((submenu_item) =>
                                <div
                                    className={classes('sub-menu', {
                                        active: filters.category.selection.includes(submenu_item.artkey) && ! filters.category.selection.includes(menu_item.artkey),
                                    })}
                                    onclick={() => this.select_category(submenu_item)}
                                >
                                    {submenu_item.name}
                                    <div class="count">{submenu_item.number_of_products}</div>
                                </div>,
                            )}
                    </div>}
                </Dropdown>,
            )
    }
}
