import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'
import {Button, FieldTextArea, Icon} from '@bitstillery/common/components'
import {$t, notifier} from '@bitstillery/common/app'

export interface RatingModel {
    closed: boolean
    comment: string
    loading: boolean
    rating: number
    submitted: boolean
}

export interface RatingStarsAttrs {
    model: RatingModel
    read_only?: boolean
    size?: 'xs' | 's' | 'd' | 'l' | 'xl' | 'xxl' | 'xxxl'
}

export interface RatingAttrs {
    className?: string | [] | {}
    model: RatingModel
    placeholder?: string
    on_submit: () => any
    texts: {
        rate: {
            description: string
            placeholder: string
            title: string
        }
        thanks: {
            description: string
            icon: string
            title: string
        }
    }
}

export class RatingStars extends MithrilTsxComponent<RatingStarsAttrs> {

    rated_icon(vnode: m.Vnode<RatingStarsAttrs>, for_rating: number) {
        return <Icon
            className={classes({
                rated: vnode.attrs.model.rating >= for_rating,
            })}
            disabled={vnode.attrs.model.loading}
            name={vnode.attrs.model.rating < for_rating ? 'star-o' : 'star'}
            onclick={() => {
                if (vnode.attrs.read_only) return
                vnode.attrs.model.rating = for_rating
            }}
            size={vnode.attrs.size ? vnode.attrs.size : 'd'}
        />
    }

    view(vnode: m.Vnode<RatingStarsAttrs>): m.Children {
        return <div className={classes('c-rating-stars', {
            readonly: vnode.attrs.read_only,
        })}>
            {this.rated_icon(vnode, 2)}
            {this.rated_icon(vnode, 4)}
            {this.rated_icon(vnode, 6)}
            {this.rated_icon(vnode, 8)}
            {this.rated_icon(vnode, 10)}
        </div>
    }
}

export class Rating extends MithrilTsxComponent<RatingAttrs> {

    view(vnode: m.Vnode<RatingAttrs>): m.Children {
        if (vnode.attrs.model.closed) return null

        return <div className={classes('c-rating', vnode.attrs.className)}>
            {!vnode.attrs.model.submitted && <div className="step-rate">
                <div className="header">
                    <div className="title">{$t('rating.rate.title')}</div>
                    <Icon
                        className="btn-close"
                        name="close"
                        onclick={() => vnode.attrs.model.closed = true}
                    />
                </div>
                <div className="description">{$t('rating.rate.description')}</div>
                <RatingStars model={vnode.attrs.model} size="xl" />

                <div className="comment">
                    <FieldTextArea
                        ref={[vnode.attrs.model, 'comment']}
                        disabled={vnode.attrs.model.loading}
                        placeholder={$t('rating.rate.placeholder')}
                    />
                </div>
                <div className="btn-toolbar">
                    <Button
                        disabled={vnode.attrs.model.rating === 0 || vnode.attrs.model.loading}
                        icon='email'
                        text={$t('rating.send.title')}
                        type="info"
                        onclick={async() => {
                            vnode.attrs.model.loading = true
                            vnode.attrs.on_submit()
                            vnode.attrs.model.submitted = true
                            vnode.attrs.model.loading = false
                            notifier.notify($t('rating.thanks.notification'), 'info', 5000)
                        }}
                    />
                </div>
            </div>}
            {vnode.attrs.model.submitted && <div className="step-thanks">
                <div className="header">
                    <div className="title">{$t('rating.thanks.title', {team: $t(`support.contact.team_${process.env.MSI_THEME}`)})}</div>
                    <Icon
                        className="btn-close"
                        name="close"
                        onclick={() => vnode.attrs.model.closed = true}
                    />
                </div>
                <img className="team" src="/theme/img/team.jpg"/>
                <div className="description mt-1">{$t('rating.thanks.description')}</div>
            </div>}
        </div>
    }
}
