import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {$s, $t} from '@bitstillery/common/app'

export class BarCategories extends MithrilTsxComponent<any> {

    slot() {
        throw new Error('BarCategories slot not implemented')
    }

    view(_vnode:m.Vnode<any>):m.Children {
        const float_panel_active = ['mobile', 'tablet'].includes($s.env.layout)
        return (
            <div className={classes('c-bar-categories float-panel', {
                'float-panel--active': float_panel_active,
                collapsed: $s.panels.view.categories.collapsed,
            })}>
                {float_panel_active && <header onclick={() => {
                    $s.panels.view.categories.collapsed = !$s.panels.view.categories.collapsed
                }}>
                    <div className="title">
                        <Icon className="icon-d" name="categories"/>
                        <span>{$t('bar_categories.title')}</span>
                    </div>
                </header>}
                <div className="categories-container">
                    {this.slot()}
                </div>
            </div>
        )
    }
}
