export default {
    ARROW_DOWN: 40,
    ARROW_LEFT: 37,
    ARROW_RIGHT: 39,
    ARROW_UP: 38,
    BACKSPACE: 8,
    CTRL: 17,
    DELETE: 46,
    ENTER: 13,
    ESCAPE: 27,
    PAGE_DOWN: 34,
    PAGE_UP: 33,
    SPACE: 32,
    TAB: 9,
}
