import {api, events} from '@bitstillery/common/app'

import {$s} from '@/app'

export async function get_product_names() {
    const {status_code, result: result_product_names} = await api.get<string[]>('portal/product-names')
    if (status_code > 299) {
        return Promise.resolve([])
    }

    return result_product_names.map((_name) => {
        const name = _name.toLowerCase()
        return [name, name, null, null, null, 'search']
    })
}

export async function get_search_categories() {
    const {status_code, result: {categories}} = await api.get('portal/categories') as any
    if (status_code > 299) {
        return Promise.resolve([])
    }
    const lookup = {}
    for (const root_category of categories) {
        root_category.children.sort((a, b) => a.name > b.name)
        for (const child_category of root_category.children) {
            lookup[child_category.artkey] = child_category.name
        }
        lookup[root_category.artkey] = root_category.name
    }

    $s.categories.lookup = lookup
    $s.categories.menu = JSON.parse(JSON.stringify(categories))

    type suggestion_type = [number, string, number, suggestion_type[], [number, string, number] | null, string]
    const category_suggestions: Array<suggestion_type> = []
    for (const root_category of categories) {
        const child_suggestions: Array<suggestion_type> = []
        for (const child_category of root_category.children) {
            if (child_category.number_of_products === 0) continue

            const child_suggestion: suggestion_type = [
                child_category.artkey,
                child_category.name.toLowerCase(),
                child_category.number_of_products,
                [],
                [root_category.artkey, root_category.name, root_category.number_of_products],
                'category',
            ]

            category_suggestions.push(child_suggestion)
            child_suggestions.push(child_suggestion)
        }

        if (root_category.number_of_products > 0) {
            category_suggestions.push([
                root_category.artkey,
                root_category.name.toLowerCase(),
                root_category.number_of_products,
                child_suggestions,
                null,
                'category',
            ])
        }
    }

    category_suggestions.sort((a, b) => {
        if (!a[4] && !b[4]) {
            if (a[1] > b[1]) return 1
            if (a[1] < b[1]) return -1
        } else if (a[4] && !b[4]) {
            if (a[4][1] > b[1]) return -1
            if (a[4][1] < b[1]) return 1
        } else if (b[4] && !a[4]) {
            if (b[4][1] > a[1]) return 1
            if (b[4][1] < a[1]) return -1
        } else if (a[4] && b[4]) {
            if (a[4][1] > b[4][1]) return 1
            if (a[4][1] < b[4][1]) return -1
        }
        return 0
    })
    return category_suggestions
}

export async function init() {
    events.on('identity.login', async() => {

        events.emit('search.match-suggestions')
    })
}
