import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {$s, logger} from '@bitstillery/common/app'

export class Tabs extends MithrilTsxComponent<any> {

    oninit(vnode:m.Vnode<any>) {
        this.data = vnode.attrs.data

        for (const tab of vnode.children) {
            if (tab.attrs.id === this.data.tabs.active) {
                this.activate_tab(tab, true)
            }
        }

        // This is a special kind of view that adds an extra
        // submenu layer. See the .extra-submenu class at .l-main-col
        if (vnode.attrs.type === 'nested-view') {
            $s.page.header.submenu = true
        }
    }

    onremove(vnode) {
        if (vnode.attrs.type === 'nested-view') {
            $s.page.header.submenu = false
        }
    }

    activate_tab(tab, on_init) {
        if (tab.attrs.link) {
            const urlpath = m.parsePathname(m.route.get())
            const url_params = Object.fromEntries(new URLSearchParams(urlpath.params))
            const linkpath = m.parsePathname(tab.attrs.link)
            const link_params = Object.fromEntries(new URLSearchParams(linkpath.params))

            if (url_params.tabId !== link_params.tabId) {
                const new_params = {...url_params, tabId: link_params.tabId}
                logger.debug('[tabs] update url with new tab id', new_params)
                m.route.set(urlpath.path, new_params)
            }
        }
        if (tab.attrs.onactivate) {
            tab.attrs.onactivate(tab, on_init)
        }
    }

    tab_click(vnode: m.Vnode<any>, tabNode) {
        // Call deactivate on all other children
        for (const tab of vnode.children) {
            if (tab.attrs.id === tabNode.attrs.id) {
                this.activate_tab(tab, false)
            } else {
                if (tab.attrs.ondeactivate) {
                    tab.attrs.ondeactivate()
                }
            }
        }

        this.data.tabs.active = tabNode.attrs.id
    }

    view(vnode: m.Vnode<any>) {
        return (
            <div className={classes('c-tabs', vnode.attrs.className,
                `type-${vnode.attrs.type ? vnode.attrs.type : 'default'}`,
            )}>
                <div className="tabs-container">
                    {vnode.attrs.header && <div class="header" onclick={() => {
                        if (vnode.attrs.header.onclick) {
                            vnode.attrs.header.onclick()
                        }
                    }}>
                        <Icon name={vnode.attrs.header.icon} type="unset" />
                        <span>{vnode.attrs.header.title}</span>
                    </div>}
                    <div class="tabs">
                        {vnode.children.map((tab) => {
                            tab.attrs.data = this.data
                            tab.attrs.onclick = this.tab_click.bind(this, vnode)
                            return tab
                        })}
                    </div>
                    {(() => {
                        const help_tab = vnode.children.find((tab) => (tab.attrs.id === vnode.attrs.data.tabs.active && tab.attrs.help))
                        if (help_tab) {
                            return (
                                <div class="help-block">
                                    <Icon className="icon-d" name={help_tab.attrs.icon}/>
                                    <div className="text">{help_tab.attrs.help}</div>
                                </div>
                            )
                        }
                    })()}
                </div>
                <div class="panels">
                    {vnode.children.map((tab) => {
                        tab.children[0].attrs.id = tab.attrs.id
                        tab.children[0].attrs.data = this.data
                        return tab.children[0]
                    })}
                </div>
            </div>
        )
    }
}

export class Tab extends MithrilTsxComponent<any> {

    view(vnode) {
        return (
            <button
                className={classes('c-tab', vnode.attrs.className, {active: vnode.attrs.id === vnode.attrs.data.tabs.active})}
                disabled={vnode.attrs.disabled}
                onclick={() => {
                    vnode.attrs.onclick(vnode)
                }}
            >
                <Icon name={vnode.attrs.icon}/>
                {vnode.attrs.text}
            </button>
        )
    }
}

export class TabPanel extends MithrilTsxComponent<any> {

    initialized = false

    view(vnode) {
        return (
            <div className={classes('c-tab-panel', vnode.attrs.className, {
                active: vnode.attrs.id === vnode.attrs.data.tabs.active,
            })}>
                {(() => {
                    if (!this.initialized) {
                        // Initialize the tab content on the first time it is activated.
                        if (vnode.attrs.id !== vnode.attrs.data.tabs.active) return null
                        this.initialized = true
                    }
                    return vnode.children
                })()}
            </div>
        )
    }
}
