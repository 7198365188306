import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'

export class Spinner extends MithrilTsxComponent<any> {

    view(vn: m.Vnode<any>) {
        return (
            <svg className={classes(
                'c-spinner', 'c-icon',
                vn.attrs.className,
                `type-${vn.attrs.type ? vn.attrs.type : 'default'}`,
                `icon-${vn.attrs.size ? vn.attrs.size : 'd'}`,
            )}
            height="24"
            viewBox="0 0 24 24"
            width="24"
            >
                <circle class="path" cx="12" cy="12" r="8" fill="none" stroke-width="4"></circle>
            </svg>
        )
    }
}
