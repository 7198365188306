import {DateTime} from 'luxon'

/**
 * Formats a number as money according to the users locale.
 *
 * @param money The amount to format.
 * @param currency In which currency to format ("EUR", "GBP", etc).
 * @param locale The locale to use, default = nl
 *
 * @returns A as currency formatted string, or "" if the argument money is undefined or null.
 */
export function format_money(
    money: number | undefined,
    currency: string | undefined | null,
    locale = 'nl',
) {
    if (currency && (money || money === 0)) {
        const formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
            currencyDisplay: 'code',
        })
        return formatter.format(money)
    }
    return ''
}

/**
 * Formats a number as money according to the users locale.
 *
 * @param money The amount to format.
 * @param currency In which currency to format ("EUR", "GBP", etc).
 * @param locale The locale to use, default = nl
 *
 * @returns A as currency formatted string, or "" if the argument money is undefined or null.
 */
export function format_money_with_symbol(
    money: number | string | undefined,
    currency: string | undefined | null,
    locale = 'nl',
) {
    if (currency && (money || money === 0)) {
        const formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
        })
        if (money || money === 0) {
            return formatter.format(+money)
        }
    }
    return ''
}

/**
 * Format bytes as a Mb/Gb string.
 *
 * @param bytes The bytes to format.
 * @param decimals The number of decimals to display, default 2.
 */
export function format_bytes(bytes: string | number | null | undefined, decimals = 2): string {
    if (!bytes || !+bytes) return '0 Bytes'
    const bytes_as_num = +bytes

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes_as_num) / Math.log(k))

    return `${parseFloat((bytes_as_num / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

/**
 * Displays the integer, taking into account if it is 0, null or undefined.
 *
 * @param to_display
 */
export function displayable_integer(
    to_display: number | null | undefined,
): string {
    return to_display?.toString() ?? '0'
}

/**
 * Formats a float to a certain number of fixed fraction digits, formatting according to users locale.
 *
 * @param float_to_display
 * @param number_of_fraction_digits The number of fraction digits.
 */
export function displayable_float(
    float_to_display: string | number | null | undefined,
    number_of_fraction_digits: number = 2,
    locale = 'en',
): string {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: number_of_fraction_digits,
        maximumFractionDigits: number_of_fraction_digits,
    })
    if (!float_to_display) {
        return formatter.format(0)
    }
    return formatter.format(+float_to_display)
}

/**
 * Returns true if the number is not displayable, this is the case if it is undefined or null.
 * Note that the value 0 is allowed to display.
 *
 * @param to_display
 */
export function is_not_displayable_number(
    to_display: number | null | undefined,
): boolean {
    return !to_display && to_display !== 0
}

/**
 * Capitalize the first letter of all words in a string.
 */
export function titleize(str: string | null): string {
    if (!str) {
        return ''
    }
    return str.replace(/\b[a-z]/g, (letter) => letter.toUpperCase())
}

/**
 * Generates a random transaction id.
 *
 * See https://blog.sentry.io/2019/04/04/trace-errors-through-stack-using-unique-identifiers-in-sentry
 */
export function generate_transaction_id(): string {
    return generate_unique_id()
}

/**
 * Generate unique ID.
 * See https://gist.github.com/gordonbrander/2230317.
 * @returns string
 */
export function generate_unique_id(): string {
    return Math.random().toString(36).substr(2, 9)
}

/**
 * Calculates the sum of an array of numbers.
 *
 * @param {number[]} number_array: An array with numbers to calculate the sum of.
 * @return {number}: The calculated sum.
 */
export function sum(number_array: number[]): number {
    return number_array.reduce((a, b) => a + b, 0)
}

/**
 * Generate random string of length.
 * @param length Length of string to generate.
 * @returns
 */
export function random_string(
    length: number,
    include_numbers: boolean = true,
): string {
    let result = ''
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    if (include_numbers) {
        characters += '0123456789'
    }
    const characters_length = characters.length
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * characters_length),
        )
    }
    return result
}

export function product_photo_image_location(
    host: string,
    product_photo: { s3_location: string; s3_location_thumbnail?: string | null },
): string {
    const image_location = (
        product_photo.s3_location_thumbnail || product_photo.s3_location
    )
        .replace('product_photos/', '')
        .split('/')
        .map((segment) => encodeURIComponent(segment))
        .join('/')

    return `${host}/${image_location}`
}

export function format_percentage(value: number, locale = 'en'): string {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    })
    return formatter.format(value)
}

/**
 * Format the ISO date_time as a nl-date string (dd-mm-yyyy hh:mm:ss).
 *
 * @param date_time The date to format.
 */
export const format_iso_to_date_time = function(date_time: string | null | undefined): string {
    if (!date_time) {
        return ''
    }
    const as_dt = DateTime.fromISO(date_time)
    return as_dt.toLocaleString({
        locale: 'nl',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    })
}

/**
 * Format the ISO date_time as a nl-date string (dd-mm-yyyy).
 *
 * @param date_time The date to format.
 */
export const format_iso_to_date = function(date_time: string | null | undefined): string {
    if (!date_time) {
        return ''
    }
    const as_dt = DateTime.fromISO(date_time)
    return as_dt.toLocaleString({
        locale: 'nl',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    })
}

/**
 * Format the ISO date_time as a date string (dd-MM-yyyy).
 *
 * This function retains leading zeroes, as opposed to format_iso_to_date. This keeps it consistent
 * with already existing date strings that were formatted via format-date from utils.ls.
 *
 * @param date_time The date to format.
 *
 * @return {string} the formatted date string.
 */
export const format_iso_to_date_with_leading_zeroes = function(date_time: string): string {
    if (!date_time) {
        return ''
    }
    const as_dt = DateTime.fromISO(date_time)
    return as_dt.toFormat('dd-MM-yyyy')
}

/**
 * Format the ISO date_time as a fixed string (yyyy-MM-dd).
 *
 * This function retains leading zeroes, as opposed to format_iso_to_date. This keeps it consistent
 * with already existing date strings that were formatted via format-date from utils.ls.
 *
 * @param date_time The date to format.
 *
 * @return {string} the formatted date string.
 */
export const format_iso_to_fixed_date_format = function(date_time: string): string {
    if (!date_time) {
        return ''
    }
    const as_dt = DateTime.fromISO(date_time)
    return as_dt.toFormat('yyyy-MM-dd')
}

/**
 * Return the date_time in a relative format (yesterday, today, 8 days ago, etc).
 *
 * @see https://moment.github.io/luxon/docs/class/src/datetime.js~DateTime.html#instance-method-toRelativeCalendar
 * @param date_time The iso datetime format to make relative.
 */
export const format_iso_to_relative = function(date_time: string): string {
    if (date_time === '') {
        return ''
    }
    const as_dt = DateTime.fromISO(date_time)
    return as_dt.toRelativeCalendar({locale: 'en'}) || format_iso_to_date_time(date_time)
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not be
 * triggered. The function will be called after it stops being called for N
 * milliseconds.
 */
export const debounce = function<F extends(this: any, ...args: any[]) => void>(wait: number, func: F): F {
    type Timeout = ReturnType<typeof setTimeout>
    type Context = {timeout: Timeout | undefined}
    let ctx: Context = {timeout: undefined}
    let callback = function(this: any, ...args: Parameters<F>) {
        let later = () => {
            ctx.timeout = undefined
            func.apply(this, args)
        }
        if (ctx.timeout) {
            clearTimeout(ctx.timeout)
        }
        ctx.timeout = setTimeout(later, wait)
    }
    return callback as F
}

/**
 * Returns a function that will only apply a the provided function call once
 * every threshold milliseconds at most
 */
export const throttle = function<F extends(this: any, ...args: any[]) => void>(threshold: number, func: F): F {
    type Timeout = ReturnType<typeof setTimeout>
    type Context = {last: number | null, timeout: Timeout | undefined}
    let ctx: Context = {last: null, timeout: undefined}
    let callback = function(this: any, ...args: Parameters<F>) {
        const now = new Date().getTime()
        if (ctx.last && now < ctx.last + threshold) {
            if (ctx.timeout) {
                clearTimeout(ctx.timeout)
            }
            ctx.timeout = setTimeout(() => {
                ctx.last = now
                func.apply(this, args)
            }, threshold - (now - ctx.last))
        } else {
            ctx.last = now
            func.apply(this, args)
        }
    }
    return callback as F
}
