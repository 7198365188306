import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, FieldSelect, RadioGroup} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {set_language} from '@bitstillery/common/lib/i18n'
import {supported_languages} from '@bitstillery/common/lib/i18n'
import {$t, api, notifier} from '@bitstillery/common/app'

import {$m, $s} from '@/app'
import {UpdatePortalProfileRequest} from '@/factserver_api/fact2server_api'

export class MyPortalSettings extends MithrilTsxComponent<any> {

    pricePreferenceOptions = [['bottle', 'product.bottles'], ['case', 'product.cases']]

    data = proxy({
        update_profile_request: {
            price_preference: $s.identity.user.price_preference,
            mobile_phone_number: $s.identity.user.mobile_phone_number,
            language: $s.identity.user.language,
            phone_number: $s.identity.user.phone_number ,
            email_address: $s.identity.user.email_address,
            first_name: $s.identity.user.first_name,
            last_name: $s.identity.user.last_name,
        } as UpdatePortalProfileRequest,
    })

    async update_profile() {
        const {response} = await api.post('portal/user/profile', this.data.update_profile_request, true)
        if (!response.ok) {
            notifier.notify($t('my_portal.settings.saved_profile_error'), 'warning')
            return
        }

        $m.identity.refresh_token()
        notifier.notify($t('my_portal.settings.saved_profile'), 'info')
    }

    view(_vn:m.Vnode<any>) {

        return (
            <div className="c-my-portal-settings form">
                <div className="fieldset">
                    <FieldSelect
                        help={$t('my_portal.settings.language_help')}
                        label={$t('my_portal.settings.language')}
                        onchange={(modelValue) => {
                            set_language(modelValue)
                        }}
                        options={supported_languages}
                        ref={[this.data.update_profile_request, 'language']}
                        translate={{prefix: ''}}
                    />
                    <RadioGroup
                        help={$t('my_portal.settings.price_quantity_help')}
                        label={$t('my_portal.settings.price_quantity')}
                        options={this.pricePreferenceOptions}
                        ref={[this.data.update_profile_request, 'price_preference']}
                        translate={{prefix: ''}}
                    />
                </div>
                <Button
                    className="btn-submit"
                    icon="save"
                    type="success"
                    text={$t('my_portal.settings.save')}
                    onclick={this.update_profile.bind(this)}
                    disabled={$s.identity.demo}
                />
            </div>
        )
    }
}
