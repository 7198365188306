import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {unique_id} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'

export interface FieldCheckboxAttrs {
    label: string
    disabled?: boolean
    computed?: () => boolean
    ref?: [{}, string]
    help?: string

    validation?: any
    className?: string

    onclick: () => unknown
    onAfterChange: (unknown) => unknown
}

export class FieldCheckbox extends MithrilTsxComponent<FieldCheckboxAttrs> {
    modelValue:any
    name = unique_id()
    props: {off: boolean, on: boolean}

    constructor(vn: m.Vnode<any>) {
        super()
        this.props = vn.attrs.props ? vn.attrs.props : {off: false, on: true}
    }

    view(vn:m.Vnode<FieldCheckboxAttrs>) {
        const validation = vn.attrs.validation
        const invalid = validation ? validation._invalid : false
        const disabled = vn.attrs.disabled
        // Computed property that overrides the reference.
        if (vn.attrs.computed) {
            this.modelValue = vn.attrs.computed()
        } else if (vn.attrs.ref) {
            this.modelValue = vn.attrs.ref[0][vn.attrs.ref[1]]
        }

        return (
            <div className={classes('c-checkbox', 'field', vn.attrs.className)}>
                <input
                    checked={this.modelValue === this.props.on}
                    disabled={disabled}
                    id={this.name}
                    name={this.name}
                    onclick={vn.attrs.onclick}
                    onchange={() => {
                        if (vn.attrs.validation) {
                            vn.attrs.validation.dirty = true
                        }
                        if (vn.attrs.computed) {
                            this.modelValue = vn.attrs.computed()
                        } else {
                            if (this.modelValue === this.props.on) {
                                this.modelValue = this.props.off
                            } else {
                                this.modelValue = this.props.on
                            }
                            vn.attrs.ref[0][vn.attrs.ref[1]] = this.modelValue
                        }

                        if (vn.attrs.onAfterChange) {
                            vn.attrs.onAfterChange(this.modelValue)
                        }
                    }}
                    type="checkbox"
                />
                {vn.attrs.label && <label for={this.name}>
                    {vn.attrs.label}
                    {vn.attrs.validation && <span className="validation">{validation.label}</span>}
                </label>}
                {(() => {
                    if (invalid && validation.dirty) {
                        return <div className="help validation">{invalid.message}</div>
                    } else if (vn.attrs.help) {
                        return <div class="help">{vn.attrs.help}</div>
                    }
                })()}
            </div>
        )
    }
}
