import m from 'mithril'
import {Button} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'

export interface CarouselSlide {
    description: string
    link: string
    title: string
    url: string
}

interface CarouselAttrs{
    className: string
    controls: boolean
    current?: number
    indicators: boolean
    item?: (slide: CarouselSlide, active: boolean, index: number) => m.Vnode
    onchange: (index: number) => void
    onclose: () => void
    slides: CarouselSlide[]
    type: string
}

export class Carousel extends MithrilTsxComponent<CarouselAttrs> {

    data = proxy({
        current: 0,
    })

    view(vnode:m.Vnode<CarouselAttrs>) {
        if (typeof vnode.attrs.current === 'number') {
            this.data.current = vnode.attrs.current
        }
        return <div className={classes('c-carousel', vnode.attrs.className)}>
            <div className={classes(vnode.attrs.type === 'inline' ? 'inline' : 'fullscreen c-dialog')} onclick={() => {
                if (vnode.attrs.onclose) {
                    vnode.attrs.onclose()
                }
            }}>
                <div className="detail-view" onclick={(e) => {
                    e.stopPropagation()
                }}>
                    {vnode.attrs.indicators && <ol className="c-carousel-indicators">
                        {vnode.attrs.slides.map((_slide, index) => <li
                            className={classes({active: index === this.data.current})}
                            onclick={(e) => {
                                e.stopPropagation()
                                this.data.current = index
                                vnode.attrs.onchange && vnode.attrs.onchange(this.data.current)
                            }}
                        />)}
                    </ol>}

                    <div className="c-carousel-slides">
                        {vnode.attrs.slides.map((slide, index) => {
                            if (vnode.attrs.item) {
                                return vnode.attrs.item(slide, index === this.data.current, index)
                            }

                            return <div className={classes('item', {active: index === this.data.current})}>
                                <img alt="Slide image missing" src={slide.url}/>
                            </div>
                        })}
                    </div>

                    {vnode.attrs.controls && [
                        <Button
                            className="c-carousel-controls left"
                            disabled={vnode.attrs.slides.length <= 1}
                            icon="chevronLeft"
                            onclick={(e) => {
                                e.stopPropagation()
                                if (this.data.current > 0) {
                                    this.data.current -= 1
                                } else {
                                    this.data.current = vnode.attrs.slides.length - 1
                                }

                                vnode.attrs.onchange && vnode.attrs.onchange(this.data.current)
                            }}
                            variant="toggle"
                        />,

                        <Button
                            className="c-carousel-controls right"
                            disabled={vnode.attrs.slides.length <= 1}
                            icon="chevronRight"
                            onclick={(e: Event) => {
                                e.stopPropagation()
                                if (this.data.current < vnode.attrs.slides.length - 1) {
                                    this.data.current += 1
                                } else {
                                    this.data.current = 0
                                }
                                vnode.attrs.onchange && vnode.attrs.onchange(this.data.current)
                            }}
                            variant="toggle"
                        />,
                    ]}
                    {vnode.attrs.type !== 'inline' && <Button
                        className='btn-close'
                        onclick={() => {
                            if (vnode.attrs.onclose) {
                                vnode.attrs.onclose()
                            }
                        }}
                        icon='close'
                        variant='toggle'
                    />}
                </div>
            </div>
        </div>
    }
}
