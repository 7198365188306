import m from 'mithril'
import {AuthLogin as AuthLoginCommon} from '@bitstillery/common/components'
import {set_language} from '@bitstillery/common/lib/i18n'
import {$t, api, notifier} from '@bitstillery/common/app'

import {$m, $s} from '@/app'
import {toggle_tawk} from '@/lib/tawk'
import {DEFAULT_ROUTE} from '@/routes'

export class AuthLogin extends AuthLoginCommon {

    redirect_to = m.route.param('redirect')

    oncreate() {
        toggle_tawk(false)
    }

    onupdate() {
        toggle_tawk(false)
    }

    oninit() {
        $s.page.title = $t('landing.login.title', {vendor: process.env.MSI_TITLE})
        $s.page.subtitle = $t('landing.login.subtitle')
    }

    async login() {
        const data = {
            account_slug: process.env.MSI_THEME,
            otp: this.data.$m.otp_code,
            password: this.data.$m.password,
            username: this.data.$m.email_address,
            username_support: this.data.$m.email_support,
        }

        const {result, success} = await api.post('login', data) as any
        if (success) {
            if (result.otp_required) {
                this.data.stepper.selection = 2
            } else if (result.otp_secret_url) {
                // First time setup OTP
                this.data.otp_secret = result.otp_secret_url
                this.data.stepper.selection = 1
            } else {
                // All good to go; login.
                await $m.identity.new_token(result.token)
                set_language($s.identity.user.language)
                const {result: portal_status} = await api.get('portal/status') as any
                $s.portal.ready = portal_status.pricelist_ready
                if (this.redirect_to) {
                    window.location = this.redirect_to
                } else {
                    m.route.set(DEFAULT_ROUTE)
                }
                notifier.notify($t('page.title.login', {vendor: process.env.MSI_TITLE}), 'success')
            }
        } else {
            const message = result.message
            let notify
            if (!message) {
                notify = $t('status.error.unknown')
            } else if (message.includes('Username or password is incorrect')) {
                notify = $t('status.error.login.credentials_invalid')
            } else if (message.includes('OTP is not valid')) {
                this.data.otp_fail = message
                notify = $t('status.error.otp.invalid_notify')
            }

            notifier.notify(notify, 'danger', 7000)
        }
    }
}
