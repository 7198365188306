export interface Account {
    artkey: number
    name: string
    slug: string
    informal_name: string
    address: string
    zip_code: string
    city: string
    country_code: string
    telephone_number: string
    emailaddress: string
    vat_id: string
    chamber_of_commerce_number: string
    website_url: string
    iban_eur?: string
    iban_gbp?: string
    iban_usd?: string
}
