import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {Icon, Slider} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {clear_filter} from '@bitstillery/common/lib/filters'
import {$t} from '@bitstillery/common/app'
import {is_filter_active} from '@bitstillery/common/lib/filters'

export class FilterSelectRange extends MithrilTsxComponent<any> {

    view(vnode:m.Vnode<any>) {
        const active = is_filter_active(vnode.attrs.filter)
        return <div className={classes('c-filter-select-range', 'filter', vnode.attrs.className, {
            active,
            collapsed: vnode.attrs.filter.collapsed || vnode.attrs.filter.disabled,
            disabled: vnode.attrs.filter.disabled,
        })}>
            <div className="title" onclick={() => {
                vnode.attrs.filter.collapsed = !vnode.attrs.filter.collapsed
            }}>
                <div class="name">
                    <Icon
                        active={active}
                        className="btn-filter-toggle"
                        name={(() => {
                            if (vnode.attrs.filter.icon) return vnode.attrs.filter.icon
                            return vnode.attrs.filter.selection ? 'filterRemove' : 'filterPlus'
                        })()}
                        onclick={(e) => {
                            e.stopPropagation()
                            clear_filter(vnode.attrs.filter)
                        }}
                        tip={() => {
                            const filter_title = $t(vnode.attrs.filter.title)
                            return is_filter_active(vnode.attrs.filter) ? $t('filters.actions.deactivate_filter', {name: filter_title}) : $t('filters.inactive_filter', {name: filter_title})
                        }}
                    />
                    {$t(vnode.attrs.filter.title)}
                </div>
                <Icon
                    className="btn-collapse"
                    name={vnode.attrs.filter.collapsed ? 'chevronUp' : 'chevronDown'}
                />
            </div>
            <div class="items">
                <Slider
                    minimum={vnode.attrs.filter.scale[0]}
                    maximum={vnode.attrs.filter.scale[1]}
                    step={vnode.attrs.filter.step}
                    infinity={vnode.attrs.filter.infinity}
                    value={vnode.attrs.filter.selection}
                />
            </div>
        </div>
    }
}
