import {$s} from '@bitstillery/common/app'

export class AccountSlug {
    static A2BC = 'a2bc'
    static ETR = 'etr'
    static MSI = 'msi'
    static MSP = 'msp'
}

export function account_secondary_color_rgb(slug: string): {
    red: number, green: number, blue: number
} {
    if (slug === AccountSlug.A2BC) {
        return {
            red: 255,
            green: 203,
            blue: 160,
        }
    } else if (slug === AccountSlug.ETR) {
        return {
            red: 248,
            green: 236,
            blue: 236,
        }
    }
    else if (slug === AccountSlug.MSI) {
        return {
            red: 202,
            green: 234,
            blue: 246,
        }
    } else if (slug === AccountSlug.MSP) {
        return {
            red: 248,
            green: 236,
            blue: 236,
        }
    }
    throw Error('Unknown account slug')
}

export function account_primary_color_rgb_list(slug: string): {
    red: number, green: number, blue: number
} {
    if (slug === AccountSlug.A2BC) {
        return {
            red: 250,
            green: 99,
            blue: 39,
        }
    } else if (slug === AccountSlug.ETR) {
        return {
            red: 250,
            green: 99,
            blue: 39,
        }
    } else if (slug === AccountSlug.MSI) {
        return {
            red: 42,
            green: 107,
            blue: 167,
        }
    } else if (slug === AccountSlug.MSP) {
        return {
            red: 42,
            green: 107,
            blue: 167,
        }
    }
    throw Error('Unknown account slug')
}

export function account_pdf_logo_src(slug: string): string {
    if (process.env.MSI_PACKAGE === 'discover') {
        if (slug === AccountSlug.A2BC) {
            return '/img/pdf-logo.a2bc.jpg'
        } else if (slug === AccountSlug.ETR) {
            return '/img/pdf-logo.etr.jpg'
        } else if (slug === AccountSlug.MSI) {
            return '/img/pdf-logo.msi.jpg'
        } else if (slug === AccountSlug.MSP) {
            return '/img/pdf-logo.msp.jpg'
        }

        throw Error('Unknown account slug')
    } else if (process.env.MSI_PACKAGE === 'portal') {
        // Portal uses a fixed logo provided with the theme.
        return '/theme/img/pdf-logo.jpg'
    }
}

export function set_account_styles(slug) {
    const root = document.documentElement

    if ([AccountSlug.ETR, AccountSlug.A2BC].includes(slug)) {
        root.style.setProperty('--brand-h', '17deg')
        root.style.setProperty('--info-h', '208deg')
        root.style.setProperty('--surface-h', '17deg')
        root.style.setProperty('--success-h', '130.2deg')

        if (slug === AccountSlug.A2BC) {
            root.style.setProperty('--s-3', '35%')
            root.style.setProperty('--l-2', '35%')
            root.style.setProperty('--l-3', '60%')

            document.title = 'Discover [A2BC]'
        } else if (slug === AccountSlug.ETR) {
            root.style.setProperty('--s-3', '75%')
            document.title = 'Discover [ETR]'
        }

    } else if ([AccountSlug.MSP, AccountSlug.MSI].includes(slug)) {
        root.style.setProperty('--brand-h', '353deg')
        root.style.setProperty('--info-h', '193deg')
        root.style.setProperty('--surface-h', '28deg')
        root.style.setProperty('--success-h', '130.2deg')

        if (slug === AccountSlug.MSP) {
            root.style.setProperty('--s-3', '100%')
            root.style.setProperty('--l-1', '25%')
            root.style.setProperty('--l-2', '22%')
            document.title = 'Discover [MSP]'
        } else if (slug === AccountSlug.MSI) {
            root.style.setProperty('--s-1', '20%')
            root.style.setProperty('--s-2', '20%')
            root.style.setProperty('--s-3', '40%')
            document.title = 'Discover [MSI]'
        }
    }

    let link = document.querySelector('link[rel~=\'icon\']')
    if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.head.appendChild(link)
    }
    link.href = `/theme/img/discover/${slug}-favicon.ico`
}

export function current_account_slug() {
    return $s.session.account.slug
}
