import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {FieldSwitch} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {FilterSelectSingleState} from '@bitstillery/common/types'
import {is_filter_active, unset_filter_selection} from '@bitstillery/common/lib/filters'
import {$t} from '@bitstillery/common/app'

interface FilterToggleAttrs {
    className: string
    filter: FilterSelectSingleState
    title: string
}

export class FilterToggle extends MithrilTsxComponent<FilterToggleAttrs> {

    view(vnode:m.Vnode<FilterToggleAttrs>) {
        return <FieldSwitch
            className={classes('c-filter-toggle', 'filter', vnode.attrs.className, {
                active: is_filter_active(vnode.attrs.filter),
            })}
            help={vnode.attrs.filter.help}
            label={$t(vnode.attrs.filter.title)}
            props={{on: true, off: unset_filter_selection(vnode.attrs.filter)}}
            ref={[vnode.attrs.filter, 'selection']}
        />
    }
}
