import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'
import {Spinner} from '@bitstillery/common/components'

import {Icon} from '../icon/icon'

export interface ReadMoreLessToggleAttrs {
    is_expanded: boolean
    is_loading?: boolean
    disabled?: boolean
    summary: JSX.Element
    contents: JSX.Element
    onclick: () => unknown
}

export class ReadMoreLessToggle extends MithrilTsxComponent<ReadMoreLessToggleAttrs> {

    click_handler(vnode: m.Vnode<ReadMoreLessToggleAttrs>, e: Event) {
        if (vnode.attrs.is_loading || vnode.attrs.disabled) {
            return
        }
        e.stopPropagation()
        vnode.attrs.onclick()
    }

    view(vnode: m.Vnode<ReadMoreLessToggleAttrs>): m.Children {
        const icon_name = vnode.attrs.is_expanded ? 'collapse-v' : 'chevronUpDown'
        const disabled = vnode.attrs.disabled || vnode.attrs.is_loading

        return <div
            className={classes('c-read-more-less-toggle', disabled ? 'disabled' : 'action')}
            onclick={(e: Event) => this.click_handler(vnode, e)}>
            {vnode.attrs.is_loading && <Spinner />}
            {!vnode.attrs.is_loading &&
                <div className={'contents'}>
                    <div className={'header'}>
                        <Icon size="s" className="icon" name={icon_name} disabled={vnode.attrs.disabled}/>
                        <div className={'summary'}>{vnode.attrs.summary}</div>
                    </div>
                    <div className={'body'}>
                        {vnode.attrs.is_expanded && vnode.children}
                    </div>
                </div>
            }
        </div>
    }
}
