import m from 'mithril'
import {unique_id} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {$t} from '@bitstillery/common/app'

interface CheckboxGroupAttrs {
    filter_count: number
    options: [][]
    selection: []
    disabled: boolean
}

export class CheckboxGroup extends MithrilTsxComponent<CheckboxGroupAttrs> {

    name = unique_id()

    class_name(option, vn) {
        const classes = ['item']
        if (option.length >= 3) {
            if (vn.attrs.filter_count && option[2] === 0) classes.push('disabled')
            if (vn.attrs.selection.includes(option[0])) {
                classes.push('selected')
            }
        }
        return classes.join(' ')
    }

    /**
     * Generic checkbox event handler that deals with
     * siblings, parents and children.
     * @param e - The original event
     * @param vnode - The Mithril Vnode
     * @param option - The current option datamodel
     * @param parent - A reference the current option's parent
     */
    on_change(e, vnode, option, parent) {
        const selection = JSON.parse(JSON.stringify(vnode.attrs.selection)) // Modifying the original directly would trigger multiple redraws
        if (e.target.checked) { // Selecting current option
            if (!selection.includes(option[0])) {
                selection.push(option[0])
            }

            // Selected option has parent
            if (Array.isArray(parent) && !selection.includes(parent[0])) {
                let all_sibling_selected = true
                for (const sibling_option of parent[3]) {
                    if (!selection.includes(sibling_option[0])) {
                        all_sibling_selected = false
                    }
                }
                if (all_sibling_selected) {
                    selection.push(parent[0]) // Select parent; all of the child's sibling options are selected
                }
            }

            if (option.length >= 4) { // Selected option has children; select all of the unselected children
                for (const child_option of option[3]) {
                    if (!selection.includes(child_option[0])) {
                        selection.push(child_option[0])
                    }
                }
            }
        } else {
            // Deselecting current option
            if (selection.includes(option[0])) { selection.splice(selection.indexOf(option[0]), 1) }

            // Deselected option has parent
            if (Array.isArray(parent) && selection.includes(parent[0])) {
                selection.splice(selection.indexOf(parent[0]), 1) // Deselect parent; not all child options are selected anymore
            }

            // Deselected option has children; deselect all of them
            if (option.length >= 4) {
                for (const child_option of option[3]) {
                    if (selection.includes(child_option[0])) {
                        selection.splice(selection.indexOf(child_option[0]), 1)
                    }
                }
            }
        }
        vnode.attrs.selection.splice(0, vnode.attrs.selection.length, ...selection)
    }

    render_options(vnode: m.Vnode<CheckboxGroupAttrs>, options, depth, parent) {
        return options.map((option) => {
            return (
                <div className={`cb-wrapper-${depth} ${(option.length === 4 && option[3].length) ? 'has-children' : 'no-children'}`}>
                    <div className={this.class_name(option, vnode)}>
                        <input
                            checked={vnode.attrs.selection.includes(option[0])}
                            disabled={vnode.attrs.disabled}
                            id={`${this.name}-${option[0]}`}
                            name={`${this.name}-${option[0]}`}
                            onchange={(e) => this.on_change(e, vnode, option, parent)}
                            type="checkbox"
                        />
                        <label for={`${this.name}-${option[0]}`}>{(() => {
                            if (vnode.attrs.translate) {
                                if (!vnode.attrs.translate.label) return $t(`${vnode.attrs.translate.prefix}${option[0]}`)
                                return $t(`${vnode.attrs.translate.prefix}${option[1]}`)
                            }
                            return option[1]
                        })()}
                        </label>
                        {vnode.attrs.filter_count && option.length >= 3 && <div className="count">{option[2]}</div>}
                    </div>
                    {option.length === 4 && Array.isArray(option[3]) && (
                        <div class="sub-items">
                            {this.render_options(vnode, option[3], depth + 1, option)}
                        </div>
                    )}
                </div>
            )
        })
    }

    view(vnode: m.Vnode<CheckboxGroupAttrs>) {
        return (
            <div className="c-checkbox-group">
                {this.render_options(vnode, vnode.attrs.options, 1)}
            </div>
        )
    }
}
