import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Link} from '@bitstillery/common/components'
import {$t} from '@bitstillery/common/app'

import {$s} from '@/app'

export class MyPortalPrivacy extends MithrilTsxComponent<any> {

    oninit() {
        const current_route = m.route.get()
        // The privacy statement page on the landing page.
        if (current_route.startsWith('/privacy')) {
            $s.page.title = $t('page.title.privacy', {vendor: process.env.MSI_TITLE})
            $s.page.subtitle = $t('page.subtitle.privacy')
        }
    }

    view(_vn:m.Vnode<any>) {
        return (
            <div className="c-my-portal-privacy landing landing-privacy">
                <div className="text-container">
                    <p>
                        {'We greatly value your personal data and therefore comply with the conditions in the GDPR. '}
                        {'In this privacy statement we will explain how we treat your personal data and which rights '}
                        {'you have regarding to your personal data.'}
                    </p>
                    <h4>1. Contact information</h4>
                    <p>{process.env.MSI_LEGAL_NAME} (hereafter {process.env.MSI_TITLE})</p>
                    <span>Eelkemastraat 181</span>
                    <br/>
                    <span>9723 ZW Groningen (The Netherlands)</span>
                    <br/>
                    <span>{process.env.MSI_LEGAL_COC}</span>
                    <br/>

                    <p>Mail address: <Link href={`mailto:${process.env.MSI_LEGAL_EMAIL}`}>{process.env.MSI_LEGAL_EMAIL}</Link></p>
                    <p>The following websites and web portal belong to {process.env.MSI_TITLE}:</p>
                    <ul>
                        {process.env.MSI_LEGAL_DOMAINS.split(',').map((website) => <li>{<Link href={`https://${website}`} target="_blank">{website}</Link>}</li>)}
                    </ul>

                    <p>
                        {'If you have questions about this privacy statement, you can contact us via '}
                        <Link href={`mailto:${process.env.MSI_LEGAL_EMAIL}`}>{process.env.MSI_LEGAL_EMAIL}</Link>
                    </p>

                    <h4>2. What kind of personal data do we use and for what purposes?</h4>
                    <p>
                        We can collect your personal data, such as name and address, to do business or to create an account for you on our web portal.
                    </p>
                    <p>
                        {'We only share your personal data with companies that work for us and which need your personal data '}
                        {'to help us offering our services (for example sharing your address to deliver our products). '}
                        {'We have signed Data Processing Agreements (DPA) with parties that process your personal data, '}
                        {'so we are certain that your personal data is handled properly. This may also involve countries '}
                        {'outside the European Economic Area.'}
                    </p>
                    <p>
                        {'In addition, we use this data to send you e-mails. These are e-mails about your order, '}
                        {'but can contain marketing messages as well. If we send automatic e-mails, they will always '}
                        {'include a link to unsubscribe you from further e-mails.'}
                    </p>

                    <h4>3. How do we protect personal data?</h4>
                    <p>
                        {'We think it is very important that your personal data is well protected. '}
                        {'Therefore, we have taken the following security measures:'}
                    </p>

                    <ul>
                        <li>Physical measures for access security</li>
                        <li>A secured digital work environment</li>
                        <li>A secured web portal</li>
                    </ul>

                    <h4>4. Which organisations will receive your personal data?</h4>
                    <p>Sometimes we have a legal duty to provide data to the following authorities:</p>
                    <ul>
                        <li>Tax authority</li>
                        <li>Border authority</li>
                    </ul>

                    <h4>5. Which rights do you have regarding your personal data?</h4>
                    <p>You have the following rights regarding your personal data:</p>
                    <ul>
                        <li>Access: you have the right to access the personal data we collect from you.</li>
                        <li>Rectification: you may request that we rectify your personal data when the data is incorrect.</li>
                        <li>Erasure: you may request that we (partly) erase your personal data.</li>
                        <li>Restriction: you may request that we limit the use of your data.</li>
                        <li>Data portability: you may request that we transfer certain data (or have them transferred) to other organizations.</li>
                        <li>Object: you may object to data processing</li>
                    </ul>

                    <p>
                        {'If you wish to use one of the above rights, you can let us know via '}
                        <Link href={`mailto:${process.env.MSI_LEGAL_EMAIL}`}>{process.env.MSI_LEGAL_EMAIL}</Link>
                    </p>
                    <p>
                        {'To prevent abuse, we can ask you to adequately identify yourself. '}
                        {'We will assess your request as soon as possible, but no later than 4 weeks from the '}
                        {'receipt of the request. If the request is not accepted, there is the possibility to object '}
                        {'or submit a complaint to the Dutch Data Protection Authority (Autoriteit Persoonsgegevens).'}
                    </p>

                    <h4>6. How long will the personal data be stored?</h4>
                    <p>
                        {'We store personal data no longer than it is necessary for the concerned purpose. '}
                        {'Moreover we take the legal data retention periods into account as well.'}
                    </p>

                    <h4>7. Cookies</h4>
                    <p>
                        {'To make our website(s) work properly, and to provide the most relevant products and services '}
                        {'to our customers, we place cookies on your device. Below information about the cookies that '}
                        {'we use on our website can be found.'}
                    </p>

                    <h5>Functional cookies</h5>
                    <p>
                        We use functional cookies to make parts on our website and web portal work better, such as:
                    </p>
                    <ul>
                        <li>remembering and saving products you add to the shopping basket;</li>
                        <li>giving a notification of an unfinished order so you do not lose the items in the shopping basket;</li>
                        <li>stored browser settings so you can view our website and web portal optimally on your screen;</li>
                        <li>
                            {'detecting possible abuse or potential problems in our website and web portal, '}
                            {'for example by registering successive login attempts.'}
                        </li>
                    </ul>

                    <h5>Analytical cookies</h5>
                    <p>
                        {'We use analytical cookies so we can see how our website and web portal are being used. '}
                        {'This allows us to continuously improve our website and web portal for the benefit of our users.'}
                    </p>
                    <p>
                        {'We use the Google Analytics application for our website. This allows us to see which pages '}
                        {'are frequently visited and which search queries are being used on the website. We do not share '}
                        {'the collected data with third parties. We have taken the following measures, namely:'}
                    </p>

                    <ul>
                        <li>IP addresses are anonymised;</li>
                        <li>Data sharing with Google is disabled.</li>
                    </ul>

                    <p>
                        {'In addition, we use the Tawk application for our web portal. With this program we '}
                        {'can investigate the use of the web portal and support users via chat when they encounter '}
                        {'problems. By using analytical cookies, statistics are generated about how often the web portal '}
                        {'is visited and whether a user suspiciously logs in from another country.'}
                    </p>
                    <p>Among other things, we may store the following data, namely:</p>

                    <ul>
                        <li>the IP address that is made anonymous;</li>
                        <li>
                            {'technical characteristics, such as the browser you use (for instance Chrome, '}
                            {'Safari or Firefox), the resolution of your computer screen, your language preference '}
                            {'and which device you use;'}
                        </li>
                        <li>when and how long you visit or use the web portal.</li>
                    </ul>

                    <p>
                        {'More information about Tawk and protection of privacy can be found '}
                        <Link href="https://www.tawk.to/data-protection/gdpr/">here</Link>.
                    </p>

                    <h5>Refusing or deleting cookies</h5>
                    <p>
                        {'Visitors of our website can refuse cookies via the browser settings. '}
                        {'They can also delete the already placed cookies there. If you want more information '}
                        {'about refusing or removing cookies, you can visit '}
                        <Link href="https://www.which.co.uk/help/our-policies-and-standards/1979/cookie-and-tracking-technologies">this website</Link>.
                    </p>
                </div>
            </div>
        )
    }
}
