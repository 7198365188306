import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {$t} from '@bitstillery/common/app'

import {$s} from '@/app'

export class PromotionTerms extends MithrilTsxComponent<any> {
    view(_vnode: m.Vnode<any>) {
        return <span className="c-promotion-terms" onclick={() => {
            $s.promotion.terms.collapsed = !$s.promotion.terms.collapsed
            m.route.set('my-portal/promotions')
        }}>{$t('promotions.terms.conditions')}</span>
    }
}
