import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'

interface BadgeAttrs {
    type: 'default' | 'info' | 'success' | 'danger' | 'warning' | 'brand'
    onclick: () => unknown
    className?: string
}

export class Badge extends MithrilTsxComponent<BadgeAttrs> {
    view(vn: m.Vnode<BadgeAttrs>) {
        const type = vn.attrs.type || 'default'
        return <span
            onclick={vn.attrs.onclick}
            className={classes('c-badge', `type-${type}`, vn.attrs.className)}>
            {vn.children}
        </span>
    }
}
