{
    "added": "Añadido",
    "back": "Volver",
    "bar_categories": {
        "title": "Categorías"
    },
    "cart": {
        "active": "Carros activos",
        "booked": {
            "help": "Estos artículos están confirmados y reservados por usted y, por tanto, reservados por nosotros. Se muestran aquí sólo para su referencia. Póngase en contacto con nosotros si desea realizar alguna modificación."
        },
        "checkout_button": "Pedido",
        "checkout_header": "Cesta de la compra",
        "checkout_one": "Pago ({{count}})",
        "checkout_order": "Pedido",
        "checkout_order_id": "Pedido {{id}}",
        "checkout_other": "Pago ({{count}})",
        "checkout_tip": "Checkout - comprobar su pedido actual",
        "checkout_zero": "Pedido",
        "content_one": "Usted tiene {{count}} producto en su carrito.",
        "content_other": "Tiene {{count}} {{units}} en su carrito.",
        "empty_booked_desc": "Los artículos recién añadidos aparecerán aquí para su revisión. Una vez listos, aquí es también donde se finaliza el pedido y se reservan estos artículos.",
        "empty_booked_title": "No tiene artículos recién seleccionados (todavía)",
        "empty_desc": "Los productos añadidos al pedido aparecerán aquí para su revisión. Una vez listos, aquí es también donde se finaliza el pedido y se reservan estos artículos.",
        "empty_title": "Añadir productos",
        "error_api": "Se ha producido un error en tu cesta.",
        "error_help": {
            "api": "Recarga la página y/o elimina el producto con problemas. Ponte en contacto con el servicio de asistencia si el problema persiste.",
            "less_available": "Actualizar cesta reducirá la cantidad del producto a la cantidad disponible.",
            "minimum_quantity": "Actualizar cesta elevará la cantidad del producto a la cantidad mínima del pedido",
            "price_up": "Actualizar cesta elevará el artículo de la cesta al precio correcto",
            "unavailable": "Actualizar cesta eliminará este producto de la cesta"
        },
        "error_less_available": "Menos disponible",
        "error_minimum_quantity": "Se aplica la cantidad mínima de pedido",
        "error_ok": "Productos listos para proceder",
        "error_one": "Este producto requiere tu atención",
        "error_other": "Estos productos requieren tu atención",
        "error_price_up": "Se ha subido el precio del producto",
        "error_unavailable": "No disponible",
        "issues": "Actualizaciones del carrito de la compra",
        "issues_action": "Resuelva los siguientes problemas del producto para poder comprobar su cesta.",
        "issues_none": "Tu carrito está listo",
        "less_available_action": "Ajuste el importe de la cesta en consecuencia o elimine el producto de la cesta para continuar con la compra.",
        "less_available_one": "Por el momento sólo está disponible {{count}} {{units}} .",
        "less_available_other": "Por el momento sólo está disponible {{count}} {{units}} .",
        "min_quantity": "La cantidad mínima de pedido para este producto es {{count}} {{units}} .",
        "min_quantity_action": "Ajuste el importe de la cesta en consecuencia o elimine el producto de la cesta para continuar con la compra.",
        "min_quantity_confirm": "Confirme el importe mínimo del pedido: {{count}} {{units}}",
        "minus_all": "Elimine {{product}} de su cesta.",
        "minus_one": "Elimine {{count}} {{units}} de su cesta.",
        "minus_other": "Elimine {{count}} {{units}} de su cesta.",
        "new": "Carrito vacío",
        "new_key": "Soy traductor",
        "new_quantity_confirmed": "Nueva cantidad confirmada.",
        "panel": {
            "header": {
                "cart": "Su cesta ({{id}})",
                "cart_new": "Su cesta"
            }
        },
        "plus_all": "Añada {{product}} a su cesta",
        "plus_one": "Añadir {{count}} {{units}} a la cesta",
        "plus_other": "Añadir {{count}} {{units}} a la cesta",
        "price_changed": "El precio de este producto ha cambiado de {{old_price}} {{currency}} a {{new_price}} {{currency}} .",
        "price_changed_action": "Confirme el precio modificado o elimine el producto de la cesta para continuar con la compra.",
        "price_confirm": "Confirma los nuevos precios.",
        "price_confirmed": "Nuevo precio confirmado.",
        "price_up_agree_help_one": "Actualiza tu cesta con el precio aumentado",
        "price_up_agree_help_other": "Actualiza tu cesta con los precios aumentados",
        "price_up_agree_one": "Acepto el aumento de precio",
        "price_up_agree_other": "Acepto la subida de precios",
        "product_amount": "Su cesta contiene ahora {{count}} {{units}} de {{product}}",
        "product_removed": "El producto {{product}} fue removido de su carrito",
        "rounded_one": "El número se redondea a {{count}} caja ({{bottles}} botellas)",
        "rounded_other": "El número se redondea a {{count}} cajas ({{bottles}} botellas)",
        "soi": {
            "booked_title": "Reservado para ti",
            "cart_title": "El contenido de tu cesta ({{count}} productos)"
        },
        "stock_one": "Podemos suministrarte {{count}} {{units}} ",
        "stock_other": "Podemos suministrarte {{count}} {{units}} ",
        "switched": "Acaba de cambiar al carro {{cart}}",
        "switched_new": "Acabas de cambiar de carro",
        "total_bottles": "Total botellas",
        "total_cases": "Total de casos",
        "total_price": "Precios totales",
        "unavailable": "Lamentablemente, este producto ya no está disponible.",
        "unavailable_action": "Retire el producto de su cesta para continuar con la compra.",
        "yours": "Su cesta"
    },
    "checkout": {
        "booked": {
            "types": {
                "arriving": "Artículos que llegan",
                "stock": "De Stock",
                "tbo": "Por Encargar"
            }
        },
        "cart": "Carrito",
        "comment_delete_tip": "Borrar comentario",
        "comment_store": "Guardar comentario",
        "comment_stored": "Comentario almacenado",
        "comment_title": "Añade un comentario para este producto",
        "delivery": {
            "asap": "Lo antes posible",
            "date": "En una fecha determinada"
        },
        "status": {
            "book": "Reservar productos; finalizar el pedido más tarde",
            "book_help": "Los productos se reservan para usted, puede añadir más productos más tarde y finalizar su pedido entonces.",
            "confirm_cart_update_help": "Todas las actualizaciones del carrito se aplicarán automáticamente",
            "finalize": "Finalizar pedido",
            "finalize_help": "Su pedido se convierte en definitivo y comprobaremos su pedido; ya puede descargar la factura proforma.",
            "step0_title": "Tu cesta de la compra",
            "step1_button": "Finalizar cesta",
            "step1_button_fix": "Actualizar cesta",
            "step1_title": "Verificar cesta",
            "step2_button": "Hacer pedido",
            "step2_button_book": "Productos de librería",
            "step2_title": "Cesta de la compra"
        },
        "total": "Total",
        "vat": "IVA",
        "vouchers": {
            "label": "Vales"
        }
    },
    "dashboard": {
        "widgets": {
            "demo_user": {
                "context_1": "Con esta cuenta de demostración puede consultar existencias, ver precios y descargar listas de precios. Puede ampliar su cuenta poniéndose en contacto con el responsable de ventas. Con su propia cuenta podrá hacer pedidos y marcar artículos como favoritos.",
                "context_2": "Cualquier orden con esta cuenta demo no será manejada por nosotros. Por lo tanto, no se le cobrará. No dude en experimentar. No dude en ponerse en contacto con su gestor de ventas si tiene alguna pregunta. Los datos se encuentran en la esquina inferior izquierda.",
                "title": "Bienvenido a nuestro portal, ¡me alegro de que se haya pasado por aquí!"
            },
            "latest_orders": {
                "activate_order_tip": "Activar pedido",
                "active_order": "Pedido actual",
                "stock_complete": "Todos los artículos están en stock"
            },
            "tips": {
                "title": "Trucos y consejos"
            }
        }
    },
    "demo": {
        "dialog": {
            "button_ok": "¡Muy bien!",
            "checkout_context_1": "¡Lo ha encontrado! La página con los productos que quieres pedir y donde puedes confirmar tu pedido no funciona con una cuenta demo. Para ello necesita una cuenta completa. Para ello, necesita una cuenta real, que su gestor de ventas puede proporcionarle.",
            "checkout_context_2": "Y, por favor, díganos qué le parece este portal. Así podremos mejorarlo para usted (y para nosotros). Si tiene alguna pregunta, póngase en contacto con su jefe de ventas. Encontrará los datos en la esquina inferior izquierda.",
            "checkout_title": "Página de pago"
        },
        "favorites_disabled": "Lamentablemente, no es posible almacenar favoritos con su cuenta de demostración. Si tiene alguna duda, póngase en contacto con su gestor de ventas. Encontrará los datos en la esquina inferior izquierda."
    },
    "email_address": "Dirección de correo electrónico",
    "excise": {
        "excluding": "Excl. impuestos especiales",
        "including": "Impuestos especiales incluidos"
    },
    "filters": {
        "pricelist": {
            "alcohol_format": "Formato del alcohol",
            "alcohol_format_help": "¿Cómo quieres formatear el porcentaje de alcohol?",
            "cancel_download": "Cancelar",
            "download": "Descargar lista de precios",
            "download_tip": "Exportar lista de precios en formato Excel (.xlsx)",
            "download_tip_busy": "La lista de precios se está elaborando para usted...",
            "filename": "exportación de listas de precios",
            "products_selected": "Productos: {{count}}/{{total}}",
            "select_all": "Seleccionar todos los productos...",
            "volume_format": "Formato del volumen",
            "volume_format_help": "¿Cómo quieres formatear el volumen de la botella?"
        },
        "reset_one": "Restablecer filtro",
        "reset_other": "Restablecer filtros",
        "selected_one": "{{count}} seleccionado",
        "selected_other": "{{count}} seleccionado",
        "title": {
            "alcohol": "Porcentaje de alcohol",
            "availability": "Disponibilidad",
            "offertype": "Tipo de oferta",
            "packaging": "Embalaje",
            "refill": "Rellenable",
            "search": "Buscar en",
            "volume": "Volumen"
        },
        "types": {
            "availability": {
                "eta": "Llegada prevista: {{period}}",
                "purchase": "Llegada",
                "stock": "En stock",
                "tbo": "Por encargo"
            },
            "offertype": {
                "FAVORITES": "Favoritos",
                "NEW_ARRIVALS": "Novedades",
                "SPECIALS": "Ofertas especiales"
            },
            "products": "Productos",
            "refill": {
                "nonref": "No recargable",
                "ref": "Rellenable"
            }
        }
    },
    "help": {
        "explain": "Explique esta página"
    },
    "loading": "Cargando",
    "location": "Ubicación",
    "messroom": {
        "description": "Se trata de una configuración única para optimizar la experiencia de compra en nuestro portal, al tiempo que le ofrecemos la información más reciente sobre precios y disponibilidad. Por favor, espere hasta que preparemos su cuenta del portal. Gracias por su paciencia.",
        "step0": "Preparar la cuenta",
        "step1": "Actualización de las disponibilidades",
        "step2": "Generación de precios",
        "steps_completed": "¡Ya está! Ahora serás redirigido al portal...",
        "steps_processing": "Espere mientras preparamos su cuenta del portal...",
        "team": "Equipo {{team}}",
        "title": "Configuración única del portal"
    },
    "my_portal": {
        "news": "Noticias",
        "privacy": "Privacidad",
        "profile": "Mi perfil",
        "promotions": "Promociones",
        "security": {
            "label": "Seguridad",
            "notifications": {
                "current_password_invalid": "Tu contraseña actual no es válida",
                "new_password_invalid": "Tu nueva contraseña no es válida",
                "password_updated": "Tu contraseña ha sido actualizada; por favor, inicia sesión de nuevo."
            },
            "password": {
                "current": "Contraseña actual",
                "current_help": "Para actualizar su contraseña actual es necesario que la introduzca.",
                "new": "Nueva contraseña",
                "new_help": "La contraseña debe tener al menos 8 caracteres",
                "repeat": "Repetir contraseña",
                "repeat_help": "Después de cambiar su contraseña, se le pedirá que se conecte de nuevo."
            },
            "save_button": "Cambiar contraseña"
        },
        "settings": {
            "label": "Ajustes",
            "price_quantity": "Precios y cantidades",
            "price_quantity_help": "Utilice precios de botella o caja en todo el portal",
            "save": "Guardar configuración",
            "saved_profile": "Su perfil ha sido guardado",
            "saved_profile_error": "No se ha podido actualizar tu perfil, inténtalo de nuevo más tarde",
            "saved_settings": "Tus ajustes se han guardado"
        }
    },
    "no": "No",
    "notifications": {
        "cart_api_error": "Lo sentimos, se ha producido un problema inesperado al actualizar tu cesta. Por favor, intenta recargar el portal. Ya estamos en ello, pero no dudes en ponerte en contacto con el servicio de asistencia si el problema persiste.",
        "comment_deleted": "Comentario eliminado",
        "comment_stored": "Comentario almacenado",
        "invite_failure": "Tu código de invitación ha caducado o ya no es válido.",
        "messroom_ready": "Su portal está listo para ser utilizado.",
        "news": {
            "title": "Últimas noticias"
        },
        "order_booked": "¡Tus productos en pedido ({{id}}) están reservados por nosotros!",
        "order_confirmed": "¡Su pedido ({{id}}) ha sido confirmado por nosotros!",
        "order_failure": "Se ha producido un problema al cargar el pedido {{order}}; Vuelve a intentarlo más tarde",
        "order_issue": "Hay un problema con uno o más artículos de su pedido; por favor, compruebe las advertencias del pedido",
        "order_repeated": "Las líneas del pedido {{order}} se copiaron en tu carrito",
        "promotion_redeemed": "¡Enhorabuena! ¡Acabas de canjear tu promoción!",
        "removed_product": "El producto {{product}} fue removido de su carrito",
        "session_expired": "Su sesión en el portal ha caducado; lamentablemente, debe iniciar sesión de nuevo."
    },
    "offer": {
        "arriving_soon": "Próximamente",
        "best_deal": "Mejor oferta",
        "delivery_week_one": "{{count}} semana",
        "delivery_week_other": "{{count}} semanas",
        "download_offer": "Descargar oferta",
        "expired": {
            "description": "Lamentablemente, la oferta personal que estás viendo ya no es válida. Consulta nuestra página de existencias para ver otras ofertas.",
            "title": "Oferta caducada"
        },
        "latest_orders": "Sus últimos pedidos",
        "latest_orders_empty": "Sus pedidos de venta más recientes aparecerán aquí",
        "loading_products": "Recuperar productos...",
        "my_favourites": "Mis favoritos",
        "my_favourites_add": "Añadir a mis favoritos",
        "my_favourites_help": "Marque los artículos como favoritos utilizando el icono del corazón. Estos productos aparecerán aquí y en la opción de menú \"Mis favoritos\".",
        "my_favourites_remove": "Eliminar de Favoritos",
        "my_favourites_tip": "Mis favoritos: una selección de sus productos preferidos",
        "name": "Oferta",
        "new_arrivals": "Recién llegados",
        "new_arrivals_tip": "Recién llegados: nuestros productos más recientes, que acaban de llegar o lo harán en breve.",
        "newest_arrivals": "Nuestros recién llegados",
        "no_personal": "No hay ofertas personales por el momento",
        "personal": "Sus ofertas personales",
        "personal_not_found": "Lamentablemente, esta oferta especial ya no está disponible; ¿has visto nuestras otras ofertas?",
        "personal_valid": "Una oferta personal para ti: '{{text}}'\n",
        "popup": {
            "continue": "Al salpicadero",
            "show_offer": "Mostrar oferta"
        },
        "show_all": "Mostrar todo",
        "special": "Ofertas especiales",
        "special_count": "Ofertas especiales",
        "special_tip": "Ofertas especiales: productos con un precio especial, válido sólo durante un tiempo limitado.",
        "valid_until": "Válido hasta el {{date}}",
        "view_offer": "Ver oferta"
    },
    "order": {
        "agree_t1": "Tiene productos con estatus aduanero T1",
        "agree_tos": "Estoy de acuerdo con los <a href={{link}} target=\"_blank\">términos y condiciones</a>",
        "completion": "Finalización del pedido",
        "confirmed": {
            "description": "Ahora nos toca a nosotros; nos aseguraremos de que la mercancía llegue hasta ti. Mientras tanto, no dudes en echar un vistazo a nuestras existencias para ver más ofertas. No dudes en ponerte en contacto con nosotros si tienes alguna pregunta sobre tu pedido.",
            "subtitle": "Su pedido se ha realizado correctamente",
            "title": "¡Felicidades!"
        },
        "delivery": "Preferencia de entrega",
        "delivery_date": "Fecha de entrega",
        "details": {
            "actions": {
                "download_delivery_note": "Descargar albarán",
                "download_invoice": "Descargar factura",
                "download_order_note": "Descargar nota de pedido",
                "download_proforma": "Descargar Proforma",
                "repeat_order": "A la cesta",
                "repeat_order_tip": "Copia el contenido de este pedido a tu cesta. Esto sólo puede hacerse para productos y cantidades que estén actualmente en stock."
            },
            "additional": {
                "description": "Descripción",
                "price_unit": "Precio unitario",
                "quantity": "Cantidad",
                "type": "Tipo"
            },
            "additionals": "Artículos adicionales y descuentos",
            "amount": "Importe",
            "booked": "Reservado",
            "booked_context": "Estos artículos son confirmados y reservados por usted y, por tanto, reservados por nosotros.",
            "cart": "Carrito",
            "cases": "Casos",
            "comment": "Cómo",
            "created_by": "Creado por",
            "credit": {
                "credited_from": "Acreditado por",
                "label": "Créditos"
            },
            "date": "Fecha",
            "empty": "Su pedido aún no tiene productos",
            "empty_context": "Añada productos a su cesta desde nuestro resumen de existencias.",
            "invoice_date": "Fecha de la factura",
            "invoice_number": "Número de factura",
            "items": {
                "arriving": "Próximamente",
                "basket_unconfirmed": "Artículos de la cesta (sin confirmar)",
                "basket_unconfirmed_help": "Estos artículos aún necesitan confirmación por su parte antes de que podamos reservarlos. Confirme su pedido para continuar.",
                "stock": "De Stock",
                "tbo": "Por encargo"
            },
            "order_number": "Número de pedido",
            "order_status": "Estado del pedido",
            "price": "Precio",
            "product": "Producto",
            "specs": "Especificaciones",
            "status": "Estado",
            "subtotal": "Subtotal",
            "total_value": "Valor total",
            "vat": "IVA"
        },
        "empty_call_to_action": "Examinar existencias",
        "loading_orders": "Cargando Pedidos...",
        "new": "Nuevo",
        "tos": "Condiciones generales"
    },
    "orders": {
        "invoice_date": "Facturado el",
        "invoice_number": "Número de factura",
        "no_orders": "Aún no tiene pedidos",
        "portal_status": {
            "Cancelled": "Cancelado",
            "Confirmed": "Confirmado",
            "Finalized": "Finalizado",
            "Invoiced": "Facturado",
            "New": "Nuevo",
            "Pending": "Pendiente"
        },
        "status": "Estado del pedido",
        "status_active": "Activo",
        "status_open": "Abrir"
    },
    "page": {
        "browse_stock_tip": "Buscar en stock - visión general de todos nuestros productos",
        "dashboard": "Cuadro de mandos",
        "dashboard_tip": "Cuadro de mandos: una visión general de nuestras mejores ofertas y promociones",
        "latest_news": "Últimas noticias",
        "my_orders": "Mis pedidos",
        "my_orders_tip": "Mis pedidos: una visión general de todos sus pedidos",
        "my_portal": "My portal",
        "my_portal_tip": "Mi portal - perfil, noticias y promociones",
        "my_portal_tip_promotion": "Mi portal - ¡Hay una promoción activa!",
        "subtitle": {
            "login": "Iniciar sesión para continuar...",
            "privacy": "Vendemos bebidas espirituosas, no sus datos.",
            "redeem": "Regístrese con su código único...",
            "registration": "Háblenos de su empresa...",
            "registration_completed": "Ya puede cerrar esta ventana..."
        },
        "title": {
            "checkout": "Pago",
            "checkout-0": "Comprar productos",
            "checkout-1": "Verificar cesta",
            "checkout-2": "Finalizar cesta",
            "login": "¡Bienvenido a {{vendor}}!",
            "login_slug": "Inicio de sesión",
            "offer": {
                "my_favourites": "Mis favoritos",
                "new_arrivals": "Recién llegados",
                "personal_offer": "Oferta personal - hasta {{date}}",
                "personal_offer_invalid": "Oferta personal caducada",
                "special_offers": "Ofertas especiales"
            },
            "order": "Pida {{id}}",
            "privacy": "Nuestra política de privacidad",
            "redeem": "¡Me alegro de verte!",
            "registration": "Inscripción",
            "registration_completed": "Gracias por su solicitud."
        }
    },
    "product": {
        "alcohol_percentage": "Porcentaje de alcohol",
        "best_before": "Mejor antes",
        "bottle_gtin": "Botella GTIN",
        "bottle_volume": "Volumen de la botella",
        "bottles": "Botellas",
        "bottles_per_case": "Botellas por caja",
        "cases": "Casos",
        "category": "Categoría",
        "country_of_origin": "País de origen",
        "customs_status": "Situación aduanera",
        "features": "Características",
        "minimum_order_quantity": "Cantidad mínima de pedido",
        "refill_status": "Rellenable",
        "stock_origin": "Este producto procede de {{country}}",
        "stock_origin_count_one": "Podemos suministrarte {{count}} {{units}} de este origen",
        "stock_origin_count_other": "Podemos suministrarte {{count}} {{units}} de este origen"
    },
    "profile": {
        "company": "Su empresa",
        "email_support": "Dirección de correo electrónico de asistencia",
        "family_name": "Apellidos",
        "first_name": "Nombre",
        "mobile_phone_number": "Número de teléfono móvil",
        "new_password": "Elija una contraseña",
        "new_password_help": "La nueva contraseña que desea establecer",
        "new_password_placeholder": "Elija una contraseña",
        "phone_email": "Número de teléfono o dirección de correo electrónico",
        "phone_number": "Número de teléfono",
        "portal_welcome_desc": "Inicia sesión para continuar...",
        "portal_welcome_title": "¡Bienvenido a nuestro portal!",
        "register": "Registrar cuenta gratuita",
        "removal": {
            "ack": "Su solicitud ha sido enviada.",
            "ack_description_1": "Su solicitud ha sido enviada. Nos pondremos en contacto con usted lo antes posible, pero al menos en un plazo de 4 semanas.",
            "ack_description_2": "Te vamos a echar de menos, así que si has archivado esta solicitud por error, ponte en contacto con nosotros en {{email_vendor}} o con tu gestor personal de asistencia, {{manager}} en {{email}} o {{phone}}.",
            "cancel": "Cancelar solicitud",
            "close_window": "Diálogo cercano",
            "confirm": "Confirmar solicitud"
        },
        "removal_description_1": "Cuando proceda, determinaremos qué hacer con su solicitud de supresión lo antes posible, pero al menos en un plazo de 4 semanas. Le informaremos de las medidas que tomaremos para satisfacer su solicitud. Si no atendemos su solicitud, puede presentar una reclamación ante la Autoridad de Datos Personales.",
        "removal_description_2": "Antes de que confirme su solicitud de supresión, nos gustaría preguntarle con quién podemos ponernos en contacto en su lugar. ¿Podría dejarnos un nombre y un número de teléfono o una dirección de correo electrónico? Por favor, hágalo sólo si tiene permiso de la nueva persona de contacto. No es obligatorio dejar estos datos de contacto.",
        "removal_notice": "Por favor, lea esto con atención.",
        "removal_questions": "Para más información, preguntas u observaciones, envíenos un correo electrónico a {{email_vendor}} o póngase en contacto con su gestor personal de asistencia, {{manager}} en {{email}} o {{phone}}.",
        "removal_title": "Te echaremos de menos.",
        "replacement": {
            "contact": "Contactinfo",
            "name": "Persona de contacto"
        },
        "request_password": "¿Ha olvidado su contraseña?",
        "request_removal": "Solicitud de retirada",
        "save_button": "Actualizar perfil"
    },
    "promotions": {
        "active": "Activo entre: {{date}}",
        "active_grace": "La promoción puede canjearse hasta: {{date}}",
        "call_to_action": "Haz tu pedido",
        "confirmed": {
            "description": "Ahora nos toca a nosotros; nos aseguraremos de que la mercancía llegue hasta ti. Mientras tanto, no dudes en echar un vistazo a nuestras existencias para ver más ofertas. No dudes en ponerte en contacto con nosotros si tienes alguna pregunta sobre tu pedido.",
            "subtitle": "¡Tu promoción ha sido canjeada!"
        },
        "description": "¡Tenemos otra gran promoción para ti! Conviértete en un auténtico barista ahorrando granos de café con cada {{token_value}} que gastes. Hay varias cafeteras que puedes ganar.",
        "details": "Haga clic para ver los detalles de la promoción",
        "eligible_multiple": "Un haba activa representa {{token_value}} del valor del pedido de ventas que ha sido confirmado o facturado por tu gestor de ventas. Has recogido {{turnover_draft}} del valor del pedido de ventas elegible, del cual {{turnover_redeemable}} ya está confirmado o facturado.",
        "gifts": {
            "available": "Regalos disponibles",
            "choose": "Su oferta",
            "choose_help": "Puedes elegir una oferta después de haberte acogido a la promoción.",
            "disabled": "Puedes elegir un regalo",
            "locked": "Desbloquee su regalo añadiendo {{amount}} a su pedido",
            "locked_placeholder": "Desbloquea primero tu don...",
            "unlocked": "¡Enhorabuena! Has desbloqueado un regalo.",
            "unlocked_choice_made": "{{choice}} - ¡Buena elección!",
            "unlocked_choose": "Seleccione su regalo preferido a continuación...",
            "unlocked_placeholder": "Seleccione su regalo...",
            "unlocked_redirect": "¡Enhorabuena! ¡Has desbloqueado un regalo! Consulta la sección <a href={{link}}>promociones</a> para obtener más información sobre los distintos regalos..."
        },
        "giveaway": "¡Pide {{spent_next}} {{currency}} para ganar un grano de café!",
        "giveaway_encourage": "¡Pide {{spent_next}} {{currency}} más y reclama un grano de café!",
        "incentive_hurry_one": "Sólo queda {{count}} ",
        "incentive_hurry_other": "¡Sólo quedan {{count}} días!",
        "incentive_progress": "¡Pide {{amount}} más por un grano de café!",
        "label": {
            "dialog_subtitle": "¿Alguna vez has querido ser barista? ¡Ahora es tu oportunidad! ¡Gana un grano de café por cada {{token_value}} que pidas y elige entre una de las tres fabulosas máquinas de café! Y por si fuera poco, ¡te regalamos nada menos que 6 kg de granos de café arábica orgánico! ¡Guarda tus granos y prepara tu propio y delicioso Americano, Latte o Expresso!",
            "promotion": "Promoción",
            "redeemable_tokens": "Alubias recogidas",
            "show_promotion": "Promoción del espectáculo"
        },
        "order_reminder": "¡Ya casi estás listo para convertirte en barista! Cuando hayas alcanzado {{token_value}}, podrás canjear tu promoción.",
        "order_reminder_eligible": "¡Estás a un paso de convertirte en barista! Estamos revisando tu pedido y recibirás un correo electrónico de confirmación en 24 horas. Una vez que recibas este correo electrónico, podrás canjear tus granos de café. ¡Gracias por tu paciencia!",
        "redeem": "Canjear",
        "terms": {
            "conditions": "Términos y condiciones",
            "period": "Periodo de promoción: {{period}}",
            "terms_0": "La promoción es válida para los pedidos realizados a través de nuestro portal; contamos todos tus pedidos finalizados durante este periodo.",
            "terms_1": "Una vez que hayas hecho el pedido y cumplas los requisitos para recibir un grano de café, puedes pedir inmediatamente una cafetera espresso a través del portal, que añadiremos a tu pedido.",
            "terms_2": "Las máquinas de café espresso ofrecidas están sujetas a disponibilidad de existencias. Si los modelos seleccionados no están disponibles, seleccionaremos un modelo comparable / equivalente en consulta contigo.",
            "terms_3": "No nos hacemos responsables de la información facilitada por el fabricante en su sitio web. Es meramente informativa.",
            "terms_4": "El importe total, es el importe del valor de la mercancía pedida y pagada por factura. Los costes adicionales facturados no cuentan para la promoción (envío / transporte por camión / palés).",
            "terms_5": "La garantía del producto sólo es aplicable en Holanda.",
            "terms_6": "Una máquina de café exprés no puede canjearse por un descuento en metálico.",
            "terms_7": "Esta promoción ha sido elaborada para ti con esmero, aunque está sujeta a errores tipográficos."
        }
    },
    "registration": {
        "address": "Dirección",
        "address_placeholder": "Dirección de la empresa",
        "agree": "Sí, estoy de acuerdo con los <a href={{link}} target=\"_blank\">términos y condiciones</a>",
        "back_login": "Volver al inicio de sesión",
        "city": "Ciudad",
        "city_placeholder": "Empresa Ciudad",
        "company_details": "Datos de la empresa",
        "company_name": "Nombre de la empresa",
        "company_name_placeholder": "Nombre de su empresa",
        "consent": "Correos electrónicos comerciales y ofertas de {{vendor}}",
        "country": "País",
        "country_help": "El país en el que opera",
        "email_address": "Su dirección de correo electrónico",
        "email_address_help": "La dirección de correo electrónico en la que podemos localizarle",
        "explanation": "{{vendor}} suministra a clientes Business to Business de toda Europa y más allá. Como valioso cliente de {{vendor}}, tendrá acceso ilimitado a nuestro Portal de Ventas. Podrá disfrutar de las ventajas de un acceso ininterrumpido a la amplia gama de productos que ofrecemos, siempre que lo desee. Hacer un pedido es muy fácil: navegue, elija, haga clic y ¡listo!",
        "family_name": "Apellidos",
        "family_name_placeholder": "Su apellido",
        "first_name": "Nombre",
        "first_name_placeholder": "Su nombre",
        "import_goods_temp_license": "Importo mercancías con una licencia temporal",
        "invite": "Invita",
        "message": "¿Preguntas?",
        "message_placeholder": "¿Tiene alguna pregunta que hacernos? No dude en hacerlas...",
        "name": "Su nombre",
        "position": "El papel de su empresa",
        "position_placeholder": "El papel de su empresa",
        "postal_code": "Código postal",
        "postal_code_placeholder": "Código postal de la empresa",
        "redeem": {
            "code": "Código de invitación",
            "code_help": "Su código único de acceso a nuestro portal",
            "partnership": {
                "footer": "Con amor por nuestro planeta",
                "header": "{{vendor}} se enorgullece de colaborar con {{partner}}."
            },
            "step0_desc": "¿Te entusiasma el negocio de las bebidas espirituosas tanto como a nosotros? Inicia sesión con tu código personal para reclamar tu regalo y acceder a nuestro surtido ampliado, variado y a precios ajustados.",
            "step0_slug": "Juntos hacemos del mundo un lugar mejor",
            "step1_desc": "Rellene la dirección de correo electrónico que desea utilizar, elija una contraseña y ¡comencemos!",
            "step1_slug": "Sobre ti",
            "step2_desc": "¿Es correcta la siguiente información? Entonces pulse el botón \"Registro e inicio de sesión\" y se situará automáticamente en el portal.",
            "step2_slug": "Revisar el registro",
            "step3_slug": "Registro e inicio de sesión"
        },
        "review_request": "Por favor, revise toda la información que nos ha facilitado. Si todo es correcto, puede enviar su inscripción y nos pondremos en contacto con usted en breve.",
        "signup": "Hágase cliente",
        "step0_desc": "{{vendor}} suministra a los clientes de Business to Business. ¿Para qué empresa desea solicitar una cuenta?",
        "step0_slug": "Su empresa",
        "step1_desc": "Introduzca la dirección de su empresa.",
        "step1_slug": "Dirección de la empresa",
        "step2_desc": "¿Cómo podemos ponernos en contacto con usted?",
        "step2_slug": "Datos de contacto",
        "step3_desc": "¿Utiliza las instalaciones de un depósito aduanero en la UE?",
        "step3_desc_vat": "Indique su número de IVA y sus preferencias de almacenamiento.",
        "step3_slug": "Detalles logísticos",
        "step4_desc": "Revise la información que va a enviarnos para su solicitud de inscripción. Puede utilizar el campo de preguntas que aparece a continuación, en caso de que tenga alguna duda para nosotros.",
        "step4_slug": "Revisar el registro",
        "step5_desc": "Verificaremos los datos de la empresa que nos ha facilitado. Este proceso puede tardar unos días. En caso de que necesitemos información adicional, nos pondremos en contacto con usted personalmente. Tras la confirmación, recibirá un correo electrónico con el acuse de recibo de su inscripción.",
        "step5_footer": "Esperamos recibir pronto su primer pedido en línea.",
        "step5_incentive": "¿Sabía que tenemos nuestro propio <a target=\"_blank\" href=\"https://www.youtube.com/@movingspirits474/videos\">canal de Youtube</a>?",
        "step5_notify": "Gracias por su solicitud. Procesaremos su solicitud en unos días laborables.",
        "step5_slug": "Enviar solicitud",
        "step5_title": "¿Y ahora qué?",
        "submit": "Enviar inscripción",
        "telephone": "Su número de teléfono",
        "telephone_placeholder": "+31 502110909",
        "terms_conditions": "Condiciones generales",
        "vat_number": "Número de IVA",
        "vat_number_help": "En los países de la UE se exige el número de IVA",
        "vat_number_placeholder": "Número de IVA",
        "warehouse": {
            "address": "Dirección del almacén",
            "address_help": "Dirección de su almacén",
            "address_unloading": "Dirección de descarga",
            "address_unloading_help": "El lugar donde podemos descargar su pedido",
            "bonded_eu": "¿Depósito aduanero en la UE?",
            "city": "Ciudad almacén",
            "city_unloading": "Ciudad de descarga",
            "excise_id": "Número de identificación fiscal del almacén",
            "location_id": "ID de ubicación del almacén",
            "name": "Nombre del almacén",
            "name_unloading": "Descarga",
            "options": {
                "bonded": "Tengo mi propio almacén aduanero",
                "inc_vat": "Compro en los Países Bajos con IVA e impuestos especiales incluidos",
                "other": "Otros",
                "temp_license": "Importo mercancías con una licencia temporal",
                "third_party": "Utilizo un almacén/socio de almacenamiento externo"
            },
            "postal_code": "Código postal del almacén",
            "postal_code_unloading": "Descarga Código postal",
            "select": "Seleccionar almacén",
            "shipping": {
                "free_shipping": "Bien hecho. Le entregaremos su pedido gratuitamente.",
                "incentive": "Añada <b>{{amount}}</b> a su pedido para la entrega gratuita. Para pedidos inferiores a <b>{{threshold}}</b>los gastos de transporte adicionales se añadirán a su factura proforma para que pueda confirmarlos."
            },
            "warehousing": "Almacenamiento"
        },
        "website": "Sitio web de la empresa",
        "website_placeholder": "su-empresa.com"
    },
    "search": {
        "categories": {
            "category": "categoría",
            "search": "nombre del producto"
        },
        "exact": "Búsqueda por nombre de producto o GTIN utilizando el término \"{{term}}\".",
        "placeholder": {
            "general": "¿Qué busca?",
            "raw": "Búsqueda por nombre de producto o GTIN",
            "raw_term": "Búsqueda por nombre de producto o GTIN utilizando el término \"{{term}}\".",
            "suggestion": "Búsqueda por {{type}} \"{{value}}\""
        },
        "suggestions": {
            "activate": "<Ctrl><Enter> para la búsqueda de términos exactos"
        }
    },
    "select": "Seleccione",
    "spotlight": {
        "countdown": {
            "still_unsold": "Aún sin vender",
            "valid_for": "Oferta aún válida:"
        }
    },
    "status": {
        "error": {
            "redeem": {
                "invalid": "Lástima, este código no es correcto; inténtalo de nuevo.",
                "length": "El código de canje es de 6 caracteres",
                "unknown": "Se ha producido un error al comprobar tu código de canje; inténtalo de nuevo más tarde.",
                "used": "Este código de invitación ya se ha utilizado para crear una cuenta."
            }
        }
    },
    "view": {
        "grid": "Rejilla",
        "grid_switch": "Cambiar a la vista de cuadrícula",
        "list": "Lista",
        "list_switch": "Cambiar a la vista de lista"
    },
    "yes": "Sí"
}
