import {telemetry} from '@bitstillery/common/app'

class Logger {
    static levels = {
        debug: 4,
        error: 0,
        info: 2,
        verbose: 3,
        warn: 1,
    }

    level = Logger.levels.info

    debug(...args) {
        if (this.level >= Logger.levels.debug) {
            args[0] = `%c${args[0]}`
            args.push('color: #aaa')

            // eslint-disable-next-line no-console
            console.info(...args)
        }
    }

    error(...args) {
        // eslint-disable-next-line no-console
        console.error(...args)
    }

    exception(message: string, context: unknown) {
        if (telemetry.enabled) {
            telemetry.capture_message(message, context)
        } else {
            // eslint-disable-next-line no-console
            console.error(message, context)
        }
    }

    group(name) {
        // eslint-disable-next-line no-console
        console.group(name)
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    group_end() {
        // eslint-disable-next-line no-console
        console.groupEnd()
    }

    info(...args) {
        if (this.level >= Logger.levels.info) {
            // eslint-disable-next-line no-console
            console.info(...args)
        }
    }

    set_level(level) {
        this.level = Logger.levels[level]
    }

    warn(...args) {
        if (this.level >= Logger.levels.warn) {
            // eslint-disable-next-line no-console
            console.warn(...args)
        }
    }
}

export default Logger
