{
    "added": "Hinzugefügt",
    "back": "Zurück",
    "bar_categories": {
        "title": "Kategorien"
    },
    "cart": {
        "active": "Aktuelle Warenkorb",
        "booked": {
            "help": "Diese Artikel sind von Ihnen bestätigt und gebucht und daher von uns reserviert. Sie werden hier nur zu Ihrer Information angezeigt. Bitte kontaktieren Sie uns, wenn Sie Änderungen vornehmen möchten."
        },
        "checkout_button": "Kasse",
        "checkout_header": "Kassenwagen",
        "checkout_one": "Kasse ({{count}})",
        "checkout_order": "Checkout Bestellung",
        "checkout_order_id": "Checkout Bestellung {{id}}",
        "checkout_other": "Kasse ({{count}})",
        "checkout_tip": "Zur Kasse gehen - Ihre aktuelle Bestellung abschließen",
        "checkout_zero": "Kasse",
        "content_one": "Sie haben {{count}} Produkt in Ihrem Warenkorb.",
        "content_other": "Sie haben {{count}} {{units}} in Ihrem Warenkorb.",
        "empty_booked_desc": "Neu hinzugefügte Artikel werden hier zur Überprüfung angezeigt. Sobald Sie fertig sind, können Sie hier auch die Bestellung abschließen und die Artikel reservieren.",
        "empty_booked_title": "Sie haben (noch) keine neu ausgewählten Artikel",
        "empty_desc": "Die der Bestellung hinzugefügten Produkte werden hier zur Überprüfung angezeigt. Sobald Sie fertig sind, können Sie hier Ihre Bestellung abschließen und die Artikel reservieren.",
        "empty_title": "Produkte hinzufügen",
        "error_api": "In deinem Warenkorb ist ein Fehler aufgetreten.",
        "error_help": {
            "api": "Lade die Seite neu und/oder entferne das Produkt mit den Problemen. Kontaktiere den Support, wenn das Problem weiterhin besteht.",
            "less_available": "Warenkorb aktualisieren senkt die Menge des Produkts auf die verfügbare Menge.",
            "minimum_quantity": "Warenkorb aktualisieren wird die Produktmenge auf die Mindestbestellmenge erhöhen",
            "price_up": "Warenkorb aktualisieren hebt den Artikel im Warenkorb auf den richtigen Preis an",
            "unavailable": "Warenkorb aktualisieren wird dieses Produkt aus dem Warenkorb entfernen"
        },
        "error_less_available": "Weniger verfügbar",
        "error_minimum_quantity": "Es gilt eine Mindestbestellmenge",
        "error_ok": "Produkte bereit zur Weiterverarbeitung",
        "error_one": "Dieses Produkt erfordert deine Aufmerksamkeit",
        "error_other": "Diese Produkte erfordern deine Aufmerksamkeit",
        "error_price_up": "Der Produktpreis wurde angehoben",
        "error_unavailable": "Nicht verfügbar",
        "issues": "Aktualisierungen des Warenkorbs",
        "issues_action": "Beantworten Sie die folgenden Produktfragen, um Ihren Einkaufswagen zu überprüfen.",
        "issues_none": "Dein Einkaufswagen ist bereit",
        "less_available_action": "Passen Sie den Betrag im Warenkorb entsprechend an oder entfernen Sie das Produkt aus dem Warenkorb, um mit der Kaufabwicklung fortzufahren.",
        "less_available_one": "Zurzeit ist nur {{count}} {{units}} verfügbar.",
        "less_available_other": "Zurzeit ist nur {{count}} {{units}} verfügbar.",
        "min_quantity": "Die Mindestbestellmenge für dieses Produkt ist {{count}} {{units}} .",
        "min_quantity_action": "Passen Sie den Betrag im Warenkorb entsprechend an oder entfernen Sie das Produkt aus dem Warenkorb, um mit der Kaufabwicklung fortzufahren.",
        "min_quantity_confirm": "Bestätigen Sie den Mindestbestellwert: {{count}} {{units}}",
        "minus_all": "Entfernen Sie {{product}} aus Ihrem Warenkorb.",
        "minus_one": "Entfernen Sie {{count}} {{units}} aus Ihrem Warenkorb.",
        "minus_other": "Entfernen Sie {{count}} {{units}} aus Ihrem Warenkorb.",
        "new": "Warenkorb leeren",
        "new_key": "Ich bin ein Übersetzer",
        "new_quantity_confirmed": "Neue Menge bestätigt.",
        "panel": {
            "header": {
                "cart": "Ihr Warenkorb ({{id}})",
                "cart_new": "Your Cart"
            }
        },
        "plus_all": "{{product}} in den Warenkorb legen",
        "plus_one": "{{count}} {{units}} in den Warenkorb legen",
        "plus_other": "{{count}} {{units}} in den Warenkorb legen",
        "price_changed": "Der Preis für dieses Produkt wurde von {{old_price}} {{currency}} auf {{new_price}} {{currency}} geändert.",
        "price_changed_action": "Bestätigen Sie den geänderten Preis oder entfernen Sie das Produkt aus dem Warenkorb, um mit der Kaufabwicklung fortzufahren.",
        "price_confirm": "Bestätige die neuen Preise.",
        "price_confirmed": "Neuer Preis bestätigt.",
        "price_up_agree_help_one": "Aktualisiert deinen Warenkorb mit dem erhöhten Preis",
        "price_up_agree_help_other": "Aktualisiert deinen Warenkorb mit den erhöhten Preisen",
        "price_up_agree_one": "Ich akzeptiere den erhöhten Preis",
        "price_up_agree_other": "Ich akzeptiere die erhöhten Preise",
        "product_amount": "Ihr Warenkorb enthält jetzt {{count}} {{units}} von {{product}}",
        "product_removed": "Das Produkt {{product}} wurde aus Ihrem Warenkorb entfernt.",
        "rounded_one": "Die Zahl wird auf {{count}} gerundet ({{bottles}} Flaschen)",
        "rounded_other": "Die Zahl wird auf {{count}} gerundet ({{bottles}} Flaschen)",
        "soi": {
            "booked_title": "Für dich reserviert",
            "cart_title": "Inhalt deines Warenkorbs ({{count}} Produkte)"
        },
        "stock_one": "Wir können {{count}} {{units}} an Sie liefern.",
        "stock_other": "Wir können {{count}} {{units}} an Sie liefern.",
        "switched": "Sie haben gerade zum Warenkorb gewechselt {{cart}}",
        "switched_new": "Sie haben gerade zu einem neuen Warenkorb gewechselt",
        "total_bottles": "Flaschen insgesamt",
        "total_cases": "Fälle insgesamt",
        "total_price": "Preise insgesamt",
        "unavailable": "Leider ist dieses Produkt nicht mehr erhältlich.",
        "unavailable_action": "Entfernen Sie das Produkt aus Ihrem Einkaufswagen, um den Bestellvorgang fortzusetzen.",
        "yours": "Your Cart"
    },
    "checkout": {
        "booked": {
            "types": {
                "arriving": "Ankommende Artikel",
                "stock": "Vom Lager",
                "tbo": "Zu bestellen"
            }
        },
        "cart": "Warenkorb",
        "comment_delete_tip": "Kommentar löschen",
        "comment_store": "Kommentar speichern",
        "comment_stored": "Gespeicherter Kommentar",
        "comment_title": "Einen Kommentar zu diesem Produkt hinzufügen",
        "delivery": {
            "asap": "So bald wie möglich",
            "date": "Zu einem bestimmten Datum"
        },
        "status": {
            "book": "Produkte buchen; Bestellung später abschließen",
            "book_help": "Die Produkte werden für Sie gebucht, Sie können später weitere Produkte hinzufügen und Ihre Bestellung dann abschließen.",
            "confirm_cart_update_help": "Alle Warenkorb-Updates werden automatisch übernommen",
            "finalize": "Bestellung abschließen",
            "finalize_help": "Ihre Bestellung wird endgültig und wir werden Ihre Bestellung überprüfen; Sie können bereits die Proformarechnung herunterladen.",
            "step0_title": "Dein Einkaufswagen",
            "step1_button": "Warenkorb abschließen",
            "step1_button_fix": "Warenkorb aktualisieren",
            "step1_title": "Warenkorb überprüfen",
            "step2_button": "Bestellung aufgeben",
            "step2_button_book": "Produkte buchen",
            "step2_title": "Kassenwagen"
        },
        "total": "Insgesamt",
        "vat": "MEHRWERTSTEUER",
        "vouchers": {
            "label": "Vouchers"
        }
    },
    "dashboard": {
        "widgets": {
            "demo_user": {
                "context_1": "Mit diesem Demokonto können Sie die Bestände durchsuchen, Preise einsehen und Preislisten herunterladen. Sie können Ihr Konto erweitern, indem Sie sich an den Verkaufsleiter wenden. Mit Ihrem eigenen Konto können Sie Bestellungen aufgeben und Artikel als Favoriten markieren.",
                "context_2": "Alle Aufträge mit diesem Demokonto werden nicht von uns bearbeitet. Sie werden also nicht belastet. Experimentieren Sie ruhig! Bitte zögern Sie nicht, Ihren Verkaufsleiter zu kontaktieren, wenn Sie Fragen haben. Die Details finden Sie in der unteren linken Ecke.",
                "title": "Willkommen auf unserem Portal, schön, dass Sie vorbeischauen!"
            },
            "latest_orders": {
                "activate_order_tip": "Auftrag aktivieren",
                "active_order": "Aktuelle Bestellung",
                "stock_complete": "Alle Artikel sind auf Lager"
            },
            "tips": {
                "title": "Tipps und Tricks"
            }
        }
    },
    "demo": {
        "dialog": {
            "button_ok": "Also gut!",
            "checkout_context_1": "Sie haben es gefunden! Die Seite mit den Produkten, die Sie bestellen möchten und auf der Sie Ihre Bestellung bestätigen können, funktioniert nicht mit einem Demokonto. Sie benötigen dafür ein volles Konto. Dazu benötigen Sie ein echtes Konto, das Ihnen Ihr Verkaufsleiter zur Verfügung stellen kann.",
            "checkout_context_2": "Und bitte lassen Sie uns wissen, was Sie von diesem Portal halten. Auf diese Weise können wir es für Sie (und für uns) noch besser machen. Wenn Sie Fragen haben, wenden Sie sich bitte an Ihren Verkaufsleiter. Die Kontaktdaten finden Sie in der unteren linken Ecke.",
            "checkout_title": "Checkout-Seite"
        },
        "favorites_disabled": "Leider ist es nicht möglich, mit Ihrem Demokonto Favoriten zu speichern. Bitte wenden Sie sich bei Fragen an Ihren Verkaufsleiter. Die Kontaktdaten finden Sie in der unteren linken Ecke."
    },
    "email_address": "E-Mail Adresse",
    "excise": {
        "excluding": "Exkl. Verbrauchssteuer",
        "including": "Inkl. Verbrauchssteuer"
    },
    "filters": {
        "pricelist": {
            "alcohol_format": "Alkohol Format",
            "alcohol_format_help": "Wie willst du den Alkoholanteil formatieren?",
            "cancel_download": "Abbrechen",
            "download": "Preisliste herunterladen",
            "download_tip": "Preisliste im Excel-Format (.xlsx) exportieren",
            "download_tip_busy": "Die Preisliste wird gerade für Sie zusammengestellt...",
            "filename": "Preislistenexport",
            "products_selected": "Produkte: {{count}}/{{total}}",
            "select_all": "Alle Produkte auswählen...",
            "volume_format": "Volume Format",
            "volume_format_help": "Wie willst du das Flaschenvolumen formatieren?"
        },
        "reset_one": "Filter zurücksetzen",
        "reset_other": "Filter zurücksetzen",
        "selected_one": "{{count}} ausgewählt",
        "selected_other": "{{count}} ausgewählt",
        "title": {
            "alcohol": "Prozentsatz des Alkohols",
            "availability": "Verfügbarkeit",
            "offertype": "Angebotstyp",
            "packaging": "Verpackung",
            "refill": "Nachfüllbar",
            "search": "Suche",
            "volume": "Volumen"
        },
        "types": {
            "availability": {
                "eta": "Erwartete Ankunft: {{period}}",
                "purchase": "Ankunft",
                "stock": "Vorrätig",
                "tbo": "Zu bestellen"
            },
            "offertype": {
                "FAVORITES": "Favoriten",
                "NEW_ARRIVALS": "Neu eingetroffen",
                "SPECIALS": "Sonderangebote"
            },
            "products": "Produkte",
            "refill": {
                "nonref": "Nicht nachfüllbar",
                "ref": "Nachfüllbar"
            }
        }
    },
    "help": {
        "explain": "Erklären Sie diese Seite"
    },
    "loading": "Laden",
    "location": "Standort",
    "messroom": {
        "description": "Dies ist eine einmalige Einrichtung, um das Einkaufserlebnis in unserem Portal zu optimieren, während wir Ihnen die neuesten Preis- und Verfügbarkeitsinformationen anbieten. Bitte warten Sie, bis wir Ihr Portalkonto vorbereitet haben. Vielen Dank für Ihre Geduld!",
        "step0": "Konto vorbereiten",
        "step1": "Verfügbarkeiten aktualisieren",
        "step2": "Preise generieren",
        "steps_completed": "Das war's! Sie werden jetzt zum Portal weitergeleitet...",
        "steps_processing": "Bitte warten Sie, während wir Ihr Portalkonto vorbereiten...",
        "team": "Team {{team}}",
        "title": "Einmalige Einrichtung des Portals"
    },
    "my_portal": {
        "news": "Nachrichten",
        "privacy": "Datenschutz",
        "profile": "Mein Profil",
        "promotions": "Werbeaktionen",
        "security": {
            "label": "Sicherheit",
            "notifications": {
                "current_password_invalid": "Dein aktuelles Passwort ist ungültig",
                "new_password_invalid": "Dein neues Passwort ist ungültig",
                "password_updated": "Dein Passwort wurde aktualisiert; bitte melde dich erneut an."
            },
            "password": {
                "current": "Aktuelles Passwort",
                "current_help": "Ihr aktuelles Passwort ist erforderlich, um es zu aktualisieren.",
                "new": "Neues Passwort",
                "new_help": "Das Passwort muss mindestens 8 Zeichen lang sein.",
                "repeat": "Passwort wiederholen",
                "repeat_help": "Nachdem Sie Ihr Passwort geändert haben, werden Sie aufgefordert, sich erneut anzumelden."
            },
            "save_button": "Passwort ändern"
        },
        "settings": {
            "label": "Einstellungen",
            "price_quantity": "Preise und Mengen",
            "price_quantity_help": "Verwenden Sie im gesamten Portal Flaschen- oder Kistenpreise",
            "save": "Einstellungen speichern",
            "saved_profile": "Ihr Profil wurde gespeichert",
            "saved_profile_error": "Dein Profil konnte nicht aktualisiert werden, bitte versuche es später noch einmal",
            "saved_settings": "Ihre Einstellungen wurden gespeichert"
        }
    },
    "no": "Nein",
    "notifications": {
        "cart_api_error": "Leider ist bei der Aktualisierung deines Warenkorbs ein unerwartetes Problem aufgetreten. Bitte versuche, das Portal neu zu laden. Wir arbeiten bereits daran, aber zögere nicht, den Support zu kontaktieren, wenn das Problem weiterhin besteht.",
        "comment_deleted": "Kommentar entfernt",
        "comment_stored": "Kommentar gespeichert",
        "invite_failure": "Dein Einladungscode ist abgelaufen oder nicht mehr gültig.",
        "messroom_ready": "Ihr Portal ist jetzt einsatzbereit!",
        "news": {
            "title": "Neuester Stand!"
        },
        "order_booked": "Deine bestellten Produkte ({{id}}) sind bei uns reserviert!",
        "order_confirmed": "Ihre Bestellung ({{id}}) wird von uns bestätigt!",
        "order_failure": "Beim Laden der Bestellung ist ein Problem aufgetreten {{order}}; Bitte versuche es später noch einmal",
        "order_issue": "Es gibt ein Problem mit einem oder mehreren Artikeln in Ihrer Bestellung; bitte überprüfen Sie die Bestellwarnungen",
        "order_repeated": "Bestellzeilen aus der Bestellung {{order}} wurden in deinen Warenkorb kopiert",
        "promotion_redeemed": "Herzlichen Glückwunsch! Du hast gerade deine Beförderung eingelöst!",
        "removed_product": "Das Produkt {{product}} wurde aus Ihrem Warenkorb entfernt.",
        "session_expired": "Ihre Portalsitzung ist abgelaufen; Sie müssen sich leider erneut anmelden"
    },
    "offer": {
        "arriving_soon": "Bald verfügbar",
        "best_deal": "Bester Deal",
        "delivery_week_one": "{{count}} Woche",
        "delivery_week_other": "{{count}} Wochen",
        "download_offer": "Angebot herunterladen",
        "expired": {
            "description": "Leider ist das persönliche Angebot, das du gerade siehst, nicht mehr gültig. Sieh dir unsere Seite mit den Lagerbeständen für andere Angebote an.",
            "title": "Angebot Abgelaufen"
        },
        "latest_orders": "Ihre letzten Bestellungen",
        "latest_orders_empty": "Ihre letzten Bestellungen werden hier angezeigt",
        "loading_products": "Produkte abrufen...",
        "my_favourites": "Meine Favoriten",
        "my_favourites_add": "Zu meinen Favoriten hinzufügen",
        "my_favourites_help": "Markieren Sie Artikel mit dem Herzsymbol als Favoriten. Diese Produkte erscheinen dann hier und unter dem Menüpunkt \"Meine Favoriten\".",
        "my_favourites_remove": "Aus den Favoriten entfernen",
        "my_favourites_tip": "Meine Favoriten - eine Auswahl Ihrer Lieblingsprodukte",
        "name": "Offer",
        "new_arrivals": "Neu Eingetroffen",
        "new_arrivals_tip": "Neu Eingetroffen - unsere neuesten Produkte; diese sind entweder gerade eingetroffen oder werden in Kürze in den Verkaufsräumen eintreffen",
        "newest_arrivals": "Neu Eingetroffen",
        "no_personal": "Zurzeit keine persönlichen Angebote",
        "personal": "Ihre persönlichen Angebote",
        "personal_not_found": "Leider ist dieses Sonderangebot nicht mehr verfügbar; hast du unsere anderen Angebote gesehen?",
        "personal_valid": "Ein persönliches Angebot für dich: '{{text}}'\n",
        "popup": {
            "continue": "Zum Startseite",
            "show_offer": "Angebot anzeigen"
        },
        "show_all": "Alle anzeigen",
        "special": "Sonderangebote",
        "special_count": "Sonderangebote",
        "special_tip": "Sonderangebote - Produkte mit einem Sonderpreis, der nur für eine begrenzte Zeit gilt",
        "valid_until": "Gültig bis {{date}}",
        "view_offer": "Angebot ansehen"
    },
    "order": {
        "agree_t1": "Hat Produkte mit Zollstatus T1",
        "agree_tos": "Ich bin mit den <a href={{link}} target=\"_blank\">Geschäftsbedingungen</a> einverstanden.</a>",
        "completion": "Abschluss der Bestellung",
        "confirmed": {
            "description": "Jetzt sind wir an der Reihe; wir sorgen dafür, dass die Ware zu dir kommt. In der Zwischenzeit kannst du gerne ein Auge auf unser Lager werfen, um weitere tolle Angebote zu finden. Bitte zögere nicht, uns zu kontaktieren, wenn du Fragen zu deiner Bestellung hast.",
            "subtitle": "Ihre Bestellung wurde erfolgreich abgeschickt",
            "title": "Herzlichen Glückwunsch!"
        },
        "delivery": "Bevorzugte Lieferung",
        "delivery_date": "Liefertermin",
        "details": {
            "actions": {
                "download_delivery_note": "Download Lieferschein",
                "download_invoice": "Rechnung herunterladen",
                "download_order_note": "Download Order Note",
                "download_proforma": "Proforma herunterladen",
                "repeat_order": "Zum Warenkorb",
                "repeat_order_tip": "Kopiert den Inhalt dieser Bestellung in deinen Warenkorb. Dies ist nur für Produkte und Mengen möglich, die derzeit auf Lager sind."
            },
            "additional": {
                "description": "Beschreibung",
                "price_unit": "Preis pro Einheit",
                "quantity": "Menge",
                "type": "Typ"
            },
            "additionals": "Zusätzliche Artikel und Rabatte",
            "amount": "Betrag",
            "booked": "Gebucht",
            "booked_context": "Diese Artikel werden von Ihnen bestätigt und gebucht und somit von uns reserviert.",
            "cart": "Wagen",
            "cases": "Kisten",
            "comment": "Wie",
            "created_by": "Erstellt von",
            "credit": {
                "credited_from": "Anerkannt von",
                "label": "Gutschriften"
            },
            "date": "Date",
            "empty": "Ihre Bestellung hat noch keine Produkte",
            "empty_context": "Legen Sie Produkte aus unserer Lagerübersicht in Ihren Warenkorb.",
            "invoice_date": "Datum der Rechnung",
            "invoice_number": "Rechnungsnummer",
            "items": {
                "arriving": "Bald verfügbar",
                "basket_unconfirmed": "Artikel im Warenkorb (unbestätigt)",
                "basket_unconfirmed_help": "Diese Artikel müssen noch von Ihnen bestätigt werden, bevor wir sie reservieren können. Bestätigen Sie Ihre Bestellung, um fortzufahren.",
                "stock": "Vom Lager",
                "tbo": "Zu bestellen"
            },
            "order_number": "Order Number",
            "order_status": "Order Status",
            "price": "Preis",
            "product": "Produkt",
            "specs": "Spezifikationen",
            "status": "Status",
            "subtotal": "Zwischensumme",
            "total_value": "Gesamtwert",
            "vat": "MEHRWERTSTEUER"
        },
        "empty_call_to_action": "Shop durchsuchen",
        "loading_orders": "Bestellungen laden...",
        "new": "Neu",
        "tos": "Bedingungen und Konditionen"
    },
    "orders": {
        "invoice_date": "In Rechnung gestellt am",
        "invoice_number": "Rechnungsnummer",
        "no_orders": "Sie haben noch keine Aufträge",
        "portal_status": {
            "Cancelled": "Abgesagt",
            "Confirmed": "Bestätigt",
            "Finalized": "Fertiggestellt",
            "Invoiced": "In Rechnung gestellt",
            "New": "Neu",
            "Pending": "Anhängig"
        },
        "status": "Order Status",
        "status_active": "Aktiv",
        "status_open": "Öffnen Sie"
    },
    "page": {
        "browse_stock_tip": "Stöbern im Lager - Übersicht über alle unsere Produkte",
        "dashboard": "Startseite",
        "dashboard_tip": "Startseite - ein Überblick über unsere besten Deals &amp; Angebote",
        "latest_news": "Neueste Nachrichten",
        "my_orders": "Meine Aufträge",
        "my_orders_tip": "Meine Bestellungen - eine Übersicht über alle Ihre Bestellungen",
        "my_portal": "Mein Portal",
        "my_portal_tip": "Mein Portal - Profil, Neuigkeiten und Aktionen",
        "my_portal_tip_promotion": "Mein Portal - Es gibt eine aktive Aktion!",
        "subtitle": {
            "login": "Anmelden um fortzufahren...",
            "privacy": "Wir verkaufen Spirituosen, nicht Ihre Daten!",
            "redeem": "Registrieren Sie sich mit Ihrem individuellen Code...",
            "registration": "Bitte erzählen Sie uns etwas über Ihr Unternehmen...",
            "registration_completed": "Sie können dieses Fenster jetzt schließen..."
        },
        "title": {
            "checkout": "Checkout",
            "checkout-0": "Shop für Produkte",
            "checkout-1": "Warenkorb überprüfen",
            "checkout-2": "Warenkorb abschließen",
            "login": "Willkommen bei {{vendor}}!",
            "login_slug": "Anmeldung",
            "offer": {
                "my_favourites": "Meine Favoriten",
                "new_arrivals": "Neu eingetroffen",
                "personal_offer": "Persönliches Angebot - bis {{date}}",
                "personal_offer_invalid": "Persönliches Angebot abgelaufen",
                "special_offers": "Sonderangebote"
            },
            "order": "Bestellung {{id}}",
            "privacy": "Unsere Datenschutzrichtlinie",
            "redeem": "Schön, Sie zu sehen!",
            "registration": "Anmeldung",
            "registration_completed": "Vielen Dank für Ihre Bewerbung!"
        }
    },
    "product": {
        "alcohol_percentage": "Prozentsatz des Alkohols",
        "best_before": "Am besten vorher",
        "bottle_gtin": "GTIN der Flasche",
        "bottle_volume": "Bottle Volume",
        "bottles": "Flaschen",
        "bottles_per_case": "Flaschen pro Kiste",
        "cases": "Kisten",
        "category": "Kategorie",
        "country_of_origin": "Herkunftsland",
        "customs_status": "Zollrechtlicher Status",
        "features": "Eigenschaften",
        "minimum_order_quantity": "Mindestbestellmenge",
        "refill_status": "Nachfüllbar",
        "stock_origin": "Dieses Produkt stammt aus {{country}}",
        "stock_origin_count_one": "Wir können dir {{count}} {{units}} von dieser Herkunft liefern.",
        "stock_origin_count_other": "Wir können dir {{count}} {{units}} von dieser Herkunft liefern."
    },
    "profile": {
        "company": "Ihr Unternehmen",
        "email_support": "E-Mail-Adresse des Kundendienstes",
        "family_name": "Familienname",
        "first_name": "Vornamen",
        "mobile_phone_number": "Handynummer",
        "new_password": "Wählen Sie ein Passwort",
        "new_password_help": "Das neue Passwort, das Sie festlegen möchten",
        "new_password_placeholder": "Wählen Sie ein Passwort",
        "phone_email": "Telefonnummer oder E-Mail Adresse",
        "phone_number": "Rufnummer",
        "portal_welcome_desc": "Bitte einloggen, um fortzufahren...",
        "portal_welcome_title": "Willkommen auf unserem Portal!",
        "register": "Kostenloses Konto registrieren",
        "removal": {
            "ack": "Ihre Anfrage wurde abgeschickt!",
            "ack_description_1": "Ihre Anfrage wurde abgeschickt. Wir werden uns so bald wie möglich, spätestens jedoch innerhalb von 4 Wochen, bei Ihnen melden.",
            "ack_description_2": "Wir werden Sie vermissen, wenn Sie also versehentlich diese Anfrage gestellt haben, kontaktieren Sie uns bitte unter {{email_vendor}} oder wenden Sie sich an Ihren persönlichen Support-Manager, {{manager}} unter {{email}} oder {{phone}}.",
            "cancel": "Anfrage stornieren",
            "close_window": "Close Dialog",
            "confirm": "Anfrage bestätigen"
        },
        "removal_description_1": "Wenn Sie fortfahren, werden wir so schnell wie möglich, spätestens jedoch innerhalb von 4 Wochen, entscheiden, was mit Ihrem Löschungsantrag geschehen soll. Wir werden Sie über die Maßnahmen informieren, die wir ergreifen werden, um Ihrem Antrag nachzukommen. Sollten wir Ihrem Antrag nicht nachkommen, können Sie eine Beschwerde bei der Behörde für personenbezogene Daten einreichen.",
        "removal_description_2": "Bevor Sie Ihren Löschungsantrag bestätigen, möchten wir Sie fragen, wen wir an Ihrer Stelle für Ihr Unternehmen kontaktieren dürfen. Würden Sie uns einen Namen und eine Telefonnummer oder eine E-Mail-Adresse hinterlassen? Bitte tun Sie dies nur, wenn Sie die Erlaubnis des neuen Ansprechpartners haben. Es ist nicht erforderlich, diese Kontaktdaten zu hinterlassen!",
        "removal_notice": "Bitte lesen Sie dies sorgfältig!",
        "removal_questions": "Für weitere Informationen, Fragen oder Anmerkungen senden Sie uns bitte eine E-Mail an {{email_vendor}} oder wenden Sie sich an Ihren persönlichen Support Manager, {{manager}} unter {{email}} oder {{phone}}.",
        "removal_title": "Wir werden Sie vermissen!",
        "replacement": {
            "contact": "Kontaktinformationen",
            "name": "Kontaktperson"
        },
        "request_password": "Passwort vergessen?",
        "request_removal": "Antrag auf Entfernung",
        "save_button": "Profil aktualisieren"
    },
    "promotions": {
        "active": "Aktiv zwischen: {{date}}",
        "active_grace": "Die Aktion kann bis zum eingelöst werden: {{date}}",
        "call_to_action": "Jetzt bestellen!",
        "confirmed": {
            "description": "Jetzt sind wir an der Reihe; wir sorgen dafür, dass die Ware zu dir kommt. In der Zwischenzeit kannst du gerne ein Auge auf unser Lager werfen, um weitere tolle Angebote zu finden. Bitte zögere nicht, uns zu kontaktieren, wenn du Fragen zu deiner Bestellung hast.",
            "subtitle": "Deine Aktion wurde eingelöst!"
        },
        "description": "Wir haben wieder eine tolle Aktion für dich! Werde ein echter Barista, indem du mit jeder {{token_value}} Ausgabe Kaffeebohnen sparst. Es gibt verschiedene Kaffeemaschinen, die du dir verdienen kannst.",
        "details": "Klicken Sie hier, um Details zur Aktion zu sehen",
        "eligible_multiple": "Eine aktive Bohne steht für {{token_value}} Kundenauftragswert, der von deinem Vertriebsleiter bestätigt oder in Rechnung gestellt wurde. Du hast {{turnover_draft}} an berechtigtem Kundenauftragswert gesammelt, von dem {{turnover_redeemable}} bereits bestätigt oder in Rechnung gestellt wurde.",
        "gifts": {
            "available": "Verfügbare Geschenke",
            "choose": "Ihr Angebot",
            "choose_help": "Sie können sich für ein Angebot entscheiden, nachdem Sie für die Aktion in Frage gekommen sind.",
            "disabled": "Sie können ein Geschenk auswählen",
            "locked": "Schalten Sie Ihr Geschenk frei, indem Sie {{amount}} zu Ihrer Bestellung hinzufügen.",
            "locked_placeholder": "Schließe dein Geschenk zuerst auf...",
            "unlocked": "Herzlichen Glückwunsch! Du hast ein kostenloses Geschenk freigeschaltet!",
            "unlocked_choice_made": "{{choice}} - Eine gute Wahl!",
            "unlocked_choose": "Wählen Sie unten Ihr bevorzugtes Geschenk aus...",
            "unlocked_placeholder": "Wählen Sie Ihr kostenloses Geschenk...",
            "unlocked_redirect": "Herzlichen Glückwunsch! Du hast ein kostenloses Geschenk freigeschaltet! Unter <a href={{link}}>Promotions</a></a> finden Sie weitere Informationen über die verschiedenen Geschenke..."
        },
        "giveaway": "Bestelle {{spent_next}} {{currency}} und verdiene dir eine Kaffeebohne!",
        "giveaway_encourage": "Bestelle {{spent_next}} {{currency}} mehr und erhalte eine Kaffeebohne!",
        "incentive_hurry_one": "Nur noch {{count}} Tag!",
        "incentive_hurry_other": "Nur noch {{count}} Tage!",
        "incentive_progress": "Bestelle {{amount}} mehr für eine Kaffeebohne!",
        "label": {
            "dialog_subtitle": "Wolltest du schon immer ein Barista sein? Jetzt hast du die Chance dazu! Für jede Bestellung auf {{token_value}} erhältst du eine Kaffeebohne und kannst dir eine von drei tollen Kaffeemaschinen aussuchen! Und als Krönung gibt es nicht weniger als 6 kg Bio-Arabica-Kaffeebohnen dazu! Hebe deine Bohnen auf und mache deinen eigenen köstlichen Americano, Latte oder Expresso!",
            "promotion": "Förderung",
            "redeemable_tokens": "Gesammelte Bohnen",
            "show_promotion": "Promotion anzeigen"
        },
        "order_reminder": "Du bist fast bereit, ein Barista zu werden! Sobald du {{token_value}} erreicht hast, kannst du deine Beförderung einlösen.",
        "order_reminder_eligible": "Du bist nur noch einen Schritt davon entfernt, ein Barista zu werden! Wir prüfen deine Bestellung und du wirst innerhalb von 24 Stunden eine Bestätigungs-E-Mail von uns erhalten. Sobald du diese E-Mail erhältst, kannst du deine Kaffeebohnen einlösen. Vielen Dank für deine Geduld!",
        "redeem": "Einlösen",
        "terms": {
            "conditions": "Bedingungen und Konditionen",
            "period": "Aktionszeitraum: {{period}}",
            "terms_0": "Die Aktion gilt für Bestellungen, die über unser Portal aufgegeben werden; wir zählen alle deine abgeschlossenen Bestellungen während dieses Zeitraums.",
            "terms_1": "Sobald du bestellt hast und eine Kaffeebohne erhalten kannst, kannst du über das Portal sofort eine Espressomaschine bestellen, die wir deiner Bestellung hinzufügen.",
            "terms_2": "Die angebotenen Espressomaschinen sind abhängig von der Verfügbarkeit auf Lager. Wenn die ausgewählten Modelle nicht verfügbar sind, wählen wir in Absprache mit dir ein vergleichbares / gleichwertiges Modell aus.",
            "terms_3": "Wir sind nicht verantwortlich für die Informationen, die der Hersteller auf seiner Website bereitstellt. Sie dienen lediglich zu deiner Information.",
            "terms_4": "Der Gesamtbetrag ist der Betrag des bestellten und bezahlten Warenwerts pro Rechnung. Zusätzlich in Rechnung gestellte Kosten werden nicht auf die Aktion angerechnet (Versand / Lkw-Transport / Paletten).",
            "terms_5": "Die Produktgarantie gilt nur in den Niederlanden.",
            "terms_6": "Eine Espressomaschine kann nicht gegen einen Bargeldrabatt eingetauscht werden.",
            "terms_7": "Dieses Angebot wurde mit Sorgfalt für dich zusammengestellt, kann aber Schreibfehler enthalten."
        }
    },
    "registration": {
        "address": "Adresse",
        "address_placeholder": "Adresse des Unternehmens",
        "agree": "Ja, ich bin mit den <a href={{link}} target=\"_blank\">Geschäftsbedingungen</a> einverstanden.</a>",
        "back_login": "Zurück zur Anmeldung",
        "city": "Stadt",
        "city_placeholder": "Unternehmen Stadt",
        "company_details": "Angaben zum Unternehmen",
        "company_name": "Name des Unternehmens",
        "company_name_placeholder": "Ihr Firmenname",
        "consent": "Kommerzielle E-Mails und Angebote von {{vendor}}",
        "country": "Land",
        "country_help": "Das Land, in dem Sie geschäftlich tätig sind",
        "email_address": "Your Email Address",
        "email_address_help": "Die E-Mail-Adresse, unter der wir Sie erreichen können",
        "explanation": "{{vendor}} beliefert Business-to-Business-Kunden in ganz Europa und darüber hinaus. Als geschätzter Kunde von {{vendor}} haben Sie unbegrenzten Zugang zu unserem Verkaufsportal. Genießen Sie die Vorteile des 24/7-Zugriffs auf unser umfangreiches Produktangebot, wann immer Sie wollen. Eine Bestellung aufgeben ist ganz einfach: Durchsuchen, auswählen, anklicken und los geht's!",
        "family_name": "Familienname",
        "family_name_placeholder": "Ihr Familienname",
        "first_name": "Vorname",
        "first_name_placeholder": "Ihr Vorname",
        "import_goods_temp_license": "Ich importiere Waren mit einer befristeten Lizenz",
        "invite": "Einladungen",
        "message": "Fragen?",
        "message_placeholder": "Haben Sie irgendwelche Fragen an uns? Stellen Sie sie ruhig...",
        "name": "Your Name",
        "position": "Die Rolle Ihres Unternehmens",
        "position_placeholder": "Die Rolle Ihres Unternehmens",
        "postal_code": "Postleitzahl",
        "postal_code_placeholder": "Postleitzahl des Unternehmens",
        "redeem": {
            "code": "Code-Einladung",
            "code_help": "Ihr einmaliger Code für den Zugang zu unserem Portal",
            "partnership": {
                "footer": "Mit Liebe für unseren Planeten",
                "header": "{{vendor}} ist ein stolzer Partner von {{partner}}."
            },
            "step0_desc": "Bist du genauso begeistert von der Spirituosenbranche wie wir? Logge dich mit deinem persönlichen Code ein, um dein Geschenk zu erhalten und Zugang zu unserem erweiterten, vielfältigen und preisgünstigen Sortiment zu bekommen.",
            "step0_slug": "Gemeinsam machen wir die Welt zu einem besseren Ort",
            "step1_desc": "Geben Sie die E-Mail-Adresse ein, die Sie verwenden möchten, wählen Sie ein Passwort und los geht's!",
            "step1_slug": "Über Sie",
            "step2_desc": "Sind die folgenden Angaben korrekt? Dann klicken Sie auf die Schaltfläche \"Registrieren &amp; Anmelden\" und Sie befinden sich automatisch im Portal.",
            "step2_slug": "Überprüfung der Registrierung",
            "step3_slug": "Register &amp; Login"
        },
        "review_request": "Bitte überprüfen Sie alle von Ihnen angegebenen Informationen. Wenn alles korrekt ist, können Sie Ihre Anmeldung abschicken und wir werden uns in Kürze bei Ihnen melden.",
        "signup": "Kunde werden",
        "step0_desc": "{{vendor}} liefert an Business-to-Business-Kunden. Für welches Unternehmen möchten Sie ein Konto beantragen?",
        "step0_slug": "Ihr Unternehmen",
        "step1_desc": "Bitte geben Sie die Adresse Ihres Unternehmens an.",
        "step1_slug": "Adresse des Unternehmens",
        "step2_desc": "Wie können wir Sie kontaktieren?",
        "step2_slug": "Kontakt Details",
        "step3_desc": "Nutzen Sie die Einrichtungen eines Zolllagers in der EU?",
        "step3_desc_vat": "Bitte geben Sie Ihre Umsatzsteuer-Identifikationsnummer und die gewünschte Lagerhaltung an.",
        "step3_slug": "Logistische Details",
        "step4_desc": "Bitte überprüfen Sie die Informationen, die Sie uns für Ihre Registrierungsanfrage übermitteln werden. Falls Sie Fragen an uns haben, können Sie das unten stehende Fragefeld nutzen.",
        "step4_slug": "Überprüfung der Registrierung",
        "step5_desc": "Wir werden die von Ihnen angegebenen Unternehmensdaten überprüfen. Dieser Vorgang kann einige Tage in Anspruch nehmen. Sollten wir zusätzliche Informationen benötigen, werden wir Sie persönlich kontaktieren. Nach der Bestätigung erhalten Sie eine E-Mail mit einer Bestätigung Ihrer Anmeldung.",
        "step5_footer": "Wir freuen uns darauf, bald Ihre erste Online-Bestellung zu erhalten!",
        "step5_incentive": "Wussten Sie, dass wir unseren eigenen <a target=\"_blank\" href=\"https://www.youtube.com/@movingspirits474/videos\">Youtube-Kanal</a> haben?",
        "step5_notify": "Vielen Dank für Ihre Bewerbung! Wir werden Ihre Bewerbung innerhalb weniger Arbeitstage bearbeiten.",
        "step5_slug": "Antrag einreichen",
        "step5_title": "Wie geht es weiter?",
        "submit": "Registrierung einreichen",
        "telephone": "Ihre Telefonnummer",
        "telephone_placeholder": "+31 502110909",
        "terms_conditions": "Allgemeine Bedingungen und Konditionen",
        "vat_number": "Umsatzsteueridentifikationsnummer",
        "vat_number_help": "In EU-Ländern ist eine Mehrwertsteuernummer erforderlich",
        "vat_number_placeholder": "Umsatzsteueridentifikationsnummer",
        "warehouse": {
            "address": "Adresse des Lagers",
            "address_help": "Adresse Ihres Lagers",
            "address_unloading": "Entladen Adresse",
            "address_unloading_help": "Der Ort, an dem wir Ihre Bestellung abladen können",
            "bonded_eu": "Zollfreilager in der EU?",
            "city": "Lagerhaus Stadt",
            "city_unloading": "Entladen Stadt",
            "excise_id": "Lagerhaus Verbrauchsteuer-ID",
            "location_id": "Lagerort-ID",
            "name": "Name des Lagers",
            "name_unloading": "Entladen",
            "options": {
                "bonded": "Ich habe mein eigenes Zolllager",
                "inc_vat": "Ich kaufe inklusive Mehrwertsteuer und Verbrauchssteuer aus den Niederlanden",
                "other": "Andere",
                "temp_license": "Ich importiere Waren mit einer befristeten Lizenz",
                "third_party": "Ich benutze einen externen Lagerhauspartner"
            },
            "postal_code": "Postleitzahl des Lagers",
            "postal_code_unloading": "Entladen Postleitzahl",
            "select": "Lagerhaus auswählen",
            "shipping": {
                "free_shipping": "Gut gemacht! Wir liefern Ihre Bestellung versandkostenfrei.",
                "incentive": "Fügen Sie <b>{{amount}}</b> zu Ihrer Bestellung hinzu, um eine kostenlose Lieferung zu erhalten. Für Bestellungen unter <b>{{threshold}}</b>werden die zusätzlichen Transportkosten auf Ihrer Proforma-Rechnung ausgewiesen, die Sie bitte bestätigen."
            },
            "warehousing": "Lagerhaltung"
        },
        "website": "Website des Unternehmens",
        "website_placeholder": "ihr-unternehmen.de"
    },
    "search": {
        "categories": {
            "category": "Kategorie",
            "search": "Produktname"
        },
        "exact": "Suche nach Produktname oder GTIN mit dem Begriff \"{{term}}\".",
        "placeholder": {
            "general": "Gesamten Shop durchsuchen",
            "raw": "Suche nach Produktname oder GTIN",
            "raw_term": "Suche nach Produktname oder GTIN mit dem Begriff \"{{term}}\".",
            "suggestion": "Suche über {{type}} \"{{value}}\""
        },
        "suggestions": {
            "activate": "<Ctrl><Enter> für die genaue Begriffssuche"
        }
    },
    "select": "Wählen Sie",
    "spotlight": {
        "countdown": {
            "still_unsold": "Noch unverkauft",
            "valid_for": "Das Angebot ist noch gültig:"
        }
    },
    "status": {
        "error": {
            "redeem": {
                "invalid": "Schade, dieser Code ist nicht korrekt; versuchen Sie es erneut.",
                "length": "Der Einlösecode besteht aus 6 Zeichen",
                "unknown": "Bei der Überprüfung Ihres Einlösecodes ist ein Fehler aufgetreten; bitte versuchen Sie es später noch einmal.",
                "used": "Dieser Einladungscode wurde bereits zur Erstellung eines Kontos verwendet."
            }
        }
    },
    "view": {
        "grid": "Raster",
        "grid_switch": "Zur Gitteransicht wechseln",
        "list": "Liste",
        "list_switch": "Zur Listenansicht wechseln"
    },
    "yes": "Ja"
}
