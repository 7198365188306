/**
 * See
 * - https://rawgit.com/MrRio/jsPDF/master/docs/jsPDF.html
 * - https://github.com/simonbengtsson/jsPDF-AutoTable auto table.
 */
import {jsPDF} from 'jspdf'
// @ts-ignore
import {countries} from '@bitstillery/common/lib/countries'
import autoTable from 'jspdf-autotable'
import {CellDef, CellHookData, Styles, Table, UserOptions} from 'jspdf-autotable/dist'

import {Account} from '../models/account'
import {
    account_pdf_logo_src,
    account_primary_color_rgb_list,
    account_secondary_color_rgb,
} from '../account/account'
import {displayable_float} from '../ts_utils'

export enum CasesOrBottles {
    cases = 'cases',
    bottles = 'bottles',
}

interface Coords {
    x: number
    y: number
}

interface Dimension {
    height: number
    width: number
}

interface Margin {
    top: number
    bottom: number
    left: number
    right: number
}

interface FontInfo {
    name: string
    style: string
    size: number
}

interface DimensionWithCoords extends Coords, Dimension {}

export interface CurrencyAndAmountCellDef extends CellDef {
    custom_content: {
        currency: string
        amount: number
        id: string
    }
}

function currency_and_amount_cell_renderer(document: jsPDF, decimal_locale: string, data: CellHookData): boolean {
    const custom_content = (data.cell?.raw as CurrencyAndAmountCellDef)?.custom_content
    if (custom_content && custom_content.id === 'currency_and_amount') {
        const currency = custom_content.currency
        const amount = custom_content.amount
        const halfway = (data.cell.height + data.cell.styles.fontSize - +data.cell.styles.cellPadding / 2) / 2
        const has_grid = data.settings.theme === 'grid'
        if (has_grid) {
            document.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'S')
        }
        const start_text_x = data.cell.x + +data.cell.styles.cellPadding + (has_grid ? 1 : 0)
        const end_text_x = data.cell.x + data.cell.width - +data.cell.styles.cellPadding - (has_grid ? 1 : 0)
        document.text(currency, start_text_x, data.cell.y + halfway)
        document.text(displayable_float(amount, 2, decimal_locale), end_text_x, data.cell.y + halfway, {
            align: 'right',
        })

        return false
    }
    return true
}

export class PDF {
    private static page_dimension: Dimension = {
        height: 880,
        width: 600,
    }

    /* page margins */
    private static page_margin: Margin = {
        left: 25,
        right: 25,
        top: 30,
        bottom: 10,
    }

    private static details_margin: Margin = {
        left: 0,
        right: 0,
        top: 38,
        bottom: 0,
    }

    /* vertical layout: header, details, body */
    private static header_height = 145
    private static details_height = 135

    private static footer: DimensionWithCoords = {
        x: PDF.page_margin.left,
        y: PDF.page_dimension.height - 110 - PDF.page_margin.bottom,
        width: PDF.page_dimension.width - PDF.page_margin.left - PDF.page_margin.right,
        height: 110,
    }

    private static sub_footer: DimensionWithCoords = {
        x: PDF.page_margin.left,
        y: PDF.page_dimension.height - 40 - PDF.page_margin.bottom,
        width: PDF.page_dimension.width - PDF.page_margin.left - PDF.page_margin.right,
        height: 20,
    }

    /* fonts */
    private static line_spacing = 12.6
    private static font_size_small = 6
    private static font_size_normal = 8

    private static table_margin: Margin = {
        top: 9,
        left: PDF.page_margin.left,
        right: PDF.page_margin.right,
        bottom: PDF.page_margin.bottom + PDF.footer.height + PDF.line_spacing * 6,
    }

    private static payment_terms: DimensionWithCoords = {
        x: PDF.page_margin.left + 10,
        y: PDF.page_dimension.height - PDF.page_margin.bottom - PDF.footer.height - PDF.line_spacing,
        height: PDF.line_spacing,
        width: PDF.page_dimension.width - PDF.page_margin.left - PDF.page_margin.right - 10,
    }

    /* logo */
    private static logo: DimensionWithCoords = {
        x: PDF.page_margin.left - 2,
        y: PDF.page_margin.top,
        height: 90,
        width: 90,
    }

    /* details blue square */
    private static details_square: DimensionWithCoords = {
        x: 0,
        y: PDF.header_height,
        height: PDF.details_height,
        width: PDF.page_dimension.width,
    }

    /* Our address */
    private static our_address: Coords = {
        x: PDF.page_dimension.width - PDF.page_margin.right,
        y: PDF.page_margin.top,
    }

    /* Legal entity/bank change notice */
    private static bank_change_square: DimensionWithCoords = {
        x: 250,
        y: 0,
        height: 100,
        width: 200,
    }

    private static title_font: FontInfo = {
        name: 'Helvetica',
        style: 'bold',
        size: 12,
    }

    private static default_header_font: FontInfo = {
        name: 'Helvetica',
        style: 'bold',
        size: PDF.font_size_normal,
    }

    private static footer_header_font: FontInfo = {
        name: 'Helvetica',
        style: 'bold',
        size: PDF.font_size_small,
    }

    private static footer_font: FontInfo = {
        name: 'Helvetica',
        style: 'normal',
        size: PDF.font_size_small,
    }

    private static default_font: FontInfo = {
        name: 'Helvetica',
        style: 'normal',
        size: PDF.font_size_normal,
    }

    private static column_one: DimensionWithCoords = {
        x: PDF.page_margin.left,
        y: PDF.header_height + PDF.details_margin.top,
        width: 185,
        height: 300,
    }

    private static column_two: DimensionWithCoords = {
        x: 220,
        y: PDF.header_height + PDF.details_margin.top,
        width: 185,
        height: 300,
    }

    private static column_three: DimensionWithCoords = {
        x: 415,
        y: PDF.header_height + PDF.details_margin.top,
        width: 90,
        height: 300,
    }

    private static column_four: DimensionWithCoords = {
        x: 515,
        y: PDF.header_height + PDF.details_margin.top,
        width: 60,
        height: 300,
    }

    private static title_location: Coords = {
        x: PDF.page_margin.left,
        y: PDF.header_height + PDF.details_margin.top - 18,
    }

    private static incoterm_location: Coords = {
        x: PDF.page_margin.left,
        y: PDF.details_height + PDF.header_height - PDF.line_spacing,
    }

    private static liters_alcohol_location: Coords = {
        x: PDF.column_three.x,
        y: PDF.details_height + PDF.header_height - PDF.line_spacing,
    }

    document: jsPDF

    constructor(account: Account) {
        this.document = this.generic_header_for_account(account)
    }

    font(font: FontInfo): void {
        this.document.setFontSize(font.size)
        this.document.setFont(font.name, font.style)
    }

    title(title: string): void {
        this.font(PDF.title_font)
        this.document.text(title, PDF.title_location.x, PDF.title_location.y)
    }

    relation_address(address: string[]): void {
        this.font(PDF.default_header_font)
        this.document.text('Relation:', PDF.column_one.x, PDF.column_one.y)
        this.font(PDF.default_font)
        this.document.text(address, PDF.column_one.x, PDF.column_one.y + PDF.line_spacing, {
            maxWidth: PDF.column_one.width,
        })
    }

    incoterm(incoterm: string): void {
        this.font(PDF.default_header_font)
        this.document.text('Incoterm 2022:', PDF.incoterm_location.x, PDF.incoterm_location.y)
        this.font(PDF.default_font)
        this.document.text(incoterm, PDF.incoterm_location.x + 60, PDF.incoterm_location.y)
    }

    destination_address(address: string[]): void {
        this.font(PDF.default_header_font)
        this.document.text('Destination:', PDF.column_two.x, PDF.column_two.y)
        this.font(PDF.default_font)
        this.document.text(address, PDF.column_two.x, PDF.column_two.y + PDF.line_spacing, {
            maxWidth: PDF.column_two.width,
        })
    }

    details(details_title: string, labels: string[], values: string[]): void {
        this.font(PDF.default_header_font)
        this.document.text(details_title, PDF.column_three.x, PDF.column_three.y)

        this.font(PDF.default_font)
        this.document.text(labels, PDF.column_three.x, PDF.column_three.y + PDF.line_spacing, {
            maxWidth: PDF.column_three.width,
        })

        this.font(PDF.default_font)
        this.document.text(values, PDF.column_four.x, PDF.column_three.y + PDF.line_spacing, {
            maxWidth: PDF.column_four.width,
        })
    }

    liters_of_alcohol(amount: string): void {
        const header = 'Total liters of alcohol 100%: '
        this.font(PDF.default_header_font)
        this.document.text(header, PDF.liters_alcohol_location.x, PDF.liters_alcohol_location.y)
        const width = this.document.getTextWidth(header)
        this.font(PDF.default_font)
        this.document.text(amount, PDF.liters_alcohol_location.x + width, PDF.liters_alcohol_location.y)
    }

    arrival_notice_footer(account) {
        const number_of_pages = this.document.getNumberOfPages()

        for (let page = 1; page <= number_of_pages; page++) {
            this.document.setPage(page)
            this.set_footer_terms_and_conditions('', account)
        }
    }

    purchase_footer(account: Account): void {
        const rfp_text = `${account.name} respects trademark rights and expects the same from its suppliers
and therefore the following conditions apply. Seller warrants and represents that the goods sold to ${account.name}
have been brought onto the market in the European Economic Area (EEA) by or with the consent of the trademark
owner (and that trademark rights are therefore exhausted) and that the goods are authentic and have not been decoded
or otherwise tampered with. Upon first request, Seller will assist ${account.name} in proving that the supplied
goods do not infringe any rights of third parties and that trademarks are exhausted by supplying all relevant
information relating to suppliers and their suppliers Dutch law is applicable with the exclusion of the CISG.
Any disputes that may arise between Seller and ${account.name} shall be submitted to the competent court
in The Hague, The Netherlands.` .replaceAll('\r\n', ' ')
            .replaceAll('\n', ' ')

        const number_of_pages = this.document.getNumberOfPages()
        for (let page = 1; page <= number_of_pages; page++) {
            this.document.setPage(page)
            this.set_footer_terms_and_conditions(rfp_text, account)
        }
    }

    sales_footer(account: Account, has_t1_items: boolean): void {
        const t2_sales =
            `The buyer is fully responsible to verify whether the rights of the trademark proprietor are exhausted
in the market where the buyer intends to sell the goods. The buyer is solely responsible for any infringement and
indemnifies the seller for all liabilities in this respect. The terms and conditions of buyer are hereby expressly
rejected. Dutch law is applicable with exclusion of the CISG. The competent court is the district court The Hague.`
                .replaceAll('\r\n', ' ')
                .replaceAll('\n', ' ')

        const t1_sales = `If ${account.name} (or any third party instructed by ${account.name}) issues a
T1-document or an (electronic) Administrative Accompanying Document for the transport of the goods, buyer acknowledges
liability for all and any loss and (legal) expenses incurred by ${account.name} (or the aforementioned
third party) with regard to the document(s) issued including but not limited to any and all levies and duties imposed
by any customs authority.`
            .replaceAll('\r\n', ' ')
            .replaceAll('\n', ' ')

        const sales_terms = has_t1_items ? `${t1_sales} ${t2_sales}` : t2_sales
        this.set_footer_terms_and_conditions(sales_terms, account)
    }

    currency_and_amount_cell(
        currency: string,
        amount: number,
        partial_styles: Partial<Styles> = {},
    ): CurrencyAndAmountCellDef {
        const styles = {
            ...partial_styles,
            cellWidth: this.document.getTextWidth(`${currency}00000${displayable_float(amount, 2)}`) + 11,
        }
        return {
            // calculate the width with some extra padding.
            styles: styles,
            custom_content: {
                currency: currency,
                amount: amount,
                id: 'currency_and_amount',
            },
        }
    }

    text_cell(text: string, partial_styles: Partial<Styles> = {}): CellDef {
        // if there are too many lines in a cell, there is not enough room. So set minCellHeight.
        const number_of_lines = text.match(/\n/g)?.length || 0
        const set_styles = partial_styles
        if (number_of_lines > 1) {
            // needed is number of lines * font-size and the padding
            set_styles.minCellHeight = number_of_lines * (PDF.default_font.size + PDF.line_spacing) + 8
        }
        return {
            content: text,
            styles: set_styles,
        }
    }

    auto_table(account: Account, decimal_locale: string, header: string[], items: CellDef[][]): Table {
        const rgb = account_primary_color_rgb_list(account.slug)
        const header_color: [number, number, number] = [rgb.red, rgb.green, rgb.blue]

        autoTable(this.document, {
            head: [header],
            body: items,
            tableWidth: 'auto',
            theme: 'grid',
            styles: {
                cellPadding: 5,
                fontSize: PDF.font_size_normal,
                valign: 'middle',
                overflow: 'linebreak',
            },
            rowPageBreak: 'avoid',
            headStyles: {
                fillColor: header_color,
            },
            bodyStyles: {
                textColor: 0,
            },
            startY: PDF.header_height + PDF.details_height + PDF.table_margin.top,
            margin: {
                left: PDF.table_margin.left,
                right: PDF.table_margin.right,
                bottom: PDF.table_margin.bottom,
            },
            willDrawCell: (data) => currency_and_amount_cell_renderer(this.document, decimal_locale, data),
        })

        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return this.document.lastAutoTable as Table
    }

    /**
     * Append a total quantity to the table. The total quantity is an auto table of one cell, usually
     * displayed under the first column.
     *
     * @param total The data to display in the table.
     * @param append_to_table Append this total auto table to that table.
     * @param align_under_colum Align the table under this column (0 based, just as in real dev life).
     */
    total_quantity(total: CellDef, append_to_table: Table, align_under_colum = 0): Table {
        const current_page_number = this.document.getCurrentPageInfo().pageNumber
        this.document.setPage(append_to_table.pageNumber)

        // calculate the widths of the first columns, except last two.
        const offset_x =
            append_to_table.columns.reduce((previous, current, index) => {
                return index < align_under_colum ? current.width + previous : previous
            }, 0) + PDF.page_margin.left
        autoTable(this.document, {
            showHead: false,
            startY: append_to_table.finalY,
            tableWidth: append_to_table.columns[align_under_colum].width,
            theme: 'grid',
            body: [[total]],
            styles: {
                cellPadding: 5,
                cellWidth: append_to_table.columns[align_under_colum].width,
                fontSize: PDF.font_size_normal,
                valign: 'middle',
                fontStyle: 'bold',
                textColor: 0,
            },
            margin: {
                left: offset_x,
            },
        })
        this.document.setPage(current_page_number)

        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return this.document.lastAutoTable as Table
    }

    total_amount(append_to_table: Table, decimal_locale: string, cells: CellDef[][]): Table {
        // calculate the widths of the first columns, except last two.
        const offset_x = append_to_table.columns.reduce((previous, current, index, array) => {
            return index < array.length - 2 ? current.width + previous : previous
        }, 0)

        for (const row of cells) {
            row[0].styles = {
                cellWidth: append_to_table.columns[append_to_table.columns.length - 2].width,
                fontStyle: 'bold',
            }
            row[1].styles = {
                cellWidth: append_to_table.columns[append_to_table.columns.length - 1].width,
                fontStyle: 'normal',
            }
        }

        const current_page_number = this.document.getCurrentPageInfo().pageNumber
        this.document.setPage(append_to_table.pageNumber)

        autoTable(this.document, {
            showHead: false,
            startY: append_to_table.finalY,
            tableWidth:
                append_to_table.columns[append_to_table.columns.length - 2].width +
                append_to_table.columns[append_to_table.columns.length - 1].width,
            theme: 'grid',
            body: cells,
            styles: {
                fontSize: PDF.font_size_normal,
                textColor: 0,
                valign: 'middle',
            },
            margin: {
                left: offset_x + PDF.page_margin.left,
                bottom: 0,
            },
            willDrawCell: (data) => currency_and_amount_cell_renderer(this.document, decimal_locale, data),
        })
        this.document.setPage(current_page_number)

        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return this.document.lastAutoTable as Table
    }

    vat_table(after_table: Table, decimal_locale: string, cells: CellDef[][]): Table {
        const start_y = (after_table.finalY || 0) + PDF.line_spacing
        autoTable(this.document, {
            startY: start_y,
            tableWidth: 220,
            theme: 'plain',
            head: [['VAT %', 'Base amount', 'VAT']],
            body: cells,
            styles: {
                cellPadding: 1,
                fontSize: PDF.font_size_normal,
                valign: 'middle',
                overflow: 'linebreak',
            },
            rowPageBreak: 'avoid',
            margin: {
                left: PDF.page_margin.left,
            },
            willDrawCell: (data) => currency_and_amount_cell_renderer(this.document, decimal_locale, data),
        })
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return this.document.lastAutoTable as Table
    }

    additional_string_table(after_table: Table, headers: string[], lines: string[]): Table {
        const show_headers = headers.length > 0
        const start_y = (after_table.finalY || 0) + PDF.line_spacing
        const table_props: UserOptions = {
            body: lines.map((term) => [term]),
            tableWidth: PDF.page_dimension.width - PDF.page_margin.left - 220,
            theme: 'plain',
            styles: {
                cellPadding: 3,
                fontSize: PDF.font_size_normal,
                valign: 'middle',
                overflow: 'linebreak',
            },
            rowPageBreak: 'avoid',
            headStyles: {
                fillColor: 255,
            },
            startY: start_y,
            margin: {
                left: PDF.page_margin.left,
                bottom: PDF.table_margin.bottom,
            },
        }
        if (show_headers) {
            table_props.head = [headers]
        } else {
            table_props.showHead = 'never'
        }

        autoTable(this.document, table_props)
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return this.document.lastAutoTable as Table
    }

    payment_terms(iban: string, include_t1: boolean, payment_term_description = 'Release after payment'): void {

        // line out the bank account
        this.font(PDF.default_font)
        const space_left = PDF.payment_terms.width - 70
        let payment = `${payment_term_description} | ABN AMRO Bank | ${iban} | ABNANL2A`
        while (this.document.getTextWidth(payment) + PDF.page_margin.right < space_left) {
            payment = payment.replaceAll(' | ', '  |  ')
        }
        let page_counter = 1
        while (page_counter <= this.document.getNumberOfPages()) {
            this.document.setPage(page_counter)
            // Payment terms with header font
            this.font(PDF.default_header_font)
            this.document.text('Payment terms:', PDF.payment_terms.x, PDF.payment_terms.y)
            this.font(PDF.default_font)
            this.document.text(payment, PDF.payment_terms.x + 70, PDF.payment_terms.y)
            page_counter += 1
        }
    }

    payment_warning(account, iban) {
        this.document.setFillColor(190, 230, 115)
        this.document.setTextColor(59, 72, 36)

        const center_x = (PDF.page_dimension.width - PDF.bank_change_square.width) / 2

        this.document.rect(
            center_x,
            PDF.bank_change_square.y,
            PDF.bank_change_square.width,
            PDF.bank_change_square.height,
            'F',
        )

        this.document.text('Please Notice!', center_x + 8, 20, {
            align: 'left',
        })
        this.font(PDF.default_font)
        this.document.text(`Our bank details recently changed.\nPlease amend these details in your system to:\n\n${account.name}\n${iban}`, center_x + 8, 40, {
            align: 'left',
        })
        // Restore text color; it might not be black though.
        this.document.setTextColor(0, 0, 0)
    }

    /** Generates the default pdf layout for documents (blue header, from / to addresses) */
    private generic_header_for_account(account: Account): jsPDF {
        this.document = new jsPDF('portrait', 'pt')

        const account_slug = account.slug
        const color_rgb = account_secondary_color_rgb(account_slug)
        this.document.setDrawColor(0)
        this.document.setFillColor(color_rgb.red, color_rgb.green, color_rgb.blue)
        this.document.rect(
            PDF.details_square.x,
            PDF.details_square.y,
            PDF.details_square.width,
            PDF.details_square.height,
            'F',
        )

        this.document.addImage({
            imageData: account_pdf_logo_src(account_slug),
            width: PDF.logo.width,
            height: PDF.logo.height,
            x: PDF.logo.x,
            y: PDF.logo.y,
        })

        this.document.setLineHeightFactor(1.51)
        this.font(PDF.default_header_font)

        const x = PDF.our_address.x
        const y = PDF.our_address.y

        this.document.text(account.name, x, y, {
            align: 'right',
        })

        this.font(PDF.default_font)

        const our_address = [
            account.address,
            `${account.zip_code} ${account.city}`,
            countries[account.country_code.toUpperCase()],
            '',
            account.telephone_number,
            account.emailaddress,
            '',
            `VAT ${account.vat_id}`,
            `CoC ${account.chamber_of_commerce_number}`,
        ]
        this.document.text(our_address, x, y + PDF.line_spacing, {
            align: 'right',
        })

        return this.document
    }

    private set_footer_terms_and_conditions(terms_and_conditions: string, account: Account): void {

        const current_page = this.document.getCurrentPageInfo().pageNumber
        let page_counter = 1

        while (page_counter <= this.document.getNumberOfPages()) {
            this.document.setPage(page_counter)
            page_counter += 1
            if (terms_and_conditions) {
                this.font(PDF.footer_header_font)
                this.document.text('Terms and conditions', PDF.footer.x, PDF.footer.y)
                this.font(PDF.footer_font)
                this.document.text(terms_and_conditions, PDF.footer.x, PDF.footer.y + PDF.line_spacing, {
                    maxWidth: PDF.footer.width,
                })
            } else {
                this.font(PDF.footer_font)
            }

            const tradename_text = `${account.informal_name} is the trade name of ${account.name}`
            const text_width = this.document.getTextWidth(tradename_text)
            const center_x = (PDF.page_dimension.width - text_width) / 2
            this.document.text(tradename_text, center_x, PDF.sub_footer.y)
        }
        this.document.setPage(current_page)
    }
}
