import m from 'mithril'
import {Button, Link, Spinner} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {get_route} from '@bitstillery/common/lib/utils'
import {format_date} from '@bitstillery/common/lib/format'
import {proxy} from '@bitstillery/common/lib/proxy'
import {$t, api} from '@bitstillery/common/app'

import {DashboardWidget} from './widget'

import {$m, $s} from '@/app'

export class LatestOrdersWidget extends MithrilTsxComponent<any> {

    data = proxy({
        latest_orders: [],
    })

    loading = true

    async oncreate() {
        await this.get_latest_orders()
    }

    async get_latest_orders() {
        const data = {
            ascending: false,
            limit: 3,
            sort_by: 'date',
            sort_order: [{
                direction: 'desc',
                name: 'reference',
            }],
            status: [$m.order.OrderStatus.PENDING, $m.order.OrderStatus.FINALIZED],
        }
        const {result, status_code} = await api.post('order.list_collection', data)
        if (status_code > 299) {
            return
        }

        this.data.latest_orders.splice(0, this.data.latest_orders.lenght, ...result)
        this.loading = false
    }

    view() {
        return <DashboardWidget
            icon="myOrders"
            name="latest-orders"
            title={$t('offer.latest_orders')}
            footer={(this.data.latest_orders.length && !this.loading) && <Link href='/orders'>Show all</Link>}
        >
            {!this.data.latest_orders.length ? (
                <div className="c-empty-message">
                    {(() => {
                        if (this.loading) {
                            return <div className="widget-loading placeholder-s">
                                <Spinner/>
                            </div>
                        } else {
                            return <span>{$t('offer.latest_orders_empty')}</span>
                        }
                    })()}
                </div>
            ) : (
                <div className="widget-list">
                    {this.data.latest_orders.map((order:any) => {
                        return (
                            <div className="widget-item" key={order.artkey} onclick={() => m.route.set(`/orders/${order.artkey}`)}>
                                <div className="info fl-1">
                                    <div className="bold">{order.reference}</div>
                                    <div className="text-light">{format_date(order.created_on)}</div>
                                </div>

                                <div className="actions">
                                    {(order.portal_status === $m.order.OrderStatus.FINALIZED && order.is_complete) && (
                                        <span className="stock-status">
                                            <Button
                                                className='active'
                                                icon='checked'
                                                tip={$t('dashboard.widgets.latest_orders.stock_complete')}
                                                type='success'
                                                variant='icon'
                                            />
                                        </span>
                                    )}

                                    {(() => {
                                        if (order.portal_status === $m.order.OrderStatus.PENDING) {
                                            const is_current = ($s.cart.artkey === order.artkey)
                                            if (is_current) {
                                                return <div>{$t('dashboard.widgets.latest_orders.active_order')}</div>
                                            } else {
                                                return (
                                                    <Button
                                                        icon='cartRight'
                                                        onclick={(e) => {
                                                            e.stopPropagation()
                                                            m.route.set(get_route('/offers'))
                                                        }}
                                                        tip={$t('dashboard.widgets.latest_orders.activate_order_tip')}
                                                        type='default'
                                                    />
                                                )
                                            }
                                        } else {
                                            return (<div>{order.portal_status}</div>)
                                        }
                                    })()}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
        </DashboardWidget>
    }
}
