import {MithrilTsxComponent} from 'mithril-tsx-component'
import {place_filters} from '@bitstillery/common/lib/filters'
import m from 'mithril'
import {
    CollectionFilterControl,
    Icon,
    FiltersActive,
} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {$s, $t} from '@bitstillery/common/app'

interface PanelFiltersAttrs {
    collection: any
}

export class PanelFilters extends MithrilTsxComponent<PanelFiltersAttrs> {

    view(vnode:m.Vnode<PanelFiltersAttrs>) {
        return <div className={classes('c-panel-filters float-panel', {
            'float-panel--active': ['mobile', 'tablet'].includes($s.env.layout),
            collapsed: $s.panels.view.filters.collapsed,
        })}>
            {vnode.attrs.collection.state.ready && [
                <header onclick={() => {
                    $s.panels.view.filters.collapsed = !$s.panels.view.filters.collapsed
                }}>
                    <div className="title">
                        <Icon className="icon-d" name="filter"/>
                        <span>{$t('filters.panel_title')}</span>
                    </div>
                </header>,

                $s.env.layout !== 'desktop' ? <CollectionFilterControl collection={vnode.attrs.collection} /> : null,
                <FiltersActive collection={vnode.attrs.collection} />,
                <div className="scroll-container">
                    {vnode.children}
                    {place_filters(vnode.attrs.collection.filters)}
                </div>,
            ]}
        </div>
    }
}
