import {Amount, Icon, Link} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'

interface CellAvailabilityAttrs {
    row: {
        offer_item_type?: 'tbo' | 'stock' | 'purchase'
        delivery_period?: number
        supplier_name: string
        maximum_quantity?: number
        minimum_quantity?: number

        number_of_cases_in_stock: number
        number_of_cases_available: number
        number_of_cases_in_purchase: number
        number_of_cases_in_sales: number

        stock_items: {
            reference: string
            lot: string
            stock_age_in_days: number
            was_bought_for_plus_costs: number
            number_of_cases_available: number
        }[]
    }
}

export class CellAvailability extends MithrilTsxComponent<CellAvailabilityAttrs> {
    view(vnode:m.Vnode<CellAvailabilityAttrs>) {
        const row = vnode.attrs.row
        return <div className="c-cell-availability td-group">
            {(() => {
                if (row.offer_item_type === 'tbo') {
                    return [
                        <span className="header">
                            {(() => {
                                return row.delivery_period ? `TBO - ${row.delivery_period} weeks` : 'TBO'
                            })()}
                        </span>,
                        row.supplier_name ? <span className="details">{row.supplier_name}</span> : null,
                    ]
                }

                return <div className="stock-info">
                    <div className="stat">
                        {(row.maximum_quantity && row.maximum_quantity < row.number_of_cases_in_stock) ? <div className="stat">
                            <Icon
                                name="stock"
                                size="xs"
                                tip={() => {
                                    return `Maximum Order Quantity: ${row.maximum_quantity}, Stock: ${row.number_of_cases_in_stock}`
                                }}
                                type="warning"
                            />
                            {`${row.maximum_quantity}(${row.number_of_cases_in_stock})`}
                        </div> : <div className="stat">
                            <Icon
                                name="stock"
                                size="xs"
                                tip={() => `Available: ${row.number_of_cases_available ? row.number_of_cases_available : row.number_of_cases_in_stock}`}
                            />
                            {row.number_of_cases_in_stock}
                        </div>}
                    </div>

                    {row.minimum_quantity && <div className="stat">
                        <Icon
                            name="minOrderQuantity"
                            size="xs"
                            tip={() => `Minimum Order Quantity: ${row.minimum_quantity}`}
                            type="warning"
                        />
                        {row.minimum_quantity}
                    </div>}
                    <div className="stat">
                        <Icon
                            name="purchase"
                            size="xs"
                            tip={() => `Already In Purchase: ${row.number_of_cases_in_purchase}`}
                        />
                        {row.number_of_cases_in_purchase}
                    </div>
                    <div className="stat">
                        <Icon
                            name="sales"
                            size="xs"
                            tip={() => `Already in Sales Orders: ${row.number_of_cases_in_sales}`}
                        />
                        {row.number_of_cases_in_sales}
                    </div>
                    <div className="stat">
                        <Icon
                            name="shieldHalfFull"
                            size="xs"
                            tip={() => `Available: ${row.number_of_cases_available}`}
                        />
                        {row.number_of_cases_available}
                    </div>
                </div>
            })()}

            {row.stock_items && row.stock_items.map((stock_item) => {
                return <span className={'details'}>
                    <Link href={`/stock/manage/${stock_item.reference}`}>
                        <span>
                            {stock_item.lot}
                            {' '}
                            {stock_item.stock_age_in_days} days old
                            {' '}
                            <Amount amount={stock_item.was_bought_for_plus_costs} currency={'EUR'}/>
                            {' '}
                            <span>
                                <Icon
                                    name="stock"
                                    size="xs"
                                />
                                {stock_item.number_of_cases_available}
                            </span>
                        </span>
                    </Link>
                </span>
            })}
        </div>
    }
}
