import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {$s, $t} from '@bitstillery/common/app'
import m from 'mithril'

export interface DataCardAttrs {
    className?: string
    type?: string
    model: DataCardModel
}

interface DataCardEntry {
    label: string | JSX.Element,
    value?: any
    type?: string,
}

export interface DataCardModel {
    data: DataCardEntry[]
}

export class DataCard extends MithrilTsxComponent<DataCardAttrs> {
    view(vn:m.Vnode<DataCardAttrs>) {
        return <div className={classes('c-data-card', `type-${vn.attrs.type ? vn.attrs.type : 'default'}`, vn.attrs.className)}>
            {vn.attrs.model.data.map((entry:DataCardEntry) => {
                return <div className={classes('entry', `type-${entry.type ? entry.type : 'default'}`)}>
                    <div className="entry-label">{entry.label}</div>
                    <div className="entry-value">{entry.value ? entry.value : '-'}</div>
                </div>
            })}
        </div>
    }
}

export class ButtonDataCard {

    previous_name = ''

    view(_vnode:m.Vnode<any>) {
        return <Button
            active={!$s.panels.context.collapsed && $s.context.name === 'data_card'}
            className="btn-data-card"
            icon="card_account_details_outline"
            onclick={() => {
                if ($s.context.name !== 'data_card') {
                    this.previous_name = $s.context.name
                    Object.assign($s.context, {
                        name: 'data_card',
                        title: $t('data_card.text'),
                    })
                } else {
                    Object.assign($s.context, {
                        name: this.previous_name,
                        title: '',
                    })
                }
            }}
            tip={$t('data_card.tip')}
            text={$t('data_card.text')}
            type="default"
            variant="context"
        />
    }
}
