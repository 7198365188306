import m from 'mithril'
import {classes, unique_id} from '@bitstillery/common/lib/utils'
import {Tippy} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {tip} from '@bitstillery/common/components'

import {svg} from './icons'

export interface IconAttrs {
    id: string
    tip: string | {(): string}
    className: string
    context: string
    name: string
    size: string
    type: 'default' | 'brand' | 'success' | 'info' | 'warning' | 'danger'
    disabled: boolean
    onclick: () => unknown
}

export class Icon extends MithrilTsxComponent<IconAttrs> {
    id: string
    tippy: Tippy

    constructor(vn: m.Vnode<IconAttrs>) {
        super()
        if (vn.attrs.id) {
            this.id = vn.attrs.id
        }
        else {
            this.id = unique_id(8, false)
        }
    }

    onremove(vn) {
        if (vn.attrs.tip && this.tippy) {
            this.tippy.destroy()
        }
    }

    oncreate(vn) {
        if (vn.attrs.tip) {
            const tip_attrs = {
                allowHTML: true,
                animation: 'scale',
                appendTo: document.body,
                content: this.update_tip(vn),
                delay: 500,
                inertia: true,
                interactive: true,
                onTrigger: (instance) => {
                    instance.setProps({content: this.update_tip(vn)})
                },
                touch: 'hold',
                zIndex: 10000000000000000,
            }
            if (vn.attrs.tip_attrs) {
                Object.assign(tip_attrs, vn.attrs.tip_attrs)
            }
            this.tippy = tip(document.querySelector(`#${this.id}`), tip_attrs)
        }
    }

    update_tip(vn) {
        if (typeof vn.attrs.tip === 'function') {
            return vn.attrs.tip()
        } else {
            return vn.attrs.tip
        }
    }

    view(vn:m.Vnode<IconAttrs>) {
        const tip_content = this.update_tip(vn)
        const element = <svg
            className={classes(
                'c-icon',
                vn.attrs.className,
                vn.attrs.size ? `icon-${vn.attrs.size}` : 'icon-d',
                vn.attrs.type ? `type-${vn.attrs.type}` : 'type-default',
                {
                    action: vn.attrs.onclick,
                    disabled: vn.attrs.disabled,
                    tip: vn.attrs.tip,
                    'tip-empty': vn.attrs.tip && !tip_content,
                },
            )}
            id={this.id}
            onclick={(e) => {
                if (vn.attrs.disabled || !vn.attrs.onclick) return
                vn.attrs.onclick(e)
            }}
            height="24"
            viewBox="0 0 24 24"
            width="24"
        >
            {m.trust(svg[vn.attrs.name])}
        </svg>

        if (vn.attrs.context) {
            return <Tippy content={vn.attrs.context}>{element}</Tippy>
        }

        return element
    }
}
