import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {Icon, Link} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'

export class Lot extends MithrilTsxComponent<any> {

    view(vn: m.Vnode<any>) {
        if (!vn.attrs.type) {
            vn.attrs.type = 'default'
        }

        return <div className={classes('c-lot', `type-${vn.attrs.type}`)}>
            {vn.attrs.lot_date && <div className="lot-date">
                {vn.attrs.lot_date}
            </div>}

            <div className="lot-reference">
                {vn.attrs.type !== 'default' && <Icon
                    name='stock'
                    size='s'
                    tip={vn.attrs.tip}
                    type={vn.attrs.type}
                />}
                {vn.attrs.warehouse_name && <div className="lot-warehouse">
                    {vn.attrs.warehouse_name.split(' ').map((i) => i[0]).join('')}
                </div>}
                {vn.attrs.warehouse_name && vn.attrs.lot_number && '-'}
                <div className="lot-number">
                    {(() => {
                        if (vn.attrs.lot_number) {
                            const stock_url = `/stock/manage/?q=${vn.attrs.lot_number}&available_only=false`.replace(/ /g, '+')
                            return <Link href={stock_url}>{vn.attrs.lot_number}</Link>
                        } else if (vn.attrs.mutation && vn.attrs.mutation.target_in) {
                            return <Icon
                                name='plane'
                                size='s'
                                tip={() => {
                                    return `Part of <a href='#!/stock/mutations/manage/${vn.attrs.mutation.reference}' target='_blank'>mutation ${vn.attrs.mutation.reference}</a>`
                                }}
                                type='info'
                            />
                        } else {
                            return <Icon
                                name='cart'
                                size='s'
                                tip={() => {
                                    return 'This is a purchase order item'
                                }}
                                type='info'
                            />
                        }
                    })()}
                </div>
            </div>
        </div>
    }
}
