import m from 'mithril'
import {Button, Dialog} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {$t} from '@bitstillery/common/app'

export class DemoUserModal extends MithrilTsxComponent<any> {
    constructor(vn: m.Vnode<any>) {
        super()
        this.attrs = {
            onclose: vn.attrs.onclose,
            title: vn.attrs.title,
        }

        this.bodyContent = vn.attrs.bodyContent
    }

    view(vn: m.Vnode<any>) {
        return (
            <Dialog {...vn.attrs}>
                {this.bodyContent}
                <Button
                    text={$t('demo.dialog.button_ok')}
                    type='info'
                    onclick={vn.attrs.onclose}
                />
            </Dialog>
        )
    }
}
