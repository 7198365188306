import {merge_deep} from '@bitstillery/common/lib/utils'
import {$t, api, events, logger, notifier, store} from '@bitstillery/common/app'

import {volatile} from '../lib/state'

import {$m, $s} from '@/app'
import {
    AdditionalSalesOrderDetailsResponse,
    GetPortalOfferItemsRequest,
    OfferItemFilterType,
    PortalOrderItemResponse,
    SalesOrderAdditionalResponse, SalesOrderCreditItemResponse,
    SalesOrderItemResponse,
    SalesOrderTBOItemResponse,
} from '@/factserver_api/fact2server_api'

export const OrderStatus = {
    CANCELLED: 'Cancelled',
    CONFIRMED: 'Confirmed',
    FINALIZED: 'Finalized',
    INVOICED: 'Invoiced',
    NEW: 'New',
    PENDING: 'Pending',
    SAVED: ['Pending', 'Finalized'],
}

let active_promotion:any = null

/**
 * Load the promotion state from the backend and update
 * the local store with it.
 * @returns
 */
export async function get_active_promotion() {
    $s.promotion.loading = true
    logger.debug('[order] retrieving active promotions')
    const response = await api.post('promotion.get_active') as any
    active_promotion = response.result
    if (!response.success || !active_promotion) {
        $s.promotion.active = false
        return
    }

    // One promotion is active? Enable the promotions UI elements.
    // We'll later distinguish between different promotions.
    const now = new Date()
    const end_date = new Date(active_promotion.end_date)
    $s.promotion.active = end_date > now
    const grace_date = new Date(active_promotion.grace_date)
    // This means either in the active period OR in the grace period.
    $s.promotion.active_grace = grace_date > now
    merge_deep($s.promotion, active_promotion, {
        current: active_promotion.gifts[0].id,
        loading: false,
        turnover_draft: active_promotion.turnover_draft + $m.cart.cart_amount(),
        turnover_redeemable: active_promotion.turnover_redeemable,
        unlocked: true, // only used on single-order promotions
    })

    $m.order.active_promotion_status()
}

export async function load_active_voucher() {
    const {result} = await api.post('voucher.get_active', {
        sales_order_artkey: $s.cart.artkey,
    }) as any

    if (result) {
        $s.cart.vouchers.available = result
    }
}

/**
 * Loads the active order for the current user.
 *
 * This function first retrieves any active promotions and then fetches
 * the current active order from the user's portal. If no active order is
 * found, it notifies the user of the failure. Otherwise, it proceeds to
 * load the details of the active order.
 *
 * @returns {Promise<void>} A promise that resolves when the active order has been loaded.
 */
export async function load_current_order() {
    get_active_promotion()
    const {result:active_order} = await api.get('portal/user/current-order')
    if (!active_order) {
        // It is important to notify the customer when loading the cart fails;
        // otherwise he/she makes a futile effort to fill the cart without persistency.
        notifier.notify($t('notifications.order_failure', 'danger', 7000))
        return
    }
    logger.info(`[order] loading active order: ${active_order.artkey}`)
    Object.assign($s.cart, {artkey: null, loading: true})
    const full_order = await load_full_order(active_order)
    Object.assign($s.cart, {
        amount: {},
        errors: JSON.parse(JSON.stringify(volatile.cart.errors)),
        items: {},
        sales_order_additionals: full_order.sales_order_additionals,
        sales_order_credit_items: full_order.sales_order_credit_items,
        sales_order_items: full_order.sales_order_items,
        sales_order_tbo_items: full_order.sales_order_tbo_items,
        unavailable: [],
        includes_excise: full_order.includes_excise,
    })

    for (const portal_order_item of full_order.portal_order_items as any) {
        if (!(portal_order_item.case_artkey in $s.cart.items)) {
            $m.cart.add_cart_item_state(portal_order_item)
        }
    }

    const voucher = $m.cart.voucher_additional() as any
    const voucher_code = voucher?.description
    $s.cart.vouchers.active = voucher_code ? voucher_code : false

    store.save()
    active_promotion_status()

    // Get related offer_items for cart items
    const cart_items = Object.values($s.cart.items)
    const {result, status_code} = await api.post<any>('portal/offer-items', {
        availabilities: [],
        category_artkeys: [],
        limit: 200,
        offer_hash: undefined,
        offer_item_type: OfferItemFilterType.BROWSE_STOCK,
        offer_item_artkeys: cart_items.map((i:any) => i.offer_item_artkey).filter((i) => typeof i === 'number'),
        offset: 0,
        packaging: [],
        search_terms: [],
        sort_ascending: true,
        sort_by: 'entry_date',
    } as GetPortalOfferItemsRequest, true)

    if (status_code === 404) {
        $s.portal.ready = false
        return
    }

    const case_artkeys = result.portal_offer_items.map((i) => String(i.case_artkey))
    const cart_case_artkeys = Object.keys($s.cart.items)

    if (case_artkeys.length !== cart_case_artkeys.length) {
        const missing_case_artkeys = cart_case_artkeys.filter((i) => !case_artkeys.includes(i))
        for (const missing_case_artkey of missing_case_artkeys) {
            // There might be cart items without a matching offer_item.
            if ($s.cart.items[missing_case_artkey].number_of_cases > 0) {
                $s.cart.errors.unavailable[missing_case_artkey] = true
            }
        }
    }

    // These are offer_items from cart items; booked items should
    // not be included, because it may no longer be linked to an offeritem.
    const offer_items = result.portal_offer_items.reduce((a, c) => {a[c.case_artkey] = c; return a}, {})
    Object.assign($s.cart.offer_items, offer_items)

    await Promise.all([
        $m.offer.load_country_of_origin(cart_items.map((i:any) => i.offer_item_artkey)),
        $m.offer.load_product_photos([...cart_items, ...$s.cart.sales_order_items]
            .filter((order_item:any) => order_item.offer_item_artkey)
            .map((order_item:any) => order_item.offer_item_artkey)),
    ])

    const {result: validation_result, success} = await api.post('order.validate', {order_artkey: full_order.sales_order.artkey})
    if (!success) {
        notifier.notify($t('notifications.order_failure', 'danger', 7000))
        return
    }
    await $m.cart.validate_cart(validation_result)

    Object.assign($s.cart, {
        artkey: full_order.sales_order.artkey,
        loading: false,
    })
    events.emit('active-order.set-order', full_order)
    return full_order
}

/**
 * Loads a complete order using either an order object or an order key.
 *
 * This asynchronous function retrieves various components of a sales
 * order and aggregates them into a single comprehensive order object.
 * The components fetched include sales order items, additional items,
 * portal order items, tbo items, credit items, and invoice details.
 *
 * The function sorts both sales order items and portal order items alphabetically
 * by the product name before returning the final composed order object.
 *
 * @param {number|object} artkey_or_order - The order's key or an order object.
 * @returns {Promise<object>} A promise that resolves to an object containing
 *                            all components of the loaded order.
 */
export async function load_full_order(artkey_or_order) {
    let sales_order
    if (typeof artkey_or_order === 'number') {
        const sales_order_response = await api.get(`portal/orders/${artkey_or_order}`)
        if (!sales_order_response.success) {
            return {}
        }
        sales_order = sales_order_response.result
    } else {
        sales_order = artkey_or_order
    }

    const responses = await Promise.all([
        api.get<SalesOrderItemResponse[]>(`portal/orders/${sales_order.artkey}/sales-order-items`),
        api.get<SalesOrderAdditionalResponse[]>(`portal/orders/${sales_order.artkey}/additionals`),
        api.get<PortalOrderItemResponse[]>(`portal/orders/${sales_order.artkey}/portal-order-items`),
        api.get<SalesOrderTBOItemResponse[]>(`portal/orders/${sales_order.artkey}/tbo-items`),
        api.get<SalesOrderCreditItemResponse[]>(`portal/orders/${sales_order.artkey}/credit-items`),
        api.get<AdditionalSalesOrderDetailsResponse>(`portal/orders/${sales_order.artkey}/invoice-details`),
    ])
    if (responses.filter((response) => !response.success).length > 0) {
        return {}
    }
    let [
        {result: sales_order_items}, {result: sales_order_additionals},
        {result: portal_order_items}, {result: sales_order_tbo_items}, {result: sales_order_credit_items},
        {result: invoice_details},
    ] = responses

    portal_order_items = portal_order_items.sort((a, b) => {
        const _a = a.product_name.toUpperCase(); const _b = b.product_name.toUpperCase()
        if (_a < _b) return -1; if (_a > _b) return 1
        return 0
    })

    sales_order_items = sales_order_items.sort((a, b) => {
        const _a = a.product_name.toUpperCase(); const _b = b.product_name.toUpperCase()
        if (_a < _b) return -1; if (_a > _b) return 1
        return 0
    })

    return {
        sales_order,
        sales_order_items,
        sales_order_additionals,
        portal_order_items,
        sales_order_credit_items,
        sales_order_tbo_items,
        invoice_details,
    }
}

/**
 * This function is separated from get_active_promotion, because
 * it calculates with local cart state, and is updated whenever
 * the cart changed(the API call is debounced). The `draft` and `redeemable`
 * values are separate numbers, while preview combines both when needed
 * for the promotion calculation.
 * @returns
 */
export function active_promotion_status() {
    if (!$s.promotion.active_grace) return

    const token_value = $s.promotion.token_value
    const turnover_redeemable = $s.promotion.turnover_redeemable
    const turnover_draft = Math.max(active_promotion.turnover_draft + $m.cart.cart_amount(), 0)

    const tokens_redeemable = Math.floor(turnover_redeemable / token_value)
    const tokens_draft = Math.floor(turnover_draft / token_value)
    const promotion_type = $s.promotion.type

    if (promotion_type === 'single-order') {
        // Single-order specific logic
    } else if (promotion_type === 'multi-order') {
        const tokens_selected = Object.entries($s.promotion.lookup).map(([slug, amount]) => {
            const gift:any = $s.promotion.gifts.find((gift) => gift.id === slug)
            if (gift) {
                return gift.tokens_required * amount
            }
            return 0
        }).reduce((total, tokens) => total + tokens, 0)

        // Reset our selection, if we have more than available tokens selected.
        if (tokens_selected > $s.promotion.tokens_redeemable) {
            // Remove the local selection and update the store
            for (const key of Object.keys($s.promotion.lookup)) {
                delete $s.promotion.lookup[key]
            }
            store.save()
        }

        $s.promotion.tokens_selected = tokens_selected
    }

    merge_deep($s.promotion, {
        turnover_draft: turnover_draft,
        tokens_draft,
        tokens_redeemable,
    })

}

export async function init() {
    events.on('identity.login', async() => {
        load_current_order()
    })
}
