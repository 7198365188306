import m from 'mithril'
import {classes, ucfirst} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    Button, ButtonGroup, FieldCheckbox, FieldSelect,
    FieldText, FieldTextArea, RadioGroup, Stepper,
} from '@bitstillery/common/components'
import {countries, european_union_countries} from '@bitstillery/common/lib/countries'
import {next_tick, proxy} from '@bitstillery/common/lib/proxy'
import {and, conditional, email, required, validation, website} from '@bitstillery/common/lib/validation'
import {$t, api, notifier} from '@bitstillery/common/app'

import {$s} from '@/app'

const country_options = Object.entries(countries)

const modes = ['embedded', 'full']
export class AuthRegistration extends MithrilTsxComponent<any> {

    data = (() => {
        const $f = {
            address: '',
            city: '',
            company_name: '',
            consent: false,
            country_code: '',
            emailaddress: '',
            excise_id: '',
            excise_location_id: '',
            family_name: '',
            first_name: '',
            has_warehouse: '',
            message: '',
            postal_code: '',
            position: '',
            telephone: '',
            terms_and_conditions: false,
            vat_id: '',
            warehouse: '', // a known third party bonded warehouse
            warehouse_address: '',
            warehouse_city: '',
            warehouse_name: '', // an unknown third party bonded warehouse
            warehouse_postalcode: '',
            website: '',
        }

        const disabled_group = (step) => {
            return () => {
                if (!this.validationGroup[step]) return false
                const validation_fields = this.validationGroup[step]
                const invalid = validation_fields.map((i) => this.$v[i]).filter((i) => i && i._invalid)
                return !!invalid.length
            }
        }

        return proxy({
            $f,
            stepper: {
                selection: 0,
                options: [
                    {icon: 'company', title: $t('registration.step0_slug')},
                    {_disabled: disabled_group(0), icon: 'address', title: $t('registration.step1_slug')},
                    {_disabled: disabled_group(1), icon: 'contact', title: $t('registration.step2_slug')},
                    {_disabled: disabled_group(2), icon: 'logistics', title: $t('registration.step3_slug')},
                    {_disabled: disabled_group(3), icon: 'clipboardText', title: $t('registration.step4_slug')},
                ],
            },
        })
    })()

    $v = {
        address: validation([this.data.$f, 'address'], required()),
        city: validation([this.data.$f, 'city'], required()),
        consent: validation([this.data.$f, 'consent'], required()),
        company_name: validation([this.data.$f, 'company_name'], required()),
        emailaddress: validation([this.data.$f, 'emailaddress'], and([required(), email()])),
        excise_id: validation([this.data.$f, 'excise_id'], conditional(() => {
            return (this.data.$f.has_warehouse === 'own_bonded')
        }, required())),
        excise_location_id: validation([this.data.$f, 'excise_location_id'], conditional(() => {
            return (this.data.$f.has_warehouse === 'own_bonded')
        }, required())),
        family_name: validation([this.data.$f, 'family_name'], required()),
        first_name: validation([this.data.$f, 'first_name'], required()),
        has_warehouse: validation([this.data.$f, 'has_warehouse'], required()),
        postal_code: validation([this.data.$f, 'postal_code'], required()),
        telephone: validation([this.data.$f, 'telephone'], required()),
        terms_and_conditions: validation([this.data.$f, 'terms_and_conditions'], required()),
        vat_id: validation([this.data.$f, 'vat_id'], conditional(() => {
            return european_union_countries.includes(this.data.$f.country_code)
        }, required())),
        warehouse_address: validation([this.data.$f, 'warehouse_address'], conditional(() => {
            return (
                ['vat_excise', 'own_bonded'].includes(this.data.$f.has_warehouse) ||
                (this.data.$f.has_warehouse === 'third_party_bonded' && (!this.data.$f.warehouse || this.data.$f.warehouse === 'OTHER'))
            )
        }, required())),
        warehouse_postalcode: validation([this.data.$f, 'warehouse_postalcode'], conditional(() => {
            return (
                ['vat_excise', 'own_bonded'].includes(this.data.$f.has_warehouse) ||
                (this.data.$f.has_warehouse === 'third_party_bonded' && (!this.data.$f.warehouse || this.data.$f.warehouse === 'OTHER'))
            )
        }, required())),
        warehouse_city: validation([this.data.$f, 'warehouse_city'], conditional(() => {
            return (
                ['vat_excise', 'own_bonded'].includes(this.data.$f.has_warehouse) ||
                (this.data.$f.has_warehouse === 'third_party_bonded' && (!this.data.$f.warehouse || this.data.$f.warehouse === 'OTHER'))
            )
        }, required())),
        warehouse_name: validation([this.data.$f, 'warehouse_name'], conditional(() => {
            return (
                (this.data.$f.has_warehouse === 'own_bonded') ||
                (this.data.$f.has_warehouse === 'third_party_bonded' && (!this.data.$f.warehouse || this.data.$f.warehouse === 'OTHER'))
            )
        }, required())),
        website: validation([this.data.$f, 'website'], website()),
    }

    last_step: number
    mode: string
    postdomain: string
    resizeObserver: null | ResizeObserver
    scrollContainer: HTMLInputElement

    terms_link = process.env.MSI_THEME === 'msp' ? 'https://movingspirits.eu/terms-and-conditions/' : 'https://a2bc.com/general-terms-and-conditions-page/'
    validationGroup = {
        0: ['company_name', 'website'],
        1: ['country', 'address', 'postal_code', 'city'],
        2: ['first_name', 'family_name', 'emailaddress', 'telephone'],
        3: [
            'has_warehouse', 'vat_id', 'warehouse_name', 'warehouse_address',
            'warehouse_postalcode', 'warehouse_city', 'warehouse_name',
            'excise_id', 'excise_location_id',
        ],
        4: ['consent', 'terms_and_conditions'],
    }

    warehouses = {
        OTHER: $t('registration.warehouse.options.other'),
        LOENDERSLOOT: 'Loendersloot (Roosendaal, Netherlands)',
        SEABROOKS: 'Seabrooks Warehousing (London, UK)',
        TOP: 'Top Logistics (Spijkenisse, Netherlands)',
    }

    constructor() {
        super()

        // Try to auto-determine the current country based on browser language
        let country_code = ''
        if (navigator.language.includes('-')) {
            country_code = navigator.language.split('-')[1].toUpperCase()
        } else {
            const language_code = navigator.language.toUpperCase()
            if (countries[language_code]) {
                country_code = language_code
            }
        }

        this.data.$f.country_code = country_code

        if (modes.includes(m.route.param('mode'))) {
            this.mode = m.route.param('mode')
            this.postdomain = m.route.param('postdomain')
            this.propagate_step(this.data.stepper.selection)
        } else {
            this.mode = 'full'
        }

        // Reference that is used several times in the view
        this.last_step = this.data.stepper.options.length - 1

        if (this.mode === 'embedded') {
            this.scrollContainer = document.querySelector('.app') as HTMLInputElement
            // Identify the html tag from the rest of the styling,
            // so we can correct the height styling that causes incorrect
            // postMessage scrollHeight to be passed.
            const html_element = document.querySelector('html') as HTMLHtmlElement
            html_element.classList.add('embedded')
            window.addEventListener('load', this.propagate_resize.bind(this))

            if (window.ResizeObserver) {
                this.resizeObserver = new ResizeObserver(this.propagate_resize.bind(this))
                this.resizeObserver.observe(this.scrollContainer)
            } else {
                notifier.notify($t('system.incompatible', {code: '!resizeobserver'}), 'danger')
            }
        }
    }

    async propagate_resize() {
        await next_tick()
        await next_tick()
        if (this.mode === 'embedded') {
            window.parent.postMessage({type: 'height', value: this.scrollContainer.scrollHeight}, this.postdomain)
            window.parent.postMessage({type: 'width', value: this.scrollContainer.scrollWidth}, this.postdomain)
        }
    }

    propagate_step(step) {
        if (this.mode === 'embedded') {
            window.parent.postMessage({type: 'step', value: step}, this.postdomain)
        }
    }

    async submit_form() {
        const data = {
            account_slug: process.env.MSI_THEME,
            city: this.data.$f.city,
            company_name: this.data.$f.company_name,
            country_code: this.data.$f.country_code,
            emailaddress: this.data.$f.emailaddress,
            family_name: this.data.$f.family_name,
            first_name: this.data.$f.first_name,
            message: this.data.$f.message,
            position: this.data.$f.position,
            street_address: this.data.$f.address,
            telephone: this.data.$f.telephone,
            vat_id: this.data.$f.vat_id,
            website: this.data.$f.website,
            zip_code: this.data.$f.postal_code,
        }

        // New LeadWarehouse is created when the warehouse_name property is set.
        if (this.data.$f.has_warehouse !== 'no') {
            Object.assign(data, {
                excise_id: this.data.$f.excise_id,
                excise_location_id: this.data.$f.excise_location_id,
                warehouse_address: this.data.$f.warehouse_address,
                warehouse_city: this.data.$f.warehouse_city,
                warehouse_name: this.data.$f.warehouse_name,
                warehouse_postalcode: this.data.$f.warehouse_postalcode,
            })
        }

        await api.post('registration.submit_form', data)
        notifier.notify($t('registration.step5_notify'), 'info')
        this.data.stepper.selection += 1
        this.propagate_step(this.data.stepper.selection)
    }

    view() {
        const vat_required = european_union_countries.includes(this.data.$f.country_code)

        if (this.data.stepper.selection === this.last_step + 1) {
            $s.page.title = $t('page.title.registration_completed')
            $s.page.subtitle = $t('page.subtitle.registration_completed')
        } else {
            $s.page.title = $t('page.title.registration')
            $s.page.subtitle = $t('page.subtitle.registration')
        }
        return (
            <div className={classes('c-registration', 'landing')}>
                <div className="form stepper-form">
                    {this.data.stepper.selection === 0 && <div className="step step-0">
                        <h3>{$t('registration.step0_slug')}</h3>
                        <p className="step-info">{$t('registration.step0_desc', {vendor: process.env.MSI_TITLE})}</p>

                        <FieldText
                            label={$t('registration.company_name')}
                            placeholder={$t('registration.company_name_placeholder')}
                            ref={[this.data.$f, 'company_name']}
                            validation={this.$v.company_name}
                        />
                        <FieldText
                            label={$t('registration.website')}
                            ref={[this.data.$f, 'website']}
                            placeholder={$t('registration.website_placeholder')}
                            validation={this.$v.website}
                        />
                    </div>}

                    {this.data.stepper.selection === 1 && <div className="step step-1">
                        <h3>{$t('registration.step1_slug')}</h3>
                        <p className="step-info">{$t('registration.step1_desc')}</p>

                        <FieldSelect
                            help={$t('registration.country_help')}
                            label={$t('registration.country')}
                            options={country_options}
                            ref={[this.data.$f, 'country_code']}
                        />

                        <FieldText
                            label={$t('registration.address')}
                            placeholder={$t('registration.address_placeholder')}
                            ref={[this.data.$f, 'address']}
                            validation={this.$v.address}
                        />

                        <div className="field-group">
                            <FieldText
                                label={$t('registration.city')}
                                placeholder={$t('registration.city_placeholder')}
                                ref={[this.data.$f, 'city']}
                                validation={this.$v.city}
                            />
                            <FieldText
                                label={$t('registration.postal_code')}
                                ref={[this.data.$f, 'postal_code']}
                                placeholder={$t('registration.postal_code_placeholder')}
                                validation={this.$v.postal_code}
                            />
                        </div>
                    </div>}

                    {this.data.stepper.selection === 2 && <div className="step step-2">
                        <h3>{$t('registration.step2_slug')}</h3>
                        <p className="step-info">{$t('registration.step2_desc')}</p>
                        <div className="field-group">
                            <FieldText
                                label={$t('registration.first_name')}
                                ref={[this.data.$f, 'first_name']}
                                placeholder={$t('registration.first_name_placeholder')}
                                validation={this.$v.first_name}
                            />
                            <FieldText
                                label={$t('registration.family_name')}
                                ref={[this.data.$f, 'family_name']}
                                placeholder={$t('registration.family_name_placeholder')}
                                validation={this.$v.family_name}
                            />
                        </div>

                        <FieldText
                            label={$t('registration.email_address')}
                            help={$t('registration.email_address_help')}
                            placeholder={$t('registration.email_address')}
                            ref={[this.data.$f, 'emailaddress']}
                            validation={this.$v.emailaddress}
                        />

                        <FieldText
                            label={$t('registration.telephone')}
                            placeholder={$t('registration.telephone')}
                            ref={[this.data.$f, 'telephone']}
                            validation={this.$v.telephone}
                        />

                        <FieldText
                            label={$t('registration.position')}
                            placeholder={$t('registration.position_placeholder')}
                            ref={[this.data.$f, 'position']}
                        />

                    </div>}

                    {this.data.stepper.selection === 3 && <div className="step step-3">
                        <h3>{$t('registration.step3_slug')}</h3>
                        <p className="step-info">{$t(`registration.step3_desc${vat_required ? '_vat' : ''}`)}</p>
                        {(() => {
                            const bonded_warehouse = [
                                <RadioGroup
                                    label={$t('registration.warehouse.select')}
                                    options={Object.entries(this.warehouses)}
                                    ref={[this.data.$f, 'warehouse']}
                                />,
                                this.data.$f.warehouse === 'OTHER' ? [
                                    <div className="field-group">
                                        <FieldText
                                            label={$t('registration.warehouse.name')}
                                            placeholder={$t('registration.warehouse.name')}
                                            ref={[this.data.$f, 'warehouse_name']}
                                            validation={this.$v.warehouse_name}
                                        />

                                        <FieldText
                                            label={$t('registration.warehouse.address')}
                                            placeholder={$t('registration.warehouse.address')}
                                            ref={[this.data.$f, 'warehouse_address']}
                                            validation={this.$v.warehouse_address}
                                        />
                                    </div>,
                                    <div className="field-group">
                                        <FieldText
                                            label={$t('registration.warehouse.postal_code')}
                                            placeholder={$t('registration.warehouse.postal_code')}
                                            ref={[this.data.$f, 'warehouse_postalcode']}
                                            validation={this.$v.warehouse_postalcode}
                                        />
                                        <FieldText
                                            label={$t('registration.warehouse.city')}
                                            placeholder={$t('registration.warehouse.city')}
                                            ref={[this.data.$f, 'warehouse_city']}
                                            validation={this.$v.warehouse_city}
                                        />
                                    </div>,
                                ] : null,
                            ]

                            if (vat_required) {
                                return [
                                    <FieldText
                                        help={$t('registration.vat_number_help')}
                                        label={$t('registration.vat_number')}
                                        placeholder={$t('registration.vat_number_placeholder')}
                                        ref={[this.data.$f, 'vat_id']}
                                        validation={this.$v.vat_id}
                                    />,

                                    <RadioGroup
                                        label={$t('registration.warehouse.warehousing')}
                                        options={this.warehouse_options()}
                                        ref={[this.data.$f, 'has_warehouse']}
                                    />,

                                    this.data.$f.has_warehouse === 'vat_excise' ? [
                                        <FieldText
                                            help={$t('registration.warehouse.address_unloading_help')}
                                            label={$t('registration.warehouse.address_unloading')}
                                            placeholder={$t('registration.warehouse.address_unloading')}
                                            ref={[this.data.$f, 'warehouse_address']}
                                            validation={this.$v.warehouse_address}
                                        />,
                                        <div className="field-group">
                                            <FieldText
                                                label={$t('registration.warehouse.postal_code_unloading')}
                                                placeholder={$t('registration.warehouse.postal_code_unloading')}
                                                ref={[this.data.$f, 'warehouse_postalcode']}
                                                validation={this.$v.warehouse_postalcode}
                                            />
                                            <FieldText
                                                label={$t('registration.warehouse.city_unloading')}
                                                placeholder={$t('registration.warehouse.city_unloading')}
                                                ref={[this.data.$f, 'warehouse_city']}
                                                validation={this.$v.warehouse_city}
                                            />
                                        </div>,
                                    ] : null,

                                    this.data.$f.has_warehouse === 'own_bonded' ? [
                                        <div className="field-group">
                                            <FieldText
                                                label={$t('registration.warehouse.name')}
                                                placeholder={$t('registration.warehouse.name')}
                                                ref={[this.data.$f, 'warehouse_name']}
                                                validation={this.$v.warehouse_name}
                                            />
                                            <FieldText
                                                help={$t('registration.warehouse.address_help')}
                                                label={$t('registration.warehouse.address')}
                                                placeholder={$t('registration.warehouse.address')}
                                                ref={[this.data.$f, 'warehouse_address']}
                                                validation={this.$v.warehouse_address}
                                            />
                                        </div>,

                                        <div className="field-group">
                                            <FieldText
                                                label={$t('registration.warehouse.postal_code')}
                                                placeholder={$t('registration.warehouse.postal_code')}
                                                ref={[this.data.$f, 'warehouse_postalcode']}
                                                validation={this.$v.warehouse_postalcode}
                                            />

                                            <FieldText
                                                label={$t('registration.warehouse.city')}
                                                placeholder={$t('registration.warehouse.city')}
                                                ref={[this.data.$f, 'warehouse_city']}
                                                validation={this.$v.warehouse_city}
                                            />
                                        </div>,
                                        <div className="field-group">
                                            <FieldText
                                                label={$t('registration.warehouse.excise_id')}
                                                placeholder={$t('registration.warehouse.excise_id')}
                                                ref={[this.data.$f, 'excise_id']}
                                                validation={this.$v.excise_id}
                                            />
                                            <FieldText
                                                label={`${$t('registration.warehouse.location_id')} ${this.data.$f.country_code === 'NL' ? '(AGP)' : ''}`}
                                                placeholder={$t('registration.warehouse.location_id')}
                                                ref={[this.data.$f, 'excise_location_id']}
                                                validation={this.$v.excise_location_id}
                                            />
                                        </div>,
                                    ] : null,
                                    this.data.$f.has_warehouse === 'third_party_bonded' ? bonded_warehouse : null,
                                ]
                            } else {
                                return [
                                    <RadioGroup
                                        label={$t('registration.warehouse.bonded_eu')}
                                        ref={[this.data.$f, 'has_warehouse']}
                                        options={[
                                            ['third_party_bonded', $t('yes')],
                                            ['no', $t('no')],
                                        ]}
                                    />,
                                    this.data.$f.has_warehouse === 'third_party_bonded' ? bonded_warehouse : null,
                                ]
                            }
                        })()}
                    </div>}

                    {this.data.stepper.selection === 4 && <div className="step step-4">
                        <h3>{$t('registration.step4_slug')}</h3>
                        <p>{$t('registration.step4_desc')}</p>

                        <div className="field-readonly-list">
                            <div className="field-readonly">
                                <div className="key">{$t('registration.company_name')}</div>
                                <div className="value">{this.data.$f.company_name ? this.data.$f.company_name : '-'}</div>
                            </div>
                            <div className="field-readonly">
                                <div className="key">{$t('registration.website')}</div>
                                <div className="value">{this.data.$f.website ? this.data.$f.website : '-'}</div>
                            </div>

                            <div className="field-readonly">
                                <div className="key">{$t('registration.postal_code')}</div>
                                <div className="value">{this.data.$f.postal_code ? this.data.$f.postal_code : '-'}</div>
                            </div>
                            <div className="field-readonly">
                                <div className="key">{$t('registration.address')}</div>
                                <div className="value">{this.data.$f.address ? this.data.$f.address : '-'}</div>
                            </div>

                            <div className="field-readonly">
                                <div className="key">{$t('registration.city')}</div>
                                <div className="value">{this.data.$f.city ? this.data.$f.city : '-'}</div>
                            </div>
                            <div className="field-readonly">
                                <div className="key">{$t('registration.country')}</div>
                                <div className="value">{countries[this.data.$f.country_code]}</div>
                            </div>

                            <div className="field-readonly">
                                <div className="key">{$t('registration.name')}</div>
                                <div className="value">{(!this.data.$f.first_name && !this.data.$f.family_name) ? '-' : `${this.data.$f.first_name} ${this.data.$f.family_name}`}</div>
                            </div>

                            <div className="field-readonly">
                                <div className="key">{$t('registration.position')}</div>
                                <div className="value">{this.data.$f.position ? this.data.$f.position : '-'}</div>
                            </div>

                            <div className="field-readonly">
                                <div className="key">{$t('registration.telephone')}</div>
                                <div className="value">{this.data.$f.telephone ? this.data.$f.telephone : '-'}</div>
                            </div>

                            <div className="field-readonly">
                                <div className="key">{$t('registration.email_address')}</div>
                                <div className="value">{this.data.$f.emailaddress ? this.data.$f.emailaddress : '-'}</div>
                            </div>

                            {vat_required && <div className="field-readonly">
                                <div className="key">{$t('registration.vat_number')}</div>
                                <div className="value">{this.data.$f.vat_id ? this.data.$f.vat_id : '-'}</div>
                            </div>}

                            <div className="field-readonly">
                                <div className="key">{this.data.$f.has_warehouse === 'vat_excise' ? $t('registration.warehouse.name_unloading') : $t('registration.warehouse.name')}</div>
                                <div className="value">
                                    {(() => {
                                        if (this.data.$f.warehouse === 'OTHER') {
                                            return `${ucfirst(this.data.$f.warehouse_name)}${this.data.$f.warehouse_address ? ` (${this.data.$f.warehouse_address})` : ''}`
                                        } else if (['own_bonded', 'vat_excise'].includes(this.data.$f.has_warehouse)) {
                                            return `${this.data.$f.warehouse_name} (${this.data.$f.warehouse_address}, ${this.data.$f.postal_code}, ${this.data.$f.warehouse_city})`
                                        } else if (this.data.$f.has_warehouse === 'temporary_license') {
                                            return $t('registration.import_goods_temp_license')
                                        } else if (Object.keys(this.data.$f.warehouse).length) {
                                            return this.warehouses[this.data.$f.warehouse.toUpperCase()]
                                        }

                                        return '-'
                                    })()}
                                </div>
                            </div>

                            {this.data.$f.excise_id && <div className="field-readonly">
                                <div className="key">{$t('registration.warehouse.excise_id')}</div>
                                <div className="value">{this.data.$f.excise_id}</div>
                            </div>}

                            {this.data.$f.excise_location_id && <div className="field-readonly">
                                <div className="key">{$t('registration.warehouse.location_id')}</div>
                                <div className="value">{this.data.$f.excise_location_id}</div>
                            </div>}
                        </div>

                        <FieldTextArea
                            placeholder={$t('registration.message_placeholder')}
                            label={$t('registration.message')}
                            ref={[this.data.$f, 'message']}
                        />

                        <div className="consent">
                            <FieldCheckbox
                                label={$t('registration.consent', {vendor: process.env.MSI_TITLE})}
                                ref={[this.data.$f, 'consent']}
                            />
                            <FieldCheckbox
                                label={m.trust($t('registration.agree', {link: this.terms_link, interpolation: {escapeValue: false}}))}
                                ref={[this.data.$f, 'terms_and_conditions']}
                            />
                        </div>
                    </div>}

                    {this.data.stepper.selection === 5 && <div className="step step-5">
                        <div className="team-regards">
                            <img className="team" src="/theme/img/team.jpg"/>
                            <span>{$t(`support.contact.team_${process.env.MSI_THEME}`)}</span>
                        </div>
                        <h2>{$t('registration.step5_title')}</h2>
                        <p className="mt-05">{$t('registration.step5_desc')}</p>
                        <p className="mt-1">
                            {$t('registration.step5_footer')}
                            {/** Reference to Youtube channel (MSI only) */}
                            {process.env.MSI_THEME === 'msp' && m.trust(`&nbsp${$t('registration.step5_incentive')}`)}
                        </p>
                    </div>}

                    {this.data.stepper.selection <= this.last_step && <ButtonGroup>
                        {this.data.stepper.selection > 0 && (
                            <Button
                                icon='chevronLeft'
                                text={$t('back')}
                                type='default'
                                onclick={() => {
                                    this.data.stepper.selection -= 1
                                    this.propagate_step(this.data.stepper.selection)
                                }}
                            />
                        )}

                        <Button
                            disabled={(() => {
                                if (!this.validationGroup[this.data.stepper.selection]) return false
                                const validation_fields = this.validationGroup[this.data.stepper.selection]
                                const invalid = validation_fields.map((i) => this.$v[i]).filter((i) => i && i._invalid)
                                return invalid.length
                            })()}
                            icon={this.data.stepper.selection === this.last_step ? 'checked' : 'chevronRight'}
                            text={(() => {
                                return $t(`registration.step${this.data.stepper.selection + 1}_slug`)
                            })()}
                            type={this.data.stepper.selection === this.last_step ? 'success' : 'info'}
                            onclick={() => {
                                // At this point the form is submitted. After registration, the next step is toggled.
                                if (this.data.stepper.selection < this.last_step) {
                                    this.data.stepper.options[this.data.stepper.selection].completed = true
                                    this.data.stepper.selection += 1

                                    this.propagate_step(this.data.stepper.selection)

                                    if (this.data.stepper.selection === 2) {
                                        // Prefill warehouse_name from company_name
                                        this.data.$f.warehouse_name = this.data.$f.company_name
                                    }
                                } else if (this.data.stepper.selection === this.last_step) {
                                    this.submit_form()
                                }
                            }}
                        />
                    </ButtonGroup>}
                </div>

                {(this.data.stepper.selection < this.data.stepper.options.length) &&
                <Stepper
                    model={this.data.stepper}
                    options={this.data.stepper.options}
                />}

                {this.data.stepper.selection < 3 && <div className="about">
                    {$t('registration.explanation', {vendor: process.env.MSI_TITLE})}
                </div>}
            </div>
        )
    }

    warehouse_options() {
        const options = [
            ['own_bonded', $t('registration.warehouse.options.bonded')],
            ['third_party_bonded', $t('registration.warehouse.options.third_party')],
            ['temporary_license', $t('registration.warehouse.options.temp_license')],
        ]
        if (this.data.$f.country_code === 'NL') {
            options.unshift(['vat_excise', $t('registration.warehouse.options.inc_vat')])
        }
        return options
    }
}
