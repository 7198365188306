{
    "collection": {
        "exclude_label": "ausschließen",
        "exclude_one": "Ausschließen {{count}} Artikel",
        "exclude_other": "Ausgeschlossen sind {{count}} Artikel",
        "exclude_tip": "Artikel ausschließen; {{count}} Artikel derzeit enthalten",
        "include_label": "einbeziehen",
        "include_one": "{{count}} Artikel einbeziehen",
        "include_other": "{{count}} Artikel einbeziehen",
        "include_tip": "Artikel einschließen; {{count}} bereits eingeschlossene Artikel",
        "modify_selection": "Auswahl ändern",
        "no_results": "Keine Ergebnisse gefunden",
        "no_results_clear_filters": "Hast du versucht, die aktiven Filter zu löschen?"
    },
    "contact": {},
    "data_card": {
        "text": "Datenkarte",
        "tip": "Datenkarte umschalten"
    },
    "dialog": {
        "cancel": "Abbrechen"
    },
    "filters": {
        "actions": {
            "clear_filter_selection": "Filter löschen: {{name}}/{{selection}}",
            "deactivate_filter": "Filter deaktivieren",
            "pricelist": "Preisliste"
        },
        "clear": "Filter löschen",
        "collapse": "Filter einklappen",
        "collapse_tip": "Alle Filter einklappen",
        "expand": "Filter erweitern",
        "expand_tip": "Alle Filter aufklappen",
        "inactive_filter": "Filter ist inaktiv",
        "no_results": "Keine Produkte",
        "no_results_hint": {
            "filters": "Ändere deine aktiven Filter?",
            "search": "Ändere deine Suchbegriffe?"
        },
        "no_selection": "Keine Artikel ausgewählt",
        "panel_title": "Filter",
        "personal_offer": "Persönlich",
        "reset_disabled": "Aktiviere Filter, um diese Option zu nutzen",
        "reset_one": "Filter zurücksetzen",
        "reset_other": "{{count}} Filter zurücksetzen",
        "sort": {
            "direction": "Sortierreihenfolge",
            "direction_tip": "Sortierreihenfolge ändern",
            "name": "Sortieren",
            "options": "Optionen sortieren",
            "reset": "Sortierung zurücksetzen",
            "types": {
                "avg_purchase_price": "Durchschnittlicher Einkaufspreis",
                "created_by": "Erstellt von",
                "created_on": "Erstellt am",
                "delivery_period": "Lieferfrist",
                "entry_date": "Eintrittsdatum",
                "invoice_number": "Rechnungsnummer",
                "is_invoiced_on": "Rechnungsdatum",
                "item_stock_age": "Alter des Bestands",
                "item_throughput": "Durchsatz",
                "last_price_change_age": "Letzte Preisänderung",
                "list_price": "Verkaufspreis",
                "list_quantity": "Menge",
                "lowest_price_competitors": "Niedrigster Mitbewerberpreis",
                "margin_percentage": "Marge",
                "market_score": "Rang",
                "number_of_cases": "Menge",
                "number_of_cases_available": "Verfügbarkeit",
                "portal_status": "Status",
                "price_age_in_days": "Preis Alter (Tage)",
                "price_per_case": "Preis pro Kiste",
                "product_name": "Produktname",
                "reference": "Referentie",
                "stock_age": "Alter des Bestands",
                "total_value": "Gesamtwert"
            }
        },
        "title": {
            "cases_available": "Anzahl der verfügbaren Fälle",
            "category": "Kategorie",
            "customs_status": "Zollrechtlicher Status",
            "flags": "Artikel Eigenschaften",
            "item_type": "Artikel Typ",
            "offer_hash": "Persönliche Angebote",
            "price_age": "Preis Alter",
            "properties": "Eigenschaften",
            "relation": "Relation",
            "relation_type": "Relationstyp",
            "stock_age": "Alter des Bestands"
        },
        "types": {
            "category": "Kategorien",
            "packaging": {
                "+ Mug": "+ Becher",
                "2 Glasses": "2 Gläser",
                "2 Golden Glasses": "2 goldene Gläser",
                "Aluminium Bottle": "Aluminiumflasche",
                "Bottle": "Flasche",
                "Can": "Kann",
                "Cannister": "Kanister",
                "Cradle": "Wiege",
                "Flask": "Flachmann",
                "Frosted Bottle": "Milchglasflasche",
                "GB + Jar of Cherries": "GB + Glas Kirschen",
                "GB with Cradle": "GB mit Wiege",
                "Giftbag": "Geschenktasche",
                "Giftbox": "Geschenkbox",
                "Gifttin": "Geschenkdose",
                "Leather GB": "Leder GB",
                "PET bottle": "PET-Flasche",
                "Pack": "Pack",
                "Porcelain Bottle": "Porzellanflasche",
                "Slightly dirty outercases": "Leicht verschmutzte Outercases",
                "Square Bottle": "Vierkantflasche",
                "Wooden Giftbox": "Geschenkbox aus Holz"
            },
            "products": "Produkte"
        },
        "units": {
            "%": "%",
            "cl": "cl",
            "cs": "cs",
            "day_one": "Tag",
            "day_other": "Tage"
        }
    },
    "forgot_password": {
        "confirmed": "Die E-Mail ist auf dem Weg. Bitte prüfe deinen Posteingang (und deinen Spam-Ordner) auf die E-Mail mit dem Link zum Zurücksetzen des Passworts.",
        "description": "Hast du dein Passwort vergessen? Gib unten deine E-Mail-Adresse ein, klicke auf die Schaltfläche und du erhältst eine E-Mail, mit der du dein Passwort zurücksetzen kannst.",
        "email": "Deine E-Mail Adresse",
        "email_help": "Die E-Mail-Adresse, die du zur Anmeldung verwendest",
        "start_flow": "E-Mail zum Zurücksetzen anfordern",
        "title": "Passwort vergessen"
    },
    "issues": {
        "button": {
            "label": {
                "select_image": "Bild auswählen",
                "submit_issue": "Thema einreichen",
                "take_snapshot": "Schnappschuss machen"
            }
        },
        "field": {
            "help": {
                "description": "Eine prägnante und vollständige Beschreibung des Problems",
                "media": "Ein Bildschirmfoto oder Video, das das Problem zeigt",
                "title": "Vier oder fünf Worte, die das Thema zusammenfassen",
                "url": "URL zur Ausgabeseite"
            },
            "label": {
                "description": "Beschreibung",
                "media": "Medien",
                "title": "Titel",
                "url": "URL"
            },
            "placeholder": {
                "description": "Eine knappe und vollständige Beschreibung des Problems, damit wir das Problem besser und schneller identifizieren können.",
                "media": "Strg-v zum Einfügen eines Bildes",
                "title": "Vier oder fünf Worte, die das Thema zusammenfassen",
                "url": "URL zur Ausgabeseite"
            }
        },
        "label": {
            "title": "Ein Problem melden",
            "title_submitted": "Vielen Dank für deinen Fehlerbericht!"
        },
        "support": {
            "help": {
                "template": "Die Vorlage, die für neue Gitlab-Themen verwendet wird"
            },
            "label": {
                "button_submit_issue": "Einstellungen speichern",
                "template": "Vorlage"
            },
            "placeholder": {
                "template": "Diese enthält eine klare Vorlage für optimale Fehlerberichte."
            }
        },
        "text": {
            "submitted": "Danke, dass du deinen Problembericht eingereicht hast! Du kannst den Status deines Problems in unserem <a href={{link}} target='_blank'>Issue Tracker</a> überprüfen. Füge gerne weitere Kommentare zu deinem Problem hinzu, entweder um den Kontext zu verdeutlichen oder wenn du zusätzliche Erklärungen möchtest. Wir machen Discover & Portal Schritt für Schritt besser, dank deiner Hilfe!"
        }
    },
    "landing": {
        "force_logout": "Tut mir leid, du musst dich neu anmelden...",
        "login": {
            "otp_code": "Sicherheits-Token",
            "otp_code_help": "Gib den von der App generierten Code hier ein",
            "step0_desc": "Verwende für den Zugang deine {{vendor}} Anmeldedaten. Solltest du dein Passwort vergessen haben, kannst du unten deine E-Mail-Adresse eingeben und auf den Link \"Passwort vergessen\" klicken.",
            "step0_slug": "Anmeldung in unserem Portal",
            "step1_desc": "Um dein Konto vor unbefugtem Zugriff zu schützen, ist eine zusätzliche Authentifizierungs-App erforderlich. Installiere eine der vorgeschlagenen Authentifizierungs-Apps auf deinem Mobiltelefon und scanne den QR-Code. Verwende den Token deines Telefons, um dein Gerät zu registrieren und dich einzuloggen. Bitte füge den Token in deiner App erneut hinzu, wenn während des Vorgangs etwas schief läuft. Immer noch kein Glück? Kontaktiere uns für Unterstützung.",
            "step1_slug": "Zusätzliche Sicherheit",
            "step2_desc": "Es wird ein zusätzliches Sicherheits-Token benötigt. Bitte suche in deiner Authenticator-App nach dem Token. Du hast den Zugang zu deiner Authenticator-App verloren? Kontaktiere uns für Hilfe.",
            "step2_slug": "Sicherheits-Token erforderlich",
            "subtitle": "Einloggen um fortzufahren...",
            "title": "Willkommen bei {{vendor}}!"
        }
    },
    "languages": {
        "de": "Deutsch",
        "en_gb": "Englisch",
        "es": "Spanisch",
        "fr": "Französisch",
        "it": "Italienisch",
        "nl": "Niederländisch"
    },
    "my_portal": {
        "label": "Mein Portal",
        "logout": "Abmelden",
        "settings": {
            "language": "Sprache",
            "language_help": "Von KI generiert; kann falschen Sprachgebrauch enthalten"
        },
        "tip": "Mein Portal - Nachrichten, Profil & Einstellungen"
    },
    "page": {
        "subtitle": {
            "forgot_password": "Setze dein Passwort zurück",
            "reset_password": "Bitte überprüfe dein neues Passwort noch einmal..."
        },
        "title": {
            "forgot_password": "Passwort vergessen",
            "login": "Willkommen bei {{vendor}}!",
            "login_slug": "Anmeldung",
            "registration": "Anmeldung",
            "reset_password": "Passwort zurücksetzen"
        }
    },
    "password_reset": {
        "confirmed": "Dein Passwort wurde zurückgesetzt",
        "description": {
            "new-account": "Nachdem du dein Passwort festgelegt hast, wirst du aufgefordert, dich einzuloggen.",
            "reset": "Nachdem du dein Passwort zurückgesetzt hast, wirst du aufgefordert, dich erneut anzumelden."
        },
        "email": "Deine E-Mail Adresse",
        "email_help": "Die E-Mail Adresse, die du für das Portal verwendest",
        "failed": "Anfrage zur Änderung deines Passworts fehlgeschlagen: \"{{reason}}\"",
        "new_password": "Neues Passwort",
        "new_password_help": "Dein bevorzugtes Passwort; überprüfe mit dem Auge, ob dein Passwort korrekt ist",
        "new_password_repeat": "Passwort wiederholen",
        "new_password_repeat_help": "Wiederhole dein Passwort",
        "reset_button": "Passwort aktualisieren",
        "title": {
            "new-account": "Passwort erstellen",
            "reset": "Passwort aktualisieren"
        }
    },
    "price": {
        "offer_until": "endet",
        "price_down": "Kürzlich ermäßigt von {{price}} {{currency}}"
    },
    "product": {
        "availability": {
            "eta": "Ankunft ~ {{period}}",
            "stock": "Vorrätig",
            "tbo": "Zu bestellen"
        }
    },
    "profile": {
        "email": "Deine E-Mail Adresse",
        "email_help": "Deine E-Mail-Adresse für die Anmeldung",
        "email_placeholder": "you@yourbusiness.com",
        "forgot_pw": "Hast du dein Passwort vergessen?",
        "login": "Einloggen",
        "password": "Dein Passwort",
        "password_help": "Das Passwort für {{vendor}}",
        "password_placeholder": "Psst, das ist ein Geheimnis!"
    },
    "rating": {
        "rate": {
            "description": "Dein Feedback wird uns helfen, unseren Service für dich zu verbessern",
            "placeholder": "Bitte lass uns wissen, wenn du Vorschläge hast, wie wir deine Bestellung verbessern können.",
            "sent": "Vielen Dank für dein Feedback!",
            "title": "Was hältst du von unserem Bestellprozess?"
        },
        "send.title": "Sende",
        "thanks": {
            "description": "Wir tun unser Bestes, um unseren Service so gut wie möglich zu machen. Wenn du weitere Fragen oder Anregungen hast, wende dich bitte an deinen Support Manager.",
            "notification": "Vielen Dank für dein Feedback!",
            "title": "Ich danke dir im Namen von {{team}}!"
        }
    },
    "sales_order_addition_types": {
        "discount": "Rabatt",
        "other": "Andere",
        "pallet": "Palette",
        "revenue": "Einnahmen",
        "transport": "transportieren",
        "voucher": "Gutschein",
        "waste_fund": "Waste Fund"
    },
    "settings": {
        "beta": {
            "active": "Beta-Modus aktiviert",
            "inactive": "Beta-Modus deaktiviert"
        }
    },
    "status": {
        "error": {
            "login": {
                "credentials_invalid": "Es gibt ein Problem mit deinen Anmeldedaten! Bitte überprüfe deine E-Mail-Adresse und dein Passwort."
            },
            "message": "Es ist ein unerwarteter Fehler aufgetreten; bitte versuche es später noch einmal (Referenz: {{message}})",
            "order": {
                "delivery_invalid": "Die Lieferbedingung ist nicht erfüllt"
            },
            "otp": {
                "invalid": "Das Authentifizierungs-Token ist ungültig; bitte überprüfe deine Authenticator-App.",
                "invalid_notify": "Dein Authentifizierungs-Token ist ungültig. Bitte überprüfe deine App auf den richtigen Code. Wenn das Problem weiterhin besteht, überprüfe, ob die Datums- und Zeiteinstellungen deines Geräts korrekt sind.",
                "length": "Der Authentifizierungs-Token besteht aus 6 Chiffren"
            },
            "unknown": "Es tut uns leid! Es ist ein unerwarteter Fehler aufgetreten. Wir gehen aktiv auf diese Art von Problemen ein. Bitte versuche es später noch einmal, um zu sehen, ob das Problem bereits gelöst wurde. Kontaktiere uns, wenn das Problem länger bestehen bleibt. Wir danken dir für deine Geduld."
        },
        "success": {
            "login": {
                "forgot_pw": "Eine E-Mail mit Anweisungen zum Zurücksetzen deines Passworts wird an {{email}} gesendet, wenn es sich um eine gültige E-Mail-Adresse handelt. Keine E-Mail? Überprüfe die E-Mail-Adresse und schaue in deinem Spam-Ordner nach. Bitte kontaktiere uns, wenn du immer noch keine E-Mail erhalten hast."
            },
            "redeem": {
                "verified": "Herzlichen Glückwunsch! Du hast den Code für erfolgreich verwendet: {{name}}!"
            }
        }
    },
    "support": {
        "contact": {
            "collapse": "Details zur Unterstützung umschalten",
            "email": "Kontakt E-Mail: {{contact}}",
            "mobile": "Kontakt mobil: {{contact}}",
            "phone": "Kontakttelefon: {{contact}}",
            "support_manager": "Support Manager",
            "team_etr": "Team A2BC",
            "team_msp": "Team Moving Spirits",
            "tech_support": "Tech Unterstütze"
        },
        "roles": {
            "default": "Support Manager",
            "sales_nl": "Händler Niederlande",
            "tech": "Technische Unterstützung"
        }
    },
    "system": {
        "incompatible": "Dein Browser erfüllt nicht die Mindestanforderungen; bitte aktualisiere deinen Browser zuerst ({{code}})",
        "reload_portal": "Portal neu laden",
        "scheme_change": "Ein aktuelles Update erfordert, dass du dich erneut anmeldest"
    },
    "unit": {
        "bottle_one": "Flasche",
        "bottle_other": "Flaschen",
        "case_one": "Fall",
        "case_other": "Fälle",
        "percentage": "%"
    },
    "validation": {
        "email": "Dies scheint eine ungültige Email-Adresse zu sein",
        "form": {
            "action_required": "Diese Felder erfordern Ihre Aufmerksamkeit:",
            "valid": "Das Formular scheint gültig zu sein"
        },
        "length": {
            "fixed_length": "Für dieses Feld sind genau {{fixed_length}} Zeichen erforderlich.",
            "max_length": "Dieses Feld benötigt nicht mehr als {{max_length}} Zeichen.",
            "min_length": "Für dieses Feld sind mindestens {{min_length}} Zeichen erforderlich."
        },
        "must_match": "Die Felder müssen übereinstimmen",
        "password": {
            "cannot_end_with_space": "Das Passwort darf nicht mit einem Leerzeichen enden",
            "min_length": "Passwort erfordert mindestens 9 Zeichen",
            "one_lower_case": "Das Passwort muss mindestens einen Kleinbuchstaben enthalten",
            "one_upper_case": "Das Passwort muss mindestens einen Großbuchstaben enthalten"
        },
        "required": "Dieses Feld ist erforderlich",
        "vat_id": {
            "country_incorrect": "Die USt-ID hat einen falschen Ländercode; erwartet: '{{expected}}'",
            "help_disabled": "Die Umsatzsteuer-ID wird für Nicht-EU-Länder nicht unterstützt: {{country}}",
            "help_validate": "Verwende die Schaltfläche \"Validieren\", um die Umsatzsteuer-ID des Landes zu überprüfen: {{country}}",
            "invalid_format": "Ungültiges <a href='{{link}}' target='_blank'>Format der Umsatzsteuer-ID</a> für das Land: {{country_code}}",
            "label": "UMSATZSTEUER-ID ({{country}})",
            "verify": "ID überprüfen",
            "vies_match": "Die Umsatzsteuer-ID stimmt überein: {{match}}",
            "vies_nomatch": "Diese Umsatzsteuer-ID scheint ungültig zu sein"
        },
        "website": "Dies ist keine gültige Website-Adresse"
    }
}