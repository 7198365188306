import m from 'mithril'
import {Changed, Icon, OfferItemAmount, Spinner} from '@bitstillery/common/components'
import {classes, get_route} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'
import {format_delivery_period} from '@bitstillery/common/lib/format'
import {to_specs} from '@bitstillery/common/lib/specs'
import {$t, api} from '@bitstillery/common/app'

import {DashboardWidget} from './widget'

import {$m, $s} from '@/app'
import {
    GetPortalOfferItemResponseOutput,
    GetPortalOfferItemsRequest,
    GetPortalOfferItemsResponseOutput,
    OfferItemFilterType,
} from '@/factserver_api/fact2server_api'

export interface ProductsWidgetAttrs {
    icon?: string
    name: string
    title: string
    all_url: string
    query: any
}

export class ProductsWidget extends MithrilTsxComponent<ProductsWidgetAttrs> {

    data = (() => {
        const query: GetPortalOfferItemsRequest = {
            alcohol_percentage: undefined,
            availabilities: [],
            category_artkeys: [],
            limit: 40,
            offer_hash: undefined,
            offer_item_type: OfferItemFilterType.NEW_ARRIVALS,
            offset: 0,
            packaging: [],
            refill_status: '',
            search_terms: [],
            sort_ascending: true,
            sort_by: 'entry_date',
            volume: undefined,
        }

        const items:GetPortalOfferItemResponseOutput[] = []

        return proxy({
            collection: {
                items,
                query,
                total: 0,
            },
            cycle: {
                offset: 0,
                speed: 5000,
            },
        })
    })()

    title: string
    all_url: string

    selectedItem: GetPortalOfferItemResponseOutput | null = null
    timer: ReturnType<typeof setTimeout>

    async load_products() {
        const {result, status_code, total} = await api.post<GetPortalOfferItemsResponseOutput>(
            'portal/offer-items', this.data.collection.query, true,
        )
        if (status_code === 404) {
            $s.portal.ready = false
            return
        }
        if (status_code > 299) {
            return
        }

        await Promise.all([
            $m.offer.load_product_photos(result.portal_offer_items.map((i) => i.artkey)),
            $m.offer.load_country_of_origin(result.portal_offer_items.map((i) => i.artkey)),
        ])
        this.data.collection.items = result.portal_offer_items
        this.data.collection.total = total
    }

    async oninit(vnode) {
        Object.assign(this.data.collection.query, vnode.attrs.query)
        this.timer = setInterval(() => {
            if (this.data.cycle.offset + 5 >= this.data.collection.items.length) {
                this.data.cycle.offset = 0
            } else {
                this.data.cycle.offset += 5
            }
        }, this.data.cycle.speed)
        this.load_products()
    }

    onremove() {
        clearInterval(this.timer)
    }

    view(vnode: m.Vnode<ProductsWidgetAttrs>) {
        return <DashboardWidget
            icon={vnode.attrs.icon}
            link={vnode.attrs.all_url}
            title={vnode.attrs.title}
            name={vnode.attrs.name}
        >
            {!this.data.collection.items.length ? (
                <div className="widget-loading placeholder-d">
                    <Spinner/>
                </div>
            ) : (
                <div className="widget-list">
                    {this.data.collection.items.slice(this.data.cycle.offset, this.data.cycle.offset + 5).map((offer_item) => {
                        const image_location = $m.offer.primary_photo(offer_item.artkey)
                        return (
                            <div
                                className={classes('widget-item product-widget-item', {
                                    'carousel-active': offer_item.artkey in $s.product_photos && $s.product_photos[offer_item.artkey].length,
                                })}
                                key={offer_item.artkey}
                                onclick={() => this.selectedItem = offer_item}
                            >
                                <div className="info">
                                    <div className="image" onclick={() => {
                                    // Not all products (e.g. TBO) have photos; don't open the carousel in that case.
                                        if (offer_item.artkey in $s.product_photos && $s.product_photos[offer_item.artkey].length) {
                                            $s.carousel.selection = offer_item.artkey
                                        }
                                    }}>
                                        {image_location ? <img className="image" src={image_location} /> : <Icon
                                            className="image-placeholder"
                                            name="bottlePlaceholder"
                                            type="unset"
                                        />}
                                    </div>

                                </div>
                                <div className="description">
                                    <div
                                        className="name"
                                        onclick={() => {
                                            m.route.set(get_route('/offers', {
                                                search: encodeURI(offer_item.product_name),
                                            }))
                                        }}>{offer_item.product_name}</div>
                                    <div className="specs">
                                        {to_specs(offer_item)}
                                    </div>
                                    {!!offer_item.delivery_period && (
                                        <div className="arriving">
                                            {`Arriving in ${format_delivery_period(offer_item.delivery_period)}`}
                                        </div>
                                    )}
                                </div>
                                <td className="price">
                                    {(() => {
                                        const is_discount = $m.offer.DISCOUNT_TYPES.includes(offer_item.offer_type)
                                        const offer_item_price = $m.offer.offer_item_price(offer_item)

                                        return (is_discount && offer_item_price.is_down) ? <Changed
                                            currency={offer_item.currency}
                                            current={offer_item_price.current}
                                            previous={offer_item_price.previous}
                                        /> : <OfferItemAmount
                                            currency={offer_item.currency}
                                            offer_item={offer_item}
                                            value={offer_item_price.current}
                                        />
                                    })()}
                                </td>
                            </div>
                        )
                    })}

                </div>
            )}
        </DashboardWidget>
    }
}

export class NewInStockWidget extends MithrilTsxComponent<any> {
    view(vnode:m.Vnode<any>) {
        return <ProductsWidget
            all_url={get_route('/offers', {
                offertype: 'NEW_ARRIVALS',
                sort_by: 'entry_date',
                sort_order: 'desc',
            })}
            icon="trolly"
            model={vnode.attrs.model}
            name="new-in-stock"
            query={{
                availabilities: ['stock'],
                offer_item_type: OfferItemFilterType.NEW_ARRIVALS,
            }}
            title={$t('offer.newest_arrivals')}

        />
    }
}

export class ArrivingSoonWidget extends MithrilTsxComponent<any> {
    view(vnode:m.Vnode<any>) {
        return <ProductsWidget
            all_url={get_route('/offers', {
                availability: 'purchase',
                offertype: 'NEW_ARRIVALS',
                sort_by: 'entry_date',
                sort_order: 'desc',
            })}
            icon="newArrivals"
            model={vnode.attrs.model}
            name="arriving-soon"
            query={{
                availabilities: ['purchase'],
                offer_item_type: OfferItemFilterType.NEW_ARRIVALS,
            }}
            title={$t('offer.arriving_soon')}

        />
    }
}

export class SpecialOffersWidget extends MithrilTsxComponent<any> {
    view(vnode:m.Vnode<any>) {
        return <ProductsWidget
            all_url={get_route('/offers', {offertype: 'SPECIALS'})}
            icon="specialOffers"
            model={vnode.attrs.model}
            name="special-offers"
            query={{
                offer_item_type: OfferItemFilterType.SPECIALS,
            }}
            title={$t('offer.special')}
        />
    }
}
