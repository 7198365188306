import * as Sentry from '@sentry/browser'
// Tree-shaking doesn't work correctly; import directly.
import {config, logger} from '@bitstillery/common/app'

// Suppress exceptions from tawk d.languageParser.
const ignored_exception_messages = [
    'null is not an object (evaluating \'d.languageParser\')',
    'TypeError: d is null',
    'TypeError: Cannot read property \'languageParser\' of null',
    'UnhandledRejection: Non-Error promise rejection captured with value', // error from sentry.browser
]

export class Telemetry {
    enabled = false

    initialize_sentry() {
        if (!config.sentry_dsn) {
            logger.info('Sentry telemetry disabled')
            return
        }

        logger.info('Sentry telemetry enabled')
        this.enabled = true
        Sentry.init({
            beforeSend: function(event, hint) {
                if (hint && hint.originalException && hint.originalException.message) {
                    if (ignored_exception_messages.find((i) => i.startsWith(hint.originalException.message))) {
                        return null
                    }
                }
                return event
            },
            dsn: config.sentry_dsn,
            environment: config.env,
            integrations: [
                Sentry.captureConsoleIntegration({
                    levels: ['warn', 'error'],
                }),
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            // Trace all page loads, reduce this when there is too much data
            // coming in to Sentry.
            tracesSampleRate: 1.0,

            replaysSessionSampleRate: 0.0,
            replaysOnErrorSampleRate: 1.0,

            normalizeDepth: 11,
            release: `${process.env.MSI_VERSION}`,
            dist: config.sentry_dist,

            tracePropagationTargets: [
                config.api_host,
                config.api_host_new,
            ],

        })
    }

    capture_exception(error: Error, context: unknown) {
        if (!this.enabled) {
            return
        }
        Sentry.captureException(error, context)
    }

    capture_message(message: string, context: unknown) {
        if (!this.enabled) {
            return
        }
        Sentry.captureMessage(message, context)
    }

    /** Set the user logged with the Sentry issues. Only the user artkey is logged for privacy reasons. */
    set_user(user) {
        if (!this.enabled || !user) {
            return
        }
        Sentry.setUser({
            id: user.artkey, // only report user_artkey in sentry.
        })
    }
}
