import m from 'mithril'
import {Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'
import {format_date} from '@bitstillery/common/lib/format'
import {$t} from '@bitstillery/common/app'

import {$s} from '@/app'
import {filters} from '@/components/pages/offers/offer_list'

export class IncentivePersonalOffer extends MithrilTsxComponent<any> {
    view(_vn:m.Vnode<any>) {
        if (!filters.offer_hash.selection || !$s.offers.personal.length) return
        const active_personal_offer = $s.offers.personal.find((i) => i.hash === filters.offer_hash.selection)

        return <div className={classes('c-incentive-personal-offer', {
            inactive: !active_personal_offer,
        })}>
            <Icon
                name="specialOffers"
                size="xl"
                type="unset"
            />
            <div className="personal-text">
                {(() => {
                    if (!active_personal_offer) {
                        return $t('offer.personal_not_found')
                    } else {
                        return [
                            <div>{active_personal_offer.title}</div>,
                            <div>{$t('offer.valid_until', {date: format_date(active_personal_offer.end_date)})}</div>,
                        ]
                    }
                })()}
            </div>
        </div>
    }
}
