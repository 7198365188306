import env from '@bitstillery/common/lib/env'
import EventEmitter from 'eventemitter3'
import i18next from 'i18next'
import Api from '@bitstillery/common/lib/api'
import Logger from '@bitstillery/common/lib/logger'
import {StateCommon} from '@bitstillery/common/lib/state'
import {Telemetry} from '@bitstillery/common/lib/telemetry'
import {Notifier} from '@bitstillery/common/components'
import {Store} from '@bitstillery/common/lib/store'
import isBetween from 'dayjs/esm/plugin/isBetween/index.js'
import localizedFormat from 'dayjs/esm/plugin/localizedFormat/index.js'
import dayjs from 'dayjs'
import {proxy_stats} from '@bitstillery/common/lib/proxy'
import {supported_languages} from '@bitstillery/common/lib/i18n'
import {View} from '@bitstillery/common/types'
import {models} from '@bitstillery/common/models'

// And some legacy globals...
dayjs.extend(isBetween)
dayjs.extend(localizedFormat)
globalThis.dayjs = dayjs

i18next.t._ = (key, context) => {
    return () => {
        return i18next.t(key, context)
    }
}

// These are filled in when the App is initialized;
// these properties can not be accessed before that
// happened.
export const config = {}
export const $m = models

export const api = new Api()
export const events = new EventEmitter()
export const notifier = new Notifier()
export const logger = new Logger()
export const store = new Store()

export const $s = store.state as StateCommon
export const $t = i18next.t
export const telemetry = new Telemetry()
export const view:View = {
    filters: null,
}

export class App {

    proxy_stats = proxy_stats
    store: Store

    init(app_config, models, store, i18n_resources) {
        Object.assign(config, app_config)
        Object.assign($m, models)
        notifier.load($s.notifications)
        env($s.env)

        telemetry.initialize_sentry()

        // This is a safehatch that allows us to reset a user's
        // localStorage by bumping the scheme version in cli/settings.js
        const version = process.env.MSI_PACKAGE === 'discover' ? process.env.MSI_SCHEME_DISCOVER : process.env.MSI_SCHEME_PORTAL
        if ($s.scheme.version !== version) {
            store.set('store', {identity: $s.identity, scheme: {changed: true, version}})
            window.location.reload()
            return
        }

        if (process.env.NODE_ENV === 'development' || $s.settings.beta) {
            logger.set_level('debug')
        } else {
            logger.set_level('info')
        }

        if ($s.settings.beta) {
            // developer debugging in the console
            Object.assign(globalThis, {$s, app})
        }

        this.init_language(i18n_resources)

        // Reload was done;
        if ($s.scheme.changed) {
            notifier.notify($t('system.scheme_change'), 'warning', 10000, 'info')
            $s.scheme.changed = false
            store.save()
        }

        for (const model of Object.values(models)) {
            if (model.bind) {
                model.bind()
            }
        }

        for (const model of Object.values(models)) {
            if (model.init) {
                model.init()
            }
        }
    }

    init_language(i18n_resources) {
        let hostname = ''
        if (window.location === window.parent.location) { // is code running in iframe?
            hostname = window.location.hostname
        } else {
            try {
                hostname = new URL(document.referrer).hostname // iframe, take url from parent
            } catch {
                // ignore
            }
        }

        const supported_language_codes = supported_languages.map((option) => option[0])

        const profile_language = $s.identity.user.language
        const selected_language = $s.language
        // We use en-GB; resolve 'en' to this language flavo(u)r.
        const navigator_language = navigator.language === 'en' ? 'en-GB' : navigator.language
        const top_level_domain = hostname.split('.').pop()?.toLocaleLowerCase()

        if (profile_language && supported_language_codes.includes(profile_language)) {
            $s.language = profile_language
        } else if (selected_language && supported_language_codes.includes(selected_language)) {
            $s.language = selected_language
        } else if (navigator_language && supported_language_codes.includes(navigator_language)) {
            $s.language = navigator_language
        } else if (top_level_domain && supported_language_codes.includes(top_level_domain)) {
            $s.language = top_level_domain
        } else {
            $s.language = 'en-GB'
        }

        i18next.init({
            debug: process.env.NODE_ENV === 'development',
            fallbackLng: 'en-GB',
            lng: $s.language,
            resources: i18n_resources,
        })
    }
}
