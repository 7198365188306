import m from 'mithril'
import {Button, Icon, Issues} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {NotificationGroup} from '@bitstillery/common/components'
import {get_support} from '@bitstillery/common/lib/support'
import {$t} from '@bitstillery/common/app'
import {$s, store} from '@bitstillery/common/app'

export class Support extends MithrilTsxComponent<any> {

    support: any

    oninit() {
        // The idea of not using $s.support.manager directly, is that
        // this component could also be used without a support manager,
        // although currently landing.tsx uses custom code for this.
        this.support = get_support()
    }

    toggle_support(e) {
        e.stopPropagation()
        $s.support.collapsed = !$s.support.collapsed
        store.save()
    }

    view() {
        const show_news = $s.identity.user.id && $s.news?.id && ($s.news.seen !== $s.news.id)
        return (
            <div className={classes('c-support', {
                collapsed: $s.panels.left.collapsed || ['mobile', 'tablet'].includes($s.env.layout),
                'collapsed-support': $s.support.collapsed,
                toggleable: $s.env.layout === 'desktop',
            })}>
                {$s.support.collapsed && <Icon
                    className="support-icon"
                    name={'support'}
                    onclick={(e) => this.toggle_support(e)}
                    size="l"
                    tip={$t._('support.contact.collapse')}
                    type="default"
                />}
                <div className="manager-contact-options">
                    <Button
                        icon='email'
                        onclick={() => {window.location.href = `mailto:${this.support.email}`}}
                        tip={$t._('support.contact.email', {contact: this.support.email})}
                        type="info"
                        variant="toggle"
                    />

                    {process.env.MSI_PACKAGE === 'discover' && <Button
                        className="btn-expand"
                        icon="bug"
                        tip={$t('issues.label.title')}
                        type="info"
                        variant="toggle"
                        onclick={() => {
                            $s.issues.collapsed = !$s.issues.collapsed
                        }}
                    />}

                    <Issues/>

                    {process.env.MSI_PACKAGE === 'portal' ? <Button
                        icon='phone'
                        onclick={() => window.location.href = `tel:${this.support.phone}`}
                        tip={$t._('support.contact.phone', {contact: this.support.phone})}
                        type="info"
                        variant="toggle"
                    /> : <Issues/>}

                    {this.support.mobile && <Button
                        icon='cellPhone'
                        onclick={() => window.location.href = `tel:${this.support.mobile}`}
                        tip={$t._('support.contact.mobile', {contact: this.support.mobile})}
                        type="info"
                        variant="toggle"
                    />}
                </div>

                <div
                    className="manager-title"
                    onclick={(e) => this.toggle_support(e)}
                >
                    {(!$s.support.collapsed && $s.env.layout !== 'mobile') && <Icon
                        name={'chevronDown'}
                        size="s"
                        tip={$t._('support.contact.collapse')}
                        type="info"
                    />}
                    {process.env.MSI_PACKAGE === 'portal' ? $t('support.contact.support_manager') : $t('support.contact.tech_support')}
                </div>
                <div
                    className="manager-contact"
                    onclick={() => {
                        if (!$s.env.layout === 'desktop') return
                        $s.panels.left.collapsed = !$s.panels.left.collapsed
                        store.save()
                    }}
                    style={{'background-image': this.support.img ? `url(${this.support.img})` : ''}}
                />
                <div className="manager-name">{this.support.name}</div>
                {<NotificationGroup notifications={$s.notifications}>
                    {show_news && <div
                        className="notification news"
                        onclick={() => {
                            m.route.set(`/news/?key=${Date.now()}`)
                        }}>
                        <Icon className="notification-icon icon-xl" name={$s.news.icon}/>
                        <div className="message">
                            <div className="title">{$t('notifications.news.title')}</div>
                            <div class="body">
                                {$s.news.header}
                            </div>
                        </div>
                        <Button
                            className="btn-close"
                            icon="close"
                            type="info"
                            onclick={(e) => {
                                e.stopPropagation()
                                $s.news.seen = $s.news.id
                                store.save()
                            }}
                        />
                    </div>}
                </NotificationGroup>}
            </div>
        )
    }
}
