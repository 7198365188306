import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {Button} from '../button/button'

export interface DialogAttrs {
    className?: string
    footer?: string
    onclose?: () => any
    loading?: boolean
    type: 'info' | 'default' | 'warning' | 'danger'
    title?: string
}

export class Dialog extends MithrilTsxComponent<DialogAttrs> {

    is_hidden = true
    onclose?: () => unknown
    escape_key_listener: (any) => void

    constructor(vnode: m.Vnode<DialogAttrs>) {
        super()
        this.onclose = vnode.attrs.onclose
    }

    oncreate() {
        function listener(e) {
            if (!this.is_hidden && e.keyCode === 27 && this.onclose) {
                this.onclose()
                m.redraw()
            }
        }

        this.escape_key_listener = listener.bind(this)
        document.body.addEventListener('keyup', this.escape_key_listener)
    }

    onremove() {
        document.body.removeEventListener('keyup', this.escape_key_listener)
    }

    view(vnode: m.Vnode<DialogAttrs>) {
        this.is_hidden = vnode.attrs.className?.includes('hidden') || false
        return <div
            className={classes('c-dialog', vnode.attrs.className, `type-${vnode.attrs.type ? vnode.attrs.type : 'info'}`)}
            onmouseup={(e) => {
                e.stopPropagation()
                if (!vnode.attrs.loading && vnode.attrs.onclose) {
                    vnode.attrs.onclose()
                }
            }}>
            <div className="dialog" onmouseup={(e) => e.stopPropagation()}>
                <div class="title">
                    <div className="title-text">{vnode.attrs.title}</div>
                    {vnode.attrs.onclose && <Button
                        disabled={vnode.attrs.loading || !vnode.attrs.onclose}
                        icon="close"
                        onclick={() => {
                            if (vnode.attrs.onclose) {
                                vnode.attrs.onclose()
                            }
                        }}
                        size="s"
                        type={vnode.attrs.type ? vnode.attrs.type : 'default'}
                        variant="toggle"
                    />}
                </div>
                <div className="body">
                    {vnode.children}
                </div>

                {vnode.attrs.footer && <div className="footer">{vnode.attrs.footer}</div>}
            </div>
        </div>
    }
}
