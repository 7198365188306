import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    active_filters,
    clear_filter,
    reset_filters,
    url_to_filters,
} from '@bitstillery/common/lib/filters'
import {classes, debounce, get_route, unique_id} from '@bitstillery/common/lib/utils'
import {watch} from '@bitstillery/common/lib/store'
import {CollectionInlineField, FieldCheckbox, Icon} from '@bitstillery/common/components'
import {filters_to_url} from '@bitstillery/common/lib/filters'
import {grid_columns} from '@bitstillery/common/lib/collection'
import {Filters} from '@bitstillery/common/types'
import m from 'mithril'
import {$s, $t, events, logger, view} from '@bitstillery/common/app'

interface CollectionItemsAttrs {
    /** Specify a different scroll container */
    scroll_container?: string
    /** Set the context when you define the columns elsewhere, and want to access the CollectionItems context elsewhere */
    context: any
    collection: any
    /** A description of how to render each row's columns and the collection header */
    columns?: any[]
    /** Whether the row has a checkbox for selection */
    has_selection?: boolean
    /** A custom row renderer can be defined here */
    item?: Function
    /** CollectionItems can be used without a collection; use the items attribute instead then */
    items?: any[]
    /** Add custom behavior when clicking a row */
    on_row_click?: Function
    /** The row action buttons shown on hovering a row */
    row_actions?: Function
    /** Extra context space beneath the row, opened after clicking a row */
    row_detail?: Function
    /** Row status icons at the end of a row */
    row_status?: Function
}

export class CollectionItems extends MithrilTsxComponent<CollectionItemsAttrs> {

    debounced_cancel = false
    id = `collection-items-${unique_id()}`
    has_row_status = false
    scroll_observer: IntersectionObserver | null
    watchers = [] as any
    container: HTMLElement

    oninit(vnode:m.Vnode<CollectionItemsAttrs>) {
        if (!vnode.attrs.collection) {
            throw new Error('CollectionItems component misses a collection')
        }

        if (vnode.attrs.collection.state.ready) {
            this.oncollectionready(vnode, vnode.attrs.collection)
        } else {
            let unwatch = watch(vnode.attrs.collection.state, 'ready', () => {
                this.oncollectionready(vnode, vnode.attrs.collection)
                unwatch()
            }) as Function
        }

        // Row status is a small column at the right that contains a few indicators.
        if (vnode.attrs.row_status && vnode.attrs.columns) {
            this.has_row_status = true
            // The columns are initialized at the top level and reused;
            // a cell-status column only needs to be added once.
            if (!vnode.attrs.columns.find((i) => i.className === 'cell-status')) {

                vnode.attrs.columns.push({
                    className: 'cell-status',
                    name: '',
                    width: 'var(--coll-status-width)',
                })
            }
        }
    }

    async oncreate(vnode:m.Vnode<CollectionItemsAttrs>) {
        this.setup_scroll_observer(vnode)

        events.on('collection:scroll_to_edit_row', () => {
            const selected_element = document.querySelector('.edit')
            if (selected_element) {
                selected_element.scrollIntoView({behavior: 'smooth', block: 'center'})
            }
        })
    }

    setup_scroll_observer(vnode: m.Vnode<CollectionItemsAttrs>) {
        // No collection, no infinite scrolling...
        const collection = vnode.attrs.collection

        if (vnode.attrs.scroll_container) {
            this.container = document.querySelector(vnode.attrs.scroll_container) as HTMLElement
            if (!this.container) {
                throw new Error(`CollectionItems component misses a DOM scrolling container (selector: ${vnode.attrs.scroll_container})`)
            }
        } else {
            this.container = document.querySelector(`#${this.id}`)?.closest('.view') as HTMLElement
        }

        if (this.scroll_observer) {
            this.scroll_observer.disconnect()
        }

        this.scroll_observer = new IntersectionObserver(async(entries) => {
            if (
                (collection && collection.state.loading) ||
                !vnode.attrs.collection.state.ready ||
                entries[0].intersectionRatio <= 0
            ) {
                return
            }
            collection.state.query.offset += collection.state.query.page_size
            const scroll_height = this.container.scrollHeight
            if (collection.state.has_items) {
                await collection.query()
                this.container.scrollTo({
                    behavior: 'smooth',
                    top: scroll_height,
                })
            }

        }, {
            root: this.container,
            rootMargin: '8px',
        })

        const observable_element = document.querySelector(`#${this.id} + .loading-observable`) as HTMLElement
        if (observable_element) {
            this.scroll_observer.observe(observable_element)
        }
    }

    oncollectionready(vnode, collection) {
        const filters = collection.filters
        view.filters = filters

        collection.state.loading = true
        collection.state.query.offset = 0
        collection.state.selection.ids.splice(0, collection.state.selection.ids.length)

        this.watchers.push(watch(collection.state.selection, 'all', this.watch_select_all.bind(this, vnode)))
        this.watchers.push(watch(collection.state.selection, 'mode', this.watch_select_mode.bind(this, vnode)))
        this.watchers.push(watch(collection.state.sort, this.watch_sort.bind(this, vnode)))
        this.watchers.push(watch(collection.state, 'bulk_mode', this.watch_bulk_mode.bind(this, vnode)))

        if (filters) {
            logger.debug(`[collection-items] setup watchers (${collection.state.endpoint.path})`)
            url_to_filters(filters, collection)

            // Watch filter data.
            for (const filter of Object.values(filters)) {
                if (['boolean', 'number', 'string'].includes(typeof filter.selection)) {
                    this.watchers.push(watch(filter, 'selection', this.watch_filters.bind(this, vnode)))
                } else {
                    this.watchers.push(watch(filter.selection, this.watch_filters.bind(this, vnode)))
                }
            }
        }

        collection.query()
    }

    onremove(vnode:m.Vnode<CollectionItemsAttrs>) {
        // No collection, no need to cleanup filters, scroll handlers & state.
        if (!vnode.attrs.collection) return
        view.filters = null
        // Cancel any query that may be triggered while cleaning up.
        vnode.attrs.collection.state.query.offset = 0
        vnode.attrs.collection.state.items.splice(0, vnode.attrs.collection.state.items.length)

        this.debounced_cancel = true
        this.watchers.map((unwatch) => unwatch())

        if (this.scroll_observer) {
            this.scroll_observer.disconnect()
        }

        if (vnode.attrs.collection.filters) {
            reset_filters(vnode.attrs.collection.filters)
        }

        // (!) Make sure to disable the collection again, so
        // the collection items view can wait for the next
        // collection.init to be called.
        vnode.attrs.collection.state.ready = false
        events.off('collection:scroll_to_edit_row')
    }

    view(vnode:m.Vnode<CollectionItemsAttrs>) {
        const loading = vnode.attrs.collection ? vnode.attrs.collection.state.loading : false
        const items = vnode.attrs.collection.state.items
        let has_selection = vnode.attrs.has_selection

        if (vnode.attrs.collection) {
            const selection = vnode.attrs.collection.state.selection
            has_selection = ['select', 'deselect'].includes(selection.mode)
        }

        return [
            <div
                className={classes('c-collection-items', {
                    'has-row-click': !!vnode.attrs.on_row_click,
                    'has-row-detail': 'row_detail' in vnode.attrs,
                    'has-row-status': this.has_row_status,
                })}
                id={this.id}
            >

                {(!items.length && !loading) && (() => {
                    let filters_active = false
                    if (vnode.attrs.collection) {
                        filters_active = active_filters(vnode.attrs.collection.filters) > 0
                    }
                    return <div className="empty-results">
                        <div className="not-found">
                            <Icon name="notfound" />
                            <span>{$t('collection.no_results')}</span>
                        </div>
                        {!!filters_active && <div className="suggest mt-1">
                            <Icon name="filterRemove" onclick={() => {
                                reset_filters(vnode.attrs.collection.filters)
                            }}/>
                            <span>{$t('collection.no_results_clear_filters')}</span>
                        </div>}
                    </div>
                })()}

                {(() => {
                    return items.map((item:any) => {
                        let row_status:any
                        if (this.has_row_status) {
                            row_status = vnode.attrs.row_status(item)
                        }

                        if (vnode.attrs.item) {
                            // Use a custom renderer...
                            return vnode.attrs.item(item)
                        }

                        if (!vnode.attrs.columns) {
                            throw new Error('item or column renderer is required')
                        }

                        const grid_template_cols = grid_columns(vnode.attrs.columns, has_selection)

                        return <div
                            key={item.artkey}
                            className={classes('item', this.has_row_status ? `item-type-${row_status.type}` : 'item-type-default', {
                                detail: vnode.attrs.collection && (vnode.attrs.collection.state.detail === item.artkey),
                                edit: $s.context.id === item.artkey,
                            })}
                            onclick={() => {
                                if (vnode.attrs.collection && 'row_detail' in vnode.attrs) {
                                    if (vnode.attrs.collection.state.detail !== item.artkey) {
                                        vnode.attrs.collection.state.detail = item.artkey
                                    } else {
                                        vnode.attrs.collection.state.detail = null
                                    }
                                }
                                if (vnode.attrs.on_row_click) {
                                    vnode.attrs.on_row_click(item)
                                }
                            }}
                        >
                            <div
                                className='cells'
                                style={`grid-template-columns: ${grid_template_cols}`}
                            >
                                <div className="cell-selection">
                                    {has_selection && <FieldCheckbox
                                        disabled={vnode.attrs.collection.state.loading}
                                        onAfterChange={(newValue) => {
                                            if (!newValue) {
                                                vnode.attrs.collection.item_select(item)
                                            } else {
                                                vnode.attrs.collection.item_deselect(item)
                                            }
                                        }}
                                        computed={() => {
                                            if (vnode.attrs.collection.state.selection.all) {
                                                return !vnode.attrs.collection.state.selection.ids.includes(item.artkey)
                                            } else {
                                                return (
                                                    vnode.attrs.collection.state.selection.ids.includes(item.artkey)
                                                )
                                            }
                                        }}
                                    />}
                                </div>

                                {vnode.attrs.columns.map((column, index) => {
                                    const is_last_column = index === vnode.attrs.columns.length - 1
                                    let rendered_row
                                    if (vnode.attrs.collection.state.bulk_mode === 'edit' || $s.context.id === item.artkey) {
                                        const bulk_data = vnode.attrs.collection.bulk
                                        if (column.id && column.id in bulk_data.fields) {
                                            // Single-edit without bulk enabled (using RowActionEdit mode="inline")
                                            // reuses the bulk logic, but only shows one editable field instead.
                                            return <CollectionInlineField
                                                column_id={column.id}
                                                collection={vnode.attrs.collection}
                                                field={bulk_data.fields[column.id]}
                                                item={item}
                                            />
                                        }
                                    }
                                    if (this.has_row_status && is_last_column) {
                                        rendered_row = row_status.render
                                    } else {
                                        rendered_row = column.render(item, vnode.attrs.context)
                                    }
                                    return <div className={classes('cell', column.className)}>
                                        {rendered_row}
                                    </div>
                                })}

                                {vnode.attrs.row_actions && <div className='cell cell-actions'>{vnode.attrs.row_actions(item, vnode.attrs.context)}</div>}
                            </div>

                            {((vnode.attrs.collection && vnode.attrs.collection.state.detail === item.artkey) && vnode.attrs.row_detail) && <div className="row-details" onclick={(e) => e.stopPropagation()}>
                                {vnode.attrs.row_detail(item)}
                            </div>}
                        </div>
                    })
                })()}
            </div>,
            <div class="loading-observable"></div>,
        ]
    }

    watch_bulk_mode = debounce(100, async function(vnode) {
        // If bulk mode is enabled, we need to fetch the bulk data.
        if (vnode.attrs.collection.state.bulk_mode) {
            for (const filter of Object.values(vnode.attrs.collection.filters as Filters)) {
                filter.disabled = true
            }
        } else {
            for (const filter of Object.values(vnode.attrs.collection.filters as Filters)) {
                filter.disabled = false
            }
        }
    })

    watch_filters = debounce(100, async function(vnode) {
        // Last minute filter value check; a filter may get a value
        // which results in resetting a filter(e.g. range filters).
        // This can be done from here.
        for (const filter of Object.values(vnode.attrs.collection.filters as Filters)) {
            // A range filter that needs to be reset.
            if ('min' in filter && filter.selection.length) {
                if (filter.selection[0] === filter.min && (filter.selection[1] === filter.max || filter.selection[1] === Infinity)) {
                    clear_filter(filter)
                }
            }
        }
        if (!this.debounced_cancel) {
            vnode.attrs.collection.state.query.offset = 0
            const {path} = m.parsePathname(m.route.get()) as any

            // Reflect the active filter state in the url; include also
            // sort_by and sort_order when they are part of the params.
            const exclude_params = ['meta'] as any
            const route = get_route(path, filters_to_url(vnode.attrs.collection.filters), false, exclude_params)
            m.route.set(route, {}, {replace: true})
            this.container.scrollTo({
                behavior: 'auto',
                top: 1,
            })
            vnode.attrs.collection.query()
        }
    })

    watch_select_all(vnode) {
        const selection = vnode.attrs.collection.state.selection
        if (selection.current) {
            // There is a list of the current selected items from the backend
            if (selection.all) {
                selection.ids.splice(0, selection.ids.length)
            } else {
                selection.ids.splice(0, selection.ids.length, ...selection.current)
            }
        } else {
            selection.ids.splice(0, selection.ids.length)
        }
    }

    watch_select_mode(vnode) {
        const selection = vnode.attrs.collection.state.selection
        if (vnode.attrs.on_selection_mode_change) {
            vnode.attrs.on_selection_mode_change(selection.mode)
        }
    }

    watch_sort = debounce(100, async function(vnode) {
        if (!this.debounced_cancel) {
            const defaults = vnode.attrs.collection.state.sort.defaults
            const sort = vnode.attrs.collection.state.sort
            // Reset the query offset; so all results are replaced after
            // changing the sort direction.
            vnode.attrs.collection.state.query.offset = 0
            if (defaults.sort_by !== sort.by || defaults.order !== sort.order) {
                const exclude_params = ['meta'] as any
                const {path} = m.parsePathname(m.route.get()) as any
                const route = get_route(path, {...filters_to_url(vnode.attrs.collection.filters),
                    sort_by: sort.by,
                    sort_order: sort.order,
                }, false, exclude_params)
                m.route.set(route, {}, {replace: true})
                this.container.scrollTo({
                    behavior: 'auto',
                    top: 1,
                })
                vnode.attrs.collection.query()
            }
        }
    })
}
