import m from 'mithril'
import {next_tick} from '@bitstillery/common/lib/proxy'
import {config, events, logger} from '@bitstillery/common/app'

import {$s} from '@/app'

window.Tawk_API = window.Tawk_API || {ready: false}
window.Tawk_API.onLoad = function() {
    // Start by always hiding the Tawk widget.
    window.Tawk_API.hideWidget()

    if (!$s.identity.token || !$s.identity.user || !$s.identity.user.name) {
        events.on('identity.login', () => {
            logger.debug('set tawk visitor')
            set_visitor_name()
        })
    } else {
        set_visitor_name()
    }
}
window.TawkLoadStart = new Date()

async function set_visitor_name() {
    const visitor = {
        email: $s.identity.user.email_address,
        hash: $s.identity.user.tawk_hash,
        name: $s.identity.user.name,
    }

    await next_tick()
    window.Tawk_API.setAttributes(visitor, (err) => {
        if (err) {
            logger.exception('Error setting Tawk visitor name', {
                extra: {
                    exception: err,
                },
            })
        }
        // At this point, the onupdate/oncreate layout lifecycle hooks
        // take over the Tawk widget visibility toggling.
        window.Tawk_API.ready = true

        // Conditionally show the Tawk widget initially:
        const route = m.route.get()
        if (!(route.startsWith('/registration') || route.startsWith('/login'))) {
            toggle_tawk(true)
        }
    })
}

/**
 * Initialises the tawk widget. It is only loaded on production and if
 * the user is logged in. The script is dynamically loaded with the tawk_id.
 * If the script is loaded, a callback is registered on the onLoad of the
 * tawk api. This callback invokes the method set_visitor_name that will,
 * set the visitor name.
 */
export function init_tawk() {
    if (!config.tawk_id) {
        logger.info('Tawk disabled')
        return
    }
    const s1 = document.createElement('script')
    const s0 = document.getElementsByTagName('script')[0]
    s1.async = true
    s1.src = `https://embed.tawk.to/${config.tawk_id}/default`
    s1.setAttribute('crossorigin', '*')
    s0.parentNode?.insertBefore(s1, s0)

}

export function toggle_tawk(visible) {
    if (!window.Tawk_API.ready) return

    if (visible) {
        if (window.Tawk_API.isChatHidden()) {
            logger.debug('show Tawk widget')
            window.Tawk_API.showWidget()
        }
    } else {
        if (!window.Tawk_API.isChatHidden()) {
            logger.debug('hide Tawk widget')
            window.Tawk_API.hideWidget()
        }
    }
}
