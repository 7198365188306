import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {unique_id} from '@bitstillery/common/lib/utils'
import {Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'
import {modelref_adapter} from '@bitstillery/common/lib/store'

// Navigation keys are keys that change the selection of the suggestion dropdown.
const NAVIGATION_KEYS = ['ArrowDown', 'ArrowUp', 'PageDown', 'PageUp']

export interface FieldTextAttrs {
    label: string
    placeholder?: string
    disabled?: boolean
    ref: [{}, string]
    help?: string

    validation: any
    className: any

    autocomplete: boolean
    autofocus: boolean
    icon?: string
    id?: string
    onafterupdate?: (value) => unknown
    submit?: (e) => unknown
    /** Autocompletes while typing; show a dropdown of items that can be selected from */
    suggestions: Function
    type?: 'currency' | 'number' | 'password' | 'text' | 'date'
}

export class FieldText extends MithrilTsxComponent<FieldTextAttrs> {

    $suggestion_container: HTMLElement | null
    data = proxy({
        password: {
            hide: true,
        },
        suggestions: {
            options: [],
            selected: null,
        },
    })

    name = unique_id()

    ref:any

    /**
     * Keep this around while this component is being used
     * together with the old props store.
     * @param vnode
     * @param value
     */
    assign_modelvalue(vnode, modelref, modelvalue) {
        if (typeof modelref[0][modelref[1]] === 'function') {
            modelref[0][modelref[1]](this.format_value(vnode, modelvalue))
        } else {
            modelref[0][modelref[1]] = this.format_value(vnode, modelvalue)
        }
    }

    format_value(vnode, value) {
        if (vnode.attrs.type === 'currency') {
            if (value === '') return ''
            if (/^-?(?!0\d)\d+(\.\d{0,2})?$/.test(value)) {
                return value
            }
            return null
        } else if (vnode.attrs.type === 'date') {

            if (!value) return null
            // The parsed value must always be formatted as "yyyy-mm-dd"
            // The displayed date format will differ from the actual value.
            return new Date(value).toLocaleDateString('en-CA')
        } else if (vnode.attrs.type === 'number') {
            // If the value is empty, return null.
            if (value === '' || value === null) {
                return null
            }

            value = Number(value)
            if (isNaN((value))) {
                return null
            } else {
                // Make sure no values outside the expected
                // boundaries are applied to the model.
                if (typeof vnode.attrs.max === 'number' && value > vnode.attrs.max) {
                    return vnode.attrs.max
                } else if (typeof vnode.attrs.min === 'number' && (value < vnode.attrs.min)) {
                    return vnode.attrs.min
                }
            }
            return value
        }

        return value
    }

    navigate_keyboard(vnode, e) {
        if (!vnode.attrs.suggestions) {
            if (vnode.attrs.type === 'currency') {
                const {modelvalue, modelref} = modelref_adapter(vnode.attrs.ref)
                const step_size = isNaN(Number(vnode.attrs.step)) ? 0.1 : Number(vnode.attrs.step)
                if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
                    e.preventDefault()
                    let new_value
                    if (e.key === 'ArrowDown') {
                        new_value = String(parseFloat((Number(modelvalue) - step_size).toFixed(2)))
                    } else if (e.key === 'ArrowUp') {
                        new_value = String(parseFloat((Number(modelvalue) + step_size).toFixed(2)))
                    }
                    // New value must always have two decimals.
                    if (new_value.includes('.')) {
                        new_value = `${this.format_value(vnode, new_value)}0`
                    }

                    if ('min' in vnode.attrs && new_value >= vnode.attrs.min) {
                        this.assign_modelvalue(vnode, modelref, new_value)
                    }
                }
            }
            return
        }
        if (e.key === 'Backspace') {
            // Backspace deselects any selected suggestion
            this.data.suggestions.selected = null
            return
        } else if (e.key === 'Enter') {
            // Enter is handled in onkeypress; the key must not influence the current selection.
            return
        }

        if (!this.data.suggestions.options.length) {
            this.data.suggestions.selected = null
            return
        }

        if (!NAVIGATION_KEYS.includes(e.key) || ['ArrowLeft', 'ArrowRight'].includes(e.key)) {
            return
        }

        if (this.data.suggestions.selected === null) {
            // Navigating the cursor in the input has no influence on the selected suggestion.
            this.data.suggestions.selected = 0
        } else {
            if (e.key === 'ArrowDown') {
                if (this.data.suggestions.selected < this.data.suggestions.options.length - 1) {
                    this.data.suggestions.selected += 1
                }
            } else if (e.key === 'ArrowUp') {
                if (this.data.suggestions.selected > 0) {
                    this.data.suggestions.selected -= 1
                } else {
                    this.data.suggestions.selected = 0
                }
            } else if (e.key === 'PageDown') {
                if (this.data.suggestions.selected < this.data.suggestions.options.length - 5) {
                    this.data.suggestions.selected += 5
                } else {
                    this.data.suggestions.selected = this.data.suggestions.options.length - 1
                }
            } else if (e.key === 'PageUp') {
                if (this.data.suggestions.selected > 4) {
                    this.data.suggestions.selected -= 5
                } else {
                    this.data.suggestions.selected = 0
                }
            }
        }

        // Apply the updated selection to the DOM; scroll to the selected option.
        let selected_option = this.$suggestion_container?.children[this.data.suggestions.selected]
        if (!selected_option && this.$suggestion_container?.children.length) {
            selected_option = this.$suggestion_container.children[0]
        }

        if (selected_option) {
            this.$suggestion_container.scrollTo({
                top: selected_option.offsetTop - this.$suggestion_container.clientHeight / 2 + selected_option.clientHeight / 2,
                behavior: 'smooth',
            })
        }
    }

    oncreate(vnode) {
        const {modelvalue, modelref} = modelref_adapter(vnode.attrs.ref)
        this.assign_modelvalue(vnode, modelref, modelvalue)

        if (vnode.attrs.suggestions) {
            this.$suggestion_container = vnode.dom.querySelector('.suggestions') as HTMLElement
        }
    }

    view(vnode: m.Vnode<FieldTextAttrs>) {
        if (!vnode.attrs.ref[0]) return
        const {modelvalue, modelref} = modelref_adapter(vnode.attrs.ref)
        const validation = vnode.attrs.validation

        if (vnode.attrs.validation && modelvalue) {
            vnode.attrs.validation.dirty = true
        }

        const invalid = validation ? validation._invalid : false
        const disabled = vnode.attrs.disabled

        return <div className={classes('c-field-text', 'field', vnode.attrs.className, {
            disabled: disabled,
            invalid: validation && invalid && validation.dirty,
            valid: validation && !invalid && validation.dirty,
        })}>
            {vnode.attrs.label && (
                <label>{vnode.attrs.label}
                    {vnode.attrs.icon && <Icon name={vnode.attrs.icon}/>}
                    {vnode.attrs.validation && <span className="validation">{validation.label}</span>}
                </label>
            )}
            <div className="input-wrapper">
                <input
                    autocomplete={vnode.attrs.autocomplete}
                    autofocus={vnode.attrs.autofocus}
                    disabled={vnode.attrs.disabled}
                    id={vnode.attrs.id}
                    onkeydown={(e) => this.navigate_keyboard(vnode, e)}
                    onkeypress={(e) => {
                        if (!vnode.attrs.submit || e.key !== 'Enter') return

                        if (vnode.attrs.suggestions && this.data.suggestions.options.length) {
                            if (this.data.suggestions.selected !== null) {
                                // A suggestion is selected; set the modelValue and submit using the suggestion.
                                this.assign_modelvalue(vnode, modelref, this.data.suggestions.options[this.data.suggestions.selected].name)
                                vnode.attrs.submit(e, this.data.suggestions.options[this.data.suggestions.selected])
                            } else if (this.data.suggestions.options[0].name === modelref[0][modelref[1]]) {
                                // The first suggestion is the same as the modelValue; submit it.
                                vnode.attrs.submit(e, this.data.suggestions.options[0])
                            } else {
                                // Submit without the suggestion.
                                vnode.attrs.submit(e)
                            }
                            this.data.suggestions.options = []
                        } else {
                            vnode.attrs.submit(e)
                        }
                    }}
                    oninput={async(e) => {
                        if (vnode.attrs.validation) {
                            vnode.attrs.validation.dirty = true
                        }
                        let value = this.format_value(vnode, e.target.value)

                        // A null formatted value just means that the value is invalid
                        // and must not be accepted as input.
                        if (value === null && !['number', 'date'].includes(vnode.attrs.type)) {
                            e.preventDefault()
                            return
                        }
                        this.assign_modelvalue(vnode, modelref, value)

                        if (vnode.attrs.suggestions) {
                            const suggestions = await vnode.attrs.suggestions(String(value)) as any
                            this.data.suggestions.options.splice(0, this.data.suggestions.options.length, ...suggestions)
                        }

                        if (vnode.attrs.onafterupdate) {
                            vnode.attrs.onafterupdate(value)
                        }
                    }}
                    placeholder={vnode.attrs.placeholder ? vnode.attrs.placeholder : ''}
                    min={'min' in vnode.attrs ? vnode.attrs.min : null}
                    max={'max' in vnode.attrs ? vnode.attrs.max : null}
                    step={'step' in vnode.attrs ? vnode.attrs.step : undefined}
                    type={(() => {
                        if ('type' in vnode.attrs) {
                            if (vnode.attrs.type === 'password') {
                                return this.data.password.hide ? 'password' : 'text'
                            }
                            if (vnode.attrs.type === 'currency') {
                                return 'text'
                            }
                            return vnode.attrs.type
                        }
                        return 'text'
                    })()}
                    value={modelvalue}
                />
                {vnode.attrs.type === 'password' && <Icon name={this.data.password.hide ? 'eye' : 'eyeRemove'} onclick={() => {
                    this.data.password.hide = !this.data.password.hide
                }}/>}
                {vnode.children}
            </div>
            {!!vnode.attrs.suggestions && <div
                className="suggestions"
                onclick={(e) => {
                    const clicked_artkey = Number(e.target.dataset.id)
                    const suggestion = this.data.suggestions.options.find((i:any) => i.artkey === clicked_artkey)
                    modelref[0][modelref[1]] = suggestion.name
                    if (vnode.attrs.submit) {
                        vnode.attrs.submit(e, suggestion)
                    }
                    this.data.suggestions.options.length = 0
                }}
            >
                {this.data.suggestions.options.map((suggestion:any, index:number) => (
                    <div className={classes('suggestion', {
                        selected: index === this.data.suggestions.selected,
                    })} data-id={suggestion.artkey}>{suggestion.name}</div>
                ))}
            </div>}

            {(() => {
                if (invalid && validation.dirty) {
                    return <div className="help validation">{invalid.message}</div>
                } else if (vnode.attrs.help) {
                    return <div class="help">{vnode.attrs.help}</div>
                }
            })()}
        </div>
    }
}
