import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, ButtonGroup, FieldCode, FieldText, Stepper} from '@bitstillery/common/components'
import {and, email, password, required, validation} from '@bitstillery/common/lib/validation'
import {proxy} from '@bitstillery/common/lib/proxy'
import {$t, api, notifier} from '@bitstillery/common/app'

import {$m, $s} from '@/app'

const redeem_status = {
    NOT_FOUND: 'Redeem code not found',
    USED: 'Lead no longer valid',
}

export class AuthRedeem extends MithrilTsxComponent<unknown> {
    is_submitting = false
    data = (() => {
        const $f = {
            email_address: '',
            password: '',
            redeem_code: '',
        }

        const disabled_group = (step) => {
            return () => {
                if (!this.validationGroup[step]) return false
                const validation_fields = this.validationGroup[step]
                const invalid = validation_fields.map((i) => this.$v[i]).filter((i) => i && i._invalid)
                return !!invalid.length
            }
        }

        const data = proxy({
            $f,
            codeFail: null,
            stepper: {
                selection: 0,
                options: [
                    {icon: 'profilePlus', title: $t('registration.redeem.step0_slug')},
                    {_disabled: disabled_group(0), icon: 'email', title: $t('registration.redeem.step1_slug')},
                    {_disabled: disabled_group(1), icon: 'checked', title: $t('registration.redeem.step2_slug')},
                ],
            },
        })

        return data
    })()

    $v = {
        email_address: validation([this.data.$f, 'email_address'], and([required(), email()])),
        password: validation([this.data.$f, 'password'], password()),
        redeem_code: validation([this.data.$f, 'redeem_code'], {
            validate: (modelvalue, validator) => {
                if (modelvalue.length < 6) {
                    validator.message = $t('status.error.redeem.length')
                    return this
                } else if (this.data.codeFail) {
                    if (this.data.codeFail === redeem_status.NOT_FOUND) {
                        validator.message = $t('status.error.redeem.invalid')
                    } else if (this.data.codeFail === redeem_status.USED) {
                        validator.message = $t('status.error.redeem.used')
                    } else {
                        // Translate all error messages, but fallback to the
                        // default message, when it's not covered.
                        validator.message = this.data.codeFail
                    }

                    return this
                }

                return false
            },
            label: '*',
            message: '',
        }),
    }

    redirect_to = m.route.param('redirect')
    validationGroup = {
        0: ['redeem_code'],
        1: ['email', 'password'],
    }

    oninit() {
        $s.page.title = $t('page.title.redeem')
        $s.page.subtitle = $t('page.subtitle.redeem')
    }

    async redeem_code() {
        this.is_submitting = true
        try {
            const {result: result_redeem, success} = await api.post('redeem-code', {
                redeem_code: this.data.$f.redeem_code,
                password: this.data.$f.password,
                email_address: this.data.$f.email_address,
            }) as any

            if (success && result_redeem.token) {
                $m.identity.new_token(result_redeem.token)
                const {result: result_status} = await api.get('portal/status') as any
                $s.portal.ready = result_status.pricelist_ready
                m.route.set('dashboard')
                notifier.notify($t('page.title.login', {vendor: process.env.MSI_TITLE}), 'success')
            } else {
                let message
                if (result_redeem.message) {
                    message = $t('status.error.message', {message: result_redeem.message})
                } else {
                    message = $t('status.error.unknown')
                }
                notifier.notify(message, 'danger')
                throw new Error(message)
            }
        } finally {
            this.is_submitting = false
        }
    }

    view() {
        return (
            <div className="c-redeem landing">
                <div className="form stepper-form">
                    {this.data.stepper.selection === 0 &&
                    <div className="step-0 redeem-widget">
                        <h3>{$t('registration.redeem.step0_slug')}</h3>
                        <p className="step-info">
                            {$t('registration.redeem.step0_desc')}
                        </p>

                        <FieldCode
                            characters={/^[A-Za-z0-9]$/}
                            help={$t('registration.redeem.code_help')}
                            label={$t('registration.redeem.code')}
                            onchange={async(redeem_code) => {
                                let {result, success} = await api.post('redeem-code-check', {redeem_code}) as any
                                if (success) {
                                    const lead = result
                                    this.data.codeFail = null
                                    notifier.notify($t('status.success.redeem.verified', {name: lead.name}), 'success', undefined, 'promotion')
                                    this.data.$f.email_address = lead.email_address
                                } else {
                                    if (!result.message) result.message = $t('status.error.redeem.unknown')
                                    this.data.codeFail = result.message

                                    if (result.message === redeem_status.NOT_FOUND) {
                                        notifier.notify($t('status.error.redeem.invalid'), 'danger', undefined, 'promotion')
                                    } else if (result.message === redeem_status.USED) {
                                        notifier.notify($t('status.error.redeem.used'), 'danger', undefined, 'promotion')
                                    } else {
                                        notifier.notify(result.message, 'danger', undefined, 'promotion')
                                    }
                                }
                            }}
                            placeholders={6}
                            ref={[this.data.$f, 'redeem_code']}
                            validation={this.$v.redeem_code}
                        />
                    </div>}

                    {this.data.stepper.selection === 1 && <div className="step-1">
                        <h3>{$t('registration.redeem.step1_slug')}</h3>
                        <p className="step-info">
                            {$t('registration.redeem.step1_desc')}
                        </p>

                        <FieldText
                            help={$t('profile.email_help')}
                            label={$t('profile.email')}
                            ref={[this.data.$f, 'email_address']}
                            placeholder={$t('profile.email_placeholder')}
                            validation={this.$v.email_address}
                        />
                        <FieldText
                            help={$t('profile.new_password_help')}
                            label={$t('profile.new_password')}
                            ref={[this.data.$f, 'password']}
                            placeholder={$t('profile.new_password_placeholder')}
                            type="password"
                            validation={this.$v.password}
                        />
                    </div>}

                    {this.data.stepper.selection === 2 && <div className="step-2 fieldset">
                        <h3>{$t('registration.redeem.step2_slug')}</h3>
                        <p className="step-info">
                            {$t('registration.redeem.step2_desc')}
                        </p>
                        <div className="fieldset-label">{$t('registration.redeem.step1_slug')}</div>
                        <div className="field-readonly">
                            <div className="key">{$t('registration.redeem.code')}</div>
                            <div className="value">{this.data.$f.redeem_code}</div>
                        </div>
                        <div className="field-readonly">
                            <div className="key">{$t('registration.email_address')}</div>
                            <div className="value">{this.data.$f.email_address}</div>
                        </div>
                    </div>
                    }
                    <ButtonGroup>
                        {this.data.stepper.selection > 0 && <Button
                            icon='chevronLeft'
                            text={$t('back')}
                            type='default'
                            onclick={() => {
                                this.data.stepper.selection -= 1
                            }}
                        />}

                        <Button
                            disabled={(() => {
                                if (this.is_submitting) {
                                    return true
                                }
                                if (!this.validationGroup[this.data.stepper.selection]) {
                                    return false
                                }
                                const validation_fields = this.validationGroup[this.data.stepper.selection]
                                const invalid = validation_fields.map((i) => this.$v[i]).filter((i) => i && i._invalid)
                                return invalid.length
                            })()}
                            icon={this.data.stepper.selection === 2 ? 'checked' : 'chevronRight'}
                            text={(() => {
                                return $t(`registration.redeem.step${this.data.stepper.selection + 1}_slug`)
                            })()}
                            type={this.data.stepper.selection === 2 ? 'success' : 'info'}
                            onclick={() => {
                                // At this point the form is submitted. After
                                // registration, the next step is toggled.
                                const last_step = this.data.stepper.options.length - 1
                                if (this.data.stepper.selection < last_step) {
                                    this.data.stepper.selection += 1
                                } else if (this.data.stepper.selection === last_step) {
                                    this.redeem_code()
                                }
                            }}
                        />
                    </ButtonGroup>
                </div>
                {(this.data.stepper.selection < this.data.stepper.options.length) && <Stepper
                    model={this.data.stepper}
                    options={this.data.stepper.options}
                />}
                {this.data.stepper.selection < 3 && <div className="about">
                    {$t('registration.explanation', {vendor: process.env.MSI_TITLE})}
                </div>}
            </div>
        )
    }
}
