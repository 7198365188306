import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'
import {Icon} from '@bitstillery/common/components'
import {format_delivery_period} from '@bitstillery/common/lib/format'
import {$s, $t} from '@bitstillery/common/app'
import {$m} from '@bitstillery/common/app'

const STATUS_ICONS = {
    eta: 'truck',
    stock: 'checkboxMarked',
    tbo: 'tbo',
}

export class ProductAvailability extends MithrilTsxComponent<any> {

    view(vnode: m.Vnode<any>) {
        let name = 'stock'
        const offer_item = vnode.attrs.offer_item
        if (offer_item.offer_item_type === 'tbo') {
            name = 'tbo'
        } else if (offer_item.offer_item_type === 'purchase' || offer_item.delivery_period) {
            name = 'eta'
        }

        const unavailable = $s.cart.errors.unavailable[offer_item.case_artkey]
        const api_error = $s.cart.errors.api[offer_item.case_artkey]
        const less_available = $s.cart.errors.less_available[offer_item.case_artkey]
        const min_quantity = $s.cart.errors.minimum_quantity[offer_item.case_artkey]

        return <div className="c-product-availability">
            <div className="stock-cart">
                <div className="in-stock">
                    <Icon
                        name="stock"
                        size="d"
                        tip={() => {return vnode.attrs.context.tips._stock}}
                    />
                    <span>
                        {vnode.attrs.context._list_quantity_unit}
                    </span>
                </div>
                {(min_quantity || vnode.attrs.context._rounding_required) && <div className="moq">
                    <Icon
                        name="min_orderQuantity"
                        size="d"
                        tip={() => {
                            if (min_quantity) {
                                return vnode.attrs.context.tips._min_quantity
                            }
                            return vnode.attrs.context.tips._rounding
                        }}
                        type={((min_quantity || vnode.attrs.context._rounding_required)) ? 'warning' : null}
                    />
                    <span>{(() => {
                        if (min_quantity) {
                            return $m.offer.unit_amount(vnode.attrs.context._min_quantityCases, offer_item.case_number_of_bottles)
                        } else {
                            return vnode.attrs.context._rounded_bottles
                        }
                    })()}</span>
                </div>}

                {vnode.attrs.context._cart_units > 0 &&
                <div className="in-cart">
                    <Icon
                        name="cart"
                        size="d"
                        tip={() => vnode.attrs.context.tips._cart}
                        type={(() => {
                            if (api_error) return 'error'
                            if (less_available || unavailable) return 'warning'
                            return 'info'
                        })()}
                    />
                    {vnode.attrs.context._cart_units}
                </div>}
            </div>

            <div className={classes('availability-conditions', name)}>
                <Icon size="xs" name={STATUS_ICONS[name]} />
                <span>
                    {name === 'tbo' && `${$t('product.availability.tbo')} - ${format_delivery_period(offer_item.delivery_period)}`}
                    {name === 'eta' && `${$t('product.availability.eta', {
                        period: format_delivery_period(offer_item.delivery_period),
                    })}`}
                    {name === 'stock' && $t('product.availability.stock')}
                </span>
            </div>
        </div>
    }
}
