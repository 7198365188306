import m from 'mithril'
import {Icon, Link} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'

export interface DashboardWidgetAttrs {
    icon?: string
    link?: string
    name: string
    title: string
    footer?: m.Children
    pagination?: any
}

export class DashboardWidget extends MithrilTsxComponent<DashboardWidgetAttrs> {
    view(vnode: m.Vnode<DashboardWidgetAttrs>) {
        return <div className={`c-widget c-widget-${vnode.attrs.name}`}>
            <div className="c-widget-title">
                {vnode.attrs.icon && <Icon name={vnode.attrs.icon} type="unset" />}
                {vnode.attrs.link ? <Link target="_self" href={vnode.attrs.link}>{vnode.attrs.title}</Link> : vnode.attrs.title}
                {!!vnode.attrs.pagination && vnode.attrs.pagination}
            </div>
            <div className="c-widget-body">
                {vnode.children}
            </div>
        </div>
    }
}
