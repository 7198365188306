import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'
import {classes} from '@bitstillery/common/lib/utils'
import {Icon, Spinner} from '@bitstillery/common/components'
import {$t, api, events, notifier, store} from '@bitstillery/common/app'

import {$s} from '@/app'

export class MessRoom extends MithrilTsxComponent<any> {

    data = proxy({
        steps: {
            options: [
                {completed: false, title: $t('messroom.step0')},
                {completed: false, title: $t('messroom.step1')},
                {completed: false, title: $t('messroom.step2')},
            ],
            selection: 0,
        },
    })

    intervalId: ReturnType<typeof setInterval>

    oninit() {
        api.post('user.activity', {page: '/messroom'})
        this.intervalId = setInterval(async() => {
            if (this.data.steps.selection === 2) {
                const {result} = await api.get('portal/status') as any
                if (result.pricelist_ready) {
                    this.data.steps.options[this.data.steps.selection].completed = true
                    $s.portal.ready = true
                    store.save()
                    events.emit('identity.login', $s.identity)
                    notifier.notify($t('notifications.messroom_ready'))
                }
            } else {
                this.data.steps.options[this.data.steps.selection].completed = true
                this.data.steps.selection += 1
            }

        }, 5000)
    }

    onremove() {
        clearInterval(this.intervalId)
    }

    view(_:m.Vnode<any>) {
        return (
            <div className="c-messroom">
                <div className="messroom-dialog">
                    <div className="title">
                        <Icon name="portal" />
                        {$t('messroom.title')}
                    </div>
                    <div className="description">
                        {$t('messroom.description')}
                    </div>

                    <div className="team">
                        {$t('messroom.team', {team: process.env.MSI_THEME === 'msp' ? ' MovingSpirits' : 'A2BC'})}
                    </div>

                    <div className="steps">
                        {this.data.steps.options.map((step, index) => {
                            return <div className={classes('step', {
                                active: !step.completed && this.data.steps.selection === index,
                                completed: step.completed,
                            })}>
                                <Icon name={step.completed ? 'checked' : 'chevronRight'}/>
                                {step.title}
                                {!step.completed && this.data.steps.selection === index && <Spinner/>}
                            </div>
                        })}
                    </div>
                    {(() => {
                        const completed = this.data.steps.options.filter((i) => i.completed).length === this.data.steps.options.length
                        return <div className={classes('steps-status', {completed})}>
                            <Icon name={completed ? 'checked' : 'clock'}/>
                            {completed ? $t('messroom.steps_completed') : $t('messroom.steps_processing')}
                        </div>
                    })()}
                </div>

            </div>
        )
    }
}
