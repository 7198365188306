import {MithrilTsxComponent} from 'mithril-tsx-component'
import {invalid_fields, validation} from '@bitstillery/common/lib/validation'
import {Button} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import m from 'mithril'
import {$s} from '@bitstillery/common/app'

interface CollectionInlineFieldAttrs {
    className: string
    column_id: string
    collection: any
    field: {
        render: Function
        validation: Function
    }
}

export class CollectionInlineField extends MithrilTsxComponent<CollectionInlineFieldAttrs> {

    /**
     * Register the action when it is about to be rendered.
     * @param vnode
     */
    oninit(vnode) {
        const bulk_data = vnode.attrs.collection.bulk
        const item = vnode.attrs.item

        bulk_data.actions[item.artkey] = {
            $v: {},
            changed: false,
            invalid: false,
            item: vnode.attrs.item,
            restore: {},
            persist: false,
        }

        const column_id = vnode.attrs.column_id
        bulk_data.actions[item.artkey].$v[column_id] = validation([item, column_id], vnode.attrs.field.validation)
        bulk_data.actions[item.artkey].restore[column_id] = item[column_id]
    }

    /**
     * Make sure to cleanup actions when the item is removed from the bulk action.
     * @param vnode
     */
    onremove(vnode) {
        const bulk_data = vnode.attrs.collection.bulk
        const item = vnode.attrs.item
        const column_id = vnode.attrs.column_id
        const action = bulk_data.actions[item.artkey]

        if (action.change && !action.persist) {
            // (!) The column id must be a valid row field.
            item[column_id] = action.restore[column_id]
        }
        delete bulk_data.actions[vnode.attrs.item.artkey]
    }

    async persist_action(vnode, item, column_id, mode) {
        const bulk_data = vnode.attrs.collection.bulk
        await bulk_data.persist([item], mode)
        bulk_data.actions[item.artkey].restore[column_id] = item[column_id]
        $s.context.id = null
        m.redraw()
    }

    view(vnode:m.Vnode<CollectionInlineFieldAttrs>) {
        const bulk_data = vnode.attrs.collection.bulk
        const item = vnode.attrs.item

        const column_id = vnode.attrs.column_id
        const action = bulk_data.actions[item.artkey]
        action.changed = bulk_data.actions[item.artkey].restore[column_id] !== item[column_id]
        action.invalid = invalid_fields(bulk_data.actions[item.artkey].$v).length > 0

        return <div
            className={classes('c-collection-inline-field', {
                changed: action.changed,
                invalid: action.invalid,
            })}
            onclick={(e) => e.stopPropagation()}
            onkeypress={(e) => {
                e.stopPropagation()
                if (action.invalid || !action.changed) return

                if (e.key === 'Enter') {
                    this.persist_action(vnode, item, column_id, e.ctrlKey ? 'alt' : '')
                }
            }}
        >
            {vnode.attrs.field.render(item, column_id, bulk_data.actions[item.artkey].$v[column_id])}
            <div className="actions">
                <Button
                    disabled={action.invalid || !action.changed}
                    icon='save'
                    onclick={() => this.persist_action(vnode, item, column_id, '')}
                    size="s"
                    tip='Update item'
                    type="success"
                    variant='toggle'
                />
                {'action_alt' in bulk_data ? <Button
                    disabled={action.invalid || !action.changed}
                    icon={bulk_data.action_alt.icon}
                    onclick={() => this.persist_action(vnode, item, column_id, 'alt')}
                    size="s"
                    tip={'Silent Update item'}
                    type="info"
                    variant='toggle'
                /> : null}
            </div>
        </div>
    }
}
