import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes, unique_id} from '@bitstillery/common/lib/utils'
import {Icon} from '@bitstillery/common/components'
import {$t} from '@bitstillery/common/app'

import {Validator} from '../../../lib/validation.tsx'

export interface FieldSelectAttrs extends Validator {
    placeholder?: string
    options: [any, string][] // array of options in object / label
    icon?: string
    disabled?: boolean
    computed?: () => boolean
    ref?: [{}, string]
    help?: string
    translate?: {
        label: boolean
        prefix: string
    }

    validation: any
    className: any

    onclick: (event) => unknown
    onchange: (event) => unknown
    onAfterChange: (unknown) => unknown
}

export class FieldSelect extends MithrilTsxComponent<FieldSelectAttrs> {

    name = unique_id()

    view(vn: m.Vnode<FieldSelectAttrs>) {
        const validation = vn.attrs.validation
        const invalid = validation ? validation._invalid : false
        let disabled = vn.attrs.disabled
        let options = vn.attrs.options
        if (vn.attrs.placeholder) {
            options = [['', vn.attrs.placeholder], ...options]
        }
        else if (options.length && ['', null, undefined].includes(vn.attrs.ref[0][vn.attrs.ref[1]])) {
            vn.attrs.ref[0][vn.attrs.ref[1]] = options[0][0]
        }

        let select_options = options.map((option, index) => {
            const key = `${index}`
            const model_ref = vn.attrs.ref[0][vn.attrs.ref[1]]
            const option_is_selected = model_ref === option[0]
            const option_is_on_artkey = model_ref && model_ref['artkey'] && model_ref['artkey'] === option[0]['artkey']

            if (option_is_selected || option_is_on_artkey) {
                return (<option selected value={key}>{vn.attrs.translate ? $t(`${vn.attrs.translate.prefix}${option[1]}`) : option[1]}</option>)
            } else {
                return (<option value={key}>{vn.attrs.translate ? $t(`${vn.attrs.translate.prefix}${option[1]}`) : option[1]}</option>)
            }
        })

        if (!vn.attrs.placeholder && options.length === 0) { // may happen if the options are still loading
            select_options = [<option>Loading...</option>]
            disabled = true
        }

        return (
            <div className={classes('c-field-select', 'field', vn.attrs.className, {
                disabled: disabled,
                invalid: validation && invalid && validation.dirty,
                valid: !validation || !invalid,
            })}>
                {vn.attrs.label && (
                    <label>{vn.attrs.label}
                        {vn.attrs.icon && <Icon name={vn.attrs.icon}/>}
                        {vn.attrs.validation && <span className="validation">{validation.label}</span>}
                    </label>
                )}
                <select
                    disabled={disabled}
                    onchange={(e) => {
                        if (vn.attrs.validation) {
                            vn.attrs.validation.dirty = true
                        }
                        const index = +e.target.value
                        vn.attrs.ref[0][vn.attrs.ref[1]] = options[index][0]
                        // Please do not use this method to adjust state, but only
                        // for non-state side-effects where a watcher is overkill.
                        if (vn.attrs.onchange) {
                            vn.attrs.onchange(options[index][0])
                        }
                    }}
                    id={this.name}
                    name={this.name}
                >
                    {select_options}
                </select>
                {(() => {
                    if (invalid && validation.dirty) {
                        return <div className="help validation">{invalid.message}</div>
                    } else if (vn.attrs.help) {
                        return <div class="help">{vn.attrs.help}</div>
                    }
                })()}
            </div>
        )
    }
}
