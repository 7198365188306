import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'

export class ButtonGroup extends MithrilTsxComponent<any> {

    view(vn: m.Vnode<any>) {
        return (
            <div className={classes('c-button-group', vn.attrs.className, vn.attrs.type ? `type-${vn.attrs.type}` : 'type-default')}>
                {vn.children}
            </div>
        )
    }
}
