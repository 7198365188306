import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'
import {Icon} from '@bitstillery/common/components'

export class ProcessStepper extends MithrilTsxComponent<unknown> {
    view(vn:m.Vnode<any>): m.Children {
        return <div className={classes('c-process-stepper', `variant-${vn.attrs.variant ? vn.attrs.variant : 'vertical'}`)}>
            {vn.attrs.process.map((step, index) => {
                if (step.id === vn.attrs.data.active && vn.attrs.data.current !== index) {
                    vn.attrs.data.current = index
                }
                return <a
                    href={step.disabled ? null : `#!${typeof step.path === 'function' ? step.path() : step.path}`}
                    onclick={(e) => {
                        if (step.disabled) {
                            e.preventDefault()
                        }
                    }}
                    className={classes('step', {
                        active: step.id === vn.attrs.data.active,
                        completed: step.completed,
                        disabled: step.disabled,
                    })}
                >
                    <div className="title">
                        {step.text}
                    </div>

                    {(() => {
                        if (step.completed) return <Icon name="checked" />
                        if (step.id === vn.attrs.data.active) return <Icon name="chevronRight" />
                        return <Icon name="chevronDown" />
                    })()}
                </a>
            })}
        </div>
    }
}
