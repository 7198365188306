import {jsPDF} from 'jspdf'
// @ts-ignore
import {countries} from '@bitstillery/common/lib/countries'
import {DateTime} from 'luxon'
import {CellDef} from 'jspdf-autotable/dist'

import {format_iso_to_date} from '../ts_utils'
import {displayable_float} from '../ts_utils'
import {to_specs_with_features} from '../models/item'
import {SalesOrder, SalesOrderItem, SalesOrderTBOItem} from '../models/sales_order'
import {Account} from '../models/account'

import {CasesOrBottles, PDF} from './pdf'

export enum ProformaInvoice {
    PROFORMA,
    INVOICE,
}

export interface ProformaInvoiceRendererAttrs {
    sales_order: SalesOrder
    account: Account
    cases_or_bottles: CasesOrBottles
    show_article_code: boolean
    show_country_of_origin: boolean
    show_cbs_code: boolean
    show_bottle_lot: boolean
    show_liters_of_alcohol: boolean
    group_similar_items: boolean
    decimal_locale: string
    proforma_or_invoice: ProformaInvoice
}

interface VatAccumulator {
    base_amount: number
    vat: number
}

/**
 * Determine the fiscal terms text that is displayed on either a sales order
 * pro forma or invoice pdf.
 *
 * @param {int} vat_code: the vat code that applies for the sales order.
 * @param includes_excise: Whether or not the sales order includes excise.
 * @param {boolean} contains_t1: specifies whether an order contains T1 items.
 * @param {boolean} contains_t2: specifies whether an order contains T2 items.
 *
 * @return {string}: the fiscal terms to display on a pdf.
 */
function get_fiscal_terms(
    vat_code: number,
    includes_excise: boolean,
    contains_t1: boolean,
    contains_t2: boolean,
): string {
    if (includes_excise) {
        // We need to charge VAT, so we won't shift.
        return ''
    }

    // Define the fiscal terms text that is used for vat code 20 and T2, and also as fallback.
    let fiscal_terms = '0% VAT, supply under table II, section a.7 Dutch VAT act 1968.'

    // Determine the fiscal terms based on the combination of the vat code and the customs statuses of the sales order items.
    if (vat_code === 20) {
        if (contains_t1) {
            if (contains_t2) {
                fiscal_terms = '0% VAT, supply under table II, section a.1 and a.7 Dutch VAT act 1968.'
            } else {
                fiscal_terms = '0% VAT, supply under table II, section a.1 Dutch VAT act 1968.'
            }
        }
    } else if (vat_code === 7) {
        fiscal_terms = 'Intra community supplies.'
    } else if (vat_code === 6) {
        fiscal_terms = 'Export to outside the EU.'
    }

    return fiscal_terms
}

/**
 * Construct the fiscal representation text that is displayed on either a sales
 * order pro forma or invoice pdf.
 *
 * @param sales_order: the sales order to construct the text for.
 * @return an array of strings to display on a pdf, one element for each line.
 */
function get_fiscal_representation(sales_order: {
    is_vat_shifted: boolean
    supplier: {
        name: string
        vat_id: string
    }
    destination: {
        name: string | null
        vat_id: string
        excise_id: string | null
    }
}): string[] {
    const client = sales_order.supplier
    const warehouse = sales_order.destination
    if (sales_order.is_vat_shifted && warehouse.name) {
        return [
            `${client.name} is fiscally represented by:`,
            `${warehouse.name}`,
            `Vat No.: ${warehouse.vat_id}`,
            `Excise Warehouse No.: ${warehouse.excise_id || ''}`,
        ]
    }
    return []
}

/**
 * Maps the ProformaInvoiceRendererAttrs to jsPDF containing the PDF.
 *
 * @param attrs Needed data for the pdf.
 * @returns The jsPDF object.
 */
export function proforma_or_invoice_renderer(attrs: ProformaInvoiceRendererAttrs): jsPDF {
    const pdf = new PDF(attrs.account)
    const doc: jsPDF = pdf.document

    const has_t1_items =
        attrs.sales_order.sales_order_items.find((soi) => soi.item.case.customs_status === 'T1') !== undefined
    const has_t2_items =
        attrs.sales_order.sales_order_items.find((soi) => soi.item.case.customs_status === 'T2') !== undefined
    const has_only_credit_items =
        attrs.sales_order.sales_order_items.length === 0 && attrs.sales_order.sales_order_credit_items.length > 0

    if (attrs.proforma_or_invoice === ProformaInvoice.INVOICE) {
        doc.setProperties({
            title: `Invoice - ${attrs.sales_order.reference} - ${attrs.sales_order.supplier.name}`,
            author: attrs.account.name,
            keywords: 'Invoice, Sales',
            creator: attrs.account.name,
            subject: `Invoice - ${attrs.sales_order.reference} - ${attrs.sales_order.supplier.name}`,
        })

        if (has_only_credit_items) {
            pdf.title(`Credit invoice ${attrs.sales_order.invoice_number}`)
        } else {
            pdf.title(`Invoice ${attrs.sales_order.invoice_number}`)
        }
    } else {
        doc.setProperties({
            title: `Proforma Invoice - ${attrs.sales_order.reference} - ${attrs.sales_order.supplier.name}`,
            author: attrs.account.name,
            keywords: 'Proforma Invoice, Sales',
            creator: attrs.account.name,
            subject: `Proforma Invoice - ${attrs.sales_order.reference} - ${attrs.sales_order.supplier.name}`,
        })

        pdf.title('Proforma')
    }

    let iban = attrs.account.iban_eur || ''
    if (attrs.sales_order.was_sold_in === 'GBP' && attrs.account.iban_gbp) {
        iban = attrs.account.iban_gbp
    }
    if (attrs.sales_order.was_sold_in === 'USD' && attrs.account.iban_usd) {
        iban = attrs.account.iban_usd
    }

    if (['msp', 'etr'].includes(attrs.account.slug)) {
        // We switched recently to new accounts; add a warning to the header
        // so customers are notified of new payment terms. This can removed
        // after a couple of months. Check with business before removing.
        pdf.payment_warning(attrs.account, iban)
    }

    const relation_address = [
        attrs.sales_order.supplier.name,
        attrs.sales_order.supplier.street_address,
        attrs.sales_order.supplier.zip_code
            ? `${attrs.sales_order.supplier.zip_code} ${attrs.sales_order.supplier.city}`
            : attrs.sales_order.supplier.city,
        countries[attrs.sales_order.supplier.country_code],
    ]

    if (!attrs.sales_order.is_vat_shifted && attrs.sales_order.supplier.vat_id) {
        relation_address.push(`VAT ID: ${attrs.sales_order.supplier.vat_id}`)
    }

    pdf.relation_address(relation_address)

    pdf.incoterm(`${attrs.sales_order.incoterm} - ${attrs.sales_order.incoterm_location}`)

    /* Destination */
    if (attrs.sales_order.destination && attrs.sales_order.destination.name) {
        const destination_address = []
        if (attrs.sales_order.destination.destination_type === 'warehouse') {
            destination_address.push(
                attrs.sales_order.destination.name,
                attrs.sales_order.destination.street_address,
                `${attrs.sales_order.destination.zip_code} ${attrs.sales_order.destination.city}`,
                countries[attrs.sales_order.destination.country_code],
            )
        }
        else {
            destination_address.push(
                attrs.sales_order.destination.name + ' - ' + countries[attrs.sales_order.destination.country_code],
            )
        }
        if (attrs.sales_order.destination.warehouse_id) {
            destination_address.push(`Location ID: ${attrs.sales_order.destination.warehouse_id}`)
        }
        if (attrs.sales_order.destination.excise_id) {
            destination_address.push(`Excise ID: ${attrs.sales_order.destination.excise_id}`)
        }
        destination_address.push('')
        pdf.destination_address(destination_address)
    } else {
        pdf.destination_address(attrs.sales_order.destination_location?.split('\n') || [])
    }

    /* Details */
    const detail_headers =
        attrs.proforma_or_invoice === ProformaInvoice.INVOICE
            ? ['Order number:', 'Relation reference:', 'Invoice date:']
            : ['Order number:', 'Relation reference:', 'Proforma date:']

    const detail_values =
        attrs.proforma_or_invoice === ProformaInvoice.INVOICE
            ? [
                attrs.sales_order.reference,
                `${attrs.sales_order.supplier.relation_nr}`,
                format_iso_to_date(attrs.sales_order.is_invoiced_on),
            ]
            : [
                attrs.sales_order.reference,
                `${attrs.sales_order.supplier.relation_nr}`,
                format_iso_to_date(DateTime.now().toISODate()),
            ]
    if (attrs.sales_order.includes_excise) {
        detail_headers.push('Excise duties:')
        detail_values.push('Paid')
    }
    if (attrs.proforma_or_invoice === ProformaInvoice.INVOICE && has_only_credit_items) {
        pdf.details('Credit invoice details', detail_headers, detail_values)
    } else if (attrs.proforma_or_invoice === ProformaInvoice.INVOICE) {
        pdf.details('Invoice details', detail_headers, detail_values)
    } else {
        pdf.details('Proforma details', detail_headers, detail_values)
    }

    /* Liters of alcohol */
    if (attrs.show_liters_of_alcohol) {
        const soi_liters = attrs.sales_order.sales_order_items.reduce(
            (previous, current) =>
                previous +
                current.number_of_cases *
                    current.item.case.number_of_bottles *
                    (+current.item.case.bottle.volume / 100) *
                    (+current.item.case.bottle.alcohol_percentage / 100),
            0,
        )
        const tbo_liters = attrs.sales_order.sales_order_tbo_items.reduce(
            (previous, current) =>
                previous +
                current.number_of_cases *
                    current.case.number_of_bottles *
                    (+current.case.bottle.volume / 100) *
                    (+current.case.bottle.alcohol_percentage / 100),
            0,
        )
        pdf.liters_of_alcohol(displayable_float(tbo_liters + soi_liters, 4, attrs.decimal_locale))
    }

    let unit_quantity = 'cs.'
    if (attrs.cases_or_bottles === CasesOrBottles.bottles) {
        unit_quantity = 'btls.'
    }

    // group similar SalesOrderItem on case_artkey and price, if attrs.group_similar_items
    const sois_copy = JSON.parse(JSON.stringify(attrs.sales_order.sales_order_items)) as SalesOrderItem[]
    const sois = attrs.group_similar_items
        ? Object.values(sois_copy.reduce((accu , soi) => {
            const key = `${soi.item.case.artkey}-${soi.price_per_case}`
            if (!accu[key]) {
                accu[key] = soi
            } else {
                accu[key].number_of_cases += soi.number_of_cases
            }
            return accu
        }, {} as Record<string, SalesOrderItem>))
        : sois_copy

    const soi_items = sois
        .sort((soi_a, soi_b) => soi_a.item.case.bottle.product.name.localeCompare(soi_b.item.case.bottle.product.name))
        .map((soi: SalesOrderItem) => {
            /* Quantity */
            let quantity = soi.number_of_cases
            if (attrs.cases_or_bottles === CasesOrBottles.bottles) {
                quantity = soi.item.case.number_of_bottles * soi.number_of_cases
            }

            /* Create specs with the features */
            let specs: string = to_specs_with_features(soi.item.case, soi.item.case.bottle, attrs.decimal_locale)
            if (attrs.show_cbs_code && soi.item.case.bottle.cbs_code) {
                specs = `${specs}\nHS Code: ${Math.floor(+soi.item.case.bottle.cbs_code / 100)}`
            }

            /* Create a description with the product name, description, article code and country code. */
            let description = soi.item.case.bottle.product.name
            if (soi.description) {
                description = `${description}\n${soi.description}`
            }
            if (attrs.show_article_code) {
                description = `${description}\nArticle code: ${soi.item.case.article_code}`
            }
            const item_country_of_origin = soi.item.country_of_origin || soi.item.purchase_order_country_of_origin
            if (attrs.show_country_of_origin && item_country_of_origin) {
                description = `${description}\nCountry of origin: ${item_country_of_origin}`
            }
            if (attrs.show_bottle_lot && soi.item.bottle_lot) {
                description = `${description}\nBottle Lot: ${soi.item.bottle_lot}`
            }

            /* Unit price */
            let price_per_unit = +soi.price_per_case
            if (attrs.cases_or_bottles === CasesOrBottles.bottles) {
                price_per_unit = +soi.price_per_case / soi.item.case.number_of_bottles
            }

            /* Total price */
            const total_price = +soi.price_per_case * soi.number_of_cases

            return [
                pdf.text_cell(`${quantity} ${unit_quantity}`),
                pdf.text_cell(description),
                pdf.text_cell(specs),
                pdf.text_cell(
                    attrs.sales_order.includes_excise ? `${soi.vat_percentage || 0}` : soi.item.case.customs_status,
                ),
                pdf.currency_and_amount_cell(attrs.sales_order.was_sold_in, price_per_unit),
                pdf.currency_and_amount_cell(attrs.sales_order.was_sold_in, total_price),
            ]
        })

    const tbos_copy = JSON.parse(JSON.stringify(attrs.sales_order.sales_order_tbo_items)) as SalesOrderTBOItem[]
    const tbos = attrs.group_similar_items
        ? Object.values(tbos_copy.reduce((accu , tbo) => {
            const key = `${tbo.case.artkey}-${tbo.price_per_case}`
            if (!accu[key]) {
                accu[key] = tbo
            } else {
                accu[key].number_of_cases += tbo.number_of_cases
            }
            return accu
        }, {} as Record<string, SalesOrderTBOItem>))
        : tbos_copy

    const tbo_items = tbos
        .sort((tbo_a, tbo_b) => tbo_a.case.bottle.product.name.localeCompare(tbo_b.case.bottle.product.name))
        .map((tbo) => {
        /* Quantity */
            let quantity = tbo.number_of_cases
            if (attrs.cases_or_bottles === CasesOrBottles.bottles) {
                quantity = tbo.case.number_of_bottles * tbo.number_of_cases
            }

            /* Create specs with the features */
            let specs = to_specs_with_features(tbo.case, tbo.case.bottle, attrs.decimal_locale)
            if (attrs.show_cbs_code && tbo.case.bottle.cbs_code) {
                specs = `${specs}\nHS Code: ${Math.floor(+tbo.case.bottle.cbs_code / 100)}`
            }

            /* Create a description with the product name, description, article code and country code. */
            let description = tbo.case.bottle.product.name
            if (attrs.show_article_code) {
                description = `${description}\nArticle code: ${tbo.case.article_code}`
            }

            /* Unit price */
            let price_per_unit = +tbo.price_per_case
            if (attrs.cases_or_bottles === CasesOrBottles.bottles) {
                price_per_unit = +tbo.price_per_case / tbo.case.number_of_bottles
            }

            /* Total price */
            const total_price = +tbo.price_per_case * tbo.number_of_cases
            return [
                pdf.text_cell(`${quantity} ${unit_quantity}`),
                pdf.text_cell(description),
                pdf.text_cell(specs),
                pdf.text_cell(tbo.case.customs_status),
                pdf.currency_and_amount_cell(attrs.sales_order.was_sold_in, price_per_unit),
                pdf.currency_and_amount_cell(attrs.sales_order.was_sold_in, total_price),
            ]
        })

    const credit_items = attrs.sales_order.sales_order_credit_items.map((soc) => {
        /* Quantity */
        let quantity = soc.number_of_cases
        if (attrs.cases_or_bottles === CasesOrBottles.bottles) {
            quantity = soc.number_of_cases * soc.sales_order_item.item.case.number_of_bottles
        }
        const description = soc.sales_order_item.item.case.bottle.product.name

        /* Create specs with the features */
        let specs = to_specs_with_features(
            soc.sales_order_item.item.case,
            soc.sales_order_item.item.case.bottle,
            attrs.decimal_locale,
        )
        if (attrs.show_cbs_code && soc.sales_order_item.item.case.bottle.cbs_code) {
            specs = `${specs}\nHS Code: ${Math.floor(+soc.sales_order_item.item.case.bottle.cbs_code / 100)}`
        }

        /* price per unit */
        let price_per_unit = +soc.sales_order_item.price_per_case
        if (attrs.cases_or_bottles === CasesOrBottles.bottles) {
            price_per_unit = +soc.sales_order_item.price_per_case / soc.sales_order_item.item.case.number_of_bottles
        }

        /* total price */
        const total_price = soc.number_of_cases * +soc.sales_order_item.price_per_case

        return [
            pdf.text_cell(`${quantity * -1} ${unit_quantity}`),
            pdf.text_cell(description),
            pdf.text_cell(specs),
            pdf.text_cell(soc.sales_order_item.item.case.customs_status),
            pdf.currency_and_amount_cell(attrs.sales_order.was_sold_in, price_per_unit * -1),
            pdf.currency_and_amount_cell(attrs.sales_order.was_sold_in, total_price * -1),
        ]
    })

    const additional_items = attrs.sales_order.sales_order_additionals.map((additional) => {
        return [
            pdf.text_cell(`${additional.quantity}`),
            pdf.text_cell(additional.description || ''),
            pdf.text_cell(''),
            pdf.text_cell(''),
            pdf.currency_and_amount_cell(attrs.sales_order.was_sold_in, +additional.price_per_unit),
            pdf.currency_and_amount_cell(attrs.sales_order.was_sold_in, +additional.total_value),
        ]
    })
    const empty_row = [[pdf.text_cell('')]]
    const tbo_header_row = [[pdf.text_cell(''), pdf.text_cell('To be ordered:')]]

    let all_items = soi_items.concat(credit_items)
    if (tbo_items.length > 0) {
        all_items = all_items.concat(empty_row).concat(tbo_header_row).concat(tbo_items)
    }
    if (additional_items.length > 0) {
        all_items = all_items.concat(empty_row).concat(additional_items)
    }

    const vat_or_customs_header = attrs.sales_order.includes_excise ? 'VAT' : ''

    const main_table = pdf.auto_table(
        attrs.account,
        attrs.decimal_locale,
        ['Quantity', 'Description', '', vat_or_customs_header, `Unit Price ${unit_quantity}`, 'Total'],
        all_items,
    )

    /* Total quantity */
    let total_quantity_soi = attrs.sales_order.sales_order_items.reduce(
        (previous, current) => current.number_of_cases + previous,
        0,
    )
    let total_quantity_credit_items = attrs.sales_order.sales_order_credit_items.reduce(
        (previous, current) => current.number_of_cases + previous,
        0,
    )
    let total_quantity_tbo_items = attrs.sales_order.sales_order_tbo_items.reduce(
        (previous, current) => current.number_of_cases + previous,
        0,
    )
    let total_quantity = `${total_quantity_credit_items + total_quantity_soi + total_quantity_tbo_items} cs.`
    if (attrs.cases_or_bottles === CasesOrBottles.bottles) {
        total_quantity_soi = attrs.sales_order.sales_order_items.reduce(
            (previous, current) => (current.item.case.number_of_bottles * current.number_of_cases) + previous,
            0,
        )
        total_quantity_credit_items = attrs.sales_order.sales_order_credit_items.reduce(
            (previous, current) =>
                current.number_of_cases * current.sales_order_item.item.case.number_of_bottles + previous,
            0,
        )
        total_quantity_tbo_items = attrs.sales_order.sales_order_tbo_items.reduce(
            (previous, current) => current.number_of_cases * current.case.number_of_bottles + previous,
            0,
        )
        total_quantity = `${
            total_quantity_soi + total_quantity_credit_items + total_quantity_tbo_items
        } btls. (${total_quantity})`
    }
    let lowest_table = pdf.total_quantity(pdf.text_cell(`${total_quantity}`), main_table)

    /* VAT table or the fiscal terms for non-vat sales orders. */
    if (attrs.sales_order.includes_excise) {
        /* Calculate total vat per percentage for sales order items. */
        let vat_amount_per_percentage = attrs.sales_order.sales_order_items.reduce((previous, current) => {
            const vat_percentage = +current.vat_percentage
            const vat_total = +current.vat_total
            const base_amount = +current.total_was_sold_for
            const accumulator = previous.get(vat_percentage)
            if (accumulator === undefined) {
                previous.set(vat_percentage, {
                    base_amount: base_amount,
                    vat: vat_total,
                })
            } else {
                accumulator.vat += vat_total
                accumulator.base_amount += base_amount
            }
            return previous
        }, new Map<number, VatAccumulator>())
        /* Calculate total vat per percentage for tbo items. */
        vat_amount_per_percentage = attrs.sales_order.sales_order_tbo_items.reduce((previous, current) => {
            const vat_percentage = +current.vat_percentage
            const vat_total = +current.vat_total
            const base_amount = +current.total_was_sold_for_incl_excise
            const accumulator = previous.get(vat_percentage)
            if (accumulator === undefined) {
                previous.set(vat_percentage, {
                    base_amount: base_amount,
                    vat: vat_total,
                })
            } else {
                accumulator.vat += vat_total
                accumulator.base_amount += base_amount
            }
            return previous
        }, vat_amount_per_percentage)
        /* Calculate total vat per percentage for sales order credits (-/-). */
        vat_amount_per_percentage = attrs.sales_order.sales_order_credit_items.reduce((previous, current) => {
            const vat_percentage = +current.sales_order_item.vat_percentage
            const vat_total = +current.sales_order_item.vat_per_case * current.number_of_cases
            const base_amount = +current.sales_order_item.price_per_case * current.number_of_cases
            const accumulator = previous.get(vat_percentage)
            if (accumulator === undefined) {
                previous.set(vat_percentage, {
                    base_amount: base_amount,
                    vat: vat_total,
                })
            } else {
                accumulator.vat -= vat_total
                accumulator.base_amount -= base_amount
            }
            return previous
        }, vat_amount_per_percentage)
        /* Calculate total vat per percentage for sales order additionals. */
        vat_amount_per_percentage = attrs.sales_order.sales_order_additionals.reduce((previous, current) => {
            const vat_percentage = +current.vat_percentage
            const base_amount = +current.total_value
            const vat_total = base_amount * (vat_percentage / 100)
            const accumulator = previous.get(vat_percentage)
            if (accumulator === undefined) {
                previous.set(vat_percentage, {
                    base_amount: base_amount,
                    vat: vat_total,
                })
            } else {
                accumulator.vat += vat_total
                accumulator.base_amount += base_amount
            }
            return previous
        }, vat_amount_per_percentage)
        const vat_cell_defs: CellDef[][] = []
        vat_amount_per_percentage.forEach((value: VatAccumulator, key: number) =>
            vat_cell_defs.push([
                pdf.text_cell(`${key}%`),
                pdf.currency_and_amount_cell(attrs.sales_order.was_sold_in, value.base_amount),
                pdf.currency_and_amount_cell(attrs.sales_order.was_sold_in, value.vat),
            ]),
        )
        // @ts-ignore
        lowest_table = pdf.vat_table(lowest_table, attrs.decimal_locale, vat_cell_defs)
    } else {
        if (attrs.sales_order.vat_code) {
            const fiscal_terms = get_fiscal_terms(
                +attrs.sales_order.vat_code,
                attrs.sales_order.includes_excise,
                has_t1_items,
                has_t2_items,
            )
            lowest_table = pdf.additional_string_table(lowest_table, [], [fiscal_terms])
        }
        const fiscal_representation = get_fiscal_representation(attrs.sales_order)
        if (fiscal_representation.length > 0) {
            lowest_table = pdf.additional_string_table(
                lowest_table,
                fiscal_representation.splice(0, 1),
                fiscal_representation,
            )
        }
    }

    /* Render the totals. */
    const totals: CellDef[][] = [
        [
            pdf.text_cell('Subtotal'),
            pdf.currency_and_amount_cell(attrs.sales_order.was_sold_in, +attrs.sales_order.was_sold_for),
        ],
        [
            pdf.text_cell('VAT'),
            pdf.currency_and_amount_cell(attrs.sales_order.was_sold_in, +attrs.sales_order.vat_total),
        ],
        [
            pdf.text_cell('Total'),
            pdf.currency_and_amount_cell(
                attrs.sales_order.was_sold_in,
                +attrs.sales_order.was_sold_for + +attrs.sales_order.vat_total,
            ),
        ],
    ]
    // @ts-ignore
    pdf.total_amount(main_table, attrs.decimal_locale, totals)

    /* Render the comments, if any. */
    if (attrs.sales_order.invoice_comment) {
        pdf.additional_string_table(lowest_table, ['Comments:'], attrs.sales_order.invoice_comment.split('\n'))
    }

    pdf.payment_terms(iban, has_t1_items, attrs.sales_order.supplier.sales_payment_term.description)

    /* Footer */
    pdf.sales_footer(attrs.account, has_t1_items)
    return doc
}
