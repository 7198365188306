import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'
import {CollectionState} from '@bitstillery/common/lib/collection'
import {proxy} from '@bitstillery/common/lib/proxy'
import {clear_filter, place_filter} from '@bitstillery/common/lib/filters'
import {Button} from '@bitstillery/common/components'
import m from 'mithril'
import {$t, notifier} from '@bitstillery/common/app'

interface CollectionSelectionAttrs {
    className: string
    disabled?: boolean
    model: CollectionState
}

/**
 * This component is meant to provide selection stats next to a
 * CollectionItems view, so it is clear how many selected items there
 * are in that CollectionItems view. Also provide a filter for non-selected
 * Items, where used.
 */
export class CollectionSelection extends MithrilTsxComponent<CollectionSelectionAttrs> {

    data = proxy({
        loading: false,
        toggle: false,
        _selection: () => {},
    })

    oninit(vnode) {
        this.data._selection = () => {
            if (!vnode.attrs.collection) return 0
            if (vnode.attrs.collection.state.selection.all) {
                const total = vnode.attrs.collection.state.total - vnode.attrs.collection.state.selection.ids.length
                return total >= 0 ? total : 0
            } else {
                return vnode.attrs.collection.state.selection.ids.length
            }
        }
    }

    view(vnode:m.Vnode<any>) {
        const collection = vnode.attrs.collection
        const selection = collection.state.selection
        return <div className={classes('c-collection-selection', vnode.attrs.className)}>
            <div className="filter-wrapper">
                {place_filter(vnode.attrs.filter)}
            </div>
            <div className="selection-wrapper">
                <Button
                    active={collection.state.selection.mode === 'select'}
                    disabled={(!collection.state.selection.mode && collection.state.loading)}
                    icon="clipboard-plus-outline"
                    onclick={() => {
                        selection.all = false
                        selection.ids.splice(0, selection.ids.length)
                        if (collection.state.selection.mode === 'select') {
                            collection.state.selection.mode = ''
                        } else {
                            collection.state.selection.mode = 'select'
                        }

                    }}
                    text={$t('collection.include_label')}
                    tip={() => {
                        return $t('collection.include_tip', {count: selection.current.length})
                    }}
                    type="success"
                    variant="context"
                />
                <Button
                    active={selection.mode === 'deselect'}
                    disabled={!selection.current.length || (!collection.state.selection.mode && collection.state.loading)}
                    icon="clipboard-minus-outline"
                    onclick={() => {
                        selection.all = false
                        selection.ids.splice(0, selection.ids.length)
                        if (collection.state.selection.mode === 'deselect') {
                            collection.state.selection.mode = ''
                        } else {
                            collection.state.selection.mode = 'deselect'
                        }
                    }}
                    text={$t('collection.exclude_label')}
                    tip={() => {
                        return $t('collection.exclude_tip', {count: selection.current.length})
                    }}
                    type="warning"
                    variant="context"
                />
            </div>

            <div className="action-wrapper mt-1">
                <Button
                    className="btn-submit"
                    disabled={(() => {
                        if (collection.state.loading) return true
                        if (this.data.loading || selection.mode === '') return true
                        return !collection.selection_count()
                    })()}
                    icon={(() => {
                        if (selection.mode === 'select') {
                            return 'clipboard-plus-outline'
                        } else if (selection.mode === 'deselect') {
                            return 'clipboard-minus-outline'
                        }
                        return 'edit'
                    })()}
                    loading={this.data.loading || collection.state.loading}
                    onclick={async() => {
                        this.data.loading = true
                        // Submit selection and requery afterwards to update the items.
                        await vnode.attrs.on_submit()
                        await Promise.all([collection.reset_query(), collection.transforms.selection()])

                        if (selection.mode === 'select') {
                            notifier.notify('Items were included successfully', 'info')
                        } else {
                            notifier.notify('Items were excluded successfully', 'info')
                        }
                        selection.ids.splice(0, selection.ids.length)
                        // The select/deselect mode has nothing more to do; exit mode.
                        if (
                            (selection.mode === 'select' && collection.state.items.length === 0) ||
                            (selection.mode === 'deselect' && selection.current.length === 0)
                        ) {
                            collection.state.selection.mode = ''
                            clear_filter(vnode.attrs.filter)
                        }

                        this.data.loading = false
                    }}
                    text={(() => {
                        if (selection.mode === 'select') {
                            return $t('collection.include', {count: collection.selection_count()})
                        } else if (selection.mode === 'deselect') {
                            return $t('collection.exclude', {count: collection.selection_count()})
                        }
                        return $t('collection.modify_selection')
                    })()}
                    type={(() => {
                        if (selection.mode === 'select') {
                            return 'success'
                        } else if (selection.mode === 'deselect') {
                            return 'warning'
                        }
                        return 'default'
                    })()}
                />
            </div>
        </div>
    }
}
