import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button} from '@bitstillery/common/components'
import m from 'mithril'
import {$t} from '@bitstillery/common/app'
import {$s, store} from '@bitstillery/common/app'

export class CollectionViewToggle extends MithrilTsxComponent<any> {

    view(vnode:m.Vnode<any>) {
        const collection = vnode.attrs.collection
        const mode = $s.env.layout === 'mobile' ? 'grid' : collection.state.view.mode
        return <Button
            icon={mode === 'list' ? 'list' : 'grid'}
            onclick={() => {
                if (mode === 'list') {
                    collection.state.view.mode = 'grid'
                } else {
                    collection.state.view.mode = 'list'
                }
                store.save()
            }}
            tip={() => {
                if (mode === 'list') {
                    return $t('view.grid_switch')
                }
                return $t('view.list_switch')
            }}
            type="info"
            variant="menu"
        />
    }
}
