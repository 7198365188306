import m from 'mithril'
import {classes, unique_id} from '@bitstillery/common/lib/utils'
import {Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'

export class FieldSwitch extends MithrilTsxComponent<any> {
    name = unique_id()

    constructor(vnode: m.Vnode<any>) {
        super()
        vnode.attrs.props ? this.props = vnode.attrs.props : this.props = {off: false, on: true}
    }

    view(vnode: m.Vnode<any>) {
        const validation = vnode.attrs.validation
        const invalid = validation ? validation._invalid : false
        const disabled = vnode.attrs.disabled
        return (
            <div className={classes('c-field-switch', 'field', `type-${vnode.attrs.type ? vnode.attrs.type : 'default'}`, vnode.attrs.className, {
                disabled: disabled,
                invalid: validation && invalid && validation.dirty,

                valid: !validation || !invalid,
            })}>
                {vnode.attrs.label && (
                    <label>{vnode.attrs.label}
                        {vnode.attrs.icon && <Icon name={vnode.attrs.icon}/>}
                        {vnode.attrs.validation && <span className="validation">{validation.label}</span>}
                    </label>
                )}
                <div className="checkbox-row">
                    <label className="switch" for={this.name}>
                        <input
                            checked={vnode.attrs.ref[0][vnode.attrs.ref[1]] === this.props.on}
                            id={this.name}
                            name={this.name}
                            onchange={() => {
                                if (vnode.attrs.validation) {
                                    vnode.attrs.validation.dirty = true
                                }
                                if (vnode.attrs.ref[0][vnode.attrs.ref[1]] === this.props.on) vnode.attrs.ref[0][vnode.attrs.ref[1]] = this.props.off
                                else vnode.attrs.ref[0][vnode.attrs.ref[1]] = this.props.on
                            }}
                            type="checkbox"
                        />
                        <span class="slider"/>
                    </label>
                </div>
                {(() => {
                    if (invalid && validation.dirty) {
                        return <div className="help validation">{invalid.message}</div>
                    } else if (vnode.attrs.help) {
                        return <div class="help">{vnode.attrs.help}</div>
                    }
                })()}
            </div>
        )
    }
}
