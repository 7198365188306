import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, ButtonGroup, Dialog} from '@bitstillery/common/components'
import {get_route} from '@bitstillery/common/lib/utils'
import {$t, config, store} from '@bitstillery/common/app'

import {$s} from '@/app'

export class DialogOffer extends MithrilTsxComponent<any> {

    onremove() {
        $s.dialog.offer = dayjs().toJSON()
        store.save()
    }

    view(vnode:m.Vnode<any>) {
        return (
            <Dialog
                onclose={vnode.attrs.onclose}
                title={vnode.attrs.offer.offer_portal_popup_title}
                className="c-dialog-offer"
            >
                <img
                    className="popup-image"
                    src={`${config.product_photo_host}/${vnode.attrs.offer.offer_portal_popup_image_s3_key}`}
                    onclick={vnode.attrs.onclick}
                    style={vnode.attrs.onclick ? {cursor: 'pointer'} : null}
                />
                <p>{vnode.attrs.offer.offer_portal_popup_text}</p>
                <div class="modal-actions">
                    <ButtonGroup>
                        <Button
                            onclick={vnode.attrs.onclose}
                            text={$t('offer.popup.continue')}
                            type="default"
                        />
                        <Button
                            onclick={() => {
                                m.route.set(get_route('/offers', {
                                    offer_hash: vnode.attrs.offer.hash,
                                }))
                            }}
                            text={$t('offer.popup.show_offer')}
                            type="success"
                        />
                    </ButtonGroup>
                </div>
            </Dialog>
        )
    }
}
