import m from 'mithril'
import {debounce} from '@bitstillery/common/lib/utils'

const computed_cache = new Map()
const proxy_subscribers = new WeakMap()

export const proxy_stats = {
    proxies: 0,
}

const redraw_frame = debounce(100, function() {
    computed_cache.clear()
    m.redraw()
})

export async function next_tick() {
    return new Promise((resolve) => {
        window.requestAnimationFrame(resolve)
    })
}

export function proxy<T>(obj: T): T {
    const subscribers = new Set() as any

    const base_object = Array.isArray(obj) ? [] : {}
    const result = new Proxy(base_object, {
        deleteProperty(target, prop) {
            if (typeof target === 'object' && !Array.isArray(target) && target !== null) {
                delete target[prop]
                window.requestAnimationFrame(redraw_frame)
            }

            return Reflect.deleteProperty(...arguments)
        },
        get(target, prop) {
            // The returned value of a computed property is
            // cached during the current render cycle.

            if (typeof prop === 'string' && prop[0] === '_') {
                if (typeof target[prop] === 'function') {
                    if (computed_cache.has(target[prop])) {
                        return computed_cache.get(target[prop])
                    } else {
                        const value = target[prop]()
                        computed_cache.set(target[prop], value)
                        return value
                    }
                } else if (typeof target[prop] === 'object') {
                    return target[prop].get()
                }
            }

            return Reflect.get(...arguments)
        },
        ownKeys(target) {
            return Reflect.ownKeys(target).filter(key => key[0] !== '_')
        },
        set(target, prop, value) {
            // Value didn't change; don't process it any further.
            if (target[prop] === value) return true

            if (value !== null && typeof value === 'object' && !proxy_subscribers.has(value)) {
                proxy_stats.proxies += 1
                value = proxy(value)
            }

            if (target[prop] !== null && typeof target[prop] === 'object' && !proxy_subscribers.has(value)) {
                if (target[prop].set) {
                    target[prop].set(value)
                } else {
                    target[prop] = value
                }
            } else {
                target[prop] = value
                window.requestAnimationFrame(redraw_frame)
            }

            // Notify subscribers with the old and the new value,
            // before setting the new value on the target object.
            for (const subscriber of subscribers) {
                subscriber(value, target[prop], prop)
            }

            return true
        },
    }) as any

    // Setup proxy.
    for (const key in obj) {
        result[key] = obj[key]
    }

    proxy_subscribers.set(result, subscribers)
    return result as any
}

export type WatchCallBack<T extends object> = (new_value: T | null, old_value: T | null, subscribeKey?: string) => any

export function subscribe<T extends object>(proxyObj: T, callback: WatchCallBack<T>) {
    if (!proxy_subscribers.has(proxyObj)) {
        throw new Error('proxyObj is not a proxy')
    }
    proxy_subscribers.get(proxyObj).add(callback)
    return () => proxy_subscribers.get(proxyObj).delete(callback)
}

export const key_reference = (obj, keypath) => {
    if (keypath.length === 1) {
        if (!obj || !obj.hasOwn(keypath[0])) {
            return undefined
        }

        // The closest referenceable object.
        let ref = obj
        if (Array.isArray(obj[keypath[0]])) {
            ref = obj[keypath[0]]
        }

        return [ref, keypath[0], obj[keypath[0]]]
    } else {
        if (!obj) {
            return undefined
        }

        return key_reference(obj[keypath[0]], keypath.slice(1))
    }
}

export type type_remove_watch_function = () => void

export const watch = <T extends object>(proxyObj: T, keyOrCallback: WatchCallBack<T> | string, callback?: WatchCallBack<T>): type_remove_watch_function => {
    if (typeof keyOrCallback === 'string') {
        return subscribe(proxyObj, function(newValue, oldValue, subscribeKey) {
            if (keyOrCallback === subscribeKey && callback) callback(newValue, oldValue)
        })
    } else if (typeof keyOrCallback === 'function') {
        return subscribe(proxyObj, keyOrCallback as WatchCallBack<T>)
    }
    return () => {}
}
