let api_host, api_host_new, gtm_id, hostname, product_photo_host, sentry_dsn, sentry_dist, tawk_id

if (process.env.NODE_ENV === 'development') {
    hostname = window.location.hostname

    api_host = '/api/v1'
    api_host_new = '/api/v2'

    gtm_id = ''
    product_photo_host = 'https://photos.staging.v5.springtimegroup.nl'
    sentry_dsn = process.env.MSI_TEST_SENTRY ? process.env.MSI_SENTRY_DSN : ''
    tawk_id = process.env.MSI_TEST_TAWK ? process.env.MSI_TAWK_ID : ''
} else if (['staging', 'production'].includes(process.env.NODE_ENV)) {
    if (process.env.NODE_ENV === 'staging') {
        hostname = 'staging.v5.springtimegroup.nl'
        gtm_id = process.env.MSI_GTM_STAGING
    } else {
        hostname = 'movingspirits.nl'
        gtm_id = process.env.MSI_GTM_PRODUCTION
    }
    api_host = `https://portal-api.${hostname}`
    api_host_new = `https://portal-fact2server-api.${hostname}`
    product_photo_host = `https://photos.${hostname}`
    sentry_dsn = process.env.MSI_SENTRY_DSN
    tawk_id = process.env.MSI_TAWK_ID
    sentry_dist = `${process.env.MSI_SENTRY_DIST}-${process.env.NODE_ENV}` // portal-a2bc-staging or discover-msi-production
}

export const config = {
    account_name: process.env.MSI_TITLE,
    api_host,
    api_host_new,
    api_password: '4Er5HsfLDdmjLHcc',
    api_user: 'ms-portal',
    env: process.env.NODE_ENV,
    hostname,
    gtm_id,
    product_photo_host,
    sentry_dsn,
    sentry_dist,
    tawk_id,
    websiteUrl: process.env.MSI_WEBSITE,
}
