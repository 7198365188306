import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes, unique_id} from '@bitstillery/common/lib/utils'
import {Button, FieldSelect} from '@bitstillery/common/components'

export interface SelectValue {
    value: string,
    label: string,
}

export interface MultiSelectAttrs {
    model: {
        options: SelectValue[], // array of options in object / label
        selection: string[],
        selected: string,
        key_to_list_repr: (key: string) => m.Child
    }

    placeholder?: string
    label?: string
    icon?: string
    disabled?: boolean

    computed?: () => boolean
    ref?: [{}, string]
    help?: string
    translate?: {
        label: boolean
        prefix: string
    }

    className: any
}

export class MultiSelect extends MithrilTsxComponent<MultiSelectAttrs> {

    name = unique_id()

    view(vnode: m.Vnode<MultiSelectAttrs>) {

        const selection = vnode.attrs.model.selection // list of selected values
        const selected = vnode.attrs.model.selected // currently selected in the select
        const options = vnode.attrs.model.options // all possible options in the select
        const selected_option = options.find((it) => it.value === selected)

        const default_key_to_list_representation = (value: string) => {
            const option = options.find((it) => it.value === value)
            return option?.label || value
        }
        const key_to_list_representation = vnode.attrs.model.key_to_list_repr || default_key_to_list_representation

        return (
            <div className={classes('c-multi-select', 'field', vnode.attrs.className, {
                disabled: vnode.attrs.disabled,
            })}>
                <div className={'selector'}>
                    <FieldSelect
                        label={vnode.attrs.label}
                        placeholder={vnode.attrs.placeholder}
                        options={options.map((it) => [it.value, it.label])}
                        ref={[vnode.attrs.model, 'selected']}
                    >
                    </FieldSelect>
                    <Button
                        icon={'plus'}
                        disabled={!selected}
                        onclick={(event) => {
                            // if not yet in selection and something is selected
                            if (!selection.includes(selected) && selected_option) {
                                selection.push(selected)
                            }
                            vnode.attrs.model.selected = ''
                            event.preventDefault()
                        }}
                    />
                </div>
                <div className={'selection-list'}>
                    {selection.map((value) => <div className={'selection-item'}>
                        <div className={'representation'}>
                            {key_to_list_representation(value)}
                        </div>
                        <div className={'actions'}>
                            <Button
                                icon={'trash'}
                                variant={'toggle'}
                                size={'s'}
                                onclick={(event) => {
                                    const index = selection.findIndex((it) => it === value)
                                    if (index >= 0) {
                                        selection.splice(index, 1)
                                    }
                                    event.preventDefault()
                                }}
                            />
                        </div>
                    </div>,
                    )}
                </div>

            </div>
        )
    }
}
