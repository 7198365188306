import m from 'mithril'
import {Tippy} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'

export class Progress extends MithrilTsxComponent<any> {
    view(vn: m.Vnode<any>) {
        return (
            <div className={classes(
                'c-progress',
                `type-${vn.attrs.type ? vn.attrs.type : 'success'}`,
            )}>
                {vn.attrs.label &&
                    <div className="label">
                        {vn.attrs.label}
                    </div>
                }

                <Tippy content={vn.attrs.tip}>
                    <div className="bar-container">
                        <div className="bar" style={`width:${vn.attrs.percentage === 0 ? 1 : vn.attrs.percentage}%`} />
                    </div>
                </Tippy>
                {vn.attrs.boundaries && (
                    <div className="boundaries">
                        <div className="left">
                            {vn.attrs.boundaries[0]}
                        </div>
                        <div className="right">
                            {vn.attrs.boundaries[1]}
                        </div>
                    </div>
                )}
            </div>
        )
    }
}
