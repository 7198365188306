import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {FieldText, Button} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {countries, european_union_countries} from '@bitstillery/common/lib/countries'
import {modelref_adapter} from '@bitstillery/common/lib/store'
import {proxy} from '@bitstillery/common/lib/proxy'
import {$t, api} from '@bitstillery/common/app'

export class FieldVatId extends MithrilTsxComponent<any> {

    data: any

    oninit(vn:m.Vnode<any>) {
        this.data = proxy({
            country_code: vn.attrs.country_code,
            loading: false,
            vies_result: null,
        })

        this.data.country_code = vn.attrs.country_code
    }

    view(vn: m.Vnode<any>) {
        this.data.country_code = vn.attrs.country_code
        const vat_label = $t('validation.vat_id.label', {country: countries[vn.attrs.country_code]})

        const validation = vn.attrs.validation
        if (validation) {
            validation.description = vat_label
        }
        const invalid = validation ? validation._invalid : false
        const disabled = vn.attrs.disabled || this.data.loading
        const {modelvalue} = modelref_adapter(vn.attrs.ref)

        return (
            <div className={classes('c-field-vat-id field-compound', vn.attrs.className, {
                disabled: disabled,
                invalid: validation && invalid && validation.dirty,
                valid: validation && !invalid && validation.dirty,
            })}>
                <FieldText
                    description={vn.attrs.description}
                    disabled={disabled}
                    onafterupdate={() => {
                        this.data.vies_result = null
                    }}
                    help={(() => {
                        if (this.data.vies_result) {
                            return this.data.vies_result
                        }
                        if (european_union_countries.includes(this.data.country_code)) {
                            return $t('validation.vat_id.help_validate', {country: this.data.country_code})
                        }
                        return $t('validation.vat_id.help_disabled', {country: this.data.country_code})

                    })()}
                    label={vat_label}
                    ref={vn.attrs.ref}
                    validation={validation}
                />
                <Button
                    disabled={disabled || invalid}
                    loading={this.data.loading}
                    icon="checkboxMarked"
                    onclick={async(e) => {
                        e.preventDefault()
                        this.data.loading = true
                        const {result} = await api.post('vatid.check', {
                            country_code: vn.attrs.country_code,
                            vat_id: modelvalue,
                        }) as any

                        if (result.valid) {
                            this.data.vies_result = $t('validation.vat_id.vies_match', {match: result.trader_name ? result.trader_name : '?'})
                        } else {
                            this.data.vies_result = $t('validation.vat_id.vies_nomatch')
                        }

                        setTimeout(() => {
                            this.data.vies_result = null
                        }, 5000)

                        this.data.loading = false
                    }}
                    text={$t('validation.vat_id.verify')}
                    type="info"
                />
            </div>
        )
    }
}
