import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {CheckboxGroup, Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {FilterSelectMultipleState} from '@bitstillery/common/types'
import {$t} from '@bitstillery/common/app'

interface FilterSelectMultipleAttrs {
    className: string
    filter_count?: boolean
    filter: FilterSelectMultipleState
    translate?: {
        label: boolean
        prefix: string
    }
}

export class FilterSelectMultiple extends MithrilTsxComponent<FilterSelectMultipleAttrs> {

    count_selected_options(vnode:m.Vnode<FilterSelectMultipleAttrs>) {
        let exclude_ids = []
        for (const selected_option of vnode.attrs.filter.selection) {
            const parent = vnode.attrs.filter.options.find((i) => i[0] === selected_option)
            if (parent && parent.length >= 4) {
                exclude_ids.push.apply(exclude_ids, parent[3].filter((i) => vnode.attrs.filter.selection.includes(i[0])).map((i) => i[0]))
            }
        }

        return vnode.attrs.filter.selection.length - exclude_ids.length
    }

    deactivate_filter(vnode:m.Vnode<FilterSelectMultipleAttrs>) {
        vnode.attrs.filter.selection.splice(0, vnode.attrs.filter.selection.length)
    }

    /**
     * Filter checkbox (sub)options that have a count or selection.
     * @returns Array
     */
    filtered_options(vnode:m.Vnode<FilterSelectMultipleAttrs>) {
        // Make a copy, or filtering the children in the next map will Mithril redraw infinitely.
        let options = JSON.parse(JSON.stringify(vnode.attrs.filter.options))
        return options
            .map((option) => {
                if (vnode.attrs.filter_count && option.length >= 4) {
                    option[3] = option[3].filter((child_option) => {
                        if (child_option.length >= 3 && child_option[2] === 0) {
                            return vnode.attrs.filter.selection.includes(child_option[0])
                        }
                        return true
                    })
                }
                return option
            }).filter((option) => {
                // The whole parent item has no count; remove it unless there
                // is still a selected subitem child.
                if (vnode.attrs.filter_count && option[2] === 0) {
                    if (option.length >= 4) {
                        return option[3].some((child_option) => vnode.attrs.filter.selection.includes(child_option[0]))
                    }
                    return vnode.attrs.filter.selection.includes(option[0])
                }

                return true
            })
    }

    view(vnode:m.Vnode<FilterSelectMultipleAttrs>) {
        const filtered_options = this.filtered_options(vnode)
        return <div className={classes('c-filter-select-multiple', 'filter', vnode.attrs.className, {
            active: vnode.attrs.filter.selection.length,
            collapsed: vnode.attrs.filter.collapsed || vnode.attrs.filter.disabled,
            disabled: filtered_options.length <= 1 || vnode.attrs.filter.disabled,
        })}>
            <div className="title" onclick={() => {
                if (filtered_options.length > 1) {
                    vnode.attrs.filter.collapsed = !vnode.attrs.filter.collapsed
                }
            }}>
                <div class="name">
                    <Icon
                        active={vnode.attrs.filter.selection.length}
                        className="btn-filter-toggle"
                        name={(() => {
                            if (vnode.attrs.filter.icon) return vnode.attrs.filter.icon
                            return vnode.attrs.filter.selection ? 'filterRemove' : 'filterPlus'
                        })()}
                        onclick={(e) => {
                            e.stopPropagation()
                            this.deactivate_filter(vnode)
                        }}
                        tip={() => {
                            return vnode.attrs.filter.selection.length ? $t('filters.actions.deactivate_filter') : $t('filters.inactive_filter')
                        }}
                    />
                    {$t(vnode.attrs.filter.title)}
                    {filtered_options.length === 1 && (
                        <span>&nbsp;({filtered_options[0][1]})</span>
                    )}
                    {(vnode.attrs.filter_count && !!vnode.attrs.filter.selection.length) && <div class="count">
                        ({$t('filters.selected', {
                            count: this.count_selected_options(vnode),
                        })})
                    </div>}
                </div>
                <Icon
                    className="btn-collapse"
                    name={vnode.attrs.filter.collapsed ? 'chevronUp' : 'chevronDown'}
                />
            </div>
            <div class="items">
                {filtered_options.length > 1 &&
                <CheckboxGroup
                    filter_count={vnode.attrs.filter_count}
                    options={filtered_options}
                    selection={vnode.attrs.filter.selection}
                    translate={vnode.attrs.translate}
                />}
            </div>
        </div>
    }
}
