import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {countries, country_codes_lookup} from '@bitstillery/common/lib/countries'
import {ButtonAttrs} from '@bitstillery/common/components/ui/button/button.tsx'
import {random_string} from '@bitstillery/common/ts_utils.ts'
import {tip} from '@bitstillery/common/components'

export interface CountryAttrs {
    country_code?: string
    size?: string
    className?: string
    onclick: () => unknown
    tip?: string | {(): string}
    tip_config?: {}
    type?: 'only_flag' | 'flag_with_country'
}

export class Country extends MithrilTsxComponent<CountryAttrs> {
    tippy:any
    id = random_string(8, false)

    oncreate(vnode: m.Vnode<CountryAttrs>) {

        const tip_config:any = {
            allowHTML: true,
            animation: 'scale',
            appendTo: document.body,
            content: this.update_tip(vnode),
            delay: 500,
            inertia: true,
            interactive: true,
            onTrigger: (instance) => {
                instance.setProps({content: this.update_tip(vnode)})
            },
            touch: 'hold',
            zIndex: 10000000000000000,
        }

        if (vnode.attrs.tip_config) {
            Object.assign(tip_config, vnode.attrs.tip_config)
        }
        if (vnode.attrs.tip) {
            this.tippy = tip(`#${this.id}`, tip_config)
        }
    }

    update_tip(vn: m.Vnode<ButtonAttrs>) {
        if (typeof vn.attrs.tip === 'function') {
            return vn.attrs.tip()
        } else {
            return vn.attrs.tip
        }
    }

    view(vnode: m.Vnode<CountryAttrs>): m.Children {
        let country_code = vnode.attrs.country_code?.toLowerCase() || 'un'
        if (country_code.length > 2) {
            country_code = country_codes_lookup[country_code.toLowerCase()] || country_code
        }

        const country_name = countries[country_code.toUpperCase()] || 'Country not known'
        const country_text = country_name
            ? `${country_name} (${country_code})`
            : vnode.attrs.country_code
        const size = vnode.attrs.size || 's'

        return (
            <div
                onclick={vnode.attrs.onclick}
                title={country_name}
                className={classes('c-country', vnode.attrs.className)}
                id={this.id}
            >
                <div className={classes('fi', `fi-${country_code}`, `icon-${size}`)} />
                {vnode.attrs.type === 'flag_with_country' && <span>{country_text}</span>}
                {vnode.children}
            </div>
        )
    }
}
