import m from 'mithril'
import {classes, unique_id} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import AirDatepicker, {AirDatepickerOptions} from 'air-datepicker'
import localeEn from 'air-datepicker/locale/en'
import {DateTime} from 'luxon'

interface DateModel {
    date: string | null
    to_date?: string
}

interface DatePickerAttrs {
    model?: DateModel
    ref?: [{}, string]
    preset?: 'months' | 'years' | 'days'
    className?: string
    label?: string
    placeholder?: string
    help?: string
    disabled?: boolean
    validation?: any
    date_picker_options?: AirDatepickerOptions
}

export class FieldDate extends MithrilTsxComponent<DatePickerAttrs> {
    preset: 'days' | 'months' | 'years'
    model?: DateModel

    id: string = unique_id(8, false)

    default_date_picker_options: AirDatepickerOptions

    async oncreate(vnode: m.Vnode<DatePickerAttrs>) {
        this.model = vnode.attrs.model
        this.preset = vnode.attrs.preset ?? 'days'

        let start_date = new Date().toISOString()
        if (this.model?.date) {
            start_date = this.model.date
        }
        else if (vnode.attrs.ref) {
            start_date = vnode.attrs.ref[0][vnode.attrs.ref[1]]
        }
        this.default_date_picker_options = {
            autoClose: true,
            locale: localeEn['default'],
            multipleDatesSeparator: ' - ',
            view: this.preset,
            minView: this.preset,
            dateFormat: (() => {
                switch (this.preset) {
                case 'years':
                    return 'yyyy'
                case 'months':
                    return 'MMMM yyyy'
                default:
                    return 'yyyy-MM-dd'
                }
            })(),
            startDate: start_date || new Date().toISOString(),
            onSelect: ({date}:{date: Date | Date[]}) => {
                if (vnode.attrs.validation) {
                    vnode.attrs.validation.dirty = true
                }

                if (this.model) {
                    if (Array.isArray(date)) {
                        this.model.date = DateTime.fromJSDate(date[0]).toISODate()
                        this.model.to_date = DateTime.fromJSDate(date[1]).toISODate() ?? null
                    } else {
                        this.model.date = DateTime.fromJSDate(date).toISODate()
                    }
                }
                else if (vnode.attrs.ref) {
                    vnode.attrs.ref[0][vnode.attrs.ref[1]] = DateTime.fromJSDate(date).toISODate()
                }
            },
        }

        const ad = new AirDatepicker(`#${this.id}`, {
            ...this.default_date_picker_options,
            ...vnode.attrs.date_picker_options,
        })

        // Set the given date(s) in the model to selected
        if (this.model?.date && Date.parse(this.model.date)) {
            ad.selectDate(this.model.date)
        }
        if (vnode.attrs.date_picker_options?.range && this.model?.to_date && Date.parse(this.model.to_date)) {
            ad.selectDate(this.model.to_date)
        }
        if (vnode.attrs.ref && vnode.attrs.ref[0][vnode.attrs.ref[1]] && Date.parse(vnode.attrs.ref[0][vnode.attrs.ref[1]])) {
            ad.selectDate(vnode.attrs.ref[0][vnode.attrs.ref[1]])
        }
    }

    view(vnode: m.Vnode<DatePickerAttrs>) {
        const validation = vnode.attrs.validation
        const invalid = validation ? validation._invalid : false

        return <div className={classes('c-field-date', 'field', vnode.attrs.className, vnode.attrs.preset, {
            disabled: vnode.attrs.disabled,
            invalid: invalid && validation.dirty,
            valid: validation && !invalid && validation.dirty,
        })}>
            {vnode.attrs.label && <label>{vnode.attrs.label}</label>}
            <input
                type="text"
                id={this.id}
                placeholder={vnode.attrs.placeholder ? vnode.attrs.placeholder : 'Select a date...'}
                disabled={vnode.attrs.disabled}
                onkeypress={() => {return false}}
                readonly=""
            />
            {(() => {
                if (invalid && validation.dirty) {
                    return <div className="help validation">{invalid.message}</div>
                } else if (vnode.attrs.help) {
                    return <div class="help">{vnode.attrs.help}</div>
                }
            })()}
        </div>
    }
}
