import m from 'mithril'
import {proxy} from '@bitstillery/common/lib/proxy'
import {MithrilTsxComponent} from 'mithril-tsx-component'

export interface DropdownAttrs {
    ref?: {
        toggle: boolean,
    }
    trigger: 'click' | 'hover'
}

export class Dropdown extends MithrilTsxComponent<DropdownAttrs> {

    data: {
        toggle: boolean
    }

    ref: {
        toggle: boolean
    }

    clickOutside = false

    constructor(vn: m.Vnode<DropdownAttrs>) {
        super()

        if (vn.attrs.ref) {
            this.ref = vn.attrs.ref
            this.data = this.ref
        } else {
            this.data = proxy({toggle: false})
            this.ref = this.data
        }
    }

    add_on_click_outside(vn) {
        const outside_click_listener = (event) => {
            if (!vn.dom.contains(event.target)) {
                this.ref.toggle = false
            } else {
                this.ref.toggle = true
            }
        }

        document.addEventListener('click', outside_click_listener)
        this.remove_on_click_outside = () => {
            document.removeEventListener('click', outside_click_listener)
        }
    }

    class_name(vn, toggle) {
        let classes = ['c-dropdown'].concat(vn.attrs.className ? vn.attrs.className.split(' ') : [])
        if (toggle) classes.push('active')
        return classes.join(' ')
    }

    oncreate(vn) {
        if (vn.attrs.trigger === 'click') {
            if (vn.attrs.clickOutside === undefined) {
                this.clickOutside = true
            } else {
                this.clickOutside = !!vn.attrs.clickOutside
            }
            if (this.clickOutside) {
                this.add_on_click_outside(vn)
            }
        }

        this._className = vn.attrs.className ? vn.attrs.className.split(' ') : []
    }

    onmouseout(vn) {
        if (vn.attrs.trigger === 'hover') {
            this.ref.toggle = false
        }
    }

    onmouseover(vn) {
        if (vn.attrs.trigger === 'hover') {
            this.ref.toggle = true
        }
    }

    onremove(vn) {
        if (vn.attrs.trigger === 'click' && this.clickOutside) {
            this.remove_on_click_outside()
        }
    }

    view(vn) {
        if (vn.attrs.ref) {
            this.ref = vn.attrs.ref
        } else {
            this.ref = this.data
        }
        return <div
            onmouseover={() => {
                this.onmouseover(vn)
            }}
            onmouseout={() => {
                this.onmouseout(vn)
            }}
            key={vn.attrs.key}
            className={this.class_name(vn, this.ref.toggle)}
        >
            {vn.children[0]}
            {vn.children[1]}
        </div>
    }
}
