import {api} from '@bitstillery/common/app'

import {$s} from '@/app'

function location_change() {
    if ($s.identity.token) {
        // If people go to the empty page, set the value to '/' to make it more explicit.
        let page = window.location.hash.replace('#!', '')
        if (!page) {
            page = '/'
        }
        api.post('user.activity', {page})
    }
}

export function init() {
    window.addEventListener('locationchange', function(e) {
        $s.router.url = e.target.location.hash
        location_change()
    })
    // Do an initial activity track on page load.
    location_change()
}
