import m from 'mithril'
import {config, events, logger} from '@bitstillery/common/app'

class GoogleTagManager {
    view() {
        // For browsers disabling scripts.
        return (
            <noscript>
                <iframe
                    src={`https://www.googletagmanager.com/ns.html?id=${config.gtm_id}`}
                    height="0"
                    width="0"
                    style="display:none;visibility:hidden"
                />
            </noscript>
        )
    }
}

export function bind() {
    if (!config.gtm_id) return

    events.on('identity.login', (identity) => {
        logger.debug('[event] identity.login (gtm)')
        window.dataLayer.push({userId: identity.user.id})
    })

    events.on('identity.logout', () => {
        logger.debug('[event] identity.logout')
        window.dataLayer.push({userId: undefined})
    })
}

export function init() {

    if (!config.gtm_id) return

    // I opted to directly enter the snippet, because it's 1) minified, and 2) because it
    // should be easier to to maintain this over a translation in Livescript.
    const gtm_script = document.createElement('script')
    gtm_script.text = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${config.gtm_id}');
    `
    // Inject gtm script before the first script.
    const first_script = document.getElementsByTagName('script')[0]
    first_script.parentNode.insertBefore(gtm_script, first_script)

    window.dataLayer = window.dataLayer || []

    m.mount(document.getElementById('gtm'), GoogleTagManager)

}
