import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, ButtonGroup, Dialog, FieldText} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {and, email, required, validation} from '@bitstillery/common/lib/validation'
import {$t, api, notifier} from '@bitstillery/common/app'

import {$m, $s} from '@/app'
import {UpdatePortalProfileRequest} from '@/factserver_api/fact2server_api.ts'

export class MyPortalProfile extends MithrilTsxComponent<any> {

    data = proxy({
        deleting: false,
        deleting_success: false,
        update_profile_request: {
            mobile_phone_number: $s.identity.user.mobile_phone_number,
            price_preference: $s.identity.user.price_preference,
            language: $s.identity.user.language,
            phone_number: $s.identity.user.phone_number,
            email_address: $s.identity.user.email_address,
            first_name: $s.identity.user.first_name,
            last_name: $s.identity.user.last_name,
        } as UpdatePortalProfileRequest,
        delete_account_request: {
            email_address: '',
            name: '',
        },
    })

    $v = {
        email_address: validation([this.data.update_profile_request, 'email_address'], and([required(), email()])),
        last_name: validation([this.data.update_profile_request, 'last_name'], required()),
        first_name: validation([this.data.update_profile_request, 'first_name'], required()),
    }

    async request_removal() {
        const data = {
            new_contact_person_contactdata: this.data.delete_account_request.email_address,
            new_contact_person_name: this.data.delete_account_request.name,
        }

        const {success} = await api.post('user.send_deletion_request_email', data)
        if (success) {
            this.data.deleting = false
            this.data.deleting_success = true
        }
    }

    async update_profile() {
        const {response} = await api.post('portal/user/profile', this.data.update_profile_request, true)
        if (!response.ok) {
            notifier.notify($t('my_portal.settings.saved_profile_error'), 'warning')
            return
        }
        $m.identity.refresh_token()
        notifier.notify($t('my_portal.settings.saved_profile'), 'info')
    }

    view(_vn:m.Vnode<any>) {
        const invalid = this.$v.email_address._invalid || this.$v.first_name._invalid || this.$v.last_name._invalid
        return (
            <div className="c-my-portal-profile form">
                {this.data.deleting && <Dialog
                    onclose={() => this.data.deleting = false}
                    title={$t('profile.removal_title')}
                >
                    <h3>{$t('profile.removal_notice')}</h3>
                    <p>{$t('profile.removal_description_1')}</p>
                    <p>{$t('profile.removal_description_2')}</p>

                    <div className="form">
                        <FieldText label={$t('profile.replacement.name')} ref={[this.data.delete_account_request, 'name']}/>
                        <FieldText label={$t('profile.replacement.contact')} ref={[this.data.delete_account_request, 'email_address']}/>
                    </div>

                    <p>{$t('profile.removal_questions', {
                        email: $s.identity.user.sales_manager.email_address,
                        email_vendor: process.env.MSI_THEME === 'msp' ? 'info@movingspirits.nl' : 'info@a2bc.com',
                        manager: $s.identity.user.sales_manager.name,
                        phone: $s.identity.user.sales_manager.phone_number,
                    })}</p>
                    <ButtonGroup>
                        <Button icon="cancel" type="default" text={$t('profile.removal.cancel')} onclick={()=> this.data.deleting = false}/>
                        <Button icon="trash" type="danger" text={$t('profile.removal.confirm')} onclick={this.request_removal.bind(this)}/>
                    </ButtonGroup>
                </Dialog>}

                {this.data.deleting_success &&
                    <Dialog title={$t('profile.removal.ack')} onclose={() => this.data.deleting_success = false}>
                        <div className="form-horizontal">
                            <p>
                                {$t('profile.removal.ack_description_1')}
                                {$t('profile.removal.ack_description_2', {
                                    email: $s.identity.user.sales_manager.email_address,
                                    email_vendor: process.env.MSI_THEME === 'msp' ? 'info@movingspirits.nl' : 'info@a2bc.com',
                                    manager: $s.identity.user.sales_manager.name,
                                    phone: $s.identity.user.sales_manager.phone_number,
                                })}
                            </p>
                            <ButtonGroup>
                                <Button
                                    icon="cancel"
                                    onclick={() => this.data.deleting_success = false}
                                    text={$t('profile.removal.close_window')}
                                    type="default"
                                />
                            </ButtonGroup>
                        </div>
                    </Dialog>
                }
                <div className="fieldset">
                    <FieldText
                        disabled={true}
                        label={$t('profile.company')}
                        ref={[$s.identity.user, 'relation_name']}
                    />
                    <div className="field-group">
                        <FieldText
                            label={$t('profile.first_name')}
                            ref={[this.data.update_profile_request, 'first_name']}
                            validation={this.$v.first_name}
                        />
                        <FieldText
                            label={$t('profile.family_name')}
                            ref={[this.data.update_profile_request, 'last_name']}
                            validation={this.$v.last_name}
                        />
                    </div>
                    <FieldText
                        label={$t('profile.phone_number')}
                        ref={[this.data.update_profile_request, 'phone_number']}
                    />
                    <FieldText
                        label={$t('profile.mobile_phone_number')}
                        ref={[this.data.update_profile_request, 'mobile_phone_number']}
                    />
                    <FieldText
                        label={$t('profile.email')}
                        ref={[this.data.update_profile_request, 'email_address']}
                        validation={this.$v.email_address}
                    />
                    <Button
                        className="btn-removal"
                        icon="profileCancel"
                        type="danger"
                        text={$t('profile.request_removal')}
                        onclick={() => this.data.deleting = true}
                        variant="link"
                        disabled={$s.identity.demo}
                    />
                </div>
                <Button
                    className="btn-submit"
                    icon="save"
                    type="success"
                    text={$t('profile.save_button')}
                    onclick={this.update_profile.bind(this)}
                    disabled={$s.identity.demo || invalid}
                />
            </div>
        )
    }
}
