import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {active_filters, reset_filters} from '@bitstillery/common/lib/filters'
import m from 'mithril'
import {$t} from '@bitstillery/common/app'

interface CollectionFilterControlAttrs {
    className: string
    collection: any
}

export class CollectionFilterControl extends MithrilTsxComponent<CollectionFilterControlAttrs> {

    view(vnode:m.Vnode<CollectionFilterControlAttrs>) {
        if (!vnode.attrs.collection.state.ready) return null

        const filters = vnode.attrs.collection.filters
        let all_collapsed = Object.values(filters).every((filter) => filter.collapsed)
        let filters_active = active_filters(filters)

        return <div className={classes('c-collection-filter-control', vnode.attrs.className)}>
            <Button
                icon={all_collapsed ? 'filterCollapsed' : 'filterCollapse'}
                onclick={() => {
                    for (const filter of Object.values(filters)) {
                        filter.collapsed = !all_collapsed
                    }
                }}
                tip={() => {
                    all_collapsed = Object.values(filters).every((filter) => filter.collapsed)
                    if (all_collapsed) {
                        return $t('filters.expand_tip')
                    }
                }}
                type="info"
                variant='toggle'
            />
            <Button
                active={filters_active > 0}
                disabled={!filters_active}
                icon={'filterRemove'}
                onclick={() => {
                    reset_filters(filters)
                }}
                tip={() => {
                    filters_active = active_filters(filters)
                    if (filters_active) return $t('filters.reset', {count: filters_active})
                    return $t('filters.reset_disabled')
                }}
                type={filters_active ? 'brand' : 'info'}
                variant='toggle'
            >
                {filters_active > 0 && <div className="amount">
                    {filters_active}
                </div>}
            </Button>
        </div>
    }
}
