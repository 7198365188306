import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {CURRENCY_SYMBOLS, format_money} from '@bitstillery/common/lib/format'

export class Changed extends MithrilTsxComponent<any> {

    view(vnode:m.Vnode<any>) {

        return (
            <div className={classes('c-changed', `type-${vnode.attrs.type ? vnode.attrs.type : 'brand'}`)}>
                {(vnode.attrs.previous !== vnode.attrs.current) &&
                <span className="previous">
                    {format_money(vnode.attrs.previous)}
                </span>}
                <span className="current">
                    {!!vnode.attrs.currency && `${CURRENCY_SYMBOLS[vnode.attrs.currency]} `}
                    {format_money(vnode.attrs.current)}
                </span>
            </div>
        )
    }
}
